var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-row',{staticClass:"shop-attributes-cover"},[_c('f7-col',[(_vm.$root.docCurrentFood)?_c('f7-swiper',{staticClass:"shop-attributes-cover-swiper",attrs:{"id":"shop-attributes-swiper","params":{
        init: true,
        effect: 'slide',
        loop: false,
        autoplay: false,
      }}},[_c('f7-swiper-slide',{staticClass:"shop-attributes-cover-swiper-slide"},[_c('f7-row',{staticClass:"container-image-responsive",style:({ 'padding-top': '100%' })},[(_vm.$root.docCurrentFood.imagen_principal)?_c('div',{staticClass:"image-responsive",style:({
              'background-position': 'center center',
              'background-image':
                'url(\'' +
                'https://' +
                _vm.$root.serverHostBase +
                encodeURI(_vm.$root.docCurrentFood.imagen_principal) +
                '\')',
            })}):_c('div',{staticClass:"image-responsive-food",style:({
              'background-position': 'center center',
              'background-image':
                'url(\'' +
                encodeURI(_vm.$root.docLocal.vista_previa_logo_pagina_principal) +
                '\')',
            })})])],1)],1):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"navigation-back-black",on:{"click":_vm.navigateToLocal}},[_c('i',{staticClass:"fas fa-arrow-left navigation-back-black-icon"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }