import { render, staticRenderFns } from "./local-loader-menu.vue?vue&type=template&id=0d7de27c&"
import script from "./local-loader-menu.vue?vue&type=script&lang=js&"
export * from "./local-loader-menu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports