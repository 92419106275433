<template id="map-categories-sheet">
  <f7-sheet
    class="categories-sheet"
    :closeByBackdropClick="false"
    :backdrop="false"
    :opened="props.categoriesSheetOpened"
    @sheet:closed="props.categoriesSheetOpened = false"
  >
    <f7-page-content class="categories-sheet-content">
      <f7-row class="categories-sheet-suggestions-row">
        <div
          v-if="props.itemsFiltered.length > 0 || props.searchQuery === ''"
          class="col categories-sheet-suggestion"
        >
          Sugerencias
        </div>
        <div v-else class="col categories-sheet-suggestion">
          No Hay Sugerencias
        </div>
      </f7-row>

      <span v-if="props.searchQuery === ''">
        <span
          @click="selectResult"
          v-for="(item, index) in props.listCategories"
          :key="index"
          :data-result-name="item.name"
          class="badge badge-categories-map"
        >
          {{ item.name }}
        </span>
      </span>

      <span v-if="props.searchQuery !== ''">
        <span
          @click="selectResult"
          v-for="(itemFiltered, index) in props.itemsFiltered"
          :key="index"
          :data-result-name="itemFiltered"
          class="badge badge-categories-map"
        >
          {{ itemFiltered }}
        </span>
      </span>

      <f7-row v-if="!props.emptyQueryMap" class="map-empty-search">
        <div class="col">
          <f7-button @click="searchInMap" class="btn-rounded btn-search-in-map"
            >Buscar</f7-button
          >
        </div>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import utils from "../../utils.js";
import map from "../../map.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    selectResult(e) {
      const self = this;
      map.selectResult(self, e);
    },
    searchInMap() {
      const self = this;
      map.searchInMap(self);
    },
  },
};
</script>
