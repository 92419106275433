<template id="shop-cart">
  <f7-page
    name="shop-cart"
    @page:reinit="onPageReinit"
    @page:afterout="onPageAfterOut"
  >
    <fullscreenLoader :props="props" />

    <f7-block class="shop-cart-main-block">
      <!-- VOLVER A LA CARTA -->
      <cartBackToLocal :props="props" />

      <!-- BARRA DE NAVEGACION -->
      <cartNavBar :props="props" />

      <!-- ITEMS DEL CARRITO -->
      <cartOrderList :props="props" />

      <!-- SUCURSAL -->
      <cartBranches :props="props" />

      <!-- FORMA DE ENTREGA -->
      <cartShippingMethods :props="props" />

      <!-- FORMA DE ENTREGA -->
      <cartShippingMapSheet :props="props" />

      <!-- FORMA DE PAGO -->
      <cartPaymentMethods :props="props" />

      <!-- CAMPOS PERSONALIZABLES -->
      <cartCustomFields :props="props" />

      <!-- CUPONES -->
      <cartCoupons :props="props" />

      <!-- DATOS DEL CLIENTE -->
      <cartCustomerDataSheet :props="props" />

      <!-- POPUP CHECKOUT -->
      <cartCheckoutPopup :props="props" />

      <!-- POPUP MIS DIRECCIONES -->
      <cartMySavedLocationsSheet :props="props" />
    </f7-block>

    <f7-block
      class="total-cart-block-under"
      v-if="$root.confirmedCart.length > 0"
    >
    </f7-block>

    <cartTotals :props="props" />
  </f7-page>
</template>

<script>
import utils from "../utils.js";
import cart from "../cart.js";
import fullscreenLoader from "../components/fullscreen-loader.vue";
import cartBackToLocal from "../components/cart/cart-back-to-local.vue";
import cartNavBar from "../components/cart/cart-navbar.vue";
import cartOrderList from "../components/cart/cart-order-list.vue";
import cartBranches from "../components/cart/cart-branches.vue";
import cartCoupons from "../components/cart/cart-coupons.vue";
import cartPaymentMethods from "../components/cart/cart-payment-methods.vue";
import cartShippingMethods from "../components/cart/cart-shipping-methods.vue";
import cartCustomFields from "../components/cart/cart-custom-fields.vue";
import cartShippingMapSheet from "../components/cart/cart-shipping-map-sheet.vue";
import cartTotals from "../components/cart/cart-totals.vue";
import cartCustomerDataSheet from "../components/cart/cart-customer-data-sheet.vue";
import cartCheckoutPopup from "../components/cart/cart-checkout-popup.vue";
import cartMySavedLocationsSheet from "../components/cart/cart-my-saved-locations-sheet.vue";

export default {
  components: {
    fullscreenLoader,
    cartBackToLocal,
    cartNavBar,
    cartOrderList,
    cartBranches,
    cartCoupons,
    cartPaymentMethods,
    cartShippingMethods,
    cartCustomFields,
    cartShippingMapSheet,
    cartTotals,
    cartCustomerDataSheet,
    cartCheckoutPopup,
    cartMySavedLocationsSheet
  },
  data: function () {
    return {
      props: {
        loading: false,
        cartDetailCollapsed: false,
        cartCustomerDataSheetOpened: false,
        vlData: {
          items: [],
        },
        totalAdicionales: 0.0,
        sucursal: false,
        items: [],
        cartShippingMapSheetOpened: false,
        cartCheckoutPopupOpened: false,
        cartCheckoutMPLinkReady: false,
        confirmCartButtonClicked: false,
        enableSubmitOrder: false,
        continueWithOrder: true,
        confirmCartCustomerDataButtonClicked: false,
        cartMySavedLocationsSheetOpened: false,
        direccionGuardadaIndexLocal: undefined,
        mySavedLocationsLs: [],
        endTimeOrder: 0,
        endTimeOrderPlus: 0,
        // metodoEnvio: "",
        // tipoMetodoEnvio: "",
        // nombreRetira: "",
        // horaRetira: "",
        // nombreRecibe: "",
        // direccionRecibe: "",
        // mesa: "",
        // nombreMesa: "",
        // metodoPago: "",
        // tipoMetodoPago: "",
        // efectivoPagaCon: 0.0,
      },
    };
  },
  mounted: function () {
    const self = this;
    if (self.$root.docLocal === undefined) {
      self.props.loading = true;
      window.location.replace("https://" + window.location.host);
    } else {
      let lsCartConfirmed = JSON.parse(
        window.localStorage.getItem("confirmed_cart")
      );
      const lsCartConfirmedArray = Object.values(lsCartConfirmed);

      if (lsCartConfirmedArray > 0) {
        let virtualListCart = self.$f7.virtualList.get(".virtual-list-cart");
        // TODO: Debe validar todo en el servidor
        virtualListCart.replaceAllItems(lsCartConfirmedArray);
      }
      cart.calculateGandTotal(self);
      // cart.updatePricesCheckout(self);

      if (self.$root.docLocal.sucursales.length === 0) {
        self.props.sucursal = self.$root.docLocal.nombre_local;
      } else {
        self.props.sucursal = false;
      }
    }
  },
  updated: function () {
    const self = this;
  },
  methods: {
    onPageReinit() {
      const self = this;
      cart.calculateGandTotal(self);
      // cart.updatePricesCheckout(self);
    },
    onPageAfterOut() {
      const self = this;
      let existSheet = self.$f7.sheet.get("#cart-shipping-map-sheet");

      if (existSheet) {
        existSheet.el.remove();
      }
    },
  },
};
</script>
