<template>
  <f7-list-item
    accordion-item
    :id="'accordionIx' + indexLevel1"
    class="accordeon-list-item"
    @accordion:opened="scrollToAccordion(indexLevel1)"
  >
    <!-- :title="item.name_category" -->
    <div slot="title" class="title-category-container">
      <span style="white-space: normal;">{{ item.name_category }}</span>
      <span
        style="font-size: 12px; font-weight: 400; margin-top: -2.5px; white-space: normal;"
        >{{ item.descripcion }}</span
      >
    </div>
    <!-- CONTENIDO DE CATEGORIA -->
    <f7-accordion-content
      v-for="(subitem, indexLevel2) in item.data"
      :key="indexLevel2"
      v-bind:style="{
        'box-shadow':
          subitem.doctype === 'Categoria Comida'
            ? '0px 0px 15px -5px #555'
            : 'none',
      }"
    >
      <!-- SUBCATEGORIA DE PLATO -->
      <menuListSubcategory
        v-if="subitem.doctype === 'Categoria Comida'"
        :props="props"
        :subitem="subitem"
      />

      <!-- PLATO -->
      <menuListItem
        v-else-if="subitem.doctype === 'Plato'"
        :props="props"
        :subitem="subitem"
      />
    </f7-accordion-content>
  </f7-list-item>
</template>

<script>
import local from "../../../local.js";
import menuListItem from "./menu-list-item.vue";
import menuListSubcategory from "./menu-list-subcategory.vue";

export default {
  components: {
    menuListItem,
    menuListSubcategory,
  },
  props: {
    props: Object,
    item: Object,
    indexLevel1: Number,
  },
  methods: {
    scrollToAccordion(index) {
      local.scrollToAccordion(this, index);
    },
  },
};
</script>
