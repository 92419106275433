var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-sheet',{staticStyle:{"max-height":"80vh"},attrs:{"id":"cart-ongoing-order-sheet","backdrop":"","swipeToClose":false,"closeByBackdropClick":false,"closeByOutsideClick":false,"opened":_vm.$root.cartOngoingOrderSheetOpened},on:{"sheet:closed":_vm.onCartOngoingOrderSheetClosed,"sheet:opened":_vm.onCartOngoingOrderSheetOpened}},[_c('span',{staticClass:"close-cart-shipping-map-sheet-container",on:{"click":_vm.onCartOngoingOrderSheetClose}},[_c('i',{staticClass:"fas fa-times close-cart-shipping-map-sheet sheet-close-button"})]),_vm._v(" "),_c('f7-page-content',{staticStyle:{"border-radius":"10px 10px 0 0","max-height":"80vh","overflow":"scroll"}},[_c('span',{on:{"click":_vm.whatsappOrderChat}},[_c('i',{staticClass:"fab fa-whatsapp check-order-tracking-whatsapp"})]),_vm._v(" "),_c('f7-block',[_c('f7-block-title',{staticClass:"text-align-center no-margin-bottom",attrs:{"medium":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Tu Pedido")])]),_vm._v(" "),_c('p',{staticClass:"text-align-center no-margin-top"},[_vm._v("\n        "+_vm._s(_vm.$root.ongoingOrder.numero_pedido)+"\n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),(
          !['Anulado', 'Rechazado', 'Cierre Automático'].includes(
            _vm.$root.ongoingOrder.estado
          ) &&
            _vm.props.orderProgress.progress >= 20 &&
            _vm.props.orderProgress.progress < 100 &&
            _vm.$root.ongoingOrder.demora_pedido > 0
        )?_c('f7-row',{staticClass:"margin-bottom-half"},[_c('span',[_vm._v("Entrega Aproximada\n          "),_c('strong',[_vm._v(_vm._s(_vm.props.endTimeOrder)+" - "+_vm._s(_vm.props.endTimeOrderPlus))])])]):_vm._e(),_vm._v(" "),(
          ['Anulado', 'Rechazado', 'Cierre Automático'].includes(
            _vm.$root.ongoingOrder.estado
          )
        )?_c('f7-row',[_c('f7-col',[_c('f7-progressbar',{staticClass:"cart-progressbar-rejected",attrs:{"progress":100}})],1)],1):_c('f7-row',[_c('f7-col',[_c('f7-progressbar',{class:_vm.props.orderProgress.progress >= 20
                ? 'cart-progressbar-completed'
                : 'cart-progressbar-uncompleted',attrs:{"infinite":_vm.props.orderProgress.progress === 20,"progress":100}})],1),_vm._v(" "),_c('f7-col',[_c('f7-progressbar',{class:_vm.props.orderProgress.progress >= 40
                ? 'cart-progressbar-completed'
                : 'cart-progressbar-uncompleted',attrs:{"infinite":_vm.props.orderProgress.progress === 40,"progress":100}})],1),_vm._v(" "),_c('f7-col',[_c('f7-progressbar',{class:_vm.props.orderProgress.progress >= 60
                ? 'cart-progressbar-completed'
                : 'cart-progressbar-uncompleted',attrs:{"infinite":_vm.props.orderProgress.progress === 60,"progress":100}})],1),_vm._v(" "),_c('f7-col',[_c('f7-progressbar',{class:_vm.props.orderProgress.progress >= 80
                ? 'cart-progressbar-completed'
                : 'cart-progressbar-uncompleted',attrs:{"infinite":_vm.props.orderProgress.progress === 80,"progress":100}})],1),_vm._v(" "),_c('f7-col',[_c('f7-progressbar',{class:_vm.props.orderProgress.progress >= 100
                ? 'cart-progressbar-completed'
                : 'cart-progressbar-uncompleted',attrs:{"progress":100}})],1)],1),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.props.orderProgress.text))])]),_vm._v(" "),(
          ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
            _vm.$root.ongoingOrder.estado
          )
        )?_c('f7-block',{staticClass:"no-padding-horizontal"},[_c('cartOngoingOrderList',{attrs:{"props":_vm.props}})],1):_vm._e(),_vm._v(" "),_c('cartOngoingOrderPayment',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('f7-block',{staticClass:"no-padding-horizontal no-margin-vertical"},[_c('cartOngoingOrderTotals',{attrs:{"props":_vm.props}})],1),_vm._v(" "),(
          ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
            _vm.$root.ongoingOrder.estado
          )
        )?_c('f7-block',{staticClass:"no-padding-horizontal no-margin-vertical"},[(
            ['Aceptado', 'En Preparación', 'Enviado'].includes(
              _vm.$root.ongoingOrder.estado
            )
          )?_c('f7-row',[_c('f7-col',{on:{"click":_vm.orderDeliveredByClient}},[_c('span',{staticClass:"badge badge-hacer-pedido no-margin-top"},[_c('i',{staticClass:"fa fa-check"}),_vm._v("  \n              "),_c('span',[_vm._v("YA LO RECIBÍ")])])])],1):_c('f7-row',[_c('f7-col',[_c('span',{staticClass:"badge badge-hacer-pedido badge-disabled no-margin-top"},[_c('i',{staticClass:"fa fa-check"}),_vm._v("  \n              "),_c('span',[_vm._v("YA LO RECIBÍ")])])])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }