<template>
  <f7-page name="map-page">
    <f7-block class="map-navbar">
      <f7-row>
        <div @click="navBack" class="col-15 map-button">
          <f7-link>
            <i data-eva="arrow-back-outline" data-eva-fill="#444444"></i>
          </f7-link>
        </div>
        <div class="col-70">
          <f7-searchbar
            v-if="$props.nameFromLocalPage === undefined"
            class="searchbar-map"
            placeholder="Buscar"
            :disable-button="!$theme.aurora"
            :clear-button="false"
            @input="searchAllMap"
          >
          </f7-searchbar>
        </div>
        <div @click="myLocation" class="col-15 map-button">
          <f7-link>
            <i data-eva="navigation-outline" data-eva-fill="#444444"></i>
          </f7-link>
        </div>
      </f7-row>
    </f7-block>

    <f7-block v-show="props.showLocals == true" class="map-block-swiper">
      <f7-row>
        <f7-col>
          <f7-swiper
            id="map-locals-swiper"
            :params="{
              init: true,
              effect: 'slide',
              slidesPerView: slidesPerView,
              spaceBetween: 10,
            }"
          >
            <f7-swiper-slide
              class="map-swiper-slide"
              v-for="(local, index) in props.locals"
              :key="index"
              :data-lat="local.latitud"
              :data-long="local.longitud"
            >
              <f7-row @click="navigateToLocalFromMapSwiper(local.name)">
                <div class="col-30 map-row-swiper-slide-row">
                  <div
                    class="map-row-swiper-slide-row-logo"
                    v-if="local.vista_previa_logo_pagina_principal"
                    v-bind:style="{
                      'background-image':
                        'url(\'' +
                        local.vista_previa_logo_pagina_principal +
                        '\')',
                    }"
                  ></div>

                  <div
                    class="map-row-swiper-slide-row-logo"
                    v-else
                    v-bind:style="{
                      'background-image':
                        'url(/static/images/Alacarta_Logo.png)',
                    }"
                  ></div>
                  <!-- <span v-if="gpsEnabled" class="badge badge-distancia"
                    >{{ local.distancia_en_km }} Kms</span
                  > -->
                </div>

                <div class="col-70 map-row-swiper-slide-row-description">
                  <span class="map-row-swiper-slide-row-title">{{
                    local.nombre_local
                  }}</span>
                  <span class="map-row-swiper-slide-row-address"
                    >{{ local.direccion_1 }}
                    <span v-if="local.direccion_2">-</span>
                    {{ local.direccion_2 }}</span
                  >
                  <span class="map-row-swiper-slide-row-address"
                    >({{ local.codigo_postal }}) - {{ local.ciudad }}</span
                  >
                  <span
                    v-if="local.latitud && local.longitud"
                    class="badge badge-distancia no-margin-left"
                    style="width: 100px"
                    ><a
                      class="external"
                      :href="
                        'https://www.google.com/maps/dir//' +
                        local.latitud +
                        ',' +
                        local.longitud
                      "
                      >Navegar</a
                    ></span
                  >
                </div>
              </f7-row>
            </f7-swiper-slide>
          </f7-swiper>
        </f7-col>
      </f7-row>
    </f7-block>

    <mapCategoriesSheet :props="props" />
    <div id="map-locals" class="map-container"></div>
    <!-- <div id="maprm" class="map-rm-container"></div> -->
  </f7-page>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import map from "../map.js";
import mapCategoriesSheet from "../components/map/map-categories-sheet.vue";

export default {
  props: ["nameFromLocalPage"],
  components: {
    mapCategoriesSheet,
  },
  data: function () {
    return {
      props: {
        geocoder: undefined,
        map: undefined,
        markers: [],
        categoriesSheetOpened: false,
        itemsFiltered: [],
        searchQuery: "",
        emptyQueryMap: true,
        locals: [],
        showLocals: false,
        // nameFromLocalPage: undefined,
      },
      listCategories: [],
      listLocals: [],
      localsSwiper: undefined,
      geoLat: "",
      geoLng: "",
      slidesPerView: 1,
      gpsEnabled: false,
      draggedMap: false,
      // categoriesSheetOpened: false,
      // itemsFiltered: [],
      // searchQuery: '',
      // emptyQuery: true,
    };
  },
  // beforeMount: function () {
  //   const self = this;
  // },
  mounted: function () {
    const self = this;

    // No permite la carga del mapa desde la url /map/
    if (self.$root.appType === "Local" && self.$root.docLocal === undefined) {
      self.props.loading = true;
      window.location.replace("https://" + window.location.host);
    } else if (!self.$root.appType && !self.$root.ciudad) {
      self.props.loading = true;
      window.location.replace("https://" + window.location.host);
    }

    // (function() {
    //   if (window.history && window.history.pushState) {
    //     self.$$(window).on("popstate", function(e) {
    //       setTimeout(function() {
    //         self.$root.changeMap = true;
    //       }, 0);
    //     });
    //   }
    // })();

    map.loadLocalCategories(self);
    map.loadAllLocals(self);
    map.onMapMounted(self, 'map-locals');

    eva.replace();
  },
  updated: function () {
    eva.replace();
  },
  methods: {
    navBack() {
      const self = this;
      self.$f7router.back();
    },
    searchAllMap(sb) {
      const self = this;
      map.searchAllMap(self, sb);
    },
    newLoadLocals() {
      const self = this;
      map.loadLocals(self, null, "Buscando locales");
    },
    myLocation() {
      const self = this;
      map.myLocation(self);
    },
    navigateToLocalFromMapSwiper(localName) {
      const self = this;
      map.navigateToLocalFromMapSwiper(self, localName);
    },
  },
};
</script>
