var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-margin no-padding"},[(
      _vm.$root.docCurrentFood &&
      _vm.$root.listFoodType === 'Salon' &&
      _vm.$root.docCurrentFood.atributos_del_plato &&
      _vm.$root.docCurrentFood.atributos_del_plato.length > 0 &&
      _vm.$root.docCurrentFood.atributos_del_plato.some(
        function (atributo) { return atributo.habilitado === 1; }
      )
    )?_c('f7-row',{staticClass:"shop-attributes-title-section"},[_c('f7-col',[_c('span',[_vm._v("VARIANTES")])])],1):_vm._e(),_vm._v(" "),(
      _vm.$root.docCurrentFood &&
      _vm.$root.listFoodType === 'Delivery' &&
      _vm.$root.docCurrentFood.atributos_del_plato_delivery &&
      _vm.$root.docCurrentFood.atributos_del_plato_delivery.length > 0 &&
      _vm.$root.docCurrentFood.atributos_del_plato_delivery.some(
        function (atributo) { return atributo.habilitado_delivery === 1; }
      )
    )?_c('f7-row',{staticClass:"shop-attributes-title-section"},[_c('f7-col',[_c('span',[_vm._v("VARIANTES")])])],1):_vm._e(),_vm._v(" "),(_vm.$root.listFoodType === 'Salon')?_c('span',_vm._l((_vm.$root.docCurrentFood.atributos_del_plato),function(atributo,index){return _c('f7-row',{key:index,staticClass:"shop-attributes-attribute-selection"},[(atributo.habilitado === 1)?_c('f7-col',{staticClass:"shop-attributes-attribute-title",attrs:{"width":"65"}},[_vm._v("\n        "+_vm._s(atributo.nombre_atributo)+"\n        "),_c('br'),_vm._v(" "),_c('span',{class:parseFloat(atributo.precio_rebajado) > 0
              ? 'shop-sale-price'
              : 'shop-normal-price'},[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(atributo.precio))))]),_vm._v("\n          \n        "),_vm._v(" "),(parseFloat(atributo.precio_rebajado) > 0)?_c('span',{staticClass:"shop-normal-price"},[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(atributo.precio_rebajado))))]):_vm._e()]):_vm._e(),_vm._v(" "),(atributo.habilitado === 1)?_c('f7-col',{staticClass:"shop-attributes-attribute-qty",attrs:{"width":"35"}},[(atributo.sin_stock === 0)?_c('f7-stepper',{attrs:{"small":"","round":"","fill":"","value":atributo.cantidad,"buttons-only":false,"data-attribute-index":index},on:{"change":_vm.changeQtyAttributes}}):(atributo.sin_stock === 1)?_c('span',{staticStyle:{"color":"#a9a9a9"}},[_vm._v("Sin Stock")]):_vm._e()],1):_vm._e()],1)}),1):_vm._e(),_vm._v(" "),(_vm.$root.listFoodType === 'Delivery')?_c('span',_vm._l((_vm.$root.docCurrentFood
        .atributos_del_plato_delivery),function(atributo,index){return _c('f7-row',{key:index,staticClass:"shop-attributes-attribute-selection"},[(atributo.habilitado_delivery === 1)?_c('f7-col',{staticClass:"shop-attributes-attribute-title",attrs:{"width":"65"}},[_vm._v("\n        "+_vm._s(atributo.nombre_atributo_delivery)+"\n        "),_c('br'),_vm._v(" "),_c('span',{class:parseFloat(atributo.precio_rebajado_delivery) > 0
              ? 'shop-sale-price'
              : 'shop-normal-price'},[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(atributo.precio_delivery))))]),_vm._v("\n          \n        "),_vm._v(" "),(parseFloat(atributo.precio_rebajado_delivery) > 0)?_c('span',{staticClass:"shop-normal-price"},[_vm._v("$\n          "+_vm._s(_vm.formatCurrency(parseFloat(atributo.precio_rebajado_delivery))))]):_vm._e()]):_vm._e(),_vm._v(" "),(atributo.habilitado_delivery === 1)?_c('f7-col',{staticClass:"shop-attributes-attribute-qty",attrs:{"width":"35"}},[(atributo.sin_stock_delivery === 0)?_c('f7-stepper',{attrs:{"small":"","round":"","fill":"","value":atributo.cantidad,"data-attribute-index":index},on:{"change":_vm.changeQtyAttributes}}):(atributo.sin_stock_delivery === 1)?_c('span',{staticStyle:{"color":"#a9a9a9"}},[_vm._v("Sin Stock")]):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }