<template>
  <div clss="no-margin no-padding">
    <f7-row
      class="shop-attributes-attribute-selection"
      style="margin-top: 20px"
    >
      <f7-col width="50">
        <span style="font-weight: 600">Total</span>
      </f7-col>
      <f7-col
        width="50"
        style="text-align: right; font-weight: 800; font-size: 18px"
      >
        <span>$ {{ formatCurrency(parseFloat($root.totalCart)) }}</span>
      </f7-col>
    </f7-row>

    <f7-row class="shop-attributes-attribute-selection">
      <f7-col>
        <span style="margin-top: 5px; display: block">
          <!-- Boton de Pedido -->
          <span
            v-show="!props.disableAddToCartButton && !props.loadingAddToCartButton"
            :class="'badge badge-hacer-pedido'"
            @click="addFoodToCart"
          >
            <!-- <span v-if="['AGUARDE'].includes($root.textButtonShopAttributes)">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;</span
            > -->
            {{ $root.textButtonShopAttributes }}
          </span>

          <!-- Boton de Carga -->
          <span
            v-show="props.loadingAddToCartButton"
            class="badge badge-hacer-pedido badge-disabled"
          >
            <!-- <span v-if="['AGUARDE'].includes($root.textButtonShopAttributes)">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;</span
            > -->
            <i class="fas fa-spinner fa-spin"></i>
          </span>
        </span>

          <!-- Boton Deshabilitado -->
          <span
            v-show="props.disableAddToCartButton && !props.loadingAddToCartButton"
            class="badge badge-hacer-pedido badge-disabled"
          >
            <!-- <span v-if="['AGUARDE'].includes($root.textButtonShopAttributes)">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;</span
            > -->
            {{ $root.textButtonShopAttributes }}
          </span>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    addFoodToCart() {
      const self = this;
      // self.$root.textButtonShopAttributes = "AGUARDE";
      utils.checkTotals(self).then((r) => {
        if (self.props.disableAddToCartButton === false) {
          self.addNotaDelPlato();
          // When the item is modified from the cart
          if (self.$root.currentFoodCartState === "draft_cart") {
            let currentFoodName =
              self.$root.docCurrentFood.name_rand ||
              self.$root.docCurrentFood.name;
            self.confirmAddFoodToCart(self, currentFoodName, currentFoodName);
          } else {
            let currentFoodName = self.$root.docCurrentFood.name;
            let currentFoodNameRand =
              self.$root.docCurrentFood.name +
              "_" +
              Math.random().toString(36).substr(2, 9);
            self.confirmAddFoodToCart(
              self,
              currentFoodName,
              currentFoodNameRand
            );
          }
        }
      });
    },
    addNotaDelPlato() {
      const self = this;

      let lsFoodCart = JSON.parse(
        window.localStorage.getItem(self.$root.currentFoodCartState)
      );
      let itemName =
        self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      let lsFoodCurrentFood = lsFoodCart[itemName];

      lsFoodCurrentFood["nota_del_plato"] = self.props.notaDelPlato;
      window.localStorage.setItem(
        self.$root.currentFoodCartState,
        JSON.stringify(lsFoodCart)
      );

      self.props.notaDelPlato = "";
    },
    async confirmAddFoodToCart(self, currentFoodName, currentFoodNameRand) {
      let lsFoodCart = await utils.asyncLocalStorage.getItem(
        self.$root.currentFoodCartState
      );
      let lsFoodConfirmedCart = await utils.asyncLocalStorage.getItem(
        "confirmed_cart"
      );

      lsFoodConfirmedCart[currentFoodNameRand] = {};
      lsFoodConfirmedCart[currentFoodNameRand]["name_rand"] =
        currentFoodNameRand;

      Object.assign(
        lsFoodConfirmedCart[currentFoodNameRand],
        lsFoodCart[currentFoodName]
      );

      delete lsFoodCart[currentFoodName];

      await utils.asyncLocalStorage.setItem(
        "confirmed_cart",
        JSON.stringify(lsFoodConfirmedCart)
      );
      await utils.asyncLocalStorage.setItem(
        self.$root.currentFoodCartState,
        JSON.stringify(lsFoodCart)
      );

      if (!self.$root.confirmedCart.includes(currentFoodNameRand)) {
        self.$root.confirmedCart.push(currentFoodNameRand);
      }

      await utils.asyncLocalStorage.setItem(
        "food_local",
        self.$root.docLocal.nombre_local
      );

      //////////////////////////////////

      // await new Promise((resolve) => {
      //   let lsFoodCart = JSON.parse(
      //     window.localStorage.getItem(self.$root.currentFoodCartState)
      //   );
      //   let lsFoodConfirmedCart = JSON.parse(
      //     window.localStorage.getItem("confirmed_cart")
      //   );

      //   lsFoodConfirmedCart[currentFoodNameRand] = {};
      //   lsFoodConfirmedCart[currentFoodNameRand]["name_rand"] =
      //     currentFoodNameRand;
      //   // let lsFoodCurrentFood = lsFoodCart[currentFoodName];
      //   Object.assign(
      //     lsFoodConfirmedCart[currentFoodNameRand],
      //     lsFoodCart[currentFoodName]
      //   );

      //   window.localStorage.setItem(
      //     "confirmed_cart",
      //     JSON.stringify(lsFoodConfirmedCart)
      //   );
      //   delete lsFoodCart[currentFoodName];
      //   window.localStorage.setItem(
      //     self.$root.currentFoodCartState,
      //     JSON.stringify(lsFoodCart)
      //   );

      //   // if (!self.$root.confirmedCart.includes(currentFoodName)) {
      //   //   self.$root.confirmedCart.push(currentFoodName);
      //   // }
      //   if (!self.$root.confirmedCart.includes(currentFoodNameRand)) {
      //     self.$root.confirmedCart.push(currentFoodNameRand);
      //   }

      //   window.localStorage.setItem(
      //     "food_local",
      //     self.$root.docLocal.nombre_local
      //   );

      //   setTimeout(resolve(), 500);
      // });

      ///////////////////////////////////

      self.props.totalAttributes = 0;
      self.props.totalFoodQty = 0;
      self.$root.totalCart = 0;
      self.$root.docCurrentFood = [];
      self.$root.currentFoodCartState = "";
      self.$root.updateCart = true;
      self.$f7router.navigate("/local/" + self.$root.docLocal.name + "/", {
        ignoreCache: false,
      });
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
