var customRequest = async function (
  self,
  callback_fn,
  endpoint,
  method,
  data,
  preloader = null,
  callback_err = null
) {
  self.$f7.request({
    url:
      self.$root.scheme +
      self.$root.serverHostBase +
      self.$root.host +
      endpoint,
    async: true,
    method: method,
    headers: {
      Authorization: "token af9bb4db720c003:93effc1416c4f46", // Administrator
      // 'Authorization': 'token 836c1aaa52fc274:48a2ed8bcf3411b' // Development
      // 'Authorization': 'token 5e2c81f1213b468:271b8e6564accc5' // Production
    },
    contentType: "application/json",
    data: data,
    dataType: "json",
    beforeSend: function (hxr) {
      if (preloader != null) {
        if (preloader != "") {
          self.$f7.dialog.preloader(preloader);
        }
        self.loading = true;
      }
    },
    success: function (e) {
      callback_fn(e);

      if (preloader != null) {
        if (preloader != "") {
          self.$f7.dialog.close();
        }
        self.loading = false;
      }
    },
    error: function (e) {
      if (callback_err != null) {
        callback_err(e);
      }
    },
  });
};

var isEmpty = function (element) {
  if (element === "") {
    return true;
  } else if (element === undefined) {
    return true;
  } else if (element === null) {
    return true;
  } else {
    return false;
  }
};

var toastOffline = function (app) {
  var toastBottom = app.toast.create({
    text: "Su dispositivo no tiene conexión",
    position: "bottom",
    // closeTimeout: 3000,
    cssClass: "toast-error",
  });
  toastBottom.open();
};

var toastOnline = function (app) {
  var toastBottom = app.toast.create({
    text: "Su dispositivo tiene conexión",
    position: "bottom",
    closeTimeout: 3000,
    cssClass: "toast-success",
  });
  toastBottom.open();
};

var checkConnectiviy = function (self) {
  document.addEventListener(
    "online",
    function () {
      if (JSON.parse(window.localStorage.getItem("online")) === false) {
        self.params.online = true;
        window.localStorage.setItem("online", true);

        self.toast.close();
        toastOnline(self);

        self.router.navigate(self.view.current.router.url, {
          ignoreCache: true,
          reloadCurrent: true,
        });
      }
    },
    false
  );

  document.addEventListener(
    "offline",
    function () {
      self.params.online = false;
      window.localStorage.setItem("online", false);

      toastOffline(self);
    },
    false
  );
};

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

var toast = function (self, message, status, timeout = 3000) {
  var renderToast = self.$f7.toast.create({
    text: message,
    position: "top",
    closeTimeout: timeout,
    cssClass: status,
  });
  renderToast.open();
};

var customToast = function (self, message, status, position, timeout = 3000) {
  var renderToast = self.$f7.toast.create({
    text: message,
    position: position,
    closeTimeout: timeout,
    cssClass: status,
  });
  renderToast.open();
};

var enterKeySubmit = function (self) {
  if (event.keyCode === 13) {
    event.preventDefault();
    // self.$$();
  }
};

const checkTotals = async (self) => {
  let currentCartState = self.$root.currentFoodCartState;
  let lsFoodCart = JSON.parse(window.localStorage.getItem(currentCartState));
  let lsFoodCurrentFood =
    lsFoodCart[self.$root.docCurrentFood.name_rand] ||
    lsFoodCart[self.$root.docCurrentFood.name];
  let totalCart = 0.0;
  let totalAtributos = 0;
  // let subtotalAdicionales = 0.0;
  // let foodAttributesType;

  // Calculo de Cantidad Total
  await new Promise((resolve) => {
    // Calculo del Total de Atributos
    if (self.$root.listFoodType === "Salon") {
      if (lsFoodCurrentFood.atributos_del_plato.length === 0) {
        resolve();
      }

      lsFoodCurrentFood.atributos_del_plato.forEach((atributo, index) => {
        if ("cantidad" in atributo && atributo.cantidad > 0) {
          totalAtributos += atributo.cantidad;
          atributo["total_atributo"] = atributo.cantidad;
          if ("precio_rebajado" in atributo && atributo.precio_rebajado > 0) {
            totalCart +=
              atributo.cantidad * parseFloat(atributo.precio_rebajado);
            atributo["total_atributo"] =
              atributo.cantidad * parseFloat(atributo.precio_rebajado);
          } else {
            totalCart += atributo.cantidad * parseFloat(atributo.precio);
            atributo["total_atributo"] =
              atributo.cantidad * parseFloat(atributo.precio);
          }
        }

        if (lsFoodCurrentFood.atributos_del_plato.length === index + 1) {
          resolve();
        }
      });
    } else if (self.$root.listFoodType === "Delivery") {
      if (lsFoodCurrentFood.atributos_del_plato_delivery.length === 0) {
        resolve();
      }

      lsFoodCurrentFood.atributos_del_plato_delivery.forEach(
        (atributo, index) => {
          if ("cantidad" in atributo && atributo.cantidad > 0) {
            totalAtributos += atributo.cantidad;
            atributo["total_atributo"] = atributo.cantidad;
            if (
              "precio_rebajado_delivery" in atributo &&
              atributo.precio_rebajado_delivery > 0
            ) {
              totalCart +=
                atributo.cantidad *
                parseFloat(atributo.precio_rebajado_delivery);
              atributo["total_atributo"] =
                atributo.cantidad *
                parseFloat(atributo.precio_rebajado_delivery);
            } else {
              totalCart +=
                atributo.cantidad * parseFloat(atributo.precio_delivery);
              atributo["total_atributo"] =
                atributo.cantidad * parseFloat(atributo.precio_delivery);
            }
          }

          if (
            lsFoodCurrentFood.atributos_del_plato_delivery.length ===
            index + 1
          ) {
            resolve();
          }
        }
      );
    }
  });

  self.props.totalAttributes = totalAtributos;

  let totalCategoriasAdicionalesObligatorios = 0;
  let totalCategoriasAdicionalesUtilizadas = 0;

  await new Promise((resolve) => {
    // Calculo del Total de Adicionales
    if (lsFoodCurrentFood.adicionales.length === 0) {
      resolve();
    }

    lsFoodCurrentFood.adicionales.forEach((categoria_adicional, index) => {
      if (categoria_adicional.obligatorio === 1) {
        totalCategoriasAdicionalesObligatorios++;
      }

      if (
        categoria_adicional.obligatorio === 1 &&
        categoria_adicional.cantidad_seleccionada > 0
      ) {
        totalCategoriasAdicionalesUtilizadas++;
      }

      categoria_adicional.data.forEach((adicional, index) => {
        if ("precio" in adicional && adicional.precio > 0) {
          // totalCart += adicional.cantidad_seleccionada * parseFloat(adicional.precio);
          totalCart += parseFloat(adicional.total);
        }
      });

      if (lsFoodCurrentFood.adicionales.length === index + 1) {
        if (
          totalCategoriasAdicionalesObligatorios ===
          totalCategoriasAdicionalesUtilizadas
        ) {
          self.props.totalAdditionalsCompleted = true;
        } else {
          self.props.totalAdditionalsCompleted = false;
        }
      }

      if (lsFoodCurrentFood.adicionales.length === index + 1) {
        resolve();
      }
    });
  });

  totalCart += await new Promise((resolve) => {
    // Calculo del Total del Plato (Sin Atributos)
    if (self.$root.listFoodType === "Salon") {
      if (
        "precio_rebajado" in lsFoodCurrentFood &&
        lsFoodCurrentFood.precio_rebajado > 0
      ) {
        // totalCart +=
        resolve(
          (lsFoodCurrentFood.cantidad || 0) *
          parseFloat(lsFoodCurrentFood.precio_rebajado)
        );
        // resolve();
      } else {
        // totalCart +=
        resolve(
          (lsFoodCurrentFood.cantidad || 0) *
          parseFloat(lsFoodCurrentFood.precio)
        );
        // resolve();
      }
    } else if (self.$root.listFoodType === "Delivery") {
      if (
        "precio_rebajado_delivery" in lsFoodCurrentFood &&
        lsFoodCurrentFood.precio_rebajado_delivery > 0
      ) {
        // totalCart +=
        resolve(
          (lsFoodCurrentFood.cantidad || 0) *
          parseFloat(lsFoodCurrentFood.precio_rebajado_delivery)
        );
        // resolve();
      } else {
        // totalCart +=
        resolve(
          (lsFoodCurrentFood.cantidad || 0) *
          parseFloat(lsFoodCurrentFood.precio_delivery)
        );
        // resolve();
      }
    }
  });

  self.$root.totalCart = totalCart;
  lsFoodCurrentFood["total_plato"] = totalCart;
  lsFoodCurrentFood["cantidad"] = await self.$root.docCurrentFood.cantidad;
  await new Promise((resolve, reject) => {
    let data = window.localStorage.setItem(
      currentCartState,
      JSON.stringify(lsFoodCart)
    );
    resolve();
  });
  self.$root.docCurrentFood = await lsFoodCurrentFood;
  self.props.totalFoodQty = (await lsFoodCurrentFood.cantidad) || 0;
  self.props.loading = false;

  await disableAddToCartButtonComputed(self);

  return;
};

let navigateTo = (self, url, ignoreCache = false) => {
  self.$root.updateCart = true;
  self.$f7router.navigate(url, { ignoreCache: ignoreCache });
};

let disableAddToCartButtonComputed = function (self) {
  return new Promise((resolve, reject) => {
    if (self.props.listAditionalsSelected.length > 0) {
      if (self.props.totalAdditionalsCompleted === true) {
        if (self.props.totalAttributes > 0 || self.props.totalFoodQty > 0) {
          self.props.disableAddToCartButton = false;
          resolve();
        } else if (
          self.props.totalAttributes === 0 &&
          self.props.totalFoodQty === 0
        ) {
          self.props.disableAddToCartButton = true;
          resolve();
        } else {
          resolve();
        }
      } else {
        self.props.disableAddToCartButton = true;
        resolve();
      }
    } else if (self.props.listAditionalsSelected.length === 0) {
      if (self.props.totalAttributes === 0 && self.props.totalFoodQty === 0) {
        self.props.disableAddToCartButton = true;
        resolve();
      } else {
        self.props.disableAddToCartButton = false;
        resolve();
      }
    } else {
      resolve();
    }
  });
};

let formatCurrency = function (value) {
  var formattedCurrency = (Math.round(value * 100) / 100)
    .toFixed(2)
    .replace(/[.,]00$/, "");
  return formattedCurrency;
};

let getRandomString = function (length) {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return JSON.parse(localStorage.getItem(key));
  },
};

const registerApiCost = (self, apiService, functionName, functionFile) => {
  customRequest(
    self,
    () => { },
    "utils.register_api_cost",
    "POST",
    {
      servicio_api: apiService,
      cantidad_consumida: 1,
      local: self?.$root?.docLocal?.name || self?.$root?.ciudad,
    },
    null
  );
};

const getSubdomain = () => {
  const hostParts = window.location.hostname.split('.');
  if (hostParts.length > 2) {
    return hostParts[0];
  }
  return ''; // Default to 'main' if no subdomain
}

export default {
  customRequest,
  // hostBase,
  // host,
  // hostRaw,
  isEmpty,
  toastOnline,
  toastOffline,
  checkConnectiviy,
  create_UUID,
  toast,
  customToast,
  enterKeySubmit,
  checkTotals,
  navigateTo,
  disableAddToCartButtonComputed,
  formatCurrency,
  getRandomString,
  asyncLocalStorage,
  registerApiCost,
  getSubdomain,
};
