<template>
  <div class="no-margin no-padding">
    <f7-block class="quote-section no-margin padding-vertical">
      <f7-row>
        <div class="col-15"></div>

        <div class="col-100 medium-70">
          <h1 class="section-title">Nueva Plataforma</h1>
          <p class="cover-text">
            Luego de varios meses de desarrollo lanzamos esta
            <span style="font-weight: 600">nueva versión</span> totalmente
            renovada, respaldada por un
            <span style="font-weight: 600">estudio de mercado</span> realizado
            junto a nuestros clientes actuales y mas de 250 clientes de
            restaurante/bar llegando a este resultado: una interfaz clara e
            intuitiva con una estética muy cuidada y
            <span style="font-weight: 600">gran potencial</span> para continuar
            incorporando funcionalidades.
          </p>
        </div>

        <div class="col-15"></div>
        <div></div>
      </f7-row>
    </f7-block>

    <f7-block class="quote-section no-margin padding"
      ><f7-row>
        <div class="col-25"></div>
        <div class="col-100 medium-50">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/VXc4oXqzVu0?controls=0&rel=0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-25"></div>
      </f7-row>
    </f7-block>

    <f7-block class="quote-section no-margin padding">
      <f7-row>
        <div class="medium-20"></div>
        <div class="col-100 medium-30 margin-bottom">
          <div v-show="desktop" class="counter-title">Ya son</div>
          <div id="counter-number" class="counter-number">
            + {{ formatCurrency(count) }}
          </div>
          <div class="counter-title">gastronómicos</div>
        </div>
        <div class="col-100 medium-30 margin-bottom">
          <div v-show="desktop" class="counter-title">Procesando</div>
          <div id="counter-number" class="counter-number">
            + {{ formatCurrency(countOrders) }}
          </div>
          <div class="counter-title">pedidos mensuales</div>
        </div>
        <div class="medium-20"></div>
      </f7-row>
    </f7-block>

    <f7-block class="quote-section no-margin padding" style="margin: 0 auto">
      <f7-row>
        <div class="col-100 medium-50" style="margin: 0 auto">
          <div class="cta-discount">
            <span
              >Primer mes bonificado para los próximos 300 nuevos clientes</span
            >
            <f7-button
              class="cta-button-black"
              @click="scrollToAnchor('#contactanos')"
              fill
              raise
              >CONTACTANOS</f7-button
            >
          </div>
        </div>
      </f7-row>
    </f7-block>
  </div>
</template>

<script>
export default {
  props: {
    count: Number,
    countOrders: Number,
    desktop: Boolean
  },
  methods: {
    scrollToAnchor: function (id_element) {
      document.querySelector(id_element).scrollIntoView({ behavior: "smooth" });
    },
    formatCurrency(number) {
      var num_parts = number.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return num_parts.join(".");
    },
  },
};
</script>
