<template>
  <f7-row v-if="$root.docCurrentFood">
    <!-- PRECIO GLOBAL | SALON -->
    <f7-col
      v-if="
        $root.docCurrentFood &&
          $root.listFoodType === 'Salon' &&
          parseFloat($root.docCurrentFood.precio) > 0
      "
      class="shop-global-price"
    >
      <!-- PRECIO GLOBAL | NORMAL | CON DECIMAL | SALON -->
      <span v-if="parseFloat($root.docCurrentFood.precio) % 1 !== 0">
        <span
          :class="
            parseFloat($root.docCurrentFood.precio_rebajado) > 0
              ? 'shop-sale-global-price'
              : 'shop-normal-global-price'
          "
          >$ {{ formatCurrency(parseFloat($root.docCurrentFood.precio)) }}</span
        >
        &nbsp;&nbsp;
      </span>

      <!-- PRECIO GLOBAL | NORMAL | SIN DECIMAL | SALON -->
      <span v-if="parseFloat($root.docCurrentFood.precio) % 1 === 0">
        <span
          :class="
            parseFloat($root.docCurrentFood.precio_rebajado) > 0
              ? 'shop-sale-global-price'
              : 'shop-normal-global-price'
          "
          >$ {{ formatCurrency(parseFloat($root.docCurrentFood.precio)) }}</span
        >
        &nbsp;&nbsp;
      </span>

      <!-- PRECIO GLOBAL | REBAJADO | CON DECIMAL  | SALON -->
      <span v-if="parseFloat($root.docCurrentFood.precio) % 1 !== 0">
        <span
          class="shop-normal-global-price"
          v-if="parseFloat($root.docCurrentFood.precio_rebajado) > 0"
          >$
          {{
            formatCurrency(parseFloat($root.docCurrentFood.precio_rebajado))
          }}</span
        >
      </span>

      <!-- PRECIO GLOBAL | REBAJADO | CON DECIMAL  | SALON -->
      <span v-if="parseFloat($root.docCurrentFood.precio) % 1 === 0">
        <span
          class="shop-normal-global-price"
          v-if="parseFloat($root.docCurrentFood.precio_rebajado) > 0"
          >$
          {{
            formatCurrency(parseFloat($root.docCurrentFood.precio_rebajado))
          }}</span
        >
      </span>
    </f7-col>

    <!-- PRECIO GLOBAL | DELIVERY -->
    <f7-col
      class="shop-global-price"
      v-else-if="
        $root.docCurrentFood &&
          $root.listFoodType === 'Delivery' &&
          parseFloat($root.docCurrentFood.precio_delivery) > 0
      "
    >
      <!-- PRECIO GLOBAL NORMAL | DELIVERY -->
      <span
        :class="
          parseFloat($root.docCurrentFood.precio_rebajado_delivery) > 0
            ? 'shop-sale-global-price'
            : 'shop-normal-global-price'
        "
        >$
        {{
          formatCurrency(parseFloat($root.docCurrentFood.precio_delivery))
        }}</span
      >
      &nbsp;&nbsp;
      <!-- PRECIO GLOBAL REBAJADO | DELIVERY -->
      <span
        class="shop-normal-global-price"
        v-if="parseFloat($root.docCurrentFood.precio_rebajado_delivery) > 0"
        >$
        {{
          formatCurrency(
            parseFloat($root.docCurrentFood.precio_rebajado_delivery)
          )
        }}</span
      >
    </f7-col>
  </f7-row>
</template>

<script>
import utils from "../../utils.js";

export default {
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
