var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-row',{staticClass:"local-cover"},[_c('f7-col',[(
        _vm.$root.docLocal &&
          _vm.$root.docLocal.imagenes_de_slider &&
          _vm.$root.docLocal.imagenes_de_slider.length > 0
      )?_c('f7-swiper',{staticClass:"local-cover-swiper",attrs:{"id":"locals-swiper","params":{
        // init: false,
        // effect: 'slide',
        // loop: props.loopSlides,
        // autoplay: props.autoplaySlides,
        // speed: 2000,
      }}},_vm._l((_vm.$root.docLocal.imagenes_de_slider),function(slider,index){return _c('f7-swiper-slide',{key:index,staticClass:"local-cover-swiper-slide"},[_c('f7-row',{staticClass:"container-image-responsive",style:({ 'padding-top': '56.25%' })},[_c('div',{staticClass:"image-responsive",style:({
              'background-image':
                'url(\'' +
                'https://' +
                _vm.$root.serverHostBase +
                '/' +
                encodeURI(slider.imagen_recortada) +
                '\')',
            })})])],1)}),1):_c('div',{staticClass:"local-cover-placeholder-image",style:({
        'background-image': 'url(/static/images/Alacarta_Logo.png)',
      })}),_vm._v(" "),_c('f7-row',{staticClass:"local-cover-badges"},[(_vm.$root.docLocal.mostrar_moneda)?_c('span',[_c('span',{staticClass:"badge badge-carta notranslate"},[_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"aria-hidden":"true"}}),_vm._v("\n            "+_vm._s(_vm.$root.docLocal.moneda)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('span',[(_vm.$root.listFoodType === '')?_c('span',{staticClass:"badge",on:{"click":function($event){_vm.props.SelectionMenuSheetOpened = true}}},[_vm._v("\n          Elija una Carta\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.listFoodType !== '' && _vm.$root.listFoodName !== ''
          )?_c('span',{staticClass:"badge badge-carta",on:{"click":_vm.openSelectionCartaSheet}},[_c('i',{staticClass:"fas fa-utensils",attrs:{"aria-hidden":"true"}}),_vm._v("\n            "+_vm._s(_vm.$root.listFoodName)+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.$root.docLocal.mostrar_horarios)?_c('span',[(_vm.$root.estadoHorarioLocal === 'Abierto')?_c('span',{staticClass:"badge badge-abierto",on:{"click":function($event){_vm.props.openingHoursSheetOpened = true}}},[_c('i',{staticClass:"fas fa-clock",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Abierto\n        ")]):(_vm.$root.estadoHorarioLocal === 'Cerrado')?_c('span',{staticClass:"badge badge-cerrado",on:{"click":function($event){_vm.props.openingHoursSheetOpened = true}}},[_c('i',{staticClass:"fas fa-clock",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Cerrado\n        ")]):_c('span',{staticClass:"badge badge-carta",on:{"click":function($event){_vm.props.openingHoursSheetOpened = true}}},[_c('i',{staticClass:"fas fa-clock",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Horarios\n        ")])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }