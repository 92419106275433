<template id="fullscreen-loader">
  <!-- LOADER A PANTALLA COMPLETA -->
  <span
    v-if="
      $root.loading === true || props.loading === true ||
        $root.changeMap === true ||
        $root.maintenanceMode === true
    "
    class="fullscreen-loader-container"
  >
    <div class="col fullscreen-loader">
      <div class="fullscreen-loader-image-container">
        <div
          v-if="$root.maintenanceMode === true"
          class="margin-bottom"
          v-bind:style="{
            'background-image':
              'url(/static/images/Alacarta_Logo_Transparent.png)',
            'background-position': 'left center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            height: '15vh',
            width: '100%',
            'border-radius': '5px',
            // 'margin-left': '50%',
            // transform: 'translate(-25%, 0)',
            filter: 'grayscale(2) opacity(0.25)',
          }"
        ></div>
        <img
          v-if="$root.maintenanceMode === false"
          src="static/images/loader-alacarta.gif"
          class="fullscreen-loader-image"
          height="200px"
        />
        <div v-if="$root.maintenanceMode === true">
          <h3 class="">
            Estamos actualizando nuestra plataforma
          </h3>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function() {
    const self = this;
  },
  methods: {},
};
</script>
