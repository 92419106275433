var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{directives:[{name:"show",rawName:"v-show",value:(
    !_vm.$root.changeMap &&
      _vm.$root.loadingFood === false &&
      _vm.props.loading === false &&
      _vm.$root.listFoodSelected.length > 0
  ),expression:"\n    !$root.changeMap &&\n      $root.loadingFood === false &&\n      props.loading === false &&\n      $root.listFoodSelected.length > 0\n  "}],staticClass:"local-carta-block",style:({
    'background-image':
      'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(' +
      encodeURI(_vm.$root.docLocal.vista_previa_imagen_fondo_cabecera) +
      ')',
  })},[_c('f7-list',{staticClass:"local-carta-accordeons no-margin",attrs:{"accordion-list":""}},[_c('ul',_vm._l((_vm.$root.listFoodSelected),function(item,indexLevel1){return _c('menuListCategory',{key:indexLevel1,attrs:{"props":_vm.props,"item":item,"indexLevel1":indexLevel1}})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }