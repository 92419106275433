<template>
  <f7-block
    class="total-ongoing-order-block"
    :style="
      $root.docLocal && $root.docLocal.habilitar_traduccion_automatica
        ? 'margin-bottom: 75px;'
        : ''
    "
  >
    <!-- FINALIZAR PEDIDO -->
    <!-- SUBTOTAL -->
    <f7-row
      v-if="
        parseFloat($root.ongoingOrder.recargo_envio).toFixed(2) > 0.0 ||
        parseFloat($root.ongoingOrder.recargo_pago).toFixed(2) !== 0.0
      "
      class="totals-cart-row"
    >
      <f7-col width="60">
        <span style="font-weight: 600">Subtotal</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span
          >$ {{ formatCurrency(parseFloat($root.ongoingOrder.subtotal)) }}</span
        >
      </f7-col>
    </f7-row>
    <!-- COSTO DE ENVIO -->
    <f7-row
      class="totals-cart-row"
      v-if="parseFloat($root.ongoingOrder.recargo_envio) > 0"
    >
      <f7-col width="60">
        <span style="font-weight: 600">Costo de Envío</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span
          >$
          {{
            formatCurrency(parseFloat($root.ongoingOrder.recargo_envio))
          }}</span
        >
      </f7-col>
    </f7-row>
    <!-- RECARGO / BONIFICACION PAGO -->
    <f7-row
      class="totals-cart-row"
      v-if="parseFloat($root.ongoingOrder.recargo_pago) !== 0.0"
    >
      <f7-col width="60">
        <span
          v-if="parseFloat($root.ongoingOrder.recargo_pago) > 0.0"
          style="font-weight: 600"
          >Recargo de Pago</span
        >
        <span
          v-if="parseFloat($root.ongoingOrder.recargo_pago) < 0.0"
          style="font-weight: 600"
          >Descuento por Pago</span
        >
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Importe Fijo'">
          $ {{ formatCurrency(parseFloat($root.ongoingOrder.recargo_pago)) }}
        </span> -->
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Porcentaje del Subtotal'">
          $
          {{
            formatCurrency(
              parseFloat($root.ongoingOrder.total) *
                (parseFloat($root.ongoingOrder.recargo_pago) / 100)
            )
          }}
        </span> -->
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Porcentaje del Total'">
          $
          {{
            formatCurrency(
              (parseFloat($root.ongoingOrder.total) +
                parseFloat($root.ongoingOrder.recargo_envio)) *
                (parseFloat($root.ongoingOrder.recargo_pago) / 100)
            )
          }}
        </span> -->
        <span>
          $
          {{ formatCurrency(parseFloat($root.ongoingOrder.recargo_pago)) }}
        </span>
      </f7-col>
    </f7-row>
    <!-- CUPON DE DESCUENTO -->
    <f7-row
      class="totals-cart-row"
      v-if="parseFloat($root.ongoingOrder.importe_cupon) < 0.0"
    >
      <f7-col width="60">
        <span style="font-weight: 600">Cupón de Descuento</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span>
          $
          {{ formatCurrency(parseFloat($root.ongoingOrder.importe_cupon)) }}
        </span>
      </f7-col>
    </f7-row>
    <!-- TOTAL DEL PEDIDO -->
    <f7-row class="totals-cart-row">
      <f7-col width="60">
        <span style="font-weight: 600">Total del Pedido</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 800; font-size: 18px"
      >
        <!-- <span v-if="$root.ongoingOrder.recargo_envio > 0.0 || $root.ongoingOrder.recargo_pago !== 0.0"
          >$ -->
        <!-- IMPORTE FIJO -->
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Importe Fijo'">
            {{
              formatCurrency(
                parseFloat($root.ongoingOrder.total) +
                  parseFloat($root.ongoingOrder.recargo_envio) +
                  parseFloat($root.ongoingOrder.recargo_pago) +
                  parseFloat($root.ongoingOrder.importe_cupon)
              )
            }}
          </span> -->
        <!-- PORCENTAJE DEL SUBTOTAL -->
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Porcentaje del Subtotal'">
            {{
              formatCurrency(
                parseFloat($root.ongoingOrder.total) +
                  parseFloat($root.ongoingOrder.recargo_envio) +
                  parseFloat($root.ongoingOrder.total) *
                    (parseFloat($root.ongoingOrder.recargo_pago) / 100) +
                  parseFloat($root.ongoingOrder.importe_cupon)
              )
            }}
          </span> -->
        <!-- PORCENTAJE DEL TOTAL -->
        <!-- <span v-if="props.ongoingTotals.tipoRecargoPago === 'Porcentaje del Total'">
            {{
              formatCurrency(
                parseFloat($root.ongoingOrder.total) +
                  parseFloat($root.ongoingOrder.recargo_envio) +
                  (parseFloat($root.ongoingOrder.total) +
                    parseFloat($root.ongoingOrder.recargo_envio)) *
                    (parseFloat($root.ongoingOrder.recargo_pago) / 100) +
                  parseFloat($root.ongoingOrder.importe_cupon)
              )
            }}
          </span> -->
        <!-- </span> -->
        <!-- <span v-else
          >$
          {{
            formatCurrency(
              parseFloat($root.ongoingOrder.total) +
                parseFloat($root.ongoingOrder.importe_cupon)
            )
          }}</span
        > -->
        <span
          >$ {{ formatCurrency(parseFloat($root.ongoingOrder.total)) }}</span
        >
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
