<template>
  <f7-row
    v-if="
      $root.confirmedCart.length > 0 && $root.listFoodPaymentMethods.length > 0
    "
  >
    <f7-col>
      <div
        class="item-title item-label shop-cart-title-section"
        style="color: #222222"
      >
        FORMA DE PAGO
      </div>
      <!-- EFECTIVO -->
      <f7-list
        style="margin: 0"
        v-for="metodoPagoList in $root.listFoodPaymentMethods"
        :key="metodoPagoList.name"
      >
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Efectivo' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Efectivo';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- EFECTIVO | CAMPOS ASOCIADOS -->
        <f7-list-input
          v-if="
            metodoPagoList.metodo_de_pago === 'Efectivo' &&
              metodoPagoList.efectivo_paga_con &&
              $root.metodoPago === metodoPagoList.nombre
          "
          class="cart-metodos-subopciones"
          label="¿Con cuánto pagas?"
          type="text"
          maxlength="10"
          clear-button
          validate
          error-message="Debes ingresar un número válido"
          pattern="[.0-9]*"
          :info="getCheckoutTotals()"
          :value="$root.efectivoPagaCon"
          @focus="inputOnFocus"
          @input="
            (e) => {
              $root.efectivoPagaCon = e.target.value;
            }
          "
        >
        </f7-list-input>
        <!-- TARJETA DE DEBITO -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Tarjeta de Débito' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Tarjeta de Débito';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- TARJETA DE CREDITO -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Tarjeta de Crédito' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Tarjeta de Crédito';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- MERCADO PAGO -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Mercado Pago' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Mercado Pago';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- MERCADO PAGO | AUTOMATICO -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Mercado Pago - Automático' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Mercado Pago - Automático';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- LINK DE PAGO -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Link de Pago' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Link de Pago';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- LINK DE PAGO | CAMPOS ASOCIADOS -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Link de Pago' &&
              $root.metodoPago === metodoPagoList.nombre
          "
          style="font-size: 16px; font-weight: 600"
          class="text-align-center cart-metodos-subopciones"
        >
          <a class="external" :href="metodoPagoList.link_pago"
            ><span class="badge badge-edit-from-cart"
              >Ir al Link de Pago →</span
            ></a
          >
        </f7-list-item>
        <!-- TRANSFERENCIA BANCARIA -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Transferencia Bancaria' &&
              metodoPagoList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoPagoList.nombre"
          :value="metodoPagoList.nombre"
          name="radio-pago"
          :checked="$root.metodoPago === metodoPagoList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoPago = e.target.value;
              $root.tipoMetodoPago = 'Transferencia Bancaria';
              $root.recargoPago =
                metodoPagoList.importe_recargo !== 0
                  ? metodoPagoList.importe_recargo
                  : 0.0;
              $root.tipoRecargoPago = metodoPagoList.tipo_recargo;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoPagoList.importe_recargo > 0"
              style="font-size: 12px"
            >
              +
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
            <span
              v-if="metodoPagoList.importe_recargo < 0"
              style="font-size: 12px"
            >
              -
              <span
                v-if="metodoPagoList.tipo_recargo.startsWith('Importe Fijo')"
              >
                $
              </span>
              {{ Math.abs(parseFloat(metodoPagoList.importe_recargo)) }}
              <span v-if="metodoPagoList.tipo_recargo.startsWith('Porcentaje')">
                %
              </span>
            </span>
          </template>
        </f7-list-item>
        <!-- TRANSFERENCIA BANCARIA | CAMPOS ASOCIADOS -->
        <f7-list-item
          v-if="
            metodoPagoList.metodo_de_pago === 'Transferencia Bancaria' &&
              $root.metodoPago === metodoPagoList.nombre
          "
          style="font-size: 16px; font-weight: 600"
          class="text-align-center cart-metodos-subopciones"
        >
          <f7-block style="width: 100%" class="no-margin">
            <f7-block
              v-show="metodoPagoList.cbu_alias"
              class="no-margin padding-bottom no-padding-horizontal"
            >
              <f7-row style="color: #734903">C.B.U. / Alias</f7-row>
              <f7-row>
                <f7-col width="80" class="text-align-left">
                  <span style="font-weight: 400" class="btn-copy">{{
                    metodoPagoList.cbu_alias
                  }}</span>
                </f7-col>
                <f7-col width="20">
                  <i
                    style="color: #734903; font-size: 22px"
                    class="fas fa-copy btn-copy"
                    @click="copyToClipboard(metodoPagoList.cbu_alias)"
                  ></i>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block
              v-show="metodoPagoList.cuenta_bancaria"
              class="no-margin padding-bottom no-padding-horizontal"
            >
              <f7-row style="color: #734903">Cuenta Bancaria</f7-row>
              <f7-row>
                <f7-col width="80" class="text-align-left">
                  <span style="font-weight: 400">{{
                    metodoPagoList.cuenta_bancaria
                  }}</span>
                </f7-col>
                <f7-col width="20">
                  <i
                    style="color: #734903; font-size: 22px"
                    class="fas fa-copy btn-copy"
                    @click="copyToClipboard(metodoPagoList.cuenta_bancaria)"
                  ></i>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block
              v-show="metodoPagoList.banco"
              class="no-margin padding-bottom no-padding-horizontal"
            >
              <f7-row style="color: #734903">Banco</f7-row>
              <f7-row>
                <f7-col class="text-align-left">
                  <span style="font-weight: 400">{{
                    metodoPagoList.banco
                  }}</span>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block
              v-show="metodoPagoList.nombre_apellido"
              class="no-margin padding-bottom no-padding-horizontal"
            >
              <f7-row style="color: #734903">Nombre y Apellido</f7-row>
              <f7-row>
                <f7-col class="text-align-left">
                  <span style="font-weight: 400">{{
                    metodoPagoList.nombre_apellido
                  }}</span>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block
              v-show="metodoPagoList.id_tributario"
              class="no-margin padding-bottom no-padding-horizontal"
            >
              <f7-row style="color: #734903">C.U.I.T. / R.U.T.</f7-row>
              <f7-row>
                <f7-col width="80" class="text-align-left">
                  <span style="font-weight: 400" class="btn-copy">{{
                    metodoPagoList.id_tributario
                  }}</span>
                </f7-col>
                <f7-col width="20">
                  <i
                    style="color: #734903; font-size: 22px"
                    class="fas fa-copy btn-copy"
                    @click="copyToClipboard(metodoPagoList.id_tributario)"
                  ></i>
                </f7-col>
              </f7-row>
            </f7-block>
          </f7-block>
        </f7-list-item>
      </f7-list>
      <!-- CAMPOS PERSONALIZABLES -->
      <f7-list
        class="no-margin no-padding"
        v-for="(campo, index) in $root.listCustomFields"
        :key="campo.name"
      >
        <f7-list-input
          v-if="campo.asociado_a_metodo === 'Método de Pago'"
          class="cart-metodos-subopciones"
          :label="campo.nombre_campo"
          :type="$root.customFieldsTypes[campo.tipo_de_dato]"
          :info="campo.leyenda"
          @focus="inputOnFocus"
          :name="campo.name"
          clear-button
          :required="campo.obligatorio === 1 ? 'required' : ''"
          validate
          @change="
            (e) => {
              $root.listCustomFields[index].value = e.target.value;
            }
          "
        >
        </f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";
import utils from "../../utils.js";
import ClipboardJS from "clipboard";

export default {
  props: {
    props: Object,
  },
  methods: {
    inputOnFocus() {
      cart.inputOnFocus(this);
    },
    copyToClipboard(dataToCopy) {
      const self = this;
      var clip = new ClipboardJS(".btn-copy", {
        text: function() {
          return dataToCopy;
        },
      });

      clip.on("success", function() {
        // console.log("Success");
        var toastBottom = self.$f7.toast.create({
          text: "Se han copiado los datos al portapapeles",
          position: "bottom",
          closeTimeout: 3000,
          cssClass: "toast-success",
        });
        toastBottom.open();
      });
      clip.on("error", function() {
        // console.log("Error");
        var toastBottom = self.$f7.toast.create({
          text: "No se han podido copiar los datos al portapapeles",
          position: "bottom",
          closeTimeout: 3000,
          cssClass: "toast-error",
        });
        toastBottom.open();
      });
    },
    getCheckoutTotals() {
      const self = this;
      return `Ingresa un valor mayor (o igual) a $ ${utils.formatCurrency(
        cart.calculateCheckoutTotals(self)
      )}`;
    },
  },
};
</script>
