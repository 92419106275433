var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-margin no-padding"},[(
      (_vm.$root.docCurrentFood &&
        _vm.$root.listFoodType === 'Salon' &&
        parseFloat(_vm.$root.docCurrentFood.precio) > 0) ||
      (_vm.$root.listFoodType === 'Delivery' &&
        parseFloat(_vm.$root.docCurrentFood.precio_delivery) > 0)
    )?_c('f7-row',{staticClass:"shop-attributes-title-section"},[_c('f7-col',[_c('span',[_vm._v("CANTIDAD")])])],1):_vm._e(),_vm._v(" "),(
      (_vm.$root.docCurrentFood &&
        _vm.$root.listFoodType === 'Salon' &&
        parseFloat(_vm.$root.docCurrentFood.precio) > 0) ||
      (_vm.$root.listFoodType === 'Delivery' &&
        parseFloat(_vm.$root.docCurrentFood.precio_delivery) > 0)
    )?_c('span',[_c('f7-row',[_c('f7-col',{staticClass:"shop-attributes-attribute-qty"},[_c('f7-stepper',{ref:"stepperMain",attrs:{"small":"","round":"","fill":"","value":_vm.$root.docCurrentFood.cantidad,"buttons-only":false},on:{"change":_vm.changeQtyFood}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }