var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.props.loading)?_c('f7-sheet',{attrs:{"id":"cart-my-saved-locations-sheet","backdrop":"","swipeToClose":false,"closeByBackdropClick":true,"closeByOutsideClick":true,"opened":_vm.props.cartMySavedLocationsSheetOpened},on:{"sheet:open":_vm.onCartMySavedLocationsSheetOpened,"sheet:closed":_vm.onCartMySavedLocationsSheetClosed}},[_c('span',{staticClass:"close-cart-shipping-map-sheet-container",on:{"click":function($event){_vm.props.cartMySavedLocationsSheetOpened = false}}},[_c('i',{staticClass:"fas fa-times close-cart-shipping-map-sheet sheet-close-button"})]),_vm._v(" "),_c('f7-page-content',{staticStyle:{"border-radius":"10px 10px 0 0","min-height":"10vh"}},[_c('f7-block',{staticClass:"map-navbar padding-horizontal-half",staticStyle:{"position":"relative"}},[_c('f7-row',[_c('f7-block-title',{staticStyle:{"text-align":"left","font-weight":"800","margin-bottom":"0px","margin-top":"15px"},attrs:{"large":""}},[_vm._v("Mis Direcciones")]),_vm._v(" "),_c('span',{staticClass:"badge badge-shipping-map margin padding",staticStyle:{"width":"100%"},attrs:{"fill":""},on:{"click":function () {
              // inputOnFocus;
              _vm.openCartShippingMapSheet();
            }}},[_vm._v("Agregar Nueva Dirección")]),_vm._v(" "),(_vm.props.mySavedLocationsLs && _vm.props.mySavedLocationsLs.length > 0)?_c('f7-list',{staticClass:"margin-top",staticStyle:{"width":"100%","overflow":"auto","height":"30vh"}},_vm._l((_vm.props.mySavedLocationsLs),function(location,i){return _c('f7-list-item',{key:location.address,staticClass:"my-saved-location",staticStyle:{"font-size":"16px","font-weight":"600"},attrs:{"radio":"","radio-icon":"end","value":location.address,"name":"radio-saved-locations","checked":false},on:{"click":function () {
                _vm.calculateShippingCostByPosition(
                  location.address,
                  location.lat,
                  location.lng,
                  i
                );
              },"change":function (e) {
                /*$root.horaRetiraSelector = 'text';
                $root.horaRetira = 'Lo Antes Posible';
                $root.horaRetiraFormatted = e.target.value;*/
              }}},[_c('template',{staticStyle:{"white-space":"normal"},slot:"title"},[_vm._v("\n              "+_vm._s(location.address)+"\n            ")]),_vm._v(" "),_c('template',{staticStyle:{"white-space":"normal"},slot:"after-title"},[_c('br'),_vm._v(" "),_c('span',{staticStyle:{"color":"#c41515","font-size":"12px"}},[_c('i',{staticClass:"fa fa-times"}),_vm._v("  \n                "),_c('a',{staticStyle:{"color":"#c41515","font-size":"12px"},on:{"click":function () {
                      _vm.removeMyLocation(i);
                    }}},[_vm._v("ELIMINAR DIRECCION")])])])],2)}),1):_c('f7-row',{staticClass:"margin",staticStyle:{"width":"100%","height":"50vh","display":"flex","justify-content":"center","text-align":"center","align-items":"center"}},[_c('f7-col',[_c('img',{staticClass:"empty-state-image",attrs:{"src":"static/images/no_location.png","alt":""}}),_vm._v(" "),_c('div',{staticClass:"margin-top",staticStyle:{"font-size":"16px","font-weight":"500","color":"#939393"}},[_vm._v("\n              No tienes direcciones guardadas.\n              "),_c('br'),_vm._v("\n              Agrega una nueva.\n            ")])])],1),_vm._v(" "),_c('mySavedLocationsTotals',{attrs:{"props":_vm.props}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }