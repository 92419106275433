<template>
  <f7-block class="no-margin no-padding">
    <f7-row style="height: 40px;" v-if="$root.confirmedCart.length > 0">
      <f7-col width="30" style="padding: 20px;">
        <!-- <span @click="navigateTo('/local/' + $root.docLocal.name + '/')"> -->
        <span @click="navigateToLocal">
          <i class="fas fa-arrow-left" style="font-size: 20px;"></i>
        </span>
      </f7-col>
      <f7-col width="70" style="padding: 20px; text-align: right;">
        <span class="badge badge-remove-from-cart" @click="emptyCart">
          <i class="fas fa-times"></i>
          &nbsp;&nbsp;Anular Pedido
        </span>
      </f7-col>
    </f7-row>

    <!-- CABECERA -->
    <f7-row
      class="shop-cart-navbar-normal"
      style="padding: 10px 20px 20px 20px; font-size: 24px; font-weight: 800;"
      v-if="$root.confirmedCart.length > 0"
    >
      <f7-col id="shop-cart-navbar-text" class="shop-cart-navbar-text-normal">
        <span>Mi Pedido</span>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import cart from "../../cart.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    navigateToLocal() {
      cart.navigateToLocal(this);
    },
    emptyCart() {
      cart.emptyCart(this);
    },
  },
};
</script>
