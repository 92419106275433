<template>
  <f7-block
    class="total-cart-block"
    v-if="$root.confirmedCart.length > 0"
    :style="
      $root.docLocal.habilitar_traduccion_automatica
        ? 'margin-bottom: 75px;'
        : ''
    "
  >
    <!-- FINALIZAR PEDIDO -->
    <!-- SUBTOTAL -->
    <f7-row
      v-if="
        parseFloat($root.costoEnvio).toFixed(2) > 0.0 ||
          parseFloat($root.recargoPago).toFixed(2) !== 0.0
      "
      class="totals-cart-row"
    >
      <f7-col width="60">
        <span style="font-weight: 600">Subtotal</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span>$ {{ formatCurrency(parseFloat($root.grandTotalCart)) }}</span>
      </f7-col>
    </f7-row>
    <!-- COSTO DE ENVIO -->
    <f7-row class="totals-cart-row" v-if="parseFloat($root.costoEnvio) > 0">
      <f7-col width="60">
        <span style="font-weight: 600">Costo de Envío</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span>$ {{ formatCurrency(parseFloat($root.costoEnvio)) }}</span>
      </f7-col>
    </f7-row>
    <!-- RECARGO / BONIFICACION PAGO -->
    <f7-row
      class="totals-cart-row"
      v-if="parseFloat($root.recargoPago) !== 0.0"
    >
      <f7-col width="60">
        <span
          v-if="parseFloat($root.recargoPago) > 0.0"
          style="font-weight: 600"
          >Recargo de Pago</span
        >
        <span
          v-if="parseFloat($root.recargoPago) < 0.0"
          style="font-weight: 600"
          >Descuento por Pago</span
        >
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span v-if="$root.tipoRecargoPago === 'Importe Fijo'">
          $ {{ formatCurrency(parseFloat($root.recargoPago)) }}
        </span>
        <span v-if="$root.tipoRecargoPago === 'Porcentaje del Subtotal'">
          $
          {{
            formatCurrency(
              parseFloat($root.grandTotalCart) *
                (parseFloat($root.recargoPago) / 100)
            )
          }}
        </span>
        <span v-if="$root.tipoRecargoPago === 'Porcentaje del Total'">
          $
          {{
            formatCurrency(
              (parseFloat($root.grandTotalCart) +
                parseFloat($root.costoEnvio)) *
                (parseFloat($root.recargoPago) / 100)
            )
          }}
        </span>
      </f7-col>
    </f7-row>
    <!-- CUPON DE DESCUENTO -->
    <f7-row
      class="totals-cart-row"
      v-if="parseFloat($root.valorCuponDescuentoTotal) < 0.0"
    >
      <f7-col width="60">
        <span style="font-weight: 600">Cupón de Descuento</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span>
          $ {{ formatCurrency(parseFloat($root.valorCuponDescuentoTotal)) }}
        </span>
      </f7-col>
    </f7-row>
    <!-- TOTAL DEL PEDIDO -->
    <f7-row class="totals-cart-row">
      <f7-col width="60">
        <span style="font-weight: 600">Total del Pedido</span>
      </f7-col>
      <f7-col
        width="40"
        style="text-align: right; font-weight: 800; font-size: 18px"
      >
        <span v-if="$root.costoEnvio > 0.0 || $root.recargoPago !== 0.0"
          >$
          <!-- IMPORTE FIJO -->
          <span v-if="$root.tipoRecargoPago === 'Importe Fijo'">
            {{
              formatCurrency(
                parseFloat($root.grandTotalCart) +
                  parseFloat($root.costoEnvio) +
                  parseFloat($root.recargoPago) +
                  parseFloat($root.valorCuponDescuentoTotal)
              )
            }}
          </span>
          <!-- PORCENTAJE DEL SUBTOTAL -->
          <span v-if="$root.tipoRecargoPago === 'Porcentaje del Subtotal'">
            {{
              formatCurrency(
                parseFloat($root.grandTotalCart) +
                  parseFloat($root.costoEnvio) +
                  parseFloat($root.grandTotalCart) *
                    (parseFloat($root.recargoPago) / 100) +
                  parseFloat($root.valorCuponDescuentoTotal)
              )
            }}
          </span>
          <!-- PORCENTAJE DEL TOTAL -->
          <span v-if="$root.tipoRecargoPago === 'Porcentaje del Total'">
            {{
              formatCurrency(
                parseFloat($root.grandTotalCart) +
                  parseFloat($root.costoEnvio) +
                  (parseFloat($root.grandTotalCart) +
                    parseFloat($root.costoEnvio)) *
                    (parseFloat($root.recargoPago) / 100) +
                  parseFloat($root.valorCuponDescuentoTotal)
              )
            }}
          </span>
        </span>
        <span v-else
          >$
          {{
            formatCurrency(
              parseFloat($root.grandTotalCart) +
                parseFloat($root.valorCuponDescuentoTotal)
            )
          }}</span
        >
      </f7-col>
    </f7-row>

    <!-- PRECIOS MODIFICADOS -->
    <f7-row v-if="!props.continueWithOrder" class="warning-checkout-precios-modificados">
      <f7-col width="20" style="text-align: center; font-size: 18px;"
        >⚠️</f7-col
      >
      <f7-col width="80">
        <strong>Atención</strong>: Los precios fueron modificados
      </f7-col>
    </f7-row>

    <!-- PEDIDOS BLOQUEADOS -->
    <f7-row v-if="$root.bloqueoPedidos">
      <f7-col>
        <span style="margin-top: 5px; display: block">
          <span
            :class="
              'badge badge-hacer-pedido-white badge-disabled margin-top-half'
            "
          >
            <i class="fas fa-times" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;LOCAL CERRADO
          </span>
        </span>
      </f7-col>
    </f7-row>

    <!-- PEDIDOS PERMITIDOS -->
    <f7-row v-else>
      <f7-col>
        <span style="margin-top: 5px; display: block">
          <span
            v-if="props.enableSubmitOrder && props.continueWithOrder"
            :class="'badge badge-hacer-pedido-white margin-top-half'"
            @click="props.confirmCartButtonClicked ? null : confirmCart()"
          >
            <i class="fas fa-check" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;{{
              $root.docLocal.panel_pedidos && $root.docLocal.panel_pedidos == 1
                ? "CONTINUAR"
                : "CONFIRMAR PEDIDO"
            }}
          </span>

          <span
            v-if="props.enableSubmitOrder && !props.continueWithOrder"
            :class="'badge badge-hacer-pedido-white margin-top-half'"
            @click="props.confirmCartButtonClicked ? null : confirmCart()"
          >
            <i class="fas fa-check" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;{{
              $root.docLocal.panel_pedidos && $root.docLocal.panel_pedidos == 1
                ? "CONTINUAR DE TODAS FORMAS"
                : "CONFIRMAR DE TODAS FORMAS"
            }}
          </span>

          <span
            v-if="!props.enableSubmitOrder"
            :class="
              'badge badge-hacer-pedido-white badge-hacer-pedido-disabled margin-top-half'
            "
          >
            <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;AGUARDE
          </span>
        </span>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import cart from "../../cart.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    confirmCart() {
      const self = this;
      cart.confirmCart(this);
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
