var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$root.loadingFood)?_c('f7-sheet',{staticClass:"local-image-food-sheet",attrs:{"opened":_vm.props.localImageFoodSheetOpened,"backdrop":"","closeByBackdropClick":true,"closeByOutsideClick":true},on:{"sheet:closed":function($event){_vm.props.localImageFoodSheetOpened = false}}},[_c('f7-page-content',[_c('div',{staticStyle:{"height":"0","overflow":"hidden","padding-top":"100%","background":"#fff","position":"relative","width":"100%","border-radius":"10px"}},[_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","background-size":"cover","background-position":"center center","background-repeat":"no-repeat","border-radius":"10px","box-shadow":"rgba(0, 0, 0, 0.5) 0px 65px 25px 5px inset"},style:({
          'background-image':
            'linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url(\'' +
            'https://' +
            _vm.$root.serverHostBase +
            '/' +
            encodeURI(_vm.props.currentFoodImage) +
            '\')',
        })})]),_vm._v(" "),_c('f7-row',{staticStyle:{"width":"100%","position":"absolute","top":"0"}},[_c('f7-col',{staticStyle:{"padding-top":"5px","padding-left":"15px","color":"#efefef"},attrs:{"width":"85"}},[_c('span',{staticStyle:{"font-size":"26px"}},[_vm._v(_vm._s(_vm.props.currentFoodName))])]),_vm._v(" "),_c('f7-col',{staticStyle:{"padding-top":"15px","display":"flex","justify-content":"center"},attrs:{"width":"15"}},[_c('a',{on:{"click":function($event){_vm.props.localImageFoodSheetOpened = false}}},[_c('i',{staticClass:"fa fa-times",staticStyle:{"color":"#fff","font-size":"22px"}})])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }