<template>
  <f7-row
    v-show="
      $root.changeMap === true ||
        $root.loadingFood === true ||
        props.loading === true
    "
    class="local-carta-loader"
  >
    <f7-col>
      <f7-button raise>
        <i class="fas fa-sync-alt fa-spin"></i>&nbsp;&nbsp; Cargando Carta
      </f7-button>
    </f7-col>
  </f7-row>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>
