<template>
  <f7-sheet
    id="cart-my-saved-locations-sheet"
    class=""
    backdrop
    v-if="!props.loading"
    :swipeToClose="false"
    :closeByBackdropClick="true"
    :closeByOutsideClick="true"
    :opened="props.cartMySavedLocationsSheetOpened"
    @sheet:open="onCartMySavedLocationsSheetOpened"
    @sheet:closed="onCartMySavedLocationsSheetClosed"
  >
    <span
      class="close-cart-shipping-map-sheet-container"
      @click="props.cartMySavedLocationsSheetOpened = false"
      ><i
        class="fas fa-times close-cart-shipping-map-sheet sheet-close-button"
      ></i
    ></span>
    <f7-page-content style="border-radius: 10px 10px 0 0; min-height: 10vh">
      <f7-block
        class="map-navbar padding-horizontal-half"
        style="position: relative"
      >
        <f7-row>
          <f7-block-title
            large
            style="
              text-align: left;
              font-weight: 800;
              margin-bottom: 0px;
              margin-top: 15px;
            "
            >Mis Direcciones</f7-block-title
          >
          <span
            class="badge badge-shipping-map margin padding"
            style="width: 100%"
            @click="
              () => {
                // inputOnFocus;
                openCartShippingMapSheet();
              }
            "
            fill
            >Agregar Nueva Dirección</span
          >
          <f7-list
            class="margin-top"
            style="width: 100%; overflow: auto; height: 30vh"
            v-if="props.mySavedLocationsLs && props.mySavedLocationsLs.length > 0"
          >
            <f7-list-item
              v-for="(location, i) in props.mySavedLocationsLs"
              :key="location.address"
              class="my-saved-location"
              style="font-size: 16px; font-weight: 600"
              radio
              radio-icon="end"
              :value="location.address"
              name="radio-saved-locations"
              :checked="false"
              @click="
                () => {
                  calculateShippingCostByPosition(
                    location.address,
                    location.lat,
                    location.lng,
                    i
                  );
                }
              "
              @change="
                (e) => {
                  /*$root.horaRetiraSelector = 'text';
                  $root.horaRetira = 'Lo Antes Posible';
                  $root.horaRetiraFormatted = e.target.value;*/
                }
              "
            >
              <template slot="title" style="white-space: normal">
                {{ location.address }}
              </template>
              <template slot="after-title" style="white-space: normal">
                <br />
                <span style="color: #c41515; font-size: 12px">
                  <i class="fa fa-times"></i>&nbsp;&nbsp;
                  <a
                    style="color: #c41515; font-size: 12px"
                    @click="
                      () => {
                        removeMyLocation(i);
                      }
                    "
                    >ELIMINAR DIRECCION</a
                  ></span
                >
              </template>
            </f7-list-item>
          </f7-list>

          <f7-row
            v-else
            class="margin"
            style="
              width: 100%;
              height: 50vh;
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
            "
          >
            <f7-col>
              <img
                class="empty-state-image"
                src="static/images/no_location.png"
                alt=""
              />
              <div
                class="margin-top"
                style="font-size: 16px; font-weight: 500; color: #939393"
              >
                No tienes direcciones guardadas.
                <br />
                Agrega una nueva.
              </div>
            </f7-col>
          </f7-row>
          <mySavedLocationsTotals :props="props" />
        </f7-row>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import map from "../../map.js";
import utils from "../../utils.js";
import mySavedLocationsTotals from "./my-saved-locations-totals.vue";

export default {
  props: {
    props: Object,
  },
  components: {
    mySavedLocationsTotals,
  },
  data: function () {
    return {
    };
  },
  beforeMount: function () {
    const self = this;
  },
  mounted: function () {
    const self = this;
    eva.replace();
    self.props.mySavedLocationsLs = JSON.parse(
      localStorage.getItem("my_saved_locations")
    );
  },
  updated: function () {
    eva.replace();
  },
  methods: {
    navBack() {
      const self = this;
      self.props.cartMySavedLocationsSheetOpened = false;
    },
    // searchAddress() {
    //   const self = this;
    //   map.searchAddress(self);
    // },
    // myLocation() {
    //   const self = this;
    //   map.myLocation(self);
    // },
    onCartMySavedLocationsSheetOpened() {
      const self = this;
      if (self.$root.shippingMapAddress === "") {
        document
        .querySelectorAll("input[name=radio-saved-locations]:checked")
        .forEach((RadioEl) => {
          RadioEl.checked = false;
        });
      }
    },
    onCartMySavedLocationsSheetClosed() {
      const self = this;
      self.props.cartMySavedLocationsSheetOpened = false;
      map.onCartShippingMapCartSheetClosed(self);
      // self.$f7.toast.close();
    },
    // enterTriggerSearch() {
    //   const self = this;
    //   self.$$(".searchbar-map").on("keyup", (e) => {
    //     if (e.keyCode === 13) {
    //       e.preventDefault();
    //       self.searchAddress(self);
    //     }
    //   });
    // },
    // closeDialog() {
    //   const self = this;
    //   self.$f7.toast.close();
    // },
    openCartShippingMapSheet() {
      const self = this;
      self.props.cartMySavedLocationsSheetOpened = false;
      document
        .querySelectorAll("input[name=radio-saved-locations]:checked")
        .forEach((RadioEl) => {
          RadioEl.checked = false;
        });

      setTimeout(() => {
        self.props.cartShippingMapSheetOpened = true;
      }, 500);
    },
    calculateShippingCostByPosition(address, lat, lng, index) {
      const self = this;
      self.props.direccionGuardadaIndexLocal = index;
      map.calculateShippingCostByPosition(self, { lat: lat, lng: lng });

      self.$root.shippingMapLatLng = {
        lat: lat,
        lng: lng,
      };

      self.$root.shippingMapAddress = address;
    },
    removeMyLocation(i) {
      const self = this;
      self.props.mySavedLocationsLs.splice(i, 1);
      localStorage.setItem(
        "my_saved_locations",
        JSON.stringify(self.props.mySavedLocationsLs)
      );
    },
  },
};
</script>
