<template>
  <f7-block class="contactanos-section no-margin" id="contactanos">
    <f7-row style="position: relative">
      <f7-col>
        <h2 class="section-subtitle-dark">Contactanos</h2>
      </f7-col>
    </f7-row>

    <f7-row style="position: relative">
      <div class="col-20"></div>

      <div class="col-100 medium-60 large-40">
        <form id="contact-form">
          <f7-list no-hairlines class="formulario-contacto-lista">
            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="Nombre del Local"
              name="nombre_local"
              type="text"
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="Responsable"
              name="responsable"
              type="text"
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="Ciudad"
              name="ciudad"
              type="text"
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="País"
              name="pais"
              type="select"
            >
              <option value="Argentina">Argentina</option>
              <option value="Brasil">Brasil</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Chile">Chile</option>
              <option value="Colombia">Colombia</option>
              <option value="Ecuador">Ecuador</option>
              <option value="España">España</option>
              <option value="México">México</option>
              <option value="Perú">Perú</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Venezuela">Venezuela</option>
            </f7-list-input>

            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="WhatsApp"
              name="telefono"
              type="text"
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              outline
              class="input-landing-contactenos"
              label="E-mail"
              name="email"
              type="text"
              clear-button
            >
            </f7-list-input>

            <li class="input-landing-contactenos">
              <div class="item-content item-input item-input-outline">
                <div class="item-inner">
                  <div class="item-title item-label">Mensaje</div>
                  <div class="item-input-wrap">
                    <span class="input-clear-button"></span>
                    <textarea class="input-landing-contactenos" name="mensaje">
                    </textarea>
                  </div>
                </div>
              </div>
            </li>
          </f7-list>

          <f7-button
            class="cover-button"
            style="margin-top: 20px; margin-bottom: 40px"
            @click="submitForm"
            fill
            raise
          >
            <i class="fas fa-paper-plane" style="color: #222222"></i>
            ENVIAR
          </f7-button>
        </form>
      </div>

      <div class="col-20"></div>
    </f7-row>
  </f7-block>
</template>

<script>
import utils from "../../utils.js";

export default {
  methods: {
    submitForm() {
      const self = this;
      self.$f7.dialog.progress("Enviando", undefined, "#444444");
      var formData = self.$f7.form.convertToData("#contact-form");
      var requiredFields = [
        "nombre_local",
        "responsable",
        "ciudad",
        "pais",
        "telefono",
        "email",
      ];
      var validForm = true;

      var submitFormCallback = function (e) {
        dataLayer.push({ event: "success-submit-form" });
        self.$f7.dialog.close();
        self.$f7.toast
          .create({
            cssClass: "update-version-toast",
            text: "El formulario se ha enviado correctamente. Muchas Gracias!",
            closeButton: false,
            closeTimeout: 3000,
          })
          .open();
        self.$f7router.refreshPage();
      };
      var done = 0;

      self.mapKeys(formData, function (value, key) {
        if (
          validForm === true &&
          requiredFields.includes(key) &&
          !self.checkEmpty(value)
        ) {
          self.$f7.dialog.close();
          self.$f7.toast
            .create({
              cssClass: "error-validation-toast",
              text: "Recuerde completar todos los campos requeridos",
              closeButton: false,
              closeTimeout: 3000,
            })
            .open();
          validForm = false;
        }

        done++;

        if (Object.keys(formData).length === done + 1) {
          if (validForm) {
            utils.customRequest(
              self,
              submitFormCallback,
              "utils.submit_form",
              "POST",
              {
                formData,
              },
              ""
            );
          }
        }
      });
    },
    mapKeys(obj, mapper) {
      Object.entries(obj).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [mapper(value, key)]: value,
        }),
        {}
      );
    },
    checkEmpty(value) {
      if (value) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
