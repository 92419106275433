var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{staticClass:"home-page",attrs:{"name":"home-page"},on:{"page:afterin":_vm.configSideMenu,"page:beforein":_vm.homeBeforeIn}},[_c('fullscreenLoader',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('f7-block',{staticClass:"home-main-block"},[(_vm.$root.icono_ciudad)?_c('div',{staticClass:"home-banner-ciudad",style:({
        'background-image':
          'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(\'https://' +
          _vm.$root.serverHostBase +
          _vm.$root.icono_ciudad +
          '\')',
      })},[_c('f7-row',[_c('f7-col',{staticClass:"home-banner-ciudad-buttons"},[_c('f7-link',{on:{"click":_vm.nativeShare}},[_c('i',{staticClass:"home-banner-ciudad-icon",attrs:{"data-eva":"share-outline","data-eva-fill":"#ffffff"}})]),_vm._v(" "),_c('f7-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.a2hsReady),expression:"a2hsReady"}],on:{"click":_vm.installApp}},[_c('i',{staticClass:"a2hs-btn home-banner-ciudad-icon",attrs:{"data-eva":"download-outline","data-eva-fill":"#ffffff"}})]),_vm._v(" "),_c('f7-link',{on:{"click":function($event){return _vm.navigate('/map-locals/')}}},[_c('i',{staticClass:"a2hs-btn home-banner-ciudad-icon",attrs:{"data-eva":"pin-outline","data-eva-fill":"#ffffff"}})])],1)],1),_vm._v(" "),(this.$root.ciudad)?_c('f7-row',[_c('f7-col',{staticClass:"home-banner-ciudad-title-container"},[_c('span',{staticClass:"home-banner-ciudad-title"})])],1):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('f7-block',{staticClass:"searchbar-not-found searchbar-not-found-block"},[_c('f7-row',[_c('f7-col',{staticClass:"searchbar-not-found-image",style:({
          'background-image': 'url(/static/images/Alacarta_Logo.png)',
        })})],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"searchbar-not-found-text"},[_vm._v("\n        No hay resultados"),_c('br'),_vm._v("para mostrar\n      ")])],1)],1),_vm._v(" "),(_vm.props.loading)?_c('f7-list',{staticClass:"skeleton-list",attrs:{"media-list":""}},_vm._l((6),function(n){return _c('f7-list-item',{key:n,class:("skeleton-text skeleton-effect-" + _vm.effect + " skeleton-item-custom"),attrs:{"title":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXX","subtitle":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX","text":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}},[_c('f7-skeleton-block',{staticClass:"skeleton-block-style",attrs:{"slot":"media"},slot:"media"})],1)}),1):_vm._e(),_vm._v(" "),_c('f7-list',{staticClass:"virtual-list-locals search-list searchbar-found no-margin",attrs:{"no-hairlines":"","no-hairlines-between":"","media-list":"","virtual-list":"","virtual-list-params":{
      items: _vm.items,
      searchAll: _vm.searchAll,
      renderExternal: _vm.renderExternal,
      setListHeight: true,
      height: '85',
    }}},[_c('ul',{staticClass:"home-lista-locales-ul"},_vm._l((_vm.vlData.items),function(item,index){return _c('f7-list-item',{key:index,staticClass:"home-locales-list",attrs:{"media-item":"","data-docname":item.name},on:{"click":function($event){return _vm.navigateToLocal(item.name)}}},[(item.vista_previa_logo_pagina_principal)?_c('div',{staticClass:"lazy lazy-fade-in demo-lazy home-local-imagen",style:({
            'background-image':
              'url(\'' + item.vista_previa_logo_pagina_principal + '\')',
          }),attrs:{"slot":"media"},slot:"media"}):_c('div',{staticClass:"lazy lazy-fade-in demo-lazy home-local-imagen",style:({
            'background-image': 'url(/static/images/Alacarta_Logo.png)',
          }),attrs:{"slot":"media"},slot:"media"}),_vm._v(" "),_c('div',{staticClass:"home-local-nombre",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(item.nombre_local))])]),_vm._v(" "),_c('div',{staticClass:"home-footer-card",attrs:{"slot":"footer"},slot:"footer"},_vm._l((item.categorias),function(categoria,index){return _c('span',{key:index,staticClass:"badge badge-local"},[_vm._v("\n            "+_vm._s(categoria.categoria_local)+"\n          ")])}),0)])}),1)]),_vm._v(" "),_c('cityShareSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('cityBannerSheet',{attrs:{"props":_vm.props}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }