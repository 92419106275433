<template>
  <f7-block
    v-show="
      !$root.changeMap &&
        $root.loadingFood === false &&
        props.loading === false &&
        $root.listFoodSelected.length > 0
    "
    class="local-carta-block"
    v-bind:style="{
      'background-image':
        'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(' +
        encodeURI($root.docLocal.vista_previa_imagen_fondo_cabecera) +
        ')',
    }"
  >
    <!-- CATEGORIAS DE PLATOS -->
    <f7-list accordion-list class="local-carta-accordeons no-margin">
      <!-- CATEGORIA DE PLATO -->
      <ul>
        <menuListCategory
        v-for="(item, indexLevel1) in $root.listFoodSelected"
        :key="indexLevel1"
        :props="props"
        :item="item"
        :indexLevel1="indexLevel1"
      />
      </ul>
    </f7-list>
  </f7-block>
</template>

<script>
import local from "../../local.js";
import menuListCategory from "./menu-list/menu-list-category.vue";

export default {
  components: {
    menuListCategory,
  },
  props: {
    props: Object,
  },
};
</script>
