<template>
  <f7-row>
    <!-- LISTA VIRTUAL DEL PEDIDO -->
    <f7-list
      no-hairlines
      no-hairlines-between
      media-list
      class="
        virtual-list-cart
        search-list
        searchbar-found
        no-margin
        accordion-list
      "
    >
      <div
        class="accordion-item"
        @click="props.cartDetailCollapsed = !props.cartDetailCollapsed"
      >
        <a class="item-content item-link no-padding" href="#">
          <div class="item-inner no-padding">
            <div class="item-title">
              <f7-row
                class="
                  item-title item-label
                  shop-cart-title-section
                  display-flex
                  align-items-center
                "
              >
                <f7-col width="20"></f7-col>
                <f7-col width="60">
                  <div
                    style="
                      color: #222222;
                      margin-top: 0px !important;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 1.5em;
                    "
                  >
                    DETALLE DEL PEDIDO
                  </div>
                </f7-col>
                <f7-col width="20" v-if="props.cartDetailCollapsed">
                  <div
                    style="
                      position: absolute;
                      right: 15px;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  >
                    <span
                      class="hide-in-xs"
                      style="font-weight: 600; font-size: 10px"
                      >MOSTRAR</span
                    >&nbsp;<i
                      class="fas fa-chevron-down"
                      style="font-size: 12px"
                    ></i>
                  </div>
                </f7-col>
                <f7-col width="20" v-if="!props.cartDetailCollapsed">
                  <div
                    style="
                      position: absolute;
                      right: 15px;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  >
                    <span
                      class="hide-in-xs"
                      style="font-weight: 600; font-size: 10px"
                      >OCULTAR</span
                    >&nbsp;<i
                      class="fas fa-chevron-up"
                      style="font-size: 12px"
                    ></i>
                  </div>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </a>
        <!-- LISTA CONTENEDORA -->
        <div class="accordion-item-content">
          <ul class="home-lista-locales-ul">
            <!-- ITEM DE CARRITO -->
            <f7-list-item
              media-item
              v-for="(item, index) in props.vlDataOngoing.items"
              :key="index"
              :data-docname="item.name"
              class="cart-list"
            >
              <!-- CATEGORIA DEL PLATO -->
              <div slot="title" class="cart-list-nombre">
                <!-- NOMBRE DEL PLATO -->
                <div>
                  {{ item.cantidad > 0 ? item.cantidad + " x" : "" }}
                  {{ item.nombre_plato }}
                </div>

                <!-- ATRIBUTOS DEL PLATO -->
                <div
                  v-for="variable in item.atributos_del_plato"
                  :key="variable.name"
                  v-show="$root.listFoodType === 'Salon'"
                >
                  <div class="cart-atributo-plato" v-if="variable.cantidad > 0">
                    {{ variable.cantidad }} x
                    {{ variable.nombre_atributo }}
                  </div>
                </div>

                <div
                  v-for="variable_delivery in item.atributos_del_plato_delivery"
                  :key="variable_delivery.name"
                  v-show="$root.listFoodType === 'Delivery'"
                >
                  <div
                    class="cart-atributo-plato"
                    v-if="variable_delivery.cantidad > 0"
                  >
                    {{ variable_delivery.cantidad }} x
                    {{ variable_delivery.nombre_atributo_delivery }}
                  </div>
                </div>

                <!-- ADICIONALES DEL PLATO -->
                <div>
                  <div
                    class="cart-atributo-plato"
                    v-if="calculateAdicionalesQty(item) > 0"
                  >
                    Adicionales
                  </div>
                </div>

                <!-- <div
                  v-for="categoria_adicional in item.adicionales"
                  :key="categoria_adicional.name_treelist"
                >
                  <div v-if="categoria_adicional.cantidad_seleccionada > 0">
                    <span class="cart-atributo-plato">{{
                      categoria_adicional.name_category
                    }}</span
                    ><br />
                    <span
                      v-for="(adicional, i) in categoria_adicional.data"
                      :key="adicional.name_treelist"
                    >
                      <span
                        class="cart-atributo-plato"
                        v-if="adicional.cantidad_seleccionada > 0"
                        >{{
                          adicional.cantidad_seleccionada === 1
                            ? ""
                            : adicional.cantidad_seleccionada + "x"
                        }}
                        {{ adicional.nombre_adicional }}<br />
                      </span>
                    </span>
                  </div>
                </div> -->
              </div>

              <!-- PRECIO DEL PLATO -->
              <div slot="after" class="cart-list-price">
                <!-- PRECIO UNITARIO DEL PLATO | SALON -->
                <div v-if="$root.listFoodType === 'Salon'">
                  <span
                    v-if="item.precio_rebajado === 0"
                    class="cart-atributo-plato"
                    >{{
                      item.cantidad > 0
                        ? "$ " +
                          formatCurrency(
                            parseFloat(item.precio * item.cantidad)
                          )
                        : "&nbsp;"
                    }}</span
                  >
                  <span
                    v-else-if="item.precio_rebajado > 0"
                    class="cart-atributo-plato"
                    >{{
                      item.cantidad > 0
                        ? "$ " +
                          formatCurrency(
                            parseFloat(item.precio_rebajado * item.cantidad)
                          )
                        : "&nbsp;"
                    }}</span
                  >
                </div>

                <!-- PRECIO UNITARIO DEL PLATO | DELIVERY -->
                <div v-if="$root.listFoodType === 'Delivery'">
                  <span
                    v-if="item.precio_rebajado_delivery === 0"
                    class="cart-atributo-plato"
                    >{{
                      item.cantidad > 0
                        ? "$ " +
                          formatCurrency(parseFloat(item.precio_delivery))
                        : "&nbsp;"
                    }}</span
                  >
                  <span
                    v-else-if="item.precio_rebajado_delivery > 0"
                    class="cart-atributo-plato"
                    >{{
                      item.cantidad > 0
                        ? "$ " +
                          formatCurrency(
                            parseFloat(item.precio_rebajado_delivery)
                          )
                        : "&nbsp;"
                    }}</span
                  >
                </div>

                <!-- PRECIO DE ATRIBUTOS DEL PLATO -->
                <div
                  v-for="variable in item.atributos_del_plato"
                  :key="variable.name"
                  v-show="
                    variable.cantidad > 0 && $root.listFoodType === 'Salon'
                  "
                >
                  <span
                    v-if="
                      variable.precio_rebajado === 0 &&
                        $root.listFoodType === 'Salon'
                    "
                    class="cart-atributo-plato"
                    >{{
                      variable.cantidad > 0
                        ? "$ " + formatCurrency(parseFloat(variable.precio))
                        : "&nbsp;"
                    }}</span
                  >
                  <span
                    v-else-if="
                      variable.precio_rebajado > 0 &&
                        $root.listFoodType === 'Salon'
                    "
                    class="cart-atributo-plato"
                    >{{
                      variable.cantidad > 0
                        ? "$ " +
                          formatCurrency(parseFloat(variable.precio_rebajado))
                        : "&nbsp;"
                    }}</span
                  >
                  <!-- <span class="cart-atributo-plato" v-if="variable.cantidad > 0"
                    >$ {{ formatCurrency(parseFloat(variable.total_atributo)) }}
                  </span> -->
                </div>

                <div
                  v-for="variable_delivery in item.atributos_del_plato_delivery"
                  :key="variable_delivery.name"
                  v-show="
                    variable_delivery.cantidad > 0 &&
                      $root.listFoodType === 'Delivery'
                  "
                >
                  <span
                    v-if="
                      variable_delivery.precio_rebajado_delivery === 0 &&
                        $root.listFoodType === 'Delivery'
                    "
                    class="cart-atributo-plato"
                    >{{
                      variable_delivery.cantidad > 0
                        ? "$ " +
                          formatCurrency(
                            parseFloat(variable_delivery.precio_delivery)
                          )
                        : "&nbsp;"
                    }}</span
                  >
                  <span
                    v-else-if="
                      variable_delivery.precio_rebajado_delivery > 0 &&
                        $root.listFoodType === 'Delivery'
                    "
                    class="cart-atributo-plato"
                    >{{
                      variable_delivery.cantidad > 0
                        ? "$ " +
                          formatCurrency(
                            parseFloat(
                              variable_delivery.precio_rebajado_delivery
                            )
                          )
                        : "&nbsp;"
                    }}</span
                  >
                  <!-- <span
                    class="cart-atributo-plato"
                    v-if="variable_delivery.cantidad > 0"
                    >$
                    {{
                      formatCurrency(
                        parseFloat(variable_delivery.total_atributo)
                      )
                    }}
                  </span> -->
                </div>

                <!-- PRECIO DE ADICIONALES -->
                <div
                  v-if="calculateAdicionalesQty(item) > 0"
                  class="cart-atributo-plato"
                >
                  <!-- $ {{ parseFloat(props.totalAdicionales).toFixed(2) }} -->
                  $ {{ calculateAdicionales(item) }}
                </div>
                <!-- <div
                      v-for="categoria_adicional in item.adicionales"
                      :key="categoria_adicional.name_treelist"
                    >
                      <div v-if="categoria_adicional.cantidad_seleccionada > 0">
                        <span class="cart-atributo-plato">&nbsp;</span>
                        <span
                          v-for="adicional in categoria_adicional.data"
                          :key="adicional.name_treelist"
                        >
                          <span
                            class="cart-atributo-plato"
                            v-if="adicional.cantidad_seleccionada > 0"
                            >$ {{ parseFloat(adicional.total).toFixed(2)
                            }}<br />
                          </span>
                        </span>
                      </div>
                    </div> -->

                <div class="cart-precio-total-plato">
                  $ {{ formatCurrency(parseFloat(item.total_plato)) }}
                </div>
              </div>
            </f7-list-item>
          </ul>
        </div>
      </div>
    </f7-list>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    calculateAdicionales(item) {
      let totalAdicionales = 0.0;

      for (let i = 0; i < item.adicionales.length; i++) {
        for (let j = 0; j < item.adicionales[i].data.length; j++) {
          totalAdicionales += item.adicionales[i].data[j].total;
        }
      }
      return totalAdicionales;
    },
    calculateAdicionalesQty(item) {
      let totalAdicionalesQty = 0;

      for (let i = 0; i < item.adicionales.length; i++) {
        totalAdicionalesQty += item.adicionales[i].cantidad_seleccionada;
      }
      return totalAdicionalesQty;
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
