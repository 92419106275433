var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"local-page"},on:{"page:beforein":_vm.localBeforeIn,"page:afterin":_vm.localAfterIn}},[_c('fullscreenLoader',{attrs:{"props":_vm.props}}),_vm._v(" "),(!_vm.props.loading && _vm.$root.docLocal)?_c('f7-block',{staticClass:"local-main-block"},[_c('localNavbar',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localCover',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localInfoHeader',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localLoaderMenu',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localMenuList',{attrs:{"props":_vm.props}})],1):_vm._e(),_vm._v(" "),_c('localImageFoodSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localBannerSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localShareSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),(
      _vm.$root.docLocal &&
      _vm.$root.docLocal.cartas &&
      _vm.$root.docLocal.cartas.length > 0 &&
      _vm.props.SelectionMenuSheetOpened
    )?_c('localCartaSelectionSheet',{attrs:{"props":_vm.props}}):_vm._e(),_vm._v(" "),_c('localOpeningHoursSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('cartOngoingOrderSheet',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('localPopupLocalCerrado',{attrs:{"props":_vm.props}}),_vm._v(" "),(
      _vm.$root.docLocal &&
      _vm.$root.docLocal.panel_pedidos &&
      Object.keys(_vm.$root.ongoingOrder).length > 0
    )?_c('div',{staticClass:"fab fab-left-bottom fab-order",staticStyle:{"margin-bottom":"22px"},style:(_vm.$root.docLocal.habilitar_traduccion_automatica
        ? 'margin-bottom: 50px;'
        : '')},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.$root.cartOngoingOrderSheetOpened = true}}},[(_vm.existOngoingOrder)?_c('f7-gauge',{staticStyle:{"position":"absolute","bottom":"-2px","z-index":"1"},attrs:{"type":"circle","value":_vm.$root.ongoingOrderProgress / 100,"size":250,"border-color":"#734903","border-bg-color":"#efa113","border-width":15}}):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":"static/images/take-away-v2.png","height":"45","alt":""}})],1)]):_vm._e(),_vm._v(" "),(
      !_vm.props.loading &&
      !_vm.$root.maintenanceMode &&
      _vm.$root.confirmedCart.length > 0 &&
      _vm.$root.docLocal &&
      _vm.$root.docLocal.nombre_local === _vm.$root.ls.getItem('food_local')
    )?_c('div',{staticClass:"fab fab-right-bottom fab-cart",style:(_vm.$root.docLocal.habilitar_traduccion_automatica
        ? 'margin-bottom: 50px;'
        : '')},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.navigateTo('/cart/', false)}}},[_c('i',{staticClass:"fas fa-shopping-basket"})]),_vm._v(" "),_c('span',{staticClass:"shopping-basket-counter",attrs:{"slot":"text"},slot:"text"},[(_vm.$root.grandTotalCart && _vm.$root.grandTotalCart > 0)?_c('span',[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.grandTotalCart))))]):_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin",staticStyle:{"font-size":"12px","padding":"0 15px"},attrs:{"aria-hidden":"true"}})])])]):_vm._e(),_vm._v(" "),(_vm.props.BannerSheetLocalOpened)?_c('div',{staticClass:"sheet-backdrop backdrop-in sheet-backdrop-banner",staticStyle:{"z-index":"10000"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }