import utils from "./utils.js";

var getCities = function(self, docname) {
  var getCitiesCallback = function(e) {
    self.listCities = e.message.data;

    // var virtualListLocals = self.$f7.virtualList.get('.virtual-list-cities');
    // virtualListLocals.replaceAllItems(e.message.data);
    //
    // setTimeout(function() {
    //   self.$f7.lazy.create('.city-page');
    //   self.Dom7('div.lazy').trigger('lazy');
    // }, 150);
  };

  // if (JSON.parse(self.$root.ls.getItem('online')) === false) {
  //   refresh(self);
  // } else {
  utils.customRequest(
    self,
    getCitiesCallback,
    "ciudad.ciudad.get_cities",
    "POST",
    {},
    ""
  );
  // }
};

var refresh = function(self, event) {
  if (JSON.parse(self.$root.ls.getItem("online")) === false) {
    // var data = JSON.parse(self.$root.ls.getItem("offlinePoints"));
    self.data = data.message.data;
    var virtualListLocals = self.$f7.virtualList.get(".virtual-list-cities");
    virtualListLocals.replaceAllItems(data.message.data);

    var ptr = self.$$(".ptr-content")[1];
    self.$f7.ptr.done(ptr);
  } else {
    self.$f7router.refreshPage();
  }
};

var renderExternal = function(self, vl, vlData) {
  self.vlData = vlData;
};

export default { getCities, refresh, renderExternal };
