<template>
  <f7-row class="local-toolbar">
    <!-- ICONO VOLVER | SOLO ACCEDIENDO DESDE CIUDAD -->
    <f7-col
      v-if="$root.appType === 'Ciudad'"
      class="local-toolbar-back"
      width="15"
    >
      <f7-link v-if="$root.entryPoint == 'ciudad'" @click="navigate('/', true)">
        <i
          id="icon-arrow-back"
          data-eva="arrow-back-outline"
          data-eva-fill="#ffffff"
          class="local-toolbar-back-icon"
        ></i>
      </f7-link>
      <f7-link v-if="$root.entryPoint == 'map'" @click="navigate('/map/')">
        <i
          id="icon-arrow-back"
          data-eva="arrow-back-outline"
          data-eva-fill="#ffffff"
          class="local-toolbar-back-icon"
        ></i>
      </f7-link>
    </f7-col>
    <f7-col v-else width="5"></f7-col>
    <!-- LOGO MINIMIZADO -->
    <f7-col
      :width="$root.appType === 'Ciudad' ? 70 : 80"
      class="local-toolbar-logo"
    >
      <!-- IMAGEN DE LOGO -->
      <div
        class="local-toolbar-logo-image"
        id="logo-shrinked"
        v-bind:style="{
          'background-image':
            'url(' +
            encodeURI($root.docLocal.vista_previa_logo_pagina_principal) +
            ')',
        }"
      ></div>
      <!-- TITULO DE LOCAL -->
      <span id="local-name-shrinked" class="local-toolbar-logo-text">{{
        $root.docLocal.nombre_local
      }}</span>
    </f7-col>
    <!-- ICONO MENU -->
    <f7-col class="local-toolbar-menu" width="15">
      <f7-link panel-open="left">
        <i
          id="icon-local-menu"
          data-eva="menu-outline"
          data-eva-fill="#ffffff"
          class="local-toolbar-menu-icon"
        ></i>
      </f7-link>
    </f7-col>
  </f7-row>
</template>

<script>
import local from "../../local.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    navigate(url, ignoreCache) {
      const self = this;
      self.$f7router.navigate(url, {
        reloadCurrent: true,
        ignoreCache: true
      });
    },
  },
};
</script>
