<template>
  <f7-app :params="f7params">
    <!-- Left panel with cover effect-->
    <f7-panel
      left
      cover
      theme-light
      :swipe="true"
      :swipeActiveArea="20"
      :class="$root.docLocal ? 'local-menu-panel' : undefined"
      @panel:opened="getMenuData"
    >
      <f7-view>
        <!-- MENU DE CIUDAD -->
        <f7-page v-if="$root.local === undefined" name="menu-page">
          <f7-navbar no-shadow style="height: 100px; background-color: #ffffff">
            <a
              :href="'https://' + $root.domainHostName"
              class="external"
              style="height: 150px; width: 100%"
            >
              <div
                v-bind:style="{
                  'background-image':
                    'url(/static/images/Alacarta_Logo_Negro_Naranja.png)',
                  'background-position': 'center center',
                  'background-size': 'contain',
                  'background-repeat': 'no-repeat',
                  height: '150px',
                  width: '100%',
                  'border-radius': '5px',
                }"
              ></div>
            </a>
          </f7-navbar>

          <f7-block style="padding: 15px 10px; margin: 0; text-align: center">
            <a
              :href="'https://' + $root.domainHostName"
              class="external"
              style="height: 150px; width: 100%"
            >
              <span class="badge badge-local"> + SUMATE </span>
            </a>
          </f7-block>

          <f7-block style="padding: 15px 10px; margin: 0">
            <f7-link
              href="/map/"
              view="#main-view"
              panel-close
              style="display: block"
            >
              <f7-row>
                <f7-col width="80"> Mapa de Locales </f7-col>
                <f7-col width="20">
                  <i data-eva="pin-outline" data-eva-fill="#eaeaea"></i>
                </f7-col>
              </f7-row>
            </f7-link>
          </f7-block>

          <f7-list accordion-list style="margin: 0">
            <!-- LISTA DE CIUDADES -->
            <!-- <f7-list-item
              accordion-item
              title="Ciudades"
            >
              <f7-accordion-content
                v-for="(ciudad, index) in menuData.ciudades"
                :key="index"
              >
                <f7-block style="padding: 15px 10px;">
                  <f7-row>
                    <a @click="navigateToLinkMenu(ciudad.subdominio)" style="display: contents;">
                      <f7-col width="80">
                        {{ ciudad.name }}
                      </f7-col>
                      <f7-col width="20">
                        <i data-eva="chevron-right-outline" data-eva-fill="#eaeaea"></i>
                      </f7-col>
                    </a>
                  </f7-row>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item> -->

            <!-- LISTA DE LOCALES -->
            <f7-list-item accordion-item title="Locales">
              <f7-accordion-content
                v-for="(local, index) in menuData.locales"
                :key="index"
              >
                <f7-block style="padding: 15px 10px">
                  <f7-row>
                    <a
                      @click="navigateToLinkMenu(local.name)"
                      style="display: contents"
                    >
                      <f7-col width="80">
                        {{ local.nombre_local }}
                      </f7-col>
                      <f7-col width="20">
                        <i
                          data-eva="chevron-right-outline"
                          data-eva-fill="#eaeaea"
                        ></i>
                      </f7-col>
                    </a>
                  </f7-row>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </f7-page>

        <!-- MENU DE LOCALES -->
        <f7-page v-else-if="$root.local !== undefined" name="menu-page-local">
          <f7-list style="margin: 0; width: 80%; margin: 0 auto">
            <!-- FACEBOOK -->
            <f7-list-item v-if="$root.docLocal.facebook">
              <div class="menu-local-icono-container">
                <a
                  rel="noopener noreferrer"
                  class="link external menu-local-icono-link"
                  :href="'https://facebook.com/' + $root.docLocal.facebook"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f local-menu-icons"></i>
                </a>
              </div>
            </f7-list-item>
            <!-- INSTAGRAM -->
            <f7-list-item v-if="$root.docLocal.instagram">
              <div class="menu-local-icono-container">
                <a
                  rel="noopener noreferrer"
                  class="link external menu-local-icono-link"
                  :href="'https://instagram.com/' + $root.docLocal.instagram"
                  target="_blank"
                >
                  <i class="fab fa-instagram local-menu-icons"></i>
                </a>
              </div>
            </f7-list-item>

            <!-- ENCONTRANOS -->
            <f7-list-item v-if="$root.docLocal.link_google_maps ||
                ($root.docLocal.latitud && $root.docLocal.longitud)">
              <div class="menu-local-icono-container">
                <a
                  class="link external menu-local-icono-link no-margin"
                  @click="
                    !$root.localFromMapSwiper
                      ? navigateToLocalLocation($root.docLocal.name)
                      : undefined
                  "
                >
                  <i class="fas fa-map-marker-alt local-menu-icons"></i>
                  <!-- <span style="font-weight: 400">Encontranos</span> -->
                </a>
              </div>
            </f7-list-item>
            <!-- INFORMACION ADICIONAL -->
            <f7-list-item
              class="local-menu-info-adicional-item"
              v-if="$root.docLocal.informacion_adicional"
            >
              <div
                class="
                  menu-local-texto-container
                  menu-local-info-adicional-icon-container
                "
              >
                <div class="menu-local-icono-adicional-container">
                  <i
                    class="
                      fas
                      fa-info
                      local-menu-icons local-menu-info-adicional-icon
                    "
                  ></i>
                </div>
                <span style="font-weight: 400">
                  {{ $root.docLocal.informacion_adicional }}
                </span>
              </div>
            </f7-list-item>
          </f7-list>

          <!-- LOGO DE MENU | INFERIOR -->
          <a :href="`${$root.scheme}${$root.domainHostName}`" class="external">
            <div class="panel-menu-local-logo-bottom"></div>
          </a>

          <!-- <openingHoursSheet :props="props" /> -->
          <shareSheetCity :props="props" />
        </f7-page>
      </f7-view>
    </f7-panel>

    <f7-view
      class="main-view"
      id="main-view"
      :push-state="true"
      :stack-pages="false"
      push-state-separator=""
      :push-state-on-load="true"
      main
      url="/"
    ></f7-view>
  </f7-app>
</template>

<script>
import routes from "../js/routes.js";
import utils from "../utils.js";
import shareSheetCity from "../components//city/city-share-sheet.vue";

export default {
  components: {
    // openingHoursSheet,
    shareSheetCity,
  },
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "Alacarta", // App name
        theme: "auto", // Automatic theme detection
        touch: {
          mdTouchRipple: false,
          auroraTouchRipple: false,
          auroraTouchRipple: false,
        },
        dialog: {
          // set default title for all dialog shortcuts
          title: "Alacarta.online",
        },
        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: {
          path: "/service-worker.js",
        },
      },
      props: {
        shareSheetOpened: false,
        counterSWIntervals: 0,
      },
      view: {
        stackPages: true,
        pushState: true,
        pushStateSeparator: "",
        pushStateOnLoad: false,
      },

      // Login screen data
      username: "",
      password: "",
      menuData: [],
      loading: true,
    };
  },
  methods: {
    alertLoginData() {
      this.$f7.dialog.alert(
        "Username: " + this.username + "<br>Password: " + this.password,
        () => {
          this.$f7.loginScreen.close();
        }
      );
    },
  },
  beforeMount() {
    const self = this;

    navigator.serviceWorker.addEventListener("message", (event) => {
      console.log("serviceWorker.message")
      if (event.data.version !== localStorage.getItem("version")) {
        if (localStorage.getItem("version") === null) {
          localStorage.setItem("version", event.data.version);
        } else {
          if (
            // No aplica a la Landing Page
            window.location.href !== `https://${self.$root.domainHostName}/`
          ) {
            self.$f7.toast
              .create({
                title: "Nueva Versión",
                cssClass: "update-version-toast",
                text: "Nueva Versión de Alacarta.Online!",
                closeButton: true,
                closeButtonText: "Ingresar",
                on: {
                  closeButtonClick: () => {
                    self.$f7.dialog.progress("Ingresando");

                    navigator.serviceWorker
                      .getRegistration()
                      .then(function (reg) {
                        if (reg) {
                          localStorage.setItem("version", event.data.version);
                          setTimeout(() => {
                            window.location.reload(true);
                          }, 2000);
                        } else {
                          localStorage.setItem("version", event.data.version);
                          setTimeout(() => {
                            window.location.reload(true);
                          }, 2000);
                        }
                      });
                  },
                },
              })
              .open();

              self.$$(".main-view").addClass("disable-newer-version");
              self.$$(".framework7-root").addClass("disable-newer-version-bgnd");
          }
        }
      }
    });
  },
  mounted() {
    const self = this;

    this.$f7ready((f7) => {});

    self.getConfirmedCartNames();

    // Comprueba el entorno donde se esta ejecutando la App.
    if (navigator.standalone) {
      self.$root.standaloneApp = true;
    } else if (matchMedia("(display-mode: standalone)").matches) {
      self.$root.standaloneApp = true;
    } else {
      self.$root.standaloneApp = false;
    }
  },
  methods: {
    getMenuData() {
      const self = this;
      var getMenuDataCallback = function (e) {
        self.menuData = e.message.data;
      };
      utils.customRequest(
        self,
        getMenuDataCallback,
        "utils.get_menu_data",
        "POST",
        {
          ciudad: self.$root.ciudad,
        },
        ""
      );
    },
    navigateToLinkMenu(localName) {
      // window.location.replace(`https://${subdomain}.alacarta.online`);
      const self = this;
      self.$f7.panel.close();
      self.$f7.views.main.router.navigate("/local/" + localName + "/", {
        ignoreCache: false,
        reloadCurrent: true,
      });
    },
    navigateToLocalLocation(docName) {
      const self = this;
      self.$f7.panel.close();
      self.$root.showMenu = false;
      self.$f7.views.main.router.navigate("/map/", {
        // ignoreCache: true,
        // clearPreviousHistory: false,
        props: {
          nameFromLocalPage: docName,
        },
      });
    },
    nativeShare() {
      const self = this;
      const local = self.$root.local;
      const localCap =
        self.$root.local.charAt(0).toUpperCase() + self.$root.local.slice(1);
      const subDominioLocal = self.$root.docLocal.subdominio;

      if (navigator.share) {
        navigator
          .share({
            title: `${localCap}`,
            text: `Encontra todo lo que tiene para ofrecerte ${localCap} 👉`,
            url: `https://${subDominioLocal}.alacarta.online`,
          })
          .then()
          .catch((error) => undefined);
      } else {
        self.shareSheetOpened = true;
      }
    },
    checkIfEmpty(local_data, info_to_check) {
      const self = this;
      var re = new RegExp(info_to_check, "gi");

      if (re.test(local_data.id_tipo_informacion)) {
        if (!utils.isEmpty(self.$root.docLocal[info_to_check.toLowerCase()])) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getConfirmedCartNames() {
      const self = this;
      let lsFoodConfirmedCart = JSON.parse(
        window.localStorage.getItem("confirmed_cart")
      );

      if (lsFoodConfirmedCart) {
        self.$root.confirmedCart = Object.keys(lsFoodConfirmedCart);
      }
    },
  },
};
</script>
