<template>
  <span class="local-carta-atributos-plato">
    <!-- CONTENEDOR  -->
    <span
      class="badge local-carta-atributos-plato-badge"
      v-if="$root.listFoodType === 'Salon' && atributo.habilitado === 1 && atributo.sin_stock === 0"
    >
      <!-- NOMBRE DE ATRIBUTO -->
      <span style="color: #222222">{{ atributo.nombre_atributo }}</span
      >&nbsp;
      <!-- PRECIO SALON -->
      <!-- PRECIO DE ATRIBUTO | NORMAL| SALON -->
      <span
        :class="
          atributo.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            $root.listFoodType === 'Salon' &&
            atributo.precio > 0
        "
      >
        <!-- <span class="space-before-precio">&nbsp;</span> -->
        $
        {{ formatCurrency(parseFloat(atributo.precio)) || "" }}
      </span>
      <span class="space-before-precio">&nbsp;</span>
      <!-- PRECIO DE ATRIBUTO | NORMAL | CON DECIMAL | SALON -->
      <!-- <span
        :class="
          atributo.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            parseFloat(atributo.precio) % 1 !== 0 &&
            $root.listFoodType === 'Salon'
        "
      >
        <span class="space-before-precio">&nbsp;</span>$
        {{ parseFloat(atributo.precio).toFixed(2) || "" }} </span
      ><span class="space-before-precio">&nbsp;</span> -->
      <!-- PRECIO DE ATRIBUTO | NORMAL | SIN DECIMAL | SALON -->
      <!-- <span
        :class="
          atributo.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio > 0 &&
            parseFloat(atributo.precio) % 1 === 0 &&
            $root.listFoodType === 'Salon'
        "
      >
        <span class="space-before-precio">&nbsp;</span>$
        {{ parseFloat(atributo.precio).toFixed(0) || ""
        }}<span class="space-before-precio">&nbsp;</span>
      </span> -->
      <!-- PRECIO DE ATRIBUTO | REBAJADO | SALON -->
      <span
        class="precio-rebajado"
        v-if="
          $root.docLocal.mostrar_precios &&
            $root.listFoodType === 'Salon' &&
            atributo.precio_rebajado > 0
        "
      >
        <!-- &nbsp; -->
        $
        {{ formatCurrency(parseFloat(atributo.precio_rebajado)) || "" }}&nbsp;
      </span>
      <!-- PRECIO DE ATRIBUTO | REBAJADO | CON DECIMAL | SALON -->
      <!-- <span
        class="precio-rebajado"
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio > 0 &&
            parseFloat(atributo.precio) % 1 !== 0 &&
            $root.listFoodType === 'Salon'
        "
      >
        &nbsp;$
        {{ parseFloat(atributo.precio_rebajado).toFixed(2) || "" }}&nbsp;
      </span> -->
      <!-- PRECIO DE ATRIBUTO | REBAJADO | SIN DECIMAL | SALON -->
      <!-- <span
        class="precio-rebajado"
        v-else-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio_rebajado > 0 &&
            parseFloat(atributo.precio_rebajado) % 1 === 0 &&
            $root.listFoodType === 'Salon'
        "
      >
        &nbsp;$
        {{ parseFloat(atributo.precio_rebajado).toFixed(0) || "" }}&nbsp;
      </span> -->
      <!-- SEPARADOR DE ATRIBUTOS (OMITE EL PIPE DEL FINAL) -->
      <i
        v-if="
          $root.listFoodType === 'Salon' &&
            indexLevelAtributo < subitem.atributos_del_plato.length - 1
        "
        style="color: #777777"
      >
        |&nbsp;
      </i>
    </span>
  </span>
</template>

<script>
import utils from "../../../utils.js";

export default {
  props: {
    props: Object,
    subitem: Object,
    atributo: Object,
    indexLevelAtributo: Number,
  },
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
