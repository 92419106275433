<template>
  <f7-page
    name="local-page"
    @page:beforein="localBeforeIn"
    @page:afterin="localAfterIn"
  >
    <fullscreenLoader :props="props" />

    <!-- BLOQUE PRINCIPAL | LOCAL -->
    <f7-block class="local-main-block" v-if="!props.loading && $root.docLocal">
      <!-- <f7-block
      class="local-main-block"
      v-if="!props.loading && $root.docLocal && $root.changeMap === false"
    > -->
      <!-- BARRA SUPERIOR DE LOCAL -->
      <localNavbar :props="props" />

      <!-- COVER DE LOCAL -->
      <localCover :props="props" />

      <!-- CABECERA DE LOCAL -->
      <localInfoHeader :props="props" />

      <!-- LOADER DE CARTA -->
      <localLoaderMenu :props="props" />

      <!-- CARTA VACIA -->
      <!-- <localEmptyMenu :props="props" /> -->

      <!-- CARTA DE COMIDAS -->
      <localMenuList :props="props" />
    </f7-block>

    <localImageFoodSheet :props="props" />
    <localBannerSheet :props="props" />
    <localShareSheet :props="props" />
    <localCartaSelectionSheet
      v-if="
        $root.docLocal &&
        $root.docLocal.cartas &&
        $root.docLocal.cartas.length > 0 &&
        props.SelectionMenuSheetOpened
      "
      :props="props"
    />
    <localOpeningHoursSheet :props="props" />
    <cartOngoingOrderSheet :props="props" />
    <localPopupLocalCerrado :props="props" />

    <div
      class="fab fab-left-bottom fab-order"
      style="margin-bottom: 22px"
      :style="
        $root.docLocal.habilitar_traduccion_automatica
          ? 'margin-bottom: 50px;'
          : ''
      "
      v-if="
        $root.docLocal &&
        $root.docLocal.panel_pedidos &&
        Object.keys($root.ongoingOrder).length > 0
      "
    >
      <a href="#" @click="$root.cartOngoingOrderSheetOpened = true">
        <f7-gauge
          v-if="existOngoingOrder"
          type="circle"
          :value="$root.ongoingOrderProgress / 100"
          :size="250"
          border-color="#734903"
          border-bg-color="#efa113"
          :border-width="15"
          style="position: absolute; bottom: -2px; z-index: 1"
        ></f7-gauge>
        <img src="static/images/take-away-v2.png" height="45" alt="" />
        <!-- <i class="fas fa-shopping-cart"></i> -->
      </a>
      <!-- <span slot="text" class=""> </span> -->
    </div>

    <div
      class="fab fab-right-bottom fab-cart"
      :style="
        $root.docLocal.habilitar_traduccion_automatica
          ? 'margin-bottom: 50px;'
          : ''
      "
      v-if="
        !props.loading &&
        !$root.maintenanceMode &&
        $root.confirmedCart.length > 0 &&
        $root.docLocal &&
        $root.docLocal.nombre_local === $root.ls.getItem('food_local')
      "
    >
      <a href="#" @click="navigateTo('/cart/', false)">
        <i class="fas fa-shopping-basket"></i>
      </a>
      <span slot="text" class="shopping-basket-counter"
        >
        <span v-if="$root.grandTotalCart && $root.grandTotalCart > 0">$ {{ formatCurrency(parseFloat($root.grandTotalCart)) }}</span>
        <span v-else><i class="fas fa-spinner fa-spin" style="font-size: 12px; padding: 0 15px;" aria-hidden="true"></i></span>
      </span
      >
    </div>

    <div
      v-if="props.BannerSheetLocalOpened"
      class="sheet-backdrop backdrop-in sheet-backdrop-banner"
      style="z-index: 10000"
    ></div>
  </f7-page>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import local from "../local.js";
import cart from "../cart.js";
import utils from "../utils.js";
import fullscreenLoader from "../components/fullscreen-loader.vue";
import localImageFoodSheet from "../components/local/menu-list/local-image-food-sheet.vue";
import localBannerSheet from "../components/local/local-banner-sheet.vue";
import localShareSheet from "../components/local/local-share-sheet.vue";
import localCartaSelectionSheet from "../components/local/local-carta-selection-sheet.vue";
import localOpeningHoursSheet from "../components/local/local-opening-hours-sheet.vue";
import localNavbar from "../components/local/local-navbar.vue";
import localCover from "../components/local/local-cover.vue";
import localInfoHeader from "../components/local/local-info-header.vue";
import localLoaderMenu from "../components/local/local-loader-menu.vue";
import localEmptyMenu from "../components/local/local-empty-menu.vue";
import localMenuList from "../components/local/local-menu-list.vue";
import cartOngoingOrderSheet from "../components/cart/cart-ongoing-order-sheet.vue";
import localPopupLocalCerrado from "../components/local/local-popup-local-cerrado.vue";

export default {
  components: {
    fullscreenLoader,
    localImageFoodSheet,
    localBannerSheet,
    localShareSheet,
    localCartaSelectionSheet,
    localOpeningHoursSheet,
    localNavbar,
    localCover,
    localInfoHeader,
    localLoaderMenu,
    localEmptyMenu,
    localMenuList,
    cartOngoingOrderSheet,
    localPopupLocalCerrado,
  },
  data: function () {
    return {
      props: {
        loading: true,
        localPopupLocalCerradoOpened: false,
        localImageFoodSheetOpened: false,
        currentFoodImage: undefined,
        currentFoodName: undefined,
        currentFoodDescription: undefined,
        BannerSheetLocalOpened: false,
        currentBannerImage: undefined,
        // loadingFood: true,
        shareSheetOpened: false,
        // listFoodType: "",
        // listFoodTypeIndex: 0,
        // listFoodSelected: [],
        // listFoodSalon: [],
        // listFoodDelivery: [],
        SelectionMenuSheetOpened: false,
        estadoHorarioLocal: "",
        openingHoursSheetOpened: false,
        formattedOpeningHours: {},
        cambioDeCartaPromptLocal: undefined,
        autoplaySlides: false,
        loopSlides: false,
        openingHours: [],
        vlData: {
          items: [],
        },
        vlDataOngoing: {
          items: [],
        },
        cartDetailCollapsed: true,
        ongoingTotals: {},
        orderProgress: {
          progress: 0,
        },
        currentBannerLink: undefined,
        docLocal: undefined,
        // items: [],
      },

      existOngoingOrder: false,
    };
  },
  beforeMount: function () {
    const self = this;
    let confirmedCartLS = window.localStorage.getItem("confirmed_cart");
    let foodTypeLS = window.localStorage.getItem("food_type");
    let sameSession = JSON.parse(window.sessionStorage.getItem("session"));
    // self.$$("#google_translate_element").css("display", "none");
    // self.$$(".goog-te-gadget span").css("display", "none");
    // self.$$(".goog-te-menu2-item").css("color", "#000000 !important");

    if (!window.sessionStorage.getItem("session")) {
      if (
        confirmedCartLS &&
        Object.keys(JSON.parse(confirmedCartLS)).length === 0
      ) {
        window.sessionStorage.setItem("session", "true");
        self.props.SelectionMenuSheetOpened = true;
        self.cleanLocalStorage();
      } else if (
        confirmedCartLS &&
        Object.keys(JSON.parse(confirmedCartLS)).length > 0
      ) {
        window.sessionStorage.setItem("session", "true");
      } else {
        window.sessionStorage.setItem("session", "true");
        self.props.SelectionMenuSheetOpened = true;
        self.cleanLocalStorage();
      }
    } else if (window.sessionStorage.getItem("session") && !foodTypeLS) {
      self.props.SelectionMenuSheetOpened = true;
      self.cleanLocalStorage();
    }
  },
  mounted: async function () {
    const self = this;

    self.$root.listFoodType = window.localStorage.getItem("food_type");

    local.getFood(self, self.$f7route.params.localId);
    local.shrinkHeader(self);
    eva.replace();

    // Manejo del botón de ATRAS en Android (Cierra lo que este abierto)
    (function () {
      if (window.history && window.history.pushState) {
        self.$$(window).on("popstate", function (e) {
          self.$f7.sheet.close();
          self.$f7.panel.close();
          self.$f7.dialog.close();
        });
      }
    })();

    // Limpia la URL para que sea mas amigable y no mostrar el DocName del Local
    setTimeout(function () {
      window.history.replaceState({}, document.title, "/" + "");
    }, 500);

    self.$root.foodType = window.localStorage.getItem("food_type");
    self.$root.listFoodType = window.localStorage.getItem("food_type");

    cart.calculateGandTotal(self);
    // cart.updatePricesCheckout(self);

    // A2HS
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   self.a2hsReady = true;
    //   e.preventDefault();
    //   self.deferredPrompt = e;
    //   // showInstallPromotion();
    // });

    // await self.MpRedirect();

    self.existOngoingOrder = await cart.getOngoingOrderUpdate(self);

    if (self.existOngoingOrder) {
      self.$root.ongoingOrderProgress =
        self.$root.ongoingOrderStatuses[
          self.$root.ongoingOrder.estado
        ].progress;
      self.props.orderProgress =
        self.$root.ongoingOrderStatuses[self.$root.ongoingOrder.estado];
    }

    self.props.loading = false;
    setTimeout(() => {
      self.$root.loading = false;
    }, 1500);
    // self.$$(".goog-te-gadget")[0].childNodes[1].data = ""
  },
  // watch: {
  //   props: {
  //     handler(val) {
  //       const self = this;
  //       if (val.listFoodSelected.length > 0) {
  //         self.props.loadingFood = false;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  updated: function () {
    const self = this;
    eva.replace();

    // if (self.$root.changeMap === true) {
    //   setTimeout(function() {
    //     self.$root.changeMap = false;
    //   }, 2000);
    // }

    self.$root.foodType = window.localStorage.getItem("food_type");
    self.$root.listFoodType = window.localStorage.getItem("food_type");
  },
  methods: {
    navigateTo(url, ignoreCache) {
      utils.navigateTo(this, url, ignoreCache);
    },
    localBeforeIn() {
      const self = this;
      local.getLocalByDocname(self, self.$f7route.params.localId);
    },
    localAfterIn() {
      const self = this;
      setTimeout(() => {
        document
          .querySelectorAll("div[data-name='local-page']")
          .forEach((page) => {
            self.$f7.lazy.create(page);
          });
      }, 2000);
      // let ongoingOrderTrackingSheetFirstTime = localStorage.getItem(
      //   "ongoing_order_tracking_sheet_first_time"
      // );

      // if (ongoingOrderTrackingSheetFirstTime === "true") {
      //   self.$root.cartOngoingOrderSheetOpened = true;
      //   localStorage.removeItem("ongoing_order_tracking_sheet_first_time");
      // }
    },
    cleanLocalStorage() {
      local.cleanLocalStorage(this);
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
    // async MpRedirect() {
    //   const self = this;
    //   await new Promise((resolve) => {
    //     const queryString = window.location.search;
    //     const urlParams = new URLSearchParams(queryString);

    //     // Vuelve del CheckOut de MP
    //     if (localStorage.getItem("mercadopago_redirect") === "true") {
    //       // Coinciden la preferencias de MP
    //       if (
    //         localStorage.getItem("mercadopago_preference_id") ===
    //         urlParams.get("preference_id")
    //       ) {
    //         if (urlParams.get("status") === "approved") {
    //           // El pago de MP esta aprobado (modo binario)
    //           self.createPedidoMp(true, urlParams, "approved");

    //           // cart.approvedMercadoPagoPayment(self, urlParams);
    //         } else if (urlParams.get("status") !== "approved") {
    //           // El pago de MP esta rechazado (modo binario)
    //           cart.rejectedMercadoPagoPayment(self);
    //           resolve();
    //         }
    //         // No coinciden la preferencias de MP
    //       } else if (
    //         urlParams.get("preference_id") &&
    //         localStorage.getItem("mercadopago_preference_id") !==
    //           urlParams.get("preference_id")
    //       ) {
    //         cart.rejectedMercadoPagoPayment(self);
    //         resolve();
    //       } else {
    //         self.redirectToMercadoPagoPrompt = self.$f7.dialog
    //           .create({
    //             cssClass: "elimina-carrito-dialog",
    //             title: "",
    //             text: `No podemos comprobar el estado de tu pago en Mercado Pago<br /><br />¿Has realizado el pago correctamente?`,
    //             buttons: [
    //               {
    //                 text: "Ya Pagué",
    //                 cssClass: "elimina-carrito-cancel-btn-dialog",
    //                 onClick: async function (e, dialog) {
    //                   await self.createPedidoMp(
    //                     false,
    //                     urlParams,
    //                     "receipt_pending"
    //                   );

    //                   if (
    //                     self.$root.docLocal.pedidos_whatsapp &&
    //                     self.$root.docLocal.pedidos_whatsapp == 1
    //                   ) {
    //                     cart.pendingMercadoPagoPayment(self);
    //                     resolve();
    //                   } else {
    //                     self.$f7.dialog
    //                       .create({
    //                         cssClass: "elimina-carrito-dialog",
    //                         title: "",
    //                         text: `El local podría solicitarte tu comprobante de pago.`,
    //                       })
    //                       .open();

    //                     setTimeout(() => {
    //                       self.$f7.dialog.close();
    //                     }, 2000);
    //                     resolve();
    //                   }
    //                 },
    //               },
    //               {
    //                 text: "No Pagué",
    //                 cssClass: "elimina-carrito-ok-btn-dialog",
    //                 onClick: function (e, dialog) {
    //                   cart.rejectedMercadoPagoPayment(self);
    //                   resolve();
    //                 },
    //               },
    //             ],
    //             destroyOnClose: true,
    //           })
    //           .open();
    //       }
    //     } else {
    //       resolve();
    //     }
    //   });
    // },
    async createPedidoMp(envioWaAlert, urlParams, paymentStatus) {
      await new Promise((resolve) => {
        const self = this;

        if (
          self.$root.docLocal.pedidos_whatsapp &&
          self.$root.docLocal.pedidos_whatsapp == 1
        ) {
          self.$f7.dialog.preloader("Generando Pedido de WhatsApp");
        }

        let createPedidoMpApprovedCallback = (r) => {
          localStorage.setItem("pedido_en_curso", r.message.pedido.name);
          self.$root.ongoingOrder = r.message.pedido;

          localStorage.setItem(
            "ongoing_cart",
            localStorage.getItem("confirmed_cart")
          );

          localStorage.removeItem("jsonPedidoMpApproved");

          // Si tiene que enviar el pedido por WhatsApp fuerza el envío con PopUp
          if (
            self.$root.docLocal.pedidos_whatsapp &&
            self.$root.docLocal.pedidos_whatsapp == 1
          ) {
            // Modifica Nro de Pedido y Fecha con los datos del Pedido del Backend
            let cartWhatsapMessage = localStorage.getItem(
              "cart_whatsapp_message"
            );

            cartWhatsapMessage = cartWhatsapMessage.replace(
              /NUMERO_PEDIDO/gi,
              encodeURIComponent(r.message.pedido.numero_pedido)
            );
            cartWhatsapMessage = cartWhatsapMessage.replace(
              /FECHA_HORA_PEDIDO/gi,
              encodeURIComponent(
                cart.transformDateTime(r.message.pedido.fecha_hora_pedido)
              )
            );

            localStorage.setItem("cart_whatsapp_message", cartWhatsapMessage);

            if (
              self.$root.docLocal.panel_pedidos &&
              self.$root.docLocal.panel_pedidos == 1
            ) {
              localStorage.setItem(
                "ongoing_order_tracking_sheet_first_time",
                "true"
              );
            }

            if (paymentStatus === "approved") {
              cart.changeStatusMP(self, "Pagado");
            } else if (paymentStatus === "receipt_pending") {
              cart.changeStatusMP(self, "Esperando Comprobante");
            }

            if (envioWaAlert === true) {
              self.$f7.toast
                .create({
                  title: "",
                  cssClass: "update-version-toast",
                  text: "Envía tu pedido por WhatsApp",
                  closeButton: true,
                  closeButtonText: "Enviar",
                  on: {
                    closeButtonClick: () => {
                      cart.approvedMercadoPagoPayment(self, urlParams);
                    },
                  },
                })
                .open();

              self.$$(".main-view").addClass("disable-newer-version");
              self
                .$$(".framework7-root")
                .addClass("disable-newer-version-bgnd");
              resolve();
            } else {
              resolve();
            }
          } else {
            cart.clearAll(self).then((r) => {
              self.$root.cartOngoingOrderSheetOpened = true;
              resolve();
            });
          }
        };

        utils.customRequest(
          self,
          createPedidoMpApprovedCallback,
          "pedido.pedido.create_pedido",
          "POST",
          JSON.parse(localStorage.getItem("jsonPedidoMpApproved")),
          null
          // callbackErr
        );
      });
    },
  },
};
</script>
