var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"city-page"}},[_c('f7-block',{staticStyle:{"width":"100%","margin":"20px 0px 10px 0px"}},[_c('f7-row',[_c('f7-col',{attrs:{"width":"70"}},[_c('div',{style:({
            'background-image':
              'url(/static/images/Alacarta_Logo_Transparent.png)',
            'background-position': 'left center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            height: '100px',
            width: '100%',
            'border-radius': '5px',
          })})]),_vm._v(" "),_c('f7-col',{staticStyle:{"z-index":"100","text-align":"end","height":"80px","display":"flex","align-items":"center","justify-content":"flex-end"},attrs:{"width":"30"}},[_c('f7-link',{on:{"click":_vm.nativeShare}},[_c('i',{staticStyle:{"vertical-align":"sub","padding":"5px 10px","margin-top":"8px"},attrs:{"data-eva":"share-outline","data-eva-fill":"#444444"}})]),_vm._v(" "),_c('f7-link',{directives:[{name:"show",rawName:"v-show",value:(!this.$root.standaloneApp && _vm.a2hsReady === false),expression:"!this.$root.standaloneApp && a2hsReady === false"}]},[_c('i',{staticClass:"a2hs-btn",staticStyle:{"vertical-align":"sub","padding":"5px 10px","margin-top":"8px"},attrs:{"data-eva":"download-outline","data-eva-fill":"#eaeaea"}})]),_vm._v(" "),_c('f7-link',{directives:[{name:"show",rawName:"v-show",value:(!this.$root.standaloneApp && _vm.a2hsReady === true),expression:"!this.$root.standaloneApp && a2hsReady === true"}],on:{"click":_vm.installApp}},[_c('i',{staticClass:"a2hs-btn",staticStyle:{"vertical-align":"sub","padding":"5px 10px","margin-top":"8px"},attrs:{"data-eva":"download-outline","data-eva-fill":"#444444"}})])],1)],1)],1),_vm._v(" "),(!_vm.loading)?_c('f7-block',{staticStyle:{"width":"100%","margin-top":"30px"}},_vm._l((_vm.listCities),function(city,index){return _c('f7-row',{key:index,staticClass:"home-cities-list",style:({
        'background-image':
          'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(\'https://' +
          _vm.$root.serverHostBase +
          city.icono_ciudad +
          '\)',
        'background-position': 'center center',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      })},[_c('f7-col',{on:{"click":function($event){return _vm.navigateToCity(city.subdominio, city.icono_ciudad)}}},[_c('f7-row',[_c('f7-col',[_c('div',{staticStyle:{"color":"#ffffff"}},[_vm._v("\n              "+_vm._s(city.name)+"\n            ")])])],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticStyle:{"text-align":"center"},attrs:{"width":"100"}},[(city.cantidad_locales == 0)?_c('span',{staticClass:"badge"},[_vm._v("\n              No hay Locales\n            ")]):(city.cantidad_locales == 1)?_c('span',{staticClass:"badge"},[_vm._v("\n              "+_vm._s(city.cantidad_locales)+" Local\n            ")]):(city.cantidad_locales > 1)?_c('span',{staticClass:"badge"},[_vm._v("\n              "+_vm._s(city.cantidad_locales)+" Locales\n            ")]):_vm._e()])],1)],1)],1)}),1):_vm._e(),_vm._v(" "),(_vm.loading)?_c('f7-list',{attrs:{"media-list":""}},_vm._l((4),function(n){return _c('f7-list-item',{key:n,class:("skeleton-text skeleton-effect-" + _vm.effect),staticStyle:{"padding-left":"0px !important","width":"95%","margin":"10px auto"},attrs:{"title":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXX","subtitle":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX","text":"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}},[_c('f7-skeleton-block',{staticStyle:{"width":"60px","height":"60px","margin-top":"5px","border-radius":"10%"},attrs:{"slot":"media"},slot:"media"})],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }