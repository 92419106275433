var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$root.confirmedCart.length > 0)?_c('f7-block',{staticClass:"total-cart-block",style:(_vm.$root.docLocal.habilitar_traduccion_automatica
      ? 'margin-bottom: 75px;'
      : '')},[(
      parseFloat(_vm.$root.costoEnvio).toFixed(2) > 0.0 ||
        parseFloat(_vm.$root.recargoPago).toFixed(2) !== 0.0
    )?_c('f7-row',{staticClass:"totals-cart-row"},[_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Subtotal")])]),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"right","font-weight":"600","font-size":"16px"},attrs:{"width":"40"}},[_c('span',[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.grandTotalCart))))])])],1):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.costoEnvio) > 0)?_c('f7-row',{staticClass:"totals-cart-row"},[_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Costo de Envío")])]),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"right","font-weight":"600","font-size":"16px"},attrs:{"width":"40"}},[_c('span',[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.costoEnvio))))])])],1):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.recargoPago) !== 0.0)?_c('f7-row',{staticClass:"totals-cart-row"},[_c('f7-col',{attrs:{"width":"60"}},[(parseFloat(_vm.$root.recargoPago) > 0.0)?_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Recargo de Pago")]):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.recargoPago) < 0.0)?_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Descuento por Pago")]):_vm._e()]),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"right","font-weight":"600","font-size":"16px"},attrs:{"width":"40"}},[(_vm.$root.tipoRecargoPago === 'Importe Fijo')?_c('span',[_vm._v("\n        $ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.recargoPago)))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.$root.tipoRecargoPago === 'Porcentaje del Subtotal')?_c('span',[_vm._v("\n        $\n        "+_vm._s(_vm.formatCurrency(
            parseFloat(_vm.$root.grandTotalCart) *
              (parseFloat(_vm.$root.recargoPago) / 100)
          ))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.$root.tipoRecargoPago === 'Porcentaje del Total')?_c('span',[_vm._v("\n        $\n        "+_vm._s(_vm.formatCurrency(
            (parseFloat(_vm.$root.grandTotalCart) +
              parseFloat(_vm.$root.costoEnvio)) *
              (parseFloat(_vm.$root.recargoPago) / 100)
          ))+"\n      ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.valorCuponDescuentoTotal) < 0.0)?_c('f7-row',{staticClass:"totals-cart-row"},[_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Cupón de Descuento")])]),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"right","font-weight":"600","font-size":"16px"},attrs:{"width":"40"}},[_c('span',[_vm._v("\n        $ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.valorCuponDescuentoTotal)))+"\n      ")])])],1):_vm._e(),_vm._v(" "),_c('f7-row',{staticClass:"totals-cart-row"},[_c('f7-col',{attrs:{"width":"60"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Total del Pedido")])]),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"right","font-weight":"800","font-size":"18px"},attrs:{"width":"40"}},[(_vm.$root.costoEnvio > 0.0 || _vm.$root.recargoPago !== 0.0)?_c('span',[_vm._v("$\n        "),_vm._v(" "),(_vm.$root.tipoRecargoPago === 'Importe Fijo')?_c('span',[_vm._v("\n          "+_vm._s(_vm.formatCurrency(
              parseFloat(_vm.$root.grandTotalCart) +
                parseFloat(_vm.$root.costoEnvio) +
                parseFloat(_vm.$root.recargoPago) +
                parseFloat(_vm.$root.valorCuponDescuentoTotal)
            ))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.$root.tipoRecargoPago === 'Porcentaje del Subtotal')?_c('span',[_vm._v("\n          "+_vm._s(_vm.formatCurrency(
              parseFloat(_vm.$root.grandTotalCart) +
                parseFloat(_vm.$root.costoEnvio) +
                parseFloat(_vm.$root.grandTotalCart) *
                  (parseFloat(_vm.$root.recargoPago) / 100) +
                parseFloat(_vm.$root.valorCuponDescuentoTotal)
            ))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.$root.tipoRecargoPago === 'Porcentaje del Total')?_c('span',[_vm._v("\n          "+_vm._s(_vm.formatCurrency(
              parseFloat(_vm.$root.grandTotalCart) +
                parseFloat(_vm.$root.costoEnvio) +
                (parseFloat(_vm.$root.grandTotalCart) +
                  parseFloat(_vm.$root.costoEnvio)) *
                  (parseFloat(_vm.$root.recargoPago) / 100) +
                parseFloat(_vm.$root.valorCuponDescuentoTotal)
            ))+"\n        ")]):_vm._e()]):_c('span',[_vm._v("$\n        "+_vm._s(_vm.formatCurrency(
            parseFloat(_vm.$root.grandTotalCart) +
              parseFloat(_vm.$root.valorCuponDescuentoTotal)
          )))])])],1),_vm._v(" "),(!_vm.props.continueWithOrder)?_c('f7-row',{staticClass:"warning-checkout-precios-modificados"},[_c('f7-col',{staticStyle:{"text-align":"center","font-size":"18px"},attrs:{"width":"20"}},[_vm._v("⚠️")]),_vm._v(" "),_c('f7-col',{attrs:{"width":"80"}},[_c('strong',[_vm._v("Atención")]),_vm._v(": Los precios fueron modificados\n    ")])],1):_vm._e(),_vm._v(" "),(_vm.$root.bloqueoPedidos)?_c('f7-row',[_c('f7-col',[_c('span',{staticStyle:{"margin-top":"5px","display":"block"}},[_c('span',{class:'badge badge-hacer-pedido-white badge-disabled margin-top-half'},[_c('i',{staticClass:"fas fa-times",attrs:{"aria-hidden":"true"}}),_vm._v("\n             LOCAL CERRADO\n        ")])])])],1):_c('f7-row',[_c('f7-col',[_c('span',{staticStyle:{"margin-top":"5px","display":"block"}},[(_vm.props.enableSubmitOrder && _vm.props.continueWithOrder)?_c('span',{class:'badge badge-hacer-pedido-white margin-top-half',on:{"click":function($event){_vm.props.confirmCartButtonClicked ? null : _vm.confirmCart()}}},[_c('i',{staticClass:"fas fa-check",attrs:{"aria-hidden":"true"}}),_vm._v("\n             "+_vm._s(_vm.$root.docLocal.panel_pedidos && _vm.$root.docLocal.panel_pedidos == 1
              ? "CONTINUAR"
              : "CONFIRMAR PEDIDO")+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.props.enableSubmitOrder && !_vm.props.continueWithOrder)?_c('span',{class:'badge badge-hacer-pedido-white margin-top-half',on:{"click":function($event){_vm.props.confirmCartButtonClicked ? null : _vm.confirmCart()}}},[_c('i',{staticClass:"fas fa-check",attrs:{"aria-hidden":"true"}}),_vm._v("\n             "+_vm._s(_vm.$root.docLocal.panel_pedidos && _vm.$root.docLocal.panel_pedidos == 1
              ? "CONTINUAR DE TODAS FORMAS"
              : "CONFIRMAR DE TODAS FORMAS")+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.props.enableSubmitOrder)?_c('span',{class:'badge badge-hacer-pedido-white badge-hacer-pedido-disabled margin-top-half'},[_c('i',{staticClass:"fas fa-spinner fa-spin",attrs:{"aria-hidden":"true"}}),_vm._v("\n             AGUARDE\n        ")]):_vm._e()])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }