<template id="cart-checkout-payment">
  <f7-block class="no-padding-horizontal">
    <f7-row>
      <f7-col width="100" class="margin-bottom">
        Estado del Pago
        <br />
        <span class="badge badge-shop-from-local">{{
          $root.ongoingOrder.estado_pago
        }}</span></f7-col
      >

      <f7-col width="100"
        >Método de Pago
        <br />
        <span class="badge badge-shop-from-local">{{
          $root.ongoingOrder.metodo_pago
        }}</span></f7-col
      >
      <f7-col
        width="100"
        v-if="
          ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
            $root.ongoingOrder.estado
          ) &&
            ['Pendiente', 'Esperando Comprobante', 'Rechazado'].includes(
              $root.ongoingOrder.estado_pago
            ) &&
            $root.ongoingOrder.payment_link_mp != null
        "
        style="font-size: 12px; color: #8e8e93;"
      >
      <!-- <f7-col> -->
        Realiza tu pago desde este
        <a
          class="external"
          target="_blank"
          :href="$root.ongoingOrder.payment_link_mp"
          style="font-weight: 600; color: #0000EE; text-decoration: underline;"
          >enlace</a
        ></f7-col
      >
    </f7-row>
  </f7-block>
</template>

<script>
// import cart from "../../cart.js";
// import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {},
};
</script>
