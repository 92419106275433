<template>
  <f7-row>
    <f7-col>
      <!-- <div class="list no-hairlines-md">
              <ul>
                <li class="item-content item-input"> -->
      <!-- <div class="item-inner"> -->
      <div
        class="item-title item-label shop-attributes-title-section"
        style="color: #222222"
      >
        NOTAS ADICIONALES
      </div>
      <!-- <div class="item-input-wrap"> -->
      <textarea
        class="resizable shop-attributes-attribute-selection shop-textarea"
        placeholder="Escribe toda la información adicional sobre tu pedido"
        :value="$root.docCurrentFood.nota_del_plato"
        @change="
          (e) => {
            props.notaDelPlato = e.target.value;
          }
        "
      ></textarea>
      <!-- </div> -->
      <!-- </div> -->
      <!-- </li>
              </ul>
            </div> -->
    </f7-col>
  </f7-row>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>
