<template id="local-opening-hours-sheet">
  <f7-sheet
    class="local-opening-hours-sheet"
    backdrop
    swipe-to-close
    :opened="props.openingHoursSheetOpened"
    @sheet:closed="props.openingHoursSheetOpened = false"
    style="height: auto"
  >
    <f7-toolbar>
      <span class="notch"></span>
    </f7-toolbar>
    <f7-page-content>
      <f7-block-title
        large
        style="
                  text-align: left;
                  font-weight: 800;
                  margin-bottom: 0px;
                  margin-top: 15px;
                "
        >Horarios</f7-block-title
      >
      <br />
      <f7-row
        v-for="(dayOpeningHour, index) in Object.keys(
          props.formattedOpeningHours
        )"
        :key="index"
        style="padding: 5px 10px"
      >
        <!-- <span>{{ props.formattedOpeningHours[dayOpeningHour] }}</span> -->
        <f7-col>
          <span class="badge"> {{ dayOpeningHour }} </span>
        </f7-col>

        <f7-col>
          <span
            v-for="(hour, index) in props.formattedOpeningHours[dayOpeningHour]"
            :key="index"
          >
            {{ hour }}
            <br v-if="props.formattedOpeningHours[dayOpeningHour].length > 1" />
          </span>
        </f7-col>
      </f7-row>
      <br />
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function() {
    const self = this;
  },
  methods: {},
};
</script>
