<template id="city-banner-sheet">
  <f7-sheet
    class="city-banner-sheet"
    backdrop
    v-if="!props.loading"
    :swipeToClose="false"
    :closeByBackdropClick="false"
    :closeByOutsideClick="false"
    :opened="props.BannerSheetCityOpened"
    @sheet:closed="props.BannerSheetCityOpened = false"
  >
    <f7-page-content>
      <!-- <span @click="redirectToBannerLink">
        <div
          id="banner-image-sheet"
          v-bind:style="{
            'background-image': 'url(\'https://admin.alacarta.online' + encodeURI(currentBannerImage) + '\')'
          }"
          style="
            background-position:center center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 200px;
            border-radius: 10px 10px 0 0;
          "
        >
      </div> -->

      <div
        style="
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        background: white;
        position: relative;
        width: 100%;
        border-radius: 10px 10px 0 0;
      "
      >
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px 10px 0 0;
          "
          v-bind:style="{
            'background-image':
              'url(\'' +
              $root.serverHostBase +
              encodeURI(props.currentBannerImage) +
              '\')',
          }"
        ></div>
      </div>

      <!-- </span> -->

      <f7-row
        style="width: 100%; position: absolute; top: 0; justify-content: flex-end;"
      >
        <f7-col width="10" style="padding: 15px 40px 0 0">
          <a @click="props.BannerSheetCityOpened = false">
            <i
              data-eva="close-outline"
              data-eva-width="32px"
              data-eva-height="32px"
              data-eva-fill="#ffffff"
              style="filter: drop-shadow(0px 0px 2px #222);"
            ></i>
          </a>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function() {
    const self = this;
  },
  methods: {},
};
</script>
