var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$root.docCurrentFood)?_c('f7-row',[(
      _vm.$root.docCurrentFood &&
        _vm.$root.listFoodType === 'Salon' &&
        parseFloat(_vm.$root.docCurrentFood.precio) > 0
    )?_c('f7-col',{staticClass:"shop-global-price"},[(parseFloat(_vm.$root.docCurrentFood.precio) % 1 !== 0)?_c('span',[_c('span',{class:parseFloat(_vm.$root.docCurrentFood.precio_rebajado) > 0
            ? 'shop-sale-global-price'
            : 'shop-normal-global-price'},[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.docCurrentFood.precio))))]),_vm._v("\n        \n    ")]):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.docCurrentFood.precio) % 1 === 0)?_c('span',[_c('span',{class:parseFloat(_vm.$root.docCurrentFood.precio_rebajado) > 0
            ? 'shop-sale-global-price'
            : 'shop-normal-global-price'},[_vm._v("$ "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.docCurrentFood.precio))))]),_vm._v("\n        \n    ")]):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.docCurrentFood.precio) % 1 !== 0)?_c('span',[(parseFloat(_vm.$root.docCurrentFood.precio_rebajado) > 0)?_c('span',{staticClass:"shop-normal-global-price"},[_vm._v("$\n        "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.docCurrentFood.precio_rebajado))))]):_vm._e()]):_vm._e(),_vm._v(" "),(parseFloat(_vm.$root.docCurrentFood.precio) % 1 === 0)?_c('span',[(parseFloat(_vm.$root.docCurrentFood.precio_rebajado) > 0)?_c('span',{staticClass:"shop-normal-global-price"},[_vm._v("$\n        "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.docCurrentFood.precio_rebajado))))]):_vm._e()]):_vm._e()]):(
      _vm.$root.docCurrentFood &&
        _vm.$root.listFoodType === 'Delivery' &&
        parseFloat(_vm.$root.docCurrentFood.precio_delivery) > 0
    )?_c('f7-col',{staticClass:"shop-global-price"},[_c('span',{class:parseFloat(_vm.$root.docCurrentFood.precio_rebajado_delivery) > 0
          ? 'shop-sale-global-price'
          : 'shop-normal-global-price'},[_vm._v("$\n      "+_vm._s(_vm.formatCurrency(parseFloat(_vm.$root.docCurrentFood.precio_delivery))))]),_vm._v("\n      \n    "),_vm._v(" "),(parseFloat(_vm.$root.docCurrentFood.precio_rebajado_delivery) > 0)?_c('span',{staticClass:"shop-normal-global-price"},[_vm._v("$\n      "+_vm._s(_vm.formatCurrency(
          parseFloat(_vm.$root.docCurrentFood.precio_rebajado_delivery)
        )))]):_vm._e()]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }