<template>
  <f7-row class="shop-attributes-cover">
    <f7-col>
      <!-- SLIDER -->
      <f7-swiper
        v-if="$root.docCurrentFood"
        class="shop-attributes-cover-swiper"
        id="shop-attributes-swiper"
        :params="{
          init: true,
          effect: 'slide',
          loop: false,
          autoplay: false,
        }"
      >
        <!-- SLIDES DE PLATO -->
        <f7-swiper-slide class="shop-attributes-cover-swiper-slide">
          <f7-row
            class="container-image-responsive"
            :style="{ 'padding-top': '100%' }"
          >
            <div
              v-if="$root.docCurrentFood.imagen_principal"
              class="image-responsive"
              v-bind:style="{
                'background-position': 'center center',
                'background-image':
                  'url(\'' +
                  'https://' +
                  $root.serverHostBase +
                  encodeURI($root.docCurrentFood.imagen_principal) +
                  '\')',
              }"
            ></div>

            <div
              v-else
              class="image-responsive-food"
              v-bind:style="{
                'background-position': 'center center',
                'background-image':
                  'url(\'' +
                  encodeURI($root.docLocal.vista_previa_logo_pagina_principal) +
                  '\')',
              }"
            ></div>
          </f7-row>
        </f7-swiper-slide>
      </f7-swiper>

      <!-- SLIDES PLACEHOLDER -->
      <!-- <div
            v-else
            class="shop-attributes-cover-placeholder-image"
            v-bind:style="{
              'background-image': 'url(/static/images/Alacarta_Logo.png)',
            }"
          ></div> -->
    </f7-col>
    <!-- <span @click="navigateTo('/local/' + $root.docLocal.name + '/')" class="navigation-back-black"> -->
    <span @click="navigateToLocal" class="navigation-back-black">
      <i class="fas fa-arrow-left navigation-back-black-icon"></i>
    </span>
  </f7-row>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  methods: {
    navigateToLocal() {
      const self = this;

      if (self.$root.textButtonShopAttributes === "AGREGAR AL PEDIDO") {
        self.$f7router.navigate("/local/" + self.$root.docLocal.name + "/", {
          ignoreCache: false,
        });
      } else if (self.$root.textButtonShopAttributes === "MODIFICAR PEDIDO") {
        self.$f7router.navigate("/cart/", {
          ignoreCache: false,
        });
      }
    },
  },
};
</script>
