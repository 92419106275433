<template>
  <f7-sheet
    id="cart-shipping-map-sheet"
    class=""
    backdrop
    v-if="!props.loading"
    :swipeToClose="false"
    :closeByBackdropClick="false"
    :closeByOutsideClick="false"
    :opened="props.cartCustomerDataSheetOpened"
    @sheet:open="onCartCustomerDataSheetOpened"
    @sheet:closed="onCartCustomerDataSheetClosed"
  >
    <span
      class="close-cart-shipping-map-sheet-container"
      @click="
        () => {
          props.cartCustomerDataSheetOpened = false;
          props.confirmCartButtonClicked = false;
        }
      "
      ><i
        class="fas fa-times close-cart-shipping-map-sheet sheet-close-button"
      ></i
    ></span>
    <f7-page-content style="border-radius: 10px 10px 0 0">
      <f7-block>
        <f7-block-title medium class="text-align-center"
          ><span style="font-weight: bold"
            >Tus Datos de Contacto</span
          ></f7-block-title
        >
        <f7-row>
          <f7-col width="35">
            <f7-list class="no-margin no-padding">
              <f7-list-input
                class="select-sucursal-input select-customer-country"
                label="País"
                type="select"
                info="País"
                validate
                @change="
                  (e) => {
                    codigoPaisCustomer = e.target.value;
                    // setInLocalStorage('cod_pais_cliente', e.target.value);
                  }
                "
              >
                <option disabled selected></option>
                <option
                  v-for="countryCode in countryCodes"
                  :key="countryCode.country"
                  :value="countryCode.dialCode"
                  :selected="codigoPaisCustomer === countryCode.dialCode"
                >
                  {{ countryCode.icon }} ({{ countryCode.dialCode }})
                </option>
              </f7-list-input>
            </f7-list>
          </f7-col>
          <f7-col width="65">
            <f7-list class="no-margin no-padding">
              <f7-list-input
                class="cart-metodos-subopciones"
                label="Celular / WhatsApp"
                type="tel"
                info="Código de área + número"
                name="contacto-cliente"
                :value="contactoCustomer"
                validate
                @input="
                  (e) => {
                    contactoCustomer = e.target.value;
                    // setInLocalStorage('contacto_cliente', e.target.value);
                  }
                "
              >
              </f7-list-input>
            </f7-list>
          </f7-col>
        </f7-row>

        <f7-row class="shop-attributes-attribute-selection">
          <f7-col>
            <span style="margin-top: 5px; display: block">
              <span
                class="badge badge-hacer-pedido"
                @click="
                  !props.confirmCartCustomerDataButtonClicked &&
                  codigoPaisCustomer !== '' &&
                  !props.confirmCartCustomerDataButtonClicked &&
                  contactoCustomer !== ''
                    ? completeCheckout()
                    : ''
                "
                :class="
                  'badge badge-hacer-pedido ' +
                  (codigoPaisCustomer === '' || contactoCustomer === ''
                    ? 'badge-disabled'
                    : '')
                "
              >
                CONFIRMAR PEDIDO
              </span>
            </span>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import cart from "../../cart";
import utils from "../../utils";

export default {
  props: {
    props: Object,
  },
  data() {
    return {
      codigoPaisCustomer: "",
      contactoCustomer: "",
      countryCodes: [],
    };
  },
  mounted() {
    const self = this;

    self.codigoPaisCustomer = localStorage.getItem("cod_pais_cliente");
    self.contactoCustomer = localStorage.getItem("contacto_cliente");

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");

    var myInit = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`/static/phone-country-codes.json`, myInit)
      .then((response) => response.json())
      .then((data) => {
        self.countryCodes = data.data;
      });
  },
  methods: {
    onCartCustomerDataSheetClosed() {
      const self = this;
      self.props.cartCustomerDataSheetOpened = false;
      self.$f7.toast.close();
    },
    setInLocalStorage(key, val) {
      const self = this;
      self.codigoPaisCustomer = val;
      localStorage.setItem(key, val);
    },
    completeCheckout() {
      const self = this;
      if (self.codigoPaisCustomer && self.contactoCustomer) {
        self.setInLocalStorage("cod_pais_cliente", self.codigoPaisCustomer);
        self.setInLocalStorage("contacto_cliente", self.contactoCustomer);

        self.props.cartCustomerDataSheetOpened = false;
        self.props.confirmCartCustomerDataButtonClicked = true;
        cart.completeCheckout(self);
      } else {
        utils.toast(
          self,
          "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar tus datos de contacto",
          "toast-error",
          2000
        );
      }
    },
    onCartCustomerDataSheetOpened() {
      const self = this;
      self.codigoPaisCustomer = localStorage.getItem("cod_pais_cliente");
      self.contactoCustomer = localStorage.getItem("contacto_cliente");
    },
  },
};
</script>