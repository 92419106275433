import utils from "./utils.js";
import local from "./local.js";
const { zonedTimeToUtc, utcToZonedTime, format } = require("date-fns-tz");
const {
  isBefore,
  isAfter,
  parse,
  parseISO,
  isEqual,
  sub,
  add,
  getHours,
  addMinutes,
} = require("date-fns");
const { es } = require("date-fns/locale");

let navigateToLocal = (self) => {
  utils.navigateTo(self, "/local/" + self.$root.docLocal.name + "/", false);
};

let emptyCart = (self) => {
  // Dialogo de Confirmacion
  window.localStorage.setItem("cart", "{}");
  window.localStorage.setItem("draft_cart", "{}");
  window.localStorage.setItem("confirmed_cart", "{}");
  window.localStorage.removeItem("food_local");
  self.$root.grandTotalCart = 0.0;
  self.$root.confirmedCart = [];
  self.$root.draftedCart = [];
  self.$root.updateCart = false;
};

let editFood = (self, itemIndex) => {
  self.$root.currentFoodCartState = "draft_cart";
  self.$root.textButtonShopAttributes = "MODIFICAR PEDIDO";
  // let lsCart = JSON.parse(window.localStorage.getItem("cart"));
  // let lsCartConfirmed = JSON.parse(
  //   window.localStorage.getItem("confirmed_cart")
  // );
  // let lsCartDrafted = JSON.parse(window.localStorage.getItem("draft_cart"));
  var itemName = self.props.vlData.items[itemIndex].name_rand;
  foodCartFromConfirmedToDraft(self, itemName);
};

let foodCartFromConfirmedToDraft = (self, itemName) => {
  let currentFoodName = itemName;
  let lsCartConfirmed = JSON.parse(
    window.localStorage.getItem("confirmed_cart")
  );
  let lsFoodDrafted = JSON.parse(window.localStorage.getItem("draft_cart"));

  lsFoodDrafted[currentFoodName] = {};
  // let lsFoodCurrentFood = lsCartConfirmed[currentFoodName];
  Object.assign(
    lsFoodDrafted[currentFoodName],
    lsCartConfirmed[currentFoodName]
  );

  // window.localStorage.setItem("cart", JSON.stringify(lsFoodCart));
  // delete lsFoodDrafted[currentFoodName];
  window.localStorage.setItem("draft_cart", JSON.stringify(lsFoodDrafted));
  self.$root.docCurrentFood = lsFoodDrafted[currentFoodName];

  // self.$root.draftedCart.splice(
  //   self.$root.draftedCart.indexOf(currentFoodName),
  //   0
  // );

  // utils.checkTotals(self);
  self.$f7router.navigate(
    "/shop-attributes/"
    // {ignoreCache: true}
  );
};

let removeFoodFromCart = async (self, itemIndex) => {
  self.$root.updateCart = true;
  var itemName = self.props.vlData.items[itemIndex].name_rand;

  let lsFoodConfirmedCart = JSON.parse(
    window.localStorage.getItem("confirmed_cart")
  );
  delete lsFoodConfirmedCart[itemName];

  self.$root.confirmedCart.splice(
    self.$root.confirmedCart.indexOf(itemName),
    1
  );

  const lsCartConfirmedArray = Object.values(lsFoodConfirmedCart);
  self.props.vlData.items = lsCartConfirmedArray;

  await new Promise((resolve) => {
    window.localStorage.setItem(
      "confirmed_cart",
      JSON.stringify(lsFoodConfirmedCart)
    );
    setTimeout(() => {
      resolve();
    }, 100);
  });

  calculateGandTotal(self);
  // updatePricesCheckout(self);
};

const getPrice = async (self, data) => {
  return new Promise((resolve, reject) => {
    utils.customRequest(
      self,
      (r) => {
        resolve(r.message.data);
      },
      "utils.get_price",
      "POST",
      data,
      null
    );
  });
};

const checkGetPrice = async (self) => {
  return new Promise((resolve, reject) => {
    utils.customRequest(
      self,
      (r) => {
        resolve(r.message);
      },
      "utils.check_get_price",
      "POST",
      {},
      null
    );
  });
};

let updatePricesCheckout = async (self) => {
  let checkUpdatePricesCheckout = await checkGetPrice(self);

  if (checkUpdatePricesCheckout == false) {
    calculateGandTotal(self);
    return;
  }

  self.props.enableSubmitOrder = false;

  let lsFoodConfirmedCart = await new Promise((resolveLsConfirmedCart) => {
    resolveLsConfirmedCart(
      JSON.parse(window.localStorage.getItem("confirmed_cart") || "[]")
    );
  });

  let lsFoodType = await new Promise((resolveLsFoodType) => {
    resolveLsFoodType(window.localStorage.getItem("food_type") || "");
  });
  let totalFinal = 0.0;

  // console.log("PLATO")
  for await (const plato of Object.keys(lsFoodConfirmedCart)) {
    // console.log(lsFoodConfirmedCart[plato]);
    let totalCantidad = 0;
    let totalPlato = 0.0;

    let categoriaAdicionales = await lsFoodConfirmedCart[plato]["adicionales"];
    let atributosDelPlato = await lsFoodConfirmedCart[plato][
      "atributos_del_plato"
    ];
    let atributosDelPlatoDelivery = await lsFoodConfirmedCart[plato][
      "atributos_del_plato_delivery"
    ];

    let data = {
      doctype: "Plato",
      docname: lsFoodConfirmedCart[plato].name,
    };
    let price = await getPrice(self, data);

    let precioPlato = await new Promise((resolve, reject) => {
      if (lsFoodType == "Salon") {
        if (price["precio_rebajado"] > 0) {
          resolve(price["precio_rebajado"]);
        } else {
          resolve(price["precio"]);
        }
      } else if (lsFoodType == "Delivery") {
        if (price["precio_rebajado_delivery"] > 0) {
          resolve(price["precio_rebajado_delivery"]);
        } else {
          resolve(price["precio_delivery"]);
        }
      } else {
        resolve(0.0);
      }
    });

    lsFoodConfirmedCart[plato] = { ...lsFoodConfirmedCart[plato], ...price };

    totalCantidad += lsFoodConfirmedCart[plato].cantidad || 0.0;
    totalPlato += precioPlato * totalCantidad;

    // console.log("ORDEN #1");
    // console.log("precioPlato: ", precioPlato);
    // console.log("totalCantidad: ", totalCantidad);
    // console.log("totalPlato: ", totalPlato);

    // console.log("### ATRIBUTOS ###");
    let counterAtributos = 0;
    for await (const atributo of atributosDelPlato) {
      if (atributo?.cantidad && atributo?.cantidad > 0) {
        // console.log(atributo);
        let data = {
          doctype: atributo.doctype,
          docname: atributo.name,
        };
        let price = await getPrice(self, data);
        // console.log(price);

        totalCantidad += atributo.cantidad;
        let total_atributo = 0;

        if (price["precio_rebajado"] > 0) {
          total_atributo = price["precio_rebajado"] * atributo.cantidad;
        } else {
          total_atributo = price["precio"] * atributo.cantidad;
        }

        price["total_atributo"] = total_atributo;

        totalPlato += total_atributo;
        // console.log("ORDEN #2");
        // console.log("totalPlato: ", totalPlato);

        lsFoodConfirmedCart[plato]["atributos_del_plato"][counterAtributos] = {
          ...lsFoodConfirmedCart[plato]["atributos_del_plato"][
            counterAtributos
          ],
          ...price,
        };
      }
      counterAtributos++;
    }

    // console.log("### ATRIBUTOS DELIVERY ###");
    let counterAtributosDelivery = 0;
    for await (const atributo of atributosDelPlatoDelivery) {
      if (atributo?.cantidad && atributo?.cantidad > 0) {
        // console.log(atributo);
        let data = {
          doctype: atributo.doctype,
          docname: atributo.name,
        };
        let price = await getPrice(self, data);
        // console.log(price);

        let total_atributo = 0;

        if (price["precio_rebajado_delivery"] > 0) {
          total_atributo =
            price["precio_rebajado_delivery"] * atributo.cantidad;
        } else {
          total_atributo = price["precio_delivery"] * atributo.cantidad;
        }

        price["total_atributo"] = total_atributo;

        totalCantidad += atributo.cantidad;
        totalPlato += total_atributo;
        // console.log("ORDEN #3");
        // console.log("totalPlato: ", totalPlato);

        lsFoodConfirmedCart[plato]["atributos_del_plato_delivery"][
          counterAtributosDelivery
        ] = {
          ...lsFoodConfirmedCart[plato]["atributos_del_plato_delivery"][
            counterAtributosDelivery
          ],
          ...price,
        };
      }

      counterAtributosDelivery++;
    }

    // console.log("### ADICIONALES ###");
    let counterCategoriasAdicionales = 0;
    for await (const categoriaAdicional of categoriaAdicionales) {
      let counterAdicionales = 0;
      for await (const adicional of categoriaAdicional?.data) {
        if (adicional.cantidad_seleccionada > 0) {
          // console.log(adicional);
          let data = {
            doctype: adicional.doctype,
            docname: adicional.name_treelist,
            docname_parent_plato: lsFoodConfirmedCart[plato].name,
          };
          let price = await getPrice(self, data);
          // console.log(price);

          price["total"] =
            price["precio"] *
            lsFoodConfirmedCart[plato]["adicionales"][
              counterCategoriasAdicionales
            ]["data"][counterAdicionales]["cantidad_seleccionada"] *
            totalCantidad;

          totalPlato += price["total"];
          // console.log("ORDEN #4");
          // console.log("totalPlato: ", totalPlato);

          lsFoodConfirmedCart[plato]["adicionales"][
            counterCategoriasAdicionales
          ]["data"][counterAdicionales] = {
            ...lsFoodConfirmedCart[plato]["adicionales"][
              counterCategoriasAdicionales
            ]["data"][counterAdicionales],
            ...price,
          };
        }

        counterAdicionales++;
      }

      counterCategoriasAdicionales++;
    }

    // console.log("ORDEN #5");
    // console.log("totalPlato:", totalPlato);

    // console.log(totalPlato);
    lsFoodConfirmedCart[plato]["total_plato"] = totalPlato;
    totalFinal += totalPlato;
  }

  if (self.$root.grandTotalCart != totalFinal) {
    self.props.continueWithOrder = false;
  } else {
    self.props.continueWithOrder = true;
  }

  // console.log(lsFoodConfirmedCart);
  // console.log("ORDEN #5");
  // console.log(totalFinal);

  self.$root.grandTotalCart = totalFinal;
  self.props.vlData.items = lsFoodConfirmedCart;
  self.$root.updateCart = false;

  await new Promise((resolve) => {
    window.localStorage.setItem(
      "confirmed_cart",
      JSON.stringify(lsFoodConfirmedCart)
    );
    resolve();
  });

  // Se debe actualizar también el JSON global
  self.props.enableSubmitOrder = true;
};

let calculateGandTotal = async (self) => {
  self.props.enableSubmitOrder = true;
  self.props.continueWithOrder = true;

  let lsFoodConfirmedCart = await new Promise((resolveLsConfirmedCart) => {
    resolveLsConfirmedCart(
      JSON.parse(window.localStorage.getItem("confirmed_cart") || "[]")
    );
  });

  let lsFoodType = await new Promise((resolveLsFoodType) => {
    resolveLsFoodType(window.localStorage.getItem("food_type") || "");
  });
  let grandTotal = 0.0;
  let totalAdicionales = 0.0;

  let lsCartConfirmed = JSON.parse(
    window.localStorage.getItem("confirmed_cart")
  );

  const calculateGrandTotalCallback = (r) => {
    self.$root.grandTotalCart = r.message.data;
    const lsCartConfirmedArray = Object.values(lsCartConfirmed);
    self.props.vlData.items = lsCartConfirmedArray;
    self.$root.updateCart = false;
  };

  if (Object.keys(lsFoodConfirmedCart).length > 0) {
    utils.customRequest(
      self,
      calculateGrandTotalCallback,
      "utils.check_total_pedido_plato",
      "POST",
      {
        detalle_pedido: JSON.stringify(lsFoodConfirmedCart),
        tipo_carta: lsFoodType,
      },
      null
    );
  }

  /////// DEPRECATED ///////
  // Object.keys(lsFoodConfirmedCart).forEach((foodKey, index) => {
  //   grandTotal += lsFoodConfirmedCart[foodKey].total_plato;

  //   for (let i = 0; i < lsFoodConfirmedCart[foodKey].adicionales.length; i++) {
  //     for (
  //       let j = 0;
  //       j < lsFoodConfirmedCart[foodKey].adicionales[i].data.length;
  //       j++
  //     ) {
  //       totalAdicionales +=
  //         lsFoodConfirmedCart[foodKey].adicionales[i].data[j].total;
  //     }
  //   }

  //   if (Object.keys(lsFoodConfirmedCart).length === index + 1) {
  //     // if (self.$root.grandTotalCart !== grandTotal) {
  //     self.$root.grandTotalCart = grandTotal;
  //     self.props.totalAdicionales = totalAdicionales;

  //     let lsCartConfirmed = JSON.parse(
  //       window.localStorage.getItem("confirmed_cart")
  //     );
  //     const lsCartConfirmedArray = Object.values(lsCartConfirmed);
  //     self.props.vlData.items = lsCartConfirmedArray;
  //     // let virtualListCart = self.$f7.virtualList.get(".virtual-list-cart");

  //     // // TODO: Debe validar todo en el servidor
  //     // virtualListCart.replaceAllItems(lsCartConfirmedArray);

  //     self.$root.updateCart = false;
  //     // }
  //   }
  // });
};

let inputOnFocus = (self) => {
  // setTimeout(() => {
  self.$$(".total-cart-block").css("position", "relative");
  self.$$(".total-cart-block-under").css("height", "25px");
  // }, 500);
};

let confirmCart = async (self) => {
  await updatePricesCheckout(self);

  if (self.props.continueWithOrder) {
    if (cartFinalValidation(self) === true) {
      self.props.confirmCartButtonClicked = true;
      calculateCartTotals(self)
        .then((r) => {
          // if (self.$root.tipoMetodoPago === "Mercado Pago - Automático") {
          // redirectToMercadoPago(self);
          // } else {
          createWhatsAppMessage(self).then((r) => {
            // if (
            //   self.$root.tipoMetodoPago === "Mercado Pago - Automático" &&
            //   !self.$root.docLocal.panel_pedidos
            // ) {
            //   redirectToMercadoPago(self);
            // }
          });
          // }
        })
        .catch((e) => {});
    }
  }
};

// let redirectToMercadoPagoPopUp = function(self) {
//   self.redirectToMercadoPagoPrompt = self.$f7.dialog
//     .create({
//       cssClass: "elimina-carrito-dialog",
//       title: "",
//       text: `Te redirigiremos a Mercado Pago para que puedas realizar el pago de tu pedido<br /><br />¿Deseas Continuar?`,
//       buttons: [
//         {
//           text: "Volver",
//           cssClass: "elimina-carrito-ok-btn-dialog",
//           onClick: function(e, dialog) {
//             self.props.confirmCartButtonClicked = false;
//             self.props.confirmCartCustomerDataButtonClicked = false;
//             localStorage.removeItem("cart_whatsapp_message");
//             // self.redirectToMercadoPagoPrompt.close();
//           },
//         },
//         {
//           text: "Continuar",
//           cssClass: "elimina-carrito-cancel-btn-dialog",
//           onClick: function(e, dialog) {
//             createPreferenceMPAndRedirect(self);
//           },
//         },
//       ],
//       destroyOnClose: true,
//     })
//     .open();
// };

let createPreferenceMPAndRedirect = async (self, pedido_name) => {
  return new Promise((resolve, reject) => {
    // self.$f7.dialog.preloader("Creando Pedido");

    let createPreferenciaMercadoPagoCallback = function(r) {
      localStorage.setItem("mercadopago_redirect", "true");
      localStorage.setItem(
        "mercadopago_preference_id",
        r.message.data.preference_info.id
      );

      // setTimeout(() => {
      resolve(r.message.data.preference_info.init_point);
      // }, 2000);
    };

    utils.customRequest(
      self,
      createPreferenciaMercadoPagoCallback,
      "integracion_mercado_pago.integracion_mercado_pago.crear_preferencia_mercadopago",
      "POST",
      {
        local: self.$root.docLocal.name,
        importe:
          self.props.totalFinalCart +
          parseFloat(self.$root.valorCuponDescuentoTotal) -
          self.$root.costoEnvio,
        importe_envio: self.$root.costoEnvio,
        external_reference: pedido_name,
      },
      null
      // callbackErr
    );
  });
};

let createWhatsAppMessage = async function(self) {
  // TODO: Convierte el localStorage a Pedido de WhatsApp
  let lsCartConfirmed = JSON.parse(
    window.localStorage.getItem("confirmed_cart")
  );
  const lsCartConfirmedArray = Object.values(lsCartConfirmed);
  let lsCartToWhatsApp = "";
  let haveAdicionales = false;
  let grandTotal = self.$root.grandTotalCart;
  let whatsAppNumber;

  // Encabezado del WhatsApp | Salon
  lsCartToWhatsApp += `Hola+${encodeURIComponent(
    self.$root.docLocal.nombre_local
  )}%21+Quiero+hacer+un+pedido%2C+este+es+el+detalle%3A%0D%0A`;

  lsCartToWhatsApp += `%0D%0A`;
  lsCartToWhatsApp += encodeURIComponent(`Orden Nº NUMERO_PEDIDO`);
  lsCartToWhatsApp += `%0D%0A`;
  lsCartToWhatsApp += encodeURIComponent(`FECHA_HORA_PEDIDO`);
  lsCartToWhatsApp += `%0D%0A`;

  lsCartConfirmedArray.forEach(async (food, foodIndex) => {
    let foodAndAttributesQty = 0;
    // Linea de Pedido: Plato
    if (food.cantidad) {
      foodAndAttributesQty += food.cantidad;

      // Linea de Pedido: Plato Unitario
      // var precio_total_plato_float = utils.formatCurrency(
      //   parseFloat(food.total_plato)
      // );
      var precio_unitario_plato_float = 0.0;
      var precio_total_plato_float = 0.0;

      if (self.$root.listFoodType === "Salon") {
        if (food.precio_rebajado > 0) {
          precio_unitario_plato_float = utils.formatCurrency(
            parseFloat(food.precio_rebajado)
          );
          precio_total_plato_float = utils.formatCurrency(
            parseFloat(food.precio_rebajado * food.cantidad)
          );
        } else if (food.precio > 0) {
          precio_unitario_plato_float = utils.formatCurrency(
            parseFloat(food.precio)
          );
          precio_total_plato_float = utils.formatCurrency(
            parseFloat(food.precio * food.cantidad)
          );
        }
      } else if (self.$root.listFoodType === "Delivery") {
        if (food.precio_rebajado_delivery > 0) {
          precio_unitario_plato_float = utils.formatCurrency(
            parseFloat(food.precio_rebajado_delivery)
          );
          precio_total_plato_float = utils.formatCurrency(
            parseFloat(food.precio_rebajado_delivery * food.cantidad)
          );
        } else if (food.precio_delivery > 0) {
          precio_unitario_plato_float = utils.formatCurrency(
            parseFloat(food.precio_delivery)
          );
          precio_total_plato_float = utils.formatCurrency(
            parseFloat(food.precio_delivery * food.cantidad)
          );
        }
      }

      var precio_unitario_plato =
        precio_unitario_plato_float > 0.0
          ? `| $ ${precio_unitario_plato_float}`
          : "";
      var precio_total_plato =
        precio_total_plato_float > 0.0 ? `| $ ${precio_total_plato_float}` : "";

      lsCartToWhatsApp += `%0D%0A`;
      lsCartToWhatsApp += encodeURIComponent(
        `✅ ${food.cantidad} x ${food.nombre_plato} [${food.nombre_categoria_de_comida}] ${precio_unitario_plato}`
      );
      lsCartToWhatsApp += `%0D%0A`;
    } else {
      // Linea de Pedido: Plato No Unitario
      lsCartToWhatsApp += encodeURIComponent(
        `✅ ${food.nombre_plato} [${food.nombre_categoria_de_comida}]`
      );
      lsCartToWhatsApp += `%0D%0A`;
    }

    let tituloVariantes = false;

    if (food.atributos_del_plato.length > 0) {
      // lsCartToWhatsApp += `%0D%0A`;
      // Linea de Pedido: Plato con Atributo
      if (self.$root.listFoodType === "Salon") {
        food.atributos_del_plato.forEach((atributo, index) => {
          if (atributo.cantidad > 0) {
            foodAndAttributesQty += atributo.cantidad;

            if (!tituloVariantes) {
              lsCartToWhatsApp += encodeURIComponent(`  *Variantes*`);
              lsCartToWhatsApp += `%0D%0A`;
              tituloVariantes = true;
            }

            if (atributo.precio_rebajado > 0) {
              var precio_unitario_atributo_float = utils.formatCurrency(
                parseFloat(atributo.precio_rebajado)
              );
            } else {
              var precio_unitario_atributo_float = utils.formatCurrency(
                parseFloat(atributo.precio)
              );
            }

            var precio_total_atributo_float = utils.formatCurrency(
              parseFloat(atributo.total_atributo)
            );

            var precio_unitario_atributo =
              precio_unitario_atributo_float > 0.0
                ? `| $ ${precio_unitario_atributo_float}`
                : "";

            var precio_total_atributo =
              precio_total_atributo_float > 0.0
                ? `| $ ${precio_total_atributo_float}`
                : "";

            lsCartToWhatsApp += encodeURIComponent(
              `  • ${atributo.cantidad} x ${atributo.nombre_atributo} ${precio_unitario_atributo}`
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        });
      } else if (self.$root.listFoodType === "Delivery") {
        food.atributos_del_plato_delivery.forEach((atributo, index) => {
          if (atributo.cantidad > 0) {
            foodAndAttributesQty += atributo.cantidad;

            if (!tituloVariantes) {
              lsCartToWhatsApp += encodeURIComponent(`  *Variantes*`);
              lsCartToWhatsApp += `%0D%0A`;
              tituloVariantes = true;
            }

            if (atributo.precio_rebajado_delivery > 0) {
              var precio_unitario_atributo_float = utils.formatCurrency(
                parseFloat(atributo.precio_rebajado_delivery)
              );
            } else {
              var precio_unitario_atributo_float = utils.formatCurrency(
                parseFloat(atributo.precio_delivery)
              );
            }

            var precio_total_atributo_float = utils.formatCurrency(
              parseFloat(atributo.total_atributo)
            );

            var precio_unitario_atributo =
              precio_unitario_atributo_float > 0.0
                ? `| $ ${precio_unitario_atributo_float}`
                : "";

            var precio_total_atributo =
              precio_total_atributo_float > 0.0
                ? `| $ ${precio_total_atributo_float}`
                : "";

            lsCartToWhatsApp += encodeURIComponent(
              `  • ${atributo.cantidad} x ${atributo.nombre_atributo_delivery} ${precio_unitario_atributo}`
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        });
      }
    }

    food.adicionales.forEach((categoria_adicional, index) => {
      if (categoria_adicional.cantidad_seleccionada > 0) {
        lsCartToWhatsApp += encodeURIComponent(
          `  *${categoria_adicional.name_category}*`
        );
        lsCartToWhatsApp += `%0D%0A`;

        categoria_adicional.data.forEach((adicional, index) => {
          if (adicional.cantidad_seleccionada > 0) {
            var precio_unitario_adicional_float = utils.formatCurrency(
              parseFloat(adicional.precio)
            );
            var precio_total_adicional_float = utils.formatCurrency(
              parseFloat(adicional.total)
            );

            var precio_unitario_adicional =
              precio_unitario_adicional_float > 0.0
                ? `| $ ${precio_unitario_adicional_float}`
                : "";
            var precio_total_adicional =
              precio_total_adicional_float > 0.0
                ? `| $ ${precio_total_adicional_float}`
                : "";

            lsCartToWhatsApp += encodeURIComponent(
              `  • ${adicional.cantidad_seleccionada *
                foodAndAttributesQty} x ${
                adicional.nombre_adicional
              } ${precio_unitario_adicional}`
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        });
      }
    });

    // Nota del Plato
    if (food.nota_del_plato && food.nota_del_plato.length > 0) {
      lsCartToWhatsApp += encodeURIComponent(
        `  *Nota del Plato*: ${food.nota_del_plato}`
      );
      lsCartToWhatsApp += `%0D%0A`;
    }

    if (lsCartConfirmedArray.length === foodIndex + 1) {
      // Nota del Pedido
      // if (lsCartConfirmed.nota_del_pedido.length > 0) {
      //   lsCartToWhatsApp += encodeURIComponent(
      //     `Nota del Pedido: $ ${lsCartConfirmed.nota_del_pedido}`
      //   );
      //   lsCartToWhatsApp += `%0D%0A`;
      // }

      lsCartToWhatsApp += `%0D%0A`;

      // Metodo de Envio
      lsCartToWhatsApp += encodeURIComponent(`*Forma de Entrega*`);
      lsCartToWhatsApp += `%0D%0A`;
      if (self.$root.tipoMetodoEnvio !== "") {
        lsCartToWhatsApp += encodeURIComponent(
          `  • Método de Entrega: ${self.$root.metodoEnvio}`
        );
        lsCartToWhatsApp += `%0D%0A`;
        if (self.$root.tipoMetodoEnvio === "Take Away") {
          lsCartToWhatsApp += encodeURIComponent(
            `  • Retira: ${self.$root.nombreRetira}`
          );
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `  • Hora de Retiro: ${self.$root.horaRetiraFormatted ||
              self.$root.horaRetira}`
          );
          lsCartToWhatsApp += `%0D%0A`;
        } else if (
          self.$root.tipoMetodoEnvio === "Delivery" ||
          self.$root.tipoMetodoEnvio === "Delivery - Zonas de Envío" ||
          self.$root.tipoMetodoEnvio === "Delivery - Google Maps"
        ) {
          lsCartToWhatsApp += encodeURIComponent(
            `  • Recibe: ${self.$root.nombreRecibe}`
          );
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `  • Dirección: ${self.$root.direccionRecibe ||
              self.$root.shippingMapAddress} `
          );
          lsCartToWhatsApp += `%0D%0A`;

          if (self.$root.direccionEntrecalles) {
            lsCartToWhatsApp += encodeURIComponent(
              `  • Entrecalles: ${self.$root.direccionEntrecalles} `
            );
          }

          if (Object.keys(self.$root.shippingMapLatLng).length > 0) {
            let gMapsLat =
              self.$root.shippingMapLatLng["lat"].toString().split(".")[0] +
              "." +
              self.$root.shippingMapLatLng["lat"]
                .toString()
                .split(".")[1]
                .substring(0, 7);
            let gMapsLng =
              self.$root.shippingMapLatLng["lng"].toString().split(".")[0] +
              "." +
              self.$root.shippingMapLatLng["lng"]
                .toString()
                .split(".")[1]
                .substring(0, 7);
            lsCartToWhatsApp += encodeURIComponent(
              `  • Google Maps: https://www.google.com/maps/place/${gMapsLat},${gMapsLng}`
            );
            lsCartToWhatsApp += `%0D%0A`;
          }

          lsCartToWhatsApp += encodeURIComponent(
            `  • Hora de Envío: ${self.$root.horaRetiraFormatted ||
              self.$root.horaRetira}`
          );

          if (self.$root.direccionAdicionalRecibe) {
            lsCartToWhatsApp += `%0D%0A`;
            lsCartToWhatsApp += encodeURIComponent(
              `  • Dirección Adicional: ${self.$root.direccionAdicionalRecibe} `
            );
          }

          lsCartToWhatsApp += `%0D%0A`;
        }
      }

      // Metodo de Envío - Campo Personalizable
      if (self.$root.listCustomFields.length > 0) {
        for (let i = 0; i < self.$root.listCustomFields.length; i++) {
          if (
            self.$root.listCustomFields[i].asociado_a_metodo ===
              "Método de Envío" &&
            self.$root.listCustomFields[i].value
          ) {
            lsCartToWhatsApp += encodeURIComponent(
              `  • ${self.$root.listCustomFields[i].nombre_campo}: ${self.$root.listCustomFields[i].value} `
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        }
        lsCartToWhatsApp += `%0D%0A`;
      }

      lsCartToWhatsApp += `%0D%0A`;

      // Metodo de Pago
      lsCartToWhatsApp += encodeURIComponent(`*Forma de Pago*`);
      lsCartToWhatsApp += `%0D%0A`;
      if (self.$root.tipoMetodoPago !== "") {
        lsCartToWhatsApp += encodeURIComponent(
          `  • Método de Pago: ${self.$root.metodoPago}`
        );
        lsCartToWhatsApp += `%0D%0A`;
        if (self.$root.tipoMetodoPago === "Efectivo") {
          if (parseFloat(self.$root.efectivoPagaCon)) {
            lsCartToWhatsApp += encodeURIComponent(
              `  • Abona con: $ ${self.$root.efectivoPagaCon}`
            );
            lsCartToWhatsApp += `%0D%0A`;
          } else {
            lsCartToWhatsApp += encodeURIComponent(
              `  • Abona con: No Especifica`
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        }
      }

      if (self.$root.tipoMetodoPago == "Mercado Pago - Automático") {
        lsCartToWhatsApp += encodeURIComponent(`LINK_DE_PAGO`);
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Metodo de Pago - Campo Personalizable
      if (self.$root.listCustomFields.length > 0) {
        for (let i = 0; i < self.$root.listCustomFields.length; i++) {
          if (
            self.$root.listCustomFields[i].asociado_a_metodo ===
              "Método de Pago" &&
            self.$root.listCustomFields[i].value
          ) {
            lsCartToWhatsApp += encodeURIComponent(
              `  • ${self.$root.listCustomFields[i].nombre_campo}: ${self.$root.listCustomFields[i].value} `
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        }
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Otros Datos - Campo Personalizable
      let existOtrosDatos = self.$root.listCustomFields.some(
        (customField) =>
          customField.asociado_a_metodo === "Otros Datos" && customField.value
      );
      if (existOtrosDatos && self.$root.customFieldOtrosDatos) {
        lsCartToWhatsApp += encodeURIComponent(`*Otros Datos*`);
        lsCartToWhatsApp += `%0D%0A`;
        for (let i = 0; i < self.$root.listCustomFields.length; i++) {
          if (
            self.$root.listCustomFields[i].asociado_a_metodo ===
              "Otros Datos" &&
            self.$root.listCustomFields[i].value
          ) {
            lsCartToWhatsApp += encodeURIComponent(
              `  • ${self.$root.listCustomFields[i].nombre_campo}: ${self.$root.listCustomFields[i].value} `
            );
            lsCartToWhatsApp += `%0D%0A`;
          }
        }
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Mesa
      if (self.$root.mesa !== "") {
        lsCartToWhatsApp += encodeURIComponent(`• Mesa: ${self.$root.mesa}`);
        lsCartToWhatsApp += `%0D%0A`;
        lsCartToWhatsApp += encodeURIComponent(
          `• A nombre de: ${self.$root.nombreMesa}`
        );
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Subtotal del Pedido
      lsCartToWhatsApp += `%0D%0A`;
      lsCartToWhatsApp += encodeURIComponent(
        `• Subtotal del Pedido: $ ${utils.formatCurrency(
          parseFloat(grandTotal)
        )}`
      );
      lsCartToWhatsApp += `%0D%0A`;

      if (self.$root.costoEnvio > 0.0) {
        // Costo de Envio
        lsCartToWhatsApp += encodeURIComponent(
          `• Costo de Envío: $ ${utils.formatCurrency(
            parseFloat(self.$root.costoEnvio)
          )}`
        );
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Cupón de Descuento
      if (self.$root.cuponDescuento) {
        lsCartToWhatsApp += encodeURIComponent(
          `• Cupón de Descuento (${self.$root.cuponDescuento.toUpperCase()}): $ ${utils.formatCurrency(
            parseFloat(self.$root.valorCuponDescuentoTotal)
          )}`
        );
        lsCartToWhatsApp += `%0D%0A`;
      }

      // Recargo / Descuento de Pago
      if (self.$root.recargoPago > 0.0) {
        // Recargo de Pago > 0
        if (self.$root.tipoRecargoPago === "Importe Fijo") {
          // Importe Fijo
          lsCartToWhatsApp += encodeURIComponent(
            `• Recargo de Pago: $ ${utils.formatCurrency(
              parseFloat(self.$root.recargoPago)
            )}`
          );
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                parseFloat(self.$root.recargoPago) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
          // Porcentaje del Subtotal
          lsCartToWhatsApp += encodeURIComponent(
            `• Recargo de Pago: $ ${utils.formatCurrency(
              parseFloat(grandTotal) *
                (parseFloat(self.$root.recargoPago) / 100)
            )}`
          );
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                parseFloat(grandTotal) *
                  (parseFloat(self.$root.recargoPago) / 100) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
          // Porcentaje del Total
          lsCartToWhatsApp += encodeURIComponent(
            `• Recargo de Pago: $ ${utils.formatCurrency(
              (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
                (parseFloat(self.$root.recargoPago) / 100)
            )}`
          );
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
                  (parseFloat(self.$root.recargoPago) / 100) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        }
      } else if (self.$root.recargoPago <= 0.0) {
        // Recargo de Pago <= 0
        if (self.$root.tipoRecargoPago === "Importe Fijo") {
          // Importe Fijo
          if (self.$root.recargoPago < 0.0) {
            // Recargo de Pago < 0
            lsCartToWhatsApp += encodeURIComponent(
              `• Bonificación de Pago: $ ${utils.formatCurrency(
                parseFloat(self.$root.recargoPago)
              )}`
            );
          }
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                parseFloat(self.$root.recargoPago) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
          // Porcentaje del Subtotal
          if (self.$root.recargoPago < 0.0) {
            // Recargo de Pago < 0
            lsCartToWhatsApp += encodeURIComponent(
              `• Bonificación de Pago: $ ${utils.formatCurrency(
                parseFloat(grandTotal) *
                  (parseFloat(self.$root.recargoPago) / 100)
              )}`
            );
          }
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                parseFloat(grandTotal) *
                  (parseFloat(self.$root.recargoPago) / 100) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
          // Porcentaje del Total
          if (self.$root.recargoPago < 0.0) {
            // Recargo de Pago < 0
            lsCartToWhatsApp += encodeURIComponent(
              `• Bonificación de Pago: $ ${utils.formatCurrency(
                (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
                  (parseFloat(self.$root.recargoPago) / 100)
              )}`
            );
          }
          lsCartToWhatsApp += `%0D%0A`;
          lsCartToWhatsApp += encodeURIComponent(
            `• Total del Pedido: $ ${utils.formatCurrency(
              parseFloat(grandTotal) +
                parseFloat(self.$root.costoEnvio) +
                (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
                  (parseFloat(self.$root.recargoPago) / 100) +
                parseFloat(self.$root.valorCuponDescuentoTotal)
            )}`
          );
        }
      } else if (
        self.$root.costoEnvio > 0.0 &&
        self.$root.recargoPago === 0.0
      ) {
        // Costo de Envio
        // lsCartToWhatsApp += encodeURIComponent(
        //   `• Costo de Envío: $ ${utils.formatCurrency(
        //     parseFloat(self.$root.costoEnvio)
        //   )}`
        // );
        // lsCartToWhatsApp += `%0D%0A`;
        lsCartToWhatsApp += encodeURIComponent(
          `• Total del Pedido: $ ${utils.formatCurrency(
            parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)
          )}`
        );
        lsCartToWhatsApp += `%0D%0A`;
      } else if (
        // Sin Costo de Envio ni Cargos/Bonificacion de Envío
        !self.$root.costoEnvio &&
        self.$root.costoEnvio === 0.0 &&
        self.$root.recargoPago === 0
      ) {
        lsCartToWhatsApp += encodeURIComponent(
          `• Total del Pedido: $ ${utils.formatCurrency(
            parseFloat(grandTotal) +
              parseFloat(self.$root.valorCuponDescuentoTotal)
          )}`
        );
      }

      let customFieldsValidated = await new Promise((resolveCustomFields) => {
        let customFields = self.$root.listCustomFields;
        let customFieldsValidated = [];

        if (customFields.length === 0) {
          resolveCustomFields();
        }

        for (let i = 0; i < customFields.length; i++) {
          if (customFields[i].hasOwnProperty("value")) {
            customFieldsValidated.push(customFields[i]);
          }

          if (customFields.length === i + 1) {
            resolveCustomFields(customFieldsValidated);
          }
        }
      });

      let sucursalElegida = await new Promise((resolveSucursalElegida) => {
        if (self.$root.docLocal.sucursales.length === 1) {
          resolveSucursalElegida(
            self.$root.docLocal.sucursales[0]["nombre_sucursal"]
          );
        } else if (self.$root.docLocal.sucursales.length > 1) {
          resolveSucursalElegida(
            self.$root.docLocal.sucursales[self.props.sucursal][
              "nombre_sucursal"
            ]
          );
        } else {
          resolveSucursalElegida("");
        }
      });

      let dateTimeNowTmz = await new Promise((resolvedateTimeNowTmz) => {
        let formattedDateTmz = format(new Date(), "dd-MM-yyyy HH:mm:ss", {
          locale: es,
        });

        resolvedateTimeNowTmz(formattedDateTmz);
      });

      self.props.jsonPedido = await new Promise((resolveJsonPedido) => {
        resolveJsonPedido({
          fecha: dateTimeNowTmz,
          local: self.$root.docLocal.name,
          tipo_carta:
            self.$root.listFoodType === "Salón" ||
            self.$root.listFoodType === "Salon"
              ? "Primaria"
              : "Secundaria",
          sucursal: sucursalElegida,
          detalle_pedido: JSON.stringify(lsCartConfirmedArray),
          metodo_envio: self.$root.tipoMetodoEnvio,
          nombre_metodo_envio: self.$root.metodoEnvio,
          nombre_retira_pedido: self.$root.nombreRetira,
          nombre_recibe_pedido: self.$root.nombreRecibe,
          fecha_hora_retira_pedido: self.$root.horaRetira,
          fecha_hora_recibe_pedido: self.$root.horaRetira,
          direccion_envio:
            self.$root.direccionRecibe || self.$root.shippingMapAddress,
          mas_informacion_envio: self.$root.direccionAdicionalRecibe,
          direccion_entrecalles: self.$root.direccionEntrecalles,
          direccion_lat: self.$root.shippingMapLatLng["lat"],
          direccion_lng: self.$root.shippingMapLatLng["lng"],
          recargo_envio: self.$root.costoEnvio,
          metodo_pago: self.$root.metodoPago,
          recargo_pago: self.$root.totalRecargoDePago,
          efectivo_paga_con: self.$root.efectivoPagaCon,
          campos_personalizados: JSON.stringify(customFieldsValidated),
          mesa: self.$root.mesa,
          subtotal: grandTotal,
          cupon: self.$root.cuponDescuento.toUpperCase(),
          importe_cupon: self.$root.valorCuponDescuentoTotal,
          version_app: localStorage.getItem("version"),
          // localTest: self.$root.docLocal,
          // tipo_recargo_pago: self.$root.tipoRecargoPago,
          // campos_personalizados: JSON.stringify(self.$root.listCustomFields),
          // mas_informacion_otros_datos: self.$root.customFieldOtrosDatos,
          // tipo_metodo_de_pago: self.$root.tipoMetodoPago,
        });
      });

      if (self.$root.docLocal.sucursales.length === 0) {
        whatsAppNumber = self.$root.docLocal.whatsapp
          .replace("+", "")
          .replace(" ", "");
        // window.location.replace(
        //   `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${lsCartToWhatsApp}`
        // );
      } else if (self.$root.docLocal.sucursales.length === 1) {
        whatsAppNumber = self.$root.docLocal.sucursales[0].whatsapp
          .replace("+", "")
          .replace(" ", "");
        // window.location.replace(
        //   `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${lsCartToWhatsApp}`
        // );
      } else {
        whatsAppNumber = self.$root.docLocal.sucursales[
          self.props.sucursal
        ].whatsapp
          .replace("+", "")
          .replace(" ", "");
        // window.location.replace(
        //   `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${lsCartToWhatsApp}`
        // );
      }

      await whatsAppNumber;

      self.props.whatsAppNumber = whatsAppNumber;
      self.props.lsCartToWhatsApp = lsCartToWhatsApp;
      // self.props.jsonPedido = jsonPedido;

      // Chequea si esta habilitado la opción de pedidos.
      if (self.$root.docLocal.panel_pedidos) {
        self.props.cartCustomerDataSheetOpened = true;
      } else {
        completeCheckout(self);
      }
    }
  });
};

let completeCheckout = async (self) => {
  let pedidoCreated = await createPedido(self);
};

let createPedido = async (self) => {
  let cliente = "";

  if (
    localStorage.getItem("cod_pais_cliente") &&
    localStorage.getItem("contacto_cliente")
  ) {
    cliente = `${localStorage.getItem(
      "cod_pais_cliente"
    )}${localStorage.getItem("contacto_cliente")}`;
  } else {
    cliente = "";
  }
  // let cliente = `${localStorage.getItem(
  //   "cod_pais_cliente"
  // )}${localStorage.getItem("contacto_cliente")}`;

  if (self.$root.metodoEnvio === "Envío a Domicilio") {
    let mySavedLocationsLs = JSON.parse(
      localStorage.getItem("my_saved_locations")
    );

    // Comprueba si la direccion existe
    checkIfAddressExists(self, mySavedLocationsLs).then((address) => {
      if (!address.exists) {
        // La dirección no concuerda con ninguna guardada

        // Genera nuevo JSON de direccion
        let myNewLocation = [
          {
            address: self.$root.shippingMapAddress,
            lat: self.$root.shippingMapLatLng["lat"],
            lng: self.$root.shippingMapLatLng["lng"],
          },
        ];

        // Comprueba si ya existe en LocalStorage
        if (!mySavedLocationsLs) {
          // Existe en LocalStorage
          localStorage.setItem(
            "my_saved_locations",
            JSON.stringify(myNewLocation)
          );
        } else {
          // No existe en LocalStorage
          if (self.$root.direccionGuardadaIndex) {
            // Se seleccionó una dirección de las guardadas

            // Swap de las direcciones guardadas
            [
              mySavedLocationsLs[0],
              mySavedLocationsLs[self.$root.direccionGuardadaIndex],
            ] = [
              mySavedLocationsLs[self.$root.direccionGuardadaIndex],
              mySavedLocationsLs[0],
            ];

            localStorage.setItem(
              "my_saved_locations",
              JSON.stringify(mySavedLocationsLs)
            );
          } else {
            // La dirección usada es nueva
            let myNewSavedLocation = myNewLocation.concat(mySavedLocationsLs);
            localStorage.setItem(
              "my_saved_locations",
              JSON.stringify(myNewSavedLocation)
            );
          }
        }
      } else {
        // La dirección usada es igual a otra existente
        // Swap de las direcciones guardadas
        [
          mySavedLocationsLs[0],
          mySavedLocationsLs[address.direccionGuardadaIndex],
        ] = [
          mySavedLocationsLs[address.direccionGuardadaIndex],
          mySavedLocationsLs[0],
        ];

        localStorage.setItem(
          "my_saved_locations",
          JSON.stringify(mySavedLocationsLs)
        );
      }
    });
  }

  self.props.jsonPedido.cliente = cliente;

  localStorage.setItem(
    "jsonPedidoMpApproved",
    JSON.stringify(self.props.jsonPedido)
  );
  localStorage.setItem(
    "lsCartToWhatsAppMpApproved",
    JSON.stringify(self.props.lsCartToWhatsApp)
  );

  // return new Promise((resolve) => {
  let createPedidoCallback = (r) => {
    if (self.$root.docLocal.panel_pedidos) {
      localStorage.setItem("pedido_en_curso", r.message.pedido.name);
      self.$root.ongoingOrder = r.message.pedido;
    }

    self.props.lsCartToWhatsApp = self.props.lsCartToWhatsApp.replace(
      /NUMERO_PEDIDO/gi,
      encodeURIComponent(r.message.pedido.numero_pedido)
    );
    self.props.lsCartToWhatsApp = self.props.lsCartToWhatsApp.replace(
      /FECHA_HORA_PEDIDO/gi,
      encodeURIComponent(transformDateTime(r.message.pedido.fecha_hora_pedido))
    );

    localStorage.setItem(
      "ongoing_cart",
      localStorage.getItem("confirmed_cart")
    );

    // SIN MERCADOPAGO //
    if (self.$root.tipoMetodoPago !== "Mercado Pago - Automático") {
      // CON PANEL //
      if (
        self.$root.docLocal.panel_pedidos &&
        self.$root.docLocal.panel_pedidos == 1
      ) {
        // CON WHATSAPP //
        if (
          self.$root.docLocal.pedidos_whatsapp &&
          self.$root.docLocal.pedidos_whatsapp == 1
        ) {
          // Con Panel + Con WhatsApp + Sin MercadoPago
          self.props.cartCheckoutPopupOpened = true;
          self.props.cartCheckoutMPLinkReady = true;
          localStorage.setItem(
            "ongoing_order_tracking_sheet_first_time",
            "true"
          );

          // setTimeout(() => {
          //   clearAll(self).then((r) => {
          //     self.props.cartCheckoutPopupOpened = false;
          //     window.location.replace(
          //       `https://api.whatsapp.com/send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
          //     );
          //     // self.$root.cartOngoingOrderSheetOpened = true;
          //   });
          // }, 5000);
        }
        // SIN WHATSAPP //
        else if (
          !self.$root.docLocal.pedidos_whatsapp ||
          self.$root.docLocal.pedidos_whatsapp == 0
        ) {
          // Con Panel + Sin WhatsApp + Sin MercadoPago
          self.props.cartCheckoutPopupOpened = true;

          setTimeout(() => {
            self.props.cartCheckoutPopupOpened = false;
            setTimeout(() => {
              clearAllAfterOrder(self).then((r) => {
                // self.$root.cartOngoingOrderSheetOpened = true;
              });
            }, 1000);
          }, 5000);
        }
      }
      // SIN PANEL //
      else if (
        !self.$root.docLocal.panel_pedidos ||
        self.$root.docLocal.panel_pedidos == 0
      ) {
        // CON WHATSAPP //
        if (
          self.$root.docLocal.pedidos_whatsapp &&
          self.$root.docLocal.pedidos_whatsapp == 1
        ) {
          // Sin Panel + Con WhatsApp + Sin MercadoPago
          // self.props.cartCheckoutPopupOpened = true;

          clearAllAfterOrder(self).then((r) => {
            // setTimeout(() => {
            // self.props.cartCheckoutPopupOpened = false;
            if (self.$f7.device.ios || self.$f7.device.android) {
              window.location.replace(
                // `whatsapp://send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
                `https://wa.me/${self.props.whatsAppNumber}?text=${self.props.lsCartToWhatsApp}`
              );
            } else {
              window.location.replace(
                `https://wa.me/${self.props.whatsAppNumber}?text=${self.props.lsCartToWhatsApp}`
              );
            }
            // }, 4000);
          });
        }
        // SIN WHATSAPP | NO ES UNA OPCION VALIDA //
        // Sin Panel + Sin WhatsApp + Sin MercadoPago
      }
    }

    // CON MERCADOPAGO //
    else if (self.$root.tipoMetodoPago === "Mercado Pago - Automático") {
      // CON PANEL //
      if (
        self.$root.docLocal.panel_pedidos &&
        self.$root.docLocal.panel_pedidos == 1
      ) {
        // CON WHATSAPP //
        if (
          self.$root.docLocal.pedidos_whatsapp &&
          self.$root.docLocal.pedidos_whatsapp == 1
        ) {
          // Con Panel + Con WhatsApp + Con MercadoPago

          self.props.cartCheckoutPopupOpened = true;
          localStorage.setItem(
            "ongoing_order_tracking_sheet_first_time",
            "true"
          );

          createPreferenceMPAndRedirect(self, r.message.pedido.name).then(
            async (res) => {
              await new Promise((resolveReplace, rejectReplace) => {
                self.props.lsCartToWhatsApp = self.props.lsCartToWhatsApp.replace(
                  /LINK_DE_PAGO/gi,
                  encodeURIComponent(`  • Link de Pago: ${res}`)
                );
                setTimeout(() => {
                  self.props.cartCheckoutMPLinkReady = true;
                  resolveReplace();
                }, 1000);
              });

              // window.location.replace(
              //   `https://api.whatsapp.com/send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
              // );
              // window.location.replace(
              //   encodeURI(
              //     `whatsapp://send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
              //   )
              // );
              // window.location.replace(
              //   `https://wa.me/${self.props.whatsAppNumber}?text=${self.props.lsCartToWhatsApp}`
              // );
            }
          );
        }
        // SIN WHATSAPP //
        else if (
          !self.$root.docLocal.pedidos_whatsapp ||
          self.$root.docLocal.pedidos_whatsapp == 0
        ) {
          // Con Panel + Sin WhatsApp + Con MercadoPago
          self.props.cartCheckoutPopupOpened = true;

          createPreferenceMPAndRedirect(self, r.message.pedido.name).then(
            async (res) => {
              await new Promise((resolveReplace, rejectReplace) => {
                self.props.lsCartToWhatsApp = self.props.lsCartToWhatsApp.replace(
                  /LINK_DE_PAGO/gi,
                  encodeURIComponent(`  • Link de Pago: ${res}`)
                );
                setTimeout(() => {
                  self.props.cartCheckoutMPLinkReady = true;
                  resolveReplace();
                }, 1000);
              });

              // setTimeout(() => {
              clearAllAfterOrder(self).then((r) => {
                self.props.cartCheckoutPopupOpened = false;
                // setTimeout(() => {
                // self.$root.cartOngoingOrderSheetOpened = true;
                // }, 1000);
              });
              // }, 2000);
            }
          );
        }
      }
      // SIN PANEL //
      else if (
        !self.$root.docLocal.panel_pedidos ||
        self.$root.docLocal.panel_pedidos == 0
      ) {
        // CON WHATSAPP //
        if (
          self.$root.docLocal.pedidos_whatsapp &&
          self.$root.docLocal.pedidos_whatsapp == 1
        ) {
          // Sin Panel + Con WhatsApp + Con MercadoPago
          // localStorage.setItem(
          //   "cart_whatsapp_message",
          //   `https://api.whatsapp.com/send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
          // );
          // redirectToMercadoPagoPopUp(self);
          self.props.cartCheckoutPopupOpened = true;

          createPreferenceMPAndRedirect(self, r.message.pedido.name).then(
            async (res) => {
              await new Promise((resolveReplace, rejectReplace) => {
                self.props.lsCartToWhatsApp = self.props.lsCartToWhatsApp.replace(
                  /LINK_DE_PAGO/gi,
                  encodeURIComponent(`  • Link de Pago: ${res}`)
                );
                setTimeout(() => {
                  // self.props.cartCheckoutMPLinkReady = true;
                  resolveReplace();
                }, 3000);
              });
              // self.props.lsCartToWhatsApp = await new Promise(
              //   (resolveLinkDePago) => {
              //     resolveLinkDePago(
              //       self.props.lsCartToWhatsApp.replace(
              //         /LINK_DE_PAGO/gi,
              //         encodeURIComponent(`  • Link de Pago: ${res}`)
              //       )
              //     );
              //   }
              // );

              clearAllAfterOrder(self).then((r) => {
                self.props.cartCheckoutPopupOpened = false;
                window.location.replace(
                  `https://wa.me/${self.props.whatsAppNumber}?text=${self.props.lsCartToWhatsApp}`
                );
              });
            }
          );
        }
        // SIN WHATSAPP | NO ES UNA OPCION VALIDA //
        // Sin Panel + Sin WhatsApp + Con MercadoPago
      }
    }
  };

  utils.customRequest(
    self,
    createPedidoCallback,
    "pedido.pedido.create_pedido",
    "POST",
    self.props.jsonPedido,
    null
    // callbackErr
  );
};

let checkIfAddressExists = async (self, mySavedLocationsLs) => {
  return new Promise((resolve, reject) => {
    if (!mySavedLocationsLs) {
      resolve({
        exists: false,
      });
    } else {
      let addressExist = false;
      mySavedLocationsLs.forEach((location, index) => {
        if (location.address === self.$root.shippingMapAddress) {
          addressExist = true;
        }
        if (mySavedLocationsLs.length === index + 1) {
          if (addressExist) {
            resolve({
              exists: true,
              direccionGuardadaIndex: index,
            });
          } else {
            resolve({
              exists: false,
            });
          }
        }
      });
    }
  });
};

let getOngoingOrderUpdate = async (self) => {
  return new Promise(async (resolve) => {
    await local.getOngoingOrders(self);

    if (Object.keys(self.$root.ongoingOrder).length === 0) {
      if (self.$root.setIntervalUpdateOrder !== "") {
        clearInterval(self.$root.setIntervalUpdateOrder);
      }
      resolve(false);
    } else if (
      ["Entregado", "Anulado", "Rechazado", "Cierre Automático"].includes(
        self.$root.ongoingOrder.estado
      )
    ) {
      self.props.orderProgress =
        self.$root.ongoingOrderStatuses[self.$root.ongoingOrder.estado];
      self.$root.ongoingOrderProgress =
        self.$root.ongoingOrderStatuses[
          self.$root.ongoingOrder.estado
        ].progress;

      if (self.$root.setIntervalUpdateOrder !== "") {
        clearInterval(self.$root.setIntervalUpdateOrder);
      }
      resolve(false);
    } else {
      // if (!["Nuevo"].includes(self.$root.ongoingOrder.estado)) {
      clearInterval(self.$root.setIntervalUpdateOrder);
      setTimeout(() => {
        self.$root.setIntervalUpdateOrder = setInterval(
          getOngoingOrderUpdate,
          60000,
          self
        );
      }, 1000);
      // }

      self.props.orderProgress =
        self.$root.ongoingOrderStatuses[self.$root.ongoingOrder.estado];
      self.$root.ongoingOrderProgress =
        self.$root.ongoingOrderStatuses[
          self.$root.ongoingOrder.estado
        ].progress;

      // Si el pedido cambia, no se va a ver reflejado. Debe traer todo de nuevo desde el back.
      let lsCartConfirmed = JSON.parse(
        window.localStorage.getItem("ongoing_cart")
      );
      const lsCartConfirmedArray = Object.values(lsCartConfirmed);
      self.props.vlDataOngoing.items = lsCartConfirmedArray;

      await calculateEndTimeOrder(self);

      resolve(true);
    }
  });
};

let calculateEndTimeOrder = async (self) => {
  return new Promise((resolve) => {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcNow = utcToZonedTime(new Date(), timeZone);

    let pedidoCreation = parse(
      self.$root.ongoingOrder.fecha_hora_pedido,
      "yyyy-MM-dd HH:mm:ss",
      utcNow
    );
    let endTimeOrder = addMinutes(
      pedidoCreation,
      self.$root.ongoingOrder.demora_pedido
    );
    let endTimeOrderPlus = addMinutes(endTimeOrder, 15);

    self.props.endTimeOrder = format(endTimeOrder, "HH:mm");
    self.props.endTimeOrderPlus = format(endTimeOrderPlus, "HH:mm");

    resolve();
  });
};

let clearAllAfterOrder = async function(self) {
  // if (
  //   !self.$root.docLocal.pedidos_whatsapp ||
  //   self.$root.docLocal.pedidos_whatsapp === 0
  // ) {
  return new Promise(async (resolve) => {
    await clearAll(self);

    self.$f7router.navigate(`/local/${self.$root.docLocal.name}/`, {
      ignoreCache: false,
    });

    resolve();
  });
  // }
};

let clearAll = async (self) => {
  return new Promise((resolve) => {
    window.localStorage.setItem("drafted_cart", JSON.stringify({}));
    window.localStorage.setItem("cart", JSON.stringify({}));
    // window.localStorage.setItem("food_type", "");
    window.localStorage.setItem("confirmed_cart", JSON.stringify({}));
    window.localStorage.removeItem("mercadopago_redirect");
    window.localStorage.removeItem("mercadopago_preference_id");
    window.localStorage.removeItem("cart_whatsapp_message");
    self.$root.grandTotalCart = 0.0;
    self.$root.confirmedCart = [];
    self.$root.draftedCart = [];
    self.$root.updateCart = false;

    self.$root.metodoEnvio = "";
    self.$root.tipoMetodoEnvio = "";
    self.$root.nombreRetira = "";
    self.$root.horaRetiraSelector = "text";
    self.$root.horaRetira = "Lo Antes Posible";
    self.$root.nombreRecibe = "";
    self.$root.direccionRecibe = "";
    self.$root.direccionAdicionalRecibe = "";
    self.$root.direccionEntrecalles = "";
    self.$root.mesa = "";
    self.$root.nombreMesa = "";
    self.$root.cuponesLocal = {};
    self.$root.cuponDescuento = "";
    self.$root.valorCuponDescuentoTotal = 0.0;
    self.$root.totalCart = 0.0;
    self.$root.grandTotalCart = 0.0;
    self.$root.grandSubtotalCart = 0.0;
    self.$root.costoEnvio = 0.0;
    self.$root.tipoRecargoPago = "Importe Fijo";
    self.$root.metodoPago = "";
    self.$root.tipoMetodoPago = "";
    self.$root.efectivoPagaCon = "";
    self.$root.recargoPago = 0.0;
    // self.$root.listFoodSalon = [];
    // self.$root.listFoodDelivery = [];
    // self.$root.listFoodSelected = [];
    // self.$root.loadingFood = true;
    self.$root.listFoodType = "";
    self.$root.listFoodTypeIndex = 0;
    self.$root.listFoodName = "";
    self.$root.showShippingMapTotals = false;
    self.$root.shippingMapTotals = 0.0;
    self.$root.confirmedShippingMapTotals = false;
    self.$root.shippingMapAddress = "";
    self.$root.shippingMapLatLng = {};
    self.$root.confirmedCart = [];
    self.$root.draftedCart = [];
    self.$root.updateCart = false;
    self.$root.currentFoodCartState = "";
    self.$root.customFieldOtrosDatos = false;

    resolve();
  });
};

let calculateCartTotals = async function(self) {
  // let totalShippingCart = 0.0;
  // let totalPaymentCart = 0.0;
  // let totalDiscountPaymentCart = 0.0;
  // let totalFinalCart = 0.0;
  return new Promise((resolve, reject) => {
    if (self.$root.recargoPago > 0.0) {
      if (self.$root.tipoRecargoPago === "Importe Fijo") {
        // Importe Fijo
        self.$root.totalRecargoDePago = parseFloat(self.$root.recargoPago);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          parseFloat(self.$root.recargoPago);

        resolve();
      } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
        // Porcentaje del Subtotal
        self.$root.totalRecargoDePago =
          parseFloat(self.$root.grandTotalCart) *
          (parseFloat(self.$root.recargoPago) / 100);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          parseFloat(self.$root.grandTotalCart) *
            (parseFloat(self.$root.recargoPago) / 100);

        resolve();
      } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
        // Porcentaje del Total
        self.$root.totalRecargoDePago =
          (parseFloat(self.$root.grandTotalCart) +
            parseFloat(self.$root.costoEnvio)) *
          (parseFloat(self.$root.recargoPago) / 100);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          (parseFloat(self.$root.grandTotalCart) +
            parseFloat(self.$root.costoEnvio)) *
            (parseFloat(self.$root.recargoPago) / 100);

        resolve();
      }
    } else if (self.$root.recargoPago < 0.0) {
      if (self.$root.tipoRecargoPago === "Importe Fijo") {
        // Importe Fijo
        self.$root.totalRecargoDePago = parseFloat(self.$root.recargoPago);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          parseFloat(self.$root.recargoPago);

        resolve();
      } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
        // Porcentaje del Subtotal
        self.$root.totalRecargoDePago =
          parseFloat(self.$root.grandTotalCart) *
          (parseFloat(self.$root.recargoPago) / 100);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          parseFloat(self.$root.grandTotalCart) *
            (parseFloat(self.$root.recargoPago) / 100);

        resolve();
      } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
        // Porcentaje del Total
        self.$root.totalRecargoDePago =
          (parseFloat(self.$root.grandTotalCart) +
            parseFloat(self.$root.costoEnvio)) *
          (parseFloat(self.$root.recargoPago) / 100);

        self.props.totalFinalCart =
          parseFloat(self.$root.grandTotalCart) +
          parseFloat(self.$root.costoEnvio) +
          (parseFloat(self.$root.grandTotalCart) +
            parseFloat(self.$root.costoEnvio)) *
            (parseFloat(self.$root.recargoPago) / 100);

        resolve();
      }
    } else if (self.$root.costoEnvio > 0.0 && self.$root.recargoPago === 0.0) {
      // Costo de Envio
      self.props.totalShippingCart = parseFloat(self.$root.costoEnvio);
      self.props.totalFinalCart =
        parseFloat(self.$root.grandTotalCart) +
        parseFloat(self.$root.costoEnvio);

      resolve();
    } else if (
      // Sin Costo de Envio ni Cargos/Bonificacion de Envío
      !self.$root.costoEnvio &&
      self.$root.costoEnvio === 0.0 &&
      self.$root.recargoPago === 0
    ) {
      self.props.totalFinalCart = parseFloat(self.$root.grandTotalCart);
      resolve();
    }
  });
};

let createToast = (self, text, cssClass) => {
  var cartToastFinalValidation = self.$f7.toast.create({
    text: `<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;${text}`,
    closeTimeout: 2000,
    position: "top",
    cssClass: cssClass,
  });

  return cartToastFinalValidation;
};

let cartFinalValidation = (self) => {
  if (self.$root.tipoMetodoEnvio === "") {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar el método envío",
      "toast-error",
      2000
    );
  } else if (self.$root.tipoMetodoPago === "") {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar el método de pago",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoEnvio === "Salón" &&
    (self.$root.mesa === "" || self.$root.nombreMesa === "")
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar el número de mesa y tu nombre",
      "toast-error",
      2000
    );
  } else if (
    self.$root.horaRetira === "" ||
    (self.$root.horaRetiraSelector === "datetime-local" &&
      self.$root.horaRetira === "")
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar un horario de retiro o envío",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoEnvio === "Take Away" &&
    self.$root.nombreRetira === ""
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar el nombre de la persona que retira",
      "toast-error",
      2000
    );
  } else if (
    (self.$root.tipoMetodoEnvio === "Delivery" ||
      self.$root.tipoMetodoEnvio === "Delivery - Zonas de Envío") &&
    (self.$root.nombreRecibe === "" || self.$root.direccionRecibe === "")
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar la dirección de envío y el nombre de la persona que recibe",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoEnvio === "Delivery - Google Maps" &&
    self.$root.shippingMapAddress == ""
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes seleccionar tu ubicación",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoEnvio === "Delivery - Google Maps" &&
    self.$root.nombreRecibe === ""
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes completar el nombre de la persona que recibe",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoPago === "Efectivo" &&
    isNaN(self.$root.efectivoPagaCon)
  ) {
    utils.toast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debes ingresar un importe válido",
      "toast-error",
      2000
    );
  } else if (
    self.$root.tipoMetodoPago === "Efectivo" &&
    parseFloat(self.$root.efectivoPagaCon).toFixed(2) <
      calculateCheckoutTotals(self)
  ) {
    utils.toast(
      self,
      `<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Ingresa un valor mayor (o igual) a $ ${utils.formatCurrency(
        calculateCheckoutTotals(self)
      )}`,
      "toast-error",
      2000
    );
  } else if (
    self.$root.docLocal.sucursales.length > 1 &&
    self.props.sucursal === false
  ) {
    utils.toast(
      self,
      `<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Selecciona una sucursal`,
      "toast-error",
      2000
    );
  } else if (self.$root.listCustomFields.length > 0) {
    let customFieldsValid = true;

    for (let i = 0; i < self.$root.listCustomFields.length; i++) {
      if (
        (self.$root.listCustomFields[i].campo_obligatorio === 1 &&
          self.$root.listCustomFields[i].value === "") ||
        (self.$root.listCustomFields[i].campo_obligatorio === 1 &&
          !self.$root.listCustomFields[i].value)
      ) {
        customFieldsValid = false;
        utils.toast(
          self,
          `<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Debe completar el campo ${self.$root.listCustomFields[i].nombre_campo}`,
          "toast-error",
          2000
        );
      }

      if (self.$root.listCustomFields.length === i + 1) {
        if (customFieldsValid) {
          return true;
        }
      }
    }
  } else {
    return true;
  }

  // else if (
  //   self.$root.metodoPago === "Efectivo" &&
  //   (self.efectivoPagaCon === "" ||
  //     parseFloat(self.efectivoPagaCon).toFixed(2) <
  //       self.$root.grandTotalCart + self.costoEnvio)
  // ) {
  //   cartToastFinalValidation.open();
  // }
};

let calculateCheckoutTotals = function(self) {
  let grandTotal = self.$root.grandTotalCart;

  if (self.$root.recargoPago > 0.0) {
    if (self.$root.tipoRecargoPago === "Importe Fijo") {
      // Importe Fijo
      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        parseFloat(self.$root.recargoPago) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
      // Porcentaje del Subtotal
      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        parseFloat(grandTotal) * (parseFloat(self.$root.recargoPago) / 100) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
      // Porcentaje del Total
      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
          (parseFloat(self.$root.recargoPago) / 100) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    }
  } else if (self.$root.recargoPago < 0.0) {
    if (self.$root.tipoRecargoPago === "Importe Fijo") {
      // Importe Fijo
      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        parseFloat(self.$root.recargoPago) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    } else if (self.$root.tipoRecargoPago === "Porcentaje del Subtotal") {
      // Porcentaje del Subtotal
      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        parseFloat(grandTotal) * (parseFloat(self.$root.recargoPago) / 100) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    } else if (self.$root.tipoRecargoPago === "Porcentaje del Total") {
      // Porcentaje del Total

      return (
        parseFloat(grandTotal) +
        parseFloat(self.$root.costoEnvio) +
        (parseFloat(grandTotal) + parseFloat(self.$root.costoEnvio)) *
          (parseFloat(self.$root.recargoPago) / 100) +
        parseFloat(self.$root.valorCuponDescuentoTotal)
      );
    }
  } else if (self.$root.costoEnvio > 0.0 && self.$root.recargoPago === 0.0) {
    return (
      parseFloat(grandTotal) +
      parseFloat(self.$root.costoEnvio) +
      parseFloat(self.$root.recargoPago) +
      parseFloat(self.$root.valorCuponDescuentoTotal)
    );
  } else if (
    // Sin Costo de Envio ni Cargos/Bonificacion de Envío
    !self.$root.costoEnvio &&
    self.$root.costoEnvio === 0.0 &&
    self.$root.recargoPago === 0
  ) {
    return (
      parseFloat(grandTotal) + parseFloat(self.$root.valorCuponDescuentoTotal)
    );
  }
};

let approvedMercadoPagoPayment = async function(self, urlParams) {
  let approvedMPDialog = openReturnedMercadoPagoDialog(self, true);

  let urlRedirect = null;
  let cartWhatsapMessage = localStorage.getItem("cart_whatsapp_message");

  await new Promise(async (resolveUrlRedirect) => {
    if (cartWhatsapMessage !== null) {
      cartWhatsapMessage += `%0D%0A%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(`#########################`);
      cartWhatsapMessage += `%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(`• Estado del Pago: Aprobado`);
      cartWhatsapMessage += `%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(
        `• Nro de Transacción MercadoPago: ${urlParams.get("payment_id")}`
      );
      cartWhatsapMessage += `%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(`#########################`);

      await localStorage.setItem("cart_whatsapp_message", cartWhatsapMessage);

      let cartWhatsappMessage = await localStorage.getItem(
        "cart_whatsapp_message"
      );
      // await changeStatusMP(self, "Pagado");

      urlRedirect = cartWhatsappMessage;
      resolveUrlRedirect();
    } else {
      // await changeStatusMP(self, "Pagado");

      resolveUrlRedirect();
    }
  });

  clearAll(self).then((r) => {
    self.$f7router.refreshPage();
    self.$$(".main-view").removeClass("disable-newer-version");
    self.$$(".framework7-root").removeClass("disable-newer-version-bgnd");
    if (urlRedirect !== null) {
      setTimeout(() => {
        //   // approvedMPDialog.close();
        // }, 3000);
        // setTimeout(() => {
        //   self.$f7.dialog.preloader("Generando Pedido de WhatsApp");
        // }, 3500);
        // setTimeout(() => {
        window.location.replace(urlRedirect);
      }, 1000);
    }
  });
};

let rejectedMercadoPagoPayment = async function(self) {
  openReturnedMercadoPagoDialog(self, false);

  localStorage.removeItem("mercadopago_redirect");
  localStorage.removeItem("mercadopago_preference_id");

  await localStorage.removeItem("cart_whatsapp_message");
  await changeStatusMP(self, "Rechazado");

  setTimeout(() => {
    window.localStorage.removeItem("ongoing_cart");
    window.localStorage.removeItem("pedido_en_curso");
    utils.navigateTo(self, "/cart/", false);
  }, 3000);
};

let changeStatusMP = (self, status) => {
  return new Promise((resolve) => {
    let pedidoEnCurso = localStorage.getItem("pedido_en_curso");
    let changeStatusMPCallback = (e) => {
      resolve();
    };

    utils.customRequest(
      self,
      changeStatusMPCallback,
      "pedido.pedido.change_status_mercadopago",
      "POST",
      {
        pedido_docname: pedidoEnCurso,
        estado: status,
      },
      null
      // callbackErr
    );
  });
};

let pendingMercadoPagoPayment = async function(self) {
  // let approvedMPDialog = openReturnedMercadoPagoDialog(self, true);

  let urlRedirect = null;
  let cartWhatsapMessage = localStorage.getItem("cart_whatsapp_message");

  await new Promise(async (resolveUrlRedirect) => {
    if (cartWhatsapMessage !== null) {
      cartWhatsapMessage += `%0D%0A%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(`#########################`);
      cartWhatsapMessage += `%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(
        `• Estado del Pago: Comprobante Pendiente`
      );
      cartWhatsapMessage += `%0D%0A`;
      cartWhatsapMessage += encodeURIComponent(`#########################`);

      await localStorage.setItem("cart_whatsapp_message", cartWhatsapMessage);

      let cartWhatsappMessage = await localStorage.getItem(
        "cart_whatsapp_message"
      );
      await changeStatusMP(self, "Pagado");

      urlRedirect = cartWhatsappMessage;
      resolveUrlRedirect();
    } else {
      await changeStatusMP(self, "Pagado");

      resolveUrlRedirect();
    }
  });

  clearAllAfterOrder(self).then((r) => {
    if (urlRedirect !== null) {
      setTimeout(() => {
        approvedMPDialog.close();
      }, 3000);
      setTimeout(() => {
        self.$f7.dialog.preloader("Generando Pedido de WhatsApp");
      }, 3500);
      setTimeout(() => {
        window.location.href = urlRedirect;
      }, 5500);
    }
  });
};

let openReturnedMercadoPagoDialog = function(self, approved) {
  let approvedMPDialog = new Promise(async (resolve) => {
    let dialogText = undefined;

    if (approved) {
      dialogText = `<i class="fas fa-check" style="font-size: 44px;color: #44a734;"></i><br/><br/>Tu pago se ha registrado con éxito. Muchas gracias por tu compra!`;
    } else {
      dialogText = `<i class="fas fa-times-circle" style="font-size: 44px;color: #ca0000;"></i><br/><br/>Tu pago no ha podido ser completado. Intentá nuevamente o probá con con otro medio de pago`;
    }

    await dialogText;

    let dialog = self.$f7.dialog
      .create({
        cssClass: "elimina-carrito-dialog",
        title: "",
        text: dialogText,
        closeByBackdropClick: true,
        closeTimeout: 5000,
      })
      .open();

    setTimeout(() => {
      dialog.close();
    }, 5000);

    resolve(dialog);
  });

  return approvedMPDialog;
};

let confirmShippingMap = (self) => {
  self.$f7.sheet.close();
  self.props.cartMySavedLocationsSheetOpened = false;
  self.props.cartShippingMapSheetOpened = false;
  self.$root.direccionGuardadaIndex = self.props.direccionGuardadaIndexLocal;
  self.$root.metodoEnvio = "Envío a Domicilio";
  self.$root.tipoMetodoEnvio = "Delivery - Google Maps";
  self.$root.confirmedShippingMapTotals = true;
};

let validateCoupon = (self) => {
  let validateCouponCallback = function(r) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcNow = utcToZonedTime(new Date(), timeZone);

    if (r.message.success) {
      utils.toast(
        self,
        "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Felicitaciones! Se ha aplicado su cupón",
        "toast-success",
        3000
      );

      let data = r.message.data;

      calculateCheckoutTotals(self);
      calculateCouponsInTotal(self, r.message.data);
    } else {
      utils.toast(
        self,
        "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;El cupón ingresado no es válido",
        "toast-error",
        3000
      );

      calculateCheckoutTotals(self);
      self.$root.valorCuponDescuentoTotal = 0.0;
    }
  };

  utils.customRequest(
    self,
    validateCouponCallback,
    "cupon.cupon.get_coupon",
    "POST",
    {
      local: self.$root.docLocal.name,
      coupon_name: self.$root.cuponDescuento,
    },
    null
    // callbackErr
  );
};

let calculateCouponsInTotal = (self, data) => {
  if (data.tipo_descuento === "Porcentaje") {
    self.$root.valorCuponDescuentoTotal =
      parseFloat(self.$root.grandTotalCart) *
      (parseFloat(data.valor_descuento) / 100) *
      -1;
  } else if (data.tipo_descuento === "Importe") {
    self.$root.valorCuponDescuentoTotal = parseFloat(data.valor_descuento) * -1;
  }
};

let transformDateTime = (rDateTime) => {
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcNow = utcToZonedTime(new Date(), timeZone);
  // let pedidoCreation = parse(rDateTime, "yyyy-MM-dd HH:mm:ss.SSSSSS", utcNow);
  let pedidoCreation = parse(rDateTime, "yyyy-MM-dd HH:mm:ss", utcNow);

  let pedidoDatetimeObject = pedidoCreation;
  let pedidoDatetimeDia = pedidoDatetimeObject.getDate();
  let pedidoDatetimeMes = pedidoDatetimeObject.getMonth() + 1;
  let pedidoDatetimeAnio = pedidoDatetimeObject.getFullYear();
  let pedidoDatetimeHora = ("0" + pedidoDatetimeObject.getHours()).slice(-2);
  let pedidoDatetimeMinutos = ("0" + pedidoDatetimeObject.getMinutes()).slice(
    -2
  );

  return `${pedidoDatetimeHora}:${pedidoDatetimeMinutos}   ${pedidoDatetimeDia}/${pedidoDatetimeMes}/${pedidoDatetimeAnio}`;
};

export default {
  navigateToLocal,
  emptyCart,
  editFood,
  // redirectToMercadoPagoPopUp,
  createWhatsAppMessage,
  removeFoodFromCart,
  calculateCartTotals,
  updatePricesCheckout,
  calculateGandTotal,
  inputOnFocus,
  confirmCart,
  calculateCheckoutTotals,
  openReturnedMercadoPagoDialog,
  approvedMercadoPagoPayment,
  rejectedMercadoPagoPayment,
  pendingMercadoPagoPayment,
  changeStatusMP,
  confirmShippingMap,
  validateCoupon,
  calculateCouponsInTotal,
  completeCheckout,
  getOngoingOrderUpdate,
  calculateEndTimeOrder,
  transformDateTime,
  clearAll,
  clearAllAfterOrder,
};
