var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{staticClass:"no-padding-horizontal"},[_c('f7-row',[_c('f7-col',{staticClass:"margin-bottom",attrs:{"width":"100"}},[_vm._v("\n      Estado del Pago\n      "),_c('br'),_vm._v(" "),_c('span',{staticClass:"badge badge-shop-from-local"},[_vm._v(_vm._s(_vm.$root.ongoingOrder.estado_pago))])]),_vm._v(" "),_c('f7-col',{attrs:{"width":"100"}},[_vm._v("Método de Pago\n      "),_c('br'),_vm._v(" "),_c('span',{staticClass:"badge badge-shop-from-local"},[_vm._v(_vm._s(_vm.$root.ongoingOrder.metodo_pago))])]),_vm._v(" "),(
        ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
          _vm.$root.ongoingOrder.estado
        ) &&
          ['Pendiente', 'Esperando Comprobante', 'Rechazado'].includes(
            _vm.$root.ongoingOrder.estado_pago
          ) &&
          _vm.$root.ongoingOrder.payment_link_mp != null
      )?_c('f7-col',{staticStyle:{"font-size":"12px","color":"#8e8e93"},attrs:{"width":"100"}},[_vm._v("\n      Realiza tu pago desde este\n      "),_c('a',{staticClass:"external",staticStyle:{"font-weight":"600","color":"#0000EE","text-decoration":"underline"},attrs:{"target":"_blank","href":_vm.$root.ongoingOrder.payment_link_mp}},[_vm._v("enlace")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }