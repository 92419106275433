<template>
  <span class="local-carta-precio-plato">
    <span
      class="badge local-carta-precio-plato-badge"
      v-if="parseFloat(subitem.precio) > 0"
    >
      <!-- PRECIO NORMAL | CARTA SALON -->
      <span
        :class="
          subitem.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-if="subitem.precio > 0"
        >$ {{ formatCurrency(parseFloat(subitem.precio)) }}</span
      >
      <!-- PRECIO NORMAL CON DECIMAL | CARTA SALON -->
      <!-- <span
        :class="
          subitem.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-if="parseFloat(subitem.precio) % 1 !== 0"
        >$ {{ parseFloat(subitem.precio).toFixed(2) }}</span
      > -->
      <!-- PRECIO NORMAL SIN DECIMAL | CARTA SALON  -->
      <!-- <span
        :class="
          subitem.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined
        "
        v-else
        >$ {{ parseFloat(subitem.precio).toFixed(0) }}</span
      > -->
      <!-- PRECIO REBAJADO | CARTA SALON  -->
      <span class="precio-rebajado" v-if="subitem.precio_rebajado > 0"
        >&nbsp;$ {{ formatCurrency(parseFloat(subitem.precio_rebajado)) }}</span
      >
      <!-- PRECIO REBAJADO CON DECIMAL | CARTA SALON  -->
      <!-- <span
        class="precio-rebajado"
        v-if="
          subitem.precio_rebajado > 0 &&
            parseFloat(subitem.precio_rebajado) % 1 !== 0
        "
        >&nbsp;$ {{ parseFloat(subitem.precio_rebajado).toFixed(2) }}</span
      > -->
      <!-- PRECIO REBAJADO SIN DECIMAL | CARTA SALON  -->
      <!-- <span
        class="precio-rebajado"
        v-else-if="
          subitem.precio_rebajado > 0 &&
            parseFloat(subitem.precio_rebajado) % 1 === 0
        "
        >&nbsp;$ {{ parseFloat(subitem.precio_rebajado).toFixed(0) }}</span
      > -->
      <!-- SEPARADOR DE PRECIO GLOBAL DE LOS ATRIBUTOS | CARTA SALON  -->
      <!-- <span
                          v-if="subitem.atributos_del_plato.length > 0"
                          style="color: #777777"
                        >
                          &nbsp;|
                        </span> -->
    </span>
  </span>
</template>

<script>
import utils from "../../../utils.js";

export default {
  props: {
    props: Object,
    subitem: Object,
  },
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
