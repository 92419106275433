<template>
  <f7-row v-if="$root.confirmedCart.length === 0" class="empty-state-cart">
    <f7-col>
      <div>
        <i
          class="fas fa-shopping-basket"
          style="font-size: 60px;"
          aria-hidden="true"
        ></i>
      </div>
      <div style="padding: 10px 0 20px 0;">
        Su Carrito Esta Vacío
      </div>
      <span class="badge badge-cart-return-menu" @click="navigateToLocal">
        <i class="fas fa-arrow-left" aria-hidden="true"></i>
        &nbsp;&nbsp;&nbsp;VOLVER A LA CARTA
      </span>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    navigateToLocal() {
      cart.navigateToLocal(this);
    },
  },
};
</script>
