var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{staticClass:"no-margin no-padding"},[_c('f7-row',{staticClass:"local-navbar-normal",attrs:{"id":"local-navbar"}},[_c('f7-col',{attrs:{"width":"5"}}),_vm._v(" "),_c('f7-col',{staticClass:"local-header-logo",attrs:{"width":"35"}},[(_vm.$root.docLocal)?_c('div',{staticClass:"logo-local-normal",style:({
          'background-image':
            'url("' +
            encodeURI(_vm.$root.docLocal.vista_previa_logo_pagina_principal) +
            '")',
        }),attrs:{"id":"logo-pagina-principal"}}):_vm._e()]),_vm._v(" "),(_vm.$root.docLocal)?_c('f7-col',{staticClass:"local-navbar-text-normal",attrs:{"id":"local-navbar-text","width":"60"}},[_c('span',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.$root.docLocal.nombre_local))]),_vm._v(" "),_c('span',{staticClass:"slogan notranslate"},[_vm._v(_vm._s(_vm.$root.docLocal.slogan))])]):_vm._e()],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"local-cabecera-iconos",attrs:{"width":"80"}})],1),_vm._v(" "),_c('f7-row',{staticClass:"margin-top-half margin-bottom"},[_c('f7-col'),_vm._v(" "),(_vm.$root.docLocal.whatsapp)?_c('f7-col',{staticClass:"local-cabecera-iconos-col",on:{"click":function($event){_vm.navigateToExternalLink(
          ("https://api.whatsapp.com/send?phone=" + (_vm.$root.docLocal.whatsapp.replace(
            '+',
            ''
          )))
        )}}},[_c('i',{staticClass:"fab fa-whatsapp local-cabecera-icono"})]):_vm._e(),_vm._v(" "),(_vm.$root.docLocal.telefono)?_c('f7-col',{staticClass:"local-cabecera-iconos-col",on:{"click":function($event){return _vm.navigateToExternalLink(("tel:" + (_vm.$root.docLocal.telefono)))}}},[_c('i',{staticClass:"fas fa-phone local-cabecera-icono"})]):_vm._e(),_vm._v(" "),_c('f7-col',{staticClass:"local-cabecera-iconos-col link",on:{"click":_vm.nativeShare}},[_c('i',{staticClass:"fas fa-share-alt local-cabecera-icono"})]),_vm._v(" "),_c('f7-col')],1),_vm._v(" "),(_vm.$root.docLocal.texto_informativo)?_c('f7-row',{staticClass:"margin"},[_c('f7-col',{staticClass:"text-align-center"},[_vm._v("\n      "+_vm._s(_vm.$root.docLocal.texto_informativo)+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }