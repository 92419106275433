import utils from "./utils.js";
import * as eva from "eva-icons/eva.min.js";

////////////////
// LOCALS MAP
////////////////

/**
 * Devuelve todas categorias de locales.
 * @param {object} self - La instancia de la vista.
 */
var loadLocalCategories = function (self) {
  var loadLocalsCategoriesCallback = function (e) {
    self.listCategories = e.message.data;
  };

  utils.customRequest(
    self,
    loadLocalsCategoriesCallback,
    "categoria_local.categoria_local.get_local_categories",
    "POST",
    {},
    ""
  );
};

/**
 * Devuelve un local identificado por su docname.
 * @param {object} self - La instancia de la vista.
 * @param {string} docname - El identificador del local (docname).
 * @param {string} message_dialog - El texto del dialogo de carga.
 */
var loadLocal = function (self, docname, message_dialog) {
  var loadLocalCallback = function (e) {
    e.message.data = [e.message.data];

    setMapWithData(self, e, message_dialog);
  };

  utils.customRequest(
    self,
    loadLocalCallback,
    "local.local.get_local_by_docname",
    "POST",
    {
      docname: docname,
    },
    ""
  );
};

/**
 * Devuelve todos los locales pertenecientes a una ciudad.
 * @param {object} self - La instancia de la vista.
 */
var loadAllLocals = function (self) {
  var loadAllLocalsCallback = function (e) {
    self.listLocals = e.message.data;
  };

  utils.customRequest(
    self,
    loadAllLocalsCallback,
    "local.local.get_all_locals",
    "POST",
    {
      ciudad: self.$root.ciudad,
    },
    ""
  );
};

/**
 * Devuelve todos los locales pertenecientes a una ciudad.
 * @param {object} self - La instancia de la vista.
 */
var loadLocals = function (self, resultName, message_dialog) {
  var loadLocalsCallback = function (e) {
    setMapWithData(self, e, message_dialog);

    if (e.message.data.length === 0) {
      utils.customToast(
        self,
        `<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No se encontraron resultados en ${self.$root.ciudad}`,
        "toast-info",
        "center",
        3000
      );
    }
  };

  utils.customRequest(
    self,
    loadLocalsCallback,
    "local.local.get_map_locals",
    "POST",
    {
      result_name: resultName,
      ciudad: self.$root.ciudad,
    },
    ""
  );
};

/**
 * Función de búsqueda de la barra de búsqueda del mapa.
 * @param {object} self - La instancia de la vista.
 */
var searchInMap = function (self) {
  var searchBarQuery = self.$f7.searchbar.get(".searchbar-map");

  var searchInMapCallback = function (e) {
    self.props.categoriesSheetOpened = false;
    closeMapToast(self);
    setMapWithData(self, e, "Aguarde");
  };

  utils.customRequest(
    self,
    searchInMapCallback,
    "local.local.get_locals_by_search",
    "POST",
    {
      search_query: searchBarQuery.$inputEl[0].value, // searchBarQuery.query,
      ciudad: self.$root.ciudad,
    },
    ""
  );
};

/**
 * Itera y acumula en el mapa cada local. Agrega datos de ser necesario (ej.: Distancia en KMs, Tiempo).
 * @param {object} self - La instancia de la vista.
 * @param {object} local - cada local traido desde la base de datos.
 */
var routingMap = function (self, locals) {
  for (let i = 0; i < locals.length; i += 1) {
    self.props.locals.push(locals[i]);

    /**
     * @deprecated Corresponde a los mapas de Leaflet
     * @todo Eliminar
     */
    // if (!self.props.searchQuery) {
    // if (
    //   self.map
    //     .getBounds()
    //     .contains([e.message.data[i].latitud, e.message.data[i].longitud])
    // ) {
    //   // if (self.gpsEnabled) {
    //   routingMap(self, e.message.data[i]);
    //   // } else if (!self.gpsEnabled) {
    //   //   // if (self.gpsEnabled) {
    //   //     routingMap(self.props.locals); @todo Corregir los parametros
    //   //   // } else {
    //   //   //   self.props.locals.push(e.message.data[i]);
    //   //   // }
    //   // }
    // }
    // // } else if (self.props.searchQuery) {
    // //   self.props.locals.push(e.message.data[i]);
    // // }

    // var routingMap = L.Routing.control({
    //   router: L.Routing.mapbox('pk.eyJ1IjoiYmluYXJkZXYiLCJhIjoiY2p4OXR6ZGp4MHptMzNxcWxzYXZ0amhweSJ9.oNoT9FfGNmxmiXjHeLtXVw'),
    //   waypoints: [
    //       L.latLng(self.geoLat, self.geoLng),
    //       L.latLng(e.message.data[i].latitud, e.message.data[i].longitud)
    //   ],
    //   routeWhileDragging: false
    // }).addTo(self.maprm);
    //
    // routingMap.on('routesfound', function(element) {
    //   e.message.data[i].distancia_en_km = (element.routes[0].summary.totalDistance / 1000).toFixed(1);
    //   e.message.data[i].tiempo_en_min = (element.routes[0].summary.totalTime / 60).toFixed(1);
    //
    //   self.props.locals.push(e.message.data[i]);
    // });

    // let distanceFromCenter = L.latLng(self.geoLat, self.geoLng).distanceTo(
    //   L.latLng(e.message.data[i].latitud, e.message.data[i].longitud)
    // );

    // e.message.data[i].distancia_en_km = (distanceFromCenter / 1000).toFixed(2);
    // e.message.data[i].tiempo_en_min = 0;
  }
};

var setMapWithData = (self, e, message_dialog) => {
  var loadLocalsDialog = self.$f7.dialog.progress(
    message_dialog,
    undefined,
    "#444444"
  );
  self.props.locals = [];

  routingMap(self, e.message.data);
  cleanMarkersFromMap(self);
  addMarkersToMap(self);
  onMoveLocalSlider(self);

  self.props.categoriesSheetOpened = false;
  self.props.showLocals = true;

  // Centra en la ciudad actual
  self.props.map.setCenter({
    lat: self.$root.centerLat,
    lng: self.$root.centerLng,
  });
  self.props.map.setZoom(13);
  // reverseGeocode(self, self.$root.centerLat, self.$root.centerLng, 13);
};

/**
 * Limpia todos los marcadores del mapa.
 */
let cleanMarkersFromMap = (self) => {
  for (let i = 0; i < self.props.markers.length; i++) {
    self.props.markers[i].setMap(null);
  }
  self.props.markers = [];
};

/**
 * Agrega todos los marcadores al mapa.
 */
let addMarkersToMap = (self) => {
  setTimeout(async function () {
    for (let i = 0; i < self.props.locals.length; i += 1) {
      addMarkerToMap(self, i, self.props.locals[i]);
    }

    self.$f7.dialog.close();

    let searchBarQuery = self.$f7.searchbar.get(".searchbar-map");

    if (self.props.searchQuery !== "") {
      searchBarQuery.$inputEl[0].value = "";
      self.props.searchQuery = "";
    }

    /**
     * @deprecated Corresponde a los mapas de Leaflet
     * @todo Eliminar
     */
    //   var markersBounds = [];
    //   if (!self.props.searchQuery || self.gpsEnabled) {
    //     markersBounds.push([self.geoLat, self.geoLng]);
    //   }

    //   // FIT MARKERS TO BOUNDS
    //   if (self.props.locals.length >= 1) {
    //     const layersKeys = Object.keys(self.markerGroup._layers);
    //     for (const key of layersKeys) {
    //       var markerLatLng = self.markerGroup._layers[key].getLatLng();
    //       markersBounds.push([markerLatLng.lat, markerLatLng.lng]);
    //     }

    //     if (self.props.searchQuery) {
    //       self.map.flyToBounds(markersBounds, {
    //         maxZoom: 15,
    //       });
    //     } else {
    //       if (!self.draggedMap) {
    //         if (self.gpsEnabled) {
    //           self.map.flyToBounds(markersBounds);
    //         } else {
    //           self.map.fitBounds(markersBounds, {
    //             padding: [50, 100],
    //             maxZoom: 14,
    //           });
    //         }
    //       } else if (self.draggedMap) {
    //         if (self.gpsEnabled) {
    //           // self.map.flyToBounds(markersBounds);
    //         } else {
    //           // self.map.fitBounds(markersBounds, {
    //           //   // padding: [50, 100],
    //           // });
    //         }
    //       }
    //     }
    //   } else {
    //     // self.map.flyTo([self.geoLat, self.geoLng]);
    //     var toastNoLocal = self.$f7.toast.create({
    //       text: "No hay locales",
    //       position: "center",
    //       closeTimeout: 3000,
    //       cssClass: "toast-no-local",
    //     });
    //     toastNoLocal.open();
    //   }
  }, 1000);
};

/**
 * Agregar un marcador al mapa.
 */
let addMarkerToMap = async (self, i, local) => {
  const latlng = {
    lat: parseFloat(local.latitud),
    lng: parseFloat(local.longitud),
  };

  const image = {
    url: encodeURI(
      local.vista_previa_logo_pagina_principal + "?i=map-icon-logo" ||
      "/static/images/Alacarta_Logo_Negro_Naranja.png?i=map-icon-logo"
    ),
    size: new google.maps.Size(62, 62),
    scaledSize: new google.maps.Size(52, 52),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(0, 0),
  };

  await image;

  let marker = new google.maps.Marker({
    position: latlng,
    map: self.props.map,
    icon: image,
    animation: google.maps.Animation.DROP,
  });

  marker.addListener("click", () => {
    setTimeout(function () {
      self.$f7.swiper.get("#map-locals-swiper").slideTo(i);
    }, 500);
  });

  self.props.markers.push(marker);
};

/**
 * Al mover el slider posiciona dicho local con la coordenada en el mapa.
 */
let onMoveLocalSlider = (self) => {
  setTimeout(function () {
    let localsSwiper = self.$f7.swiper.get("#map-locals-swiper");

    if (localsSwiper) {
      if (self.props.locals.length === 1) {
        localsSwiper.params.slidesPerView = 1;
        localsSwiper.update();
      } else {
        localsSwiper.params.slidesPerView = 1.1;
        localsSwiper.update();
      }

      localsSwiper.on("transitionEnd", function (e) {
        var slideIx = self.$f7.swiper.get("#map-locals-swiper").snapIndex;

        closeMapToast(self);

        let localsSwiper = self.$f7.swiper.get("#map-locals-swiper");

        self.props.map.setCenter({
          lat: localsSwiper.slides[slideIx].dataset.lat,
          lng: localsSwiper.slides[slideIx].dataset.long,
        });
        self.props.map.setZoom(15);

        // reverseGeocode(
        //   self,
        //   localsSwiper.slides[slideIx].dataset.lat,
        //   localsSwiper.slides[slideIx].dataset.long,
        //   15
        // );
      });
    }
  }, 500);
};

/**
 * Navega desde el swiper hacia el local.
 */
var navigateToLocalFromMapSwiper = function (self, localName) {
  if (!self.$props.nameFromLocalPage) {
    // self.$root.localFromMapSwiper = true;

    if (self.$root.mapInitalized) {
      self.$$("#maprm").remove();
      self.$$("#map").remove();
      self.$root.mapInitalized = false;
    }
    self.$root.loading = true;

    self.$f7router.navigate("/local/" + localName + "/", {
      // reloadCurrent: true,
    });
  }
};

/**
 * Busca mi ubicación en el mapa.
 */
var myLocation = function (self) {
  closeMapToast(self);

  var gpsDialog = self.$f7.dialog.progress(
    "Estamos buscando locales cercanos a tu ubicación",
    undefined,
    "#444444"
  );

  navigator.geolocation.getCurrentPosition(
    function (position) {
      self.gpsEnabled = true;
      self.draggedMap = false;
      // self.geoLat = -35.033202; // @example Coordenada de prueba (General Villegas)
      // self.geoLng = -63.0096481; // @example Coordenada de prueba (General Villegas)
      self.geoLat = position.coords.latitude;
      self.geoLng = position.coords.longitude;
      var accuracy =
        position.coords.accuracy > 100 ? position.coords.accuracy : 100;

      reverseGeocodeMarker(self, self.geoLat, self.geoLng, 16);
      // setMarkerInMapAndCalculateCosts(
      //   self,
      //   {
      //     lat: self.geoLat,
      //     lng: self.geoLng,
      //   },
      //   16
      // );
      gpsDialog.close();

      /**
       * @deprecated Corresponde a los mapas de Leaflet
       * @todo Eliminar
       */
      // self.map.setView([self.geoLat, self.geoLng], 14);
      // L.circle(
      //   [parseFloat(self.geoLat), parseFloat(self.geoLng)],
      //   accuracy
      // ).addTo(self.map);

      // setTimeout(function() {
      //   if (self.$props.nameFromLocalPage === undefined) {
      //     loadLocals(
      //       self,
      //       null,
      //       "Estamos buscando locales cercanos a tu ubicación"
      //     );
      //   }
      // }, 500);
    },
    function (e) {
      gpsDialog.close();

      if (e.code === 1) {
        // Permiso denegado
        utils.customToast(
          self,
          "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Has denegado los permisos para acceder a su ubicación",
          "toast-info",
          "top",
          3000
        );
      } else if (e.code === 2) {
        // Posicion no disponible
        utils.customToast(
          self,
          "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No se ha podido localizar tu ubicación.",
          "toast-info",
          "top",
          3000
        );
      } else if (e.code === 3) {
        // Timeout
        utils.customToast(
          self,
          "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Comprueba que tu GPS esta encendido e intenta nuevamente.",
          "toast-info",
          "top",
          3000
        );
      }
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
    }
  );
};

/**
 * Busca los resultados que coincidan con la sugerencia seleccionada.
 */
var selectResult = function (self, e) {
  closeMapToast(self);

  var resultName = e.target.dataset.resultName;
  loadLocals(self, resultName, "Buscando en el Mapa");
};

/**
 * Busca los resultados que coincidan con la barra de búsqueda.
 */
var searchAllMap = function (self, sb) {
  const found = [];
  const foundIx = [];
  var itemsCategories = self.listCategories;
  var itemsLocals = self.listLocals;
  var query = sb.currentTarget.value; // sb.query

  if (query === undefined || query === null || query === "") {
    self.props.emptyQueryMap = true;
  } else if (query !== undefined || query !== null || query !== "") {
    self.props.emptyQueryMap = false;
  }

  self.props.itemsFiltered = [];
  self.props.searchQuery = query;

  for (let i = 0; i < itemsCategories.length; i += 1) {
    if (
      itemsCategories[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      query.trim() === ""
    ) {
      if (!foundIx.includes(i)) {
        foundIx.push(i);
        found.push(itemsCategories[i].name);
      }
    }
  }

  self.props.itemsFiltered = found;

  /**
   * @deprecated Corresponde a los mapas de Leaflet
   * @todo Eliminar
   */
  // for (let i = 0; i < itemsLocals.length; i += 1) {
  //   if (
  //     self.map
  //       .getBounds()
  //       .contains([itemsLocals[i].latitud, itemsLocals[i].longitud])
  //   ) {
  //     if (
  //       itemsLocals[i].nombre_local
  //         .toLowerCase()
  //         .indexOf(sb.query.toLowerCase()) >= 0 ||
  //       sb.query.trim() === ""
  //     ) {
  //       if (!foundIx.includes(i)) {
  //         foundIx.push(i);
  //         found.push(itemsLocals[i].nombre_local);
  //       }
  //     }
  //   }
  // }
};

/**
 * Inicialización de Google Maps.
 */
var onMapMounted = function (self, mapId) {
  self.props.geocoder = new google.maps.Geocoder();
  var mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  self.props.map = new google.maps.Map(
    document.getElementById(mapId),
    mapOptions
  );

  // Track map load
  gtag('event', 'map_loaded', {
    'event_category': 'Google Maps API',
    'event_label': 'Map Initialization',
    'subdomain': utils.getSubdomain(),
  });

  if (self.$root.centerLat && self.$root.centerLng) {
    self.props.map.setCenter({
      lat: self.$root.centerLat,
      lng: self.$root.centerLng,
    });
    self.props.map.setZoom(13);
    // reverseGeocode(self, self.$root.centerLat, self.$root.centerLng, 13);
  }

  self.$$(".searchbar-map").on("focusin", function () {
    self.props.categoriesSheetOpened = true;
    self.props.showLocals = false;
    var toastMap = self.$f7.toast.get(".toast-map");
    if (toastMap) {
      toastMap.close();
    }
  });

  self.$$("#map").on("click", function () {
    self.props.categoriesSheetOpened = false;
    self.props.showLocals = true;
  });

  setTimeout(function () {
    if (self.$props.nameFromLocalPage !== undefined) {
      loadLocal(self, self.$props.nameFromLocalPage, "Ubicando Local");
    } else {
      loadLocals(self, null, "Buscando locales");
    }
  }, 500);

  /**
   * @deprecated Corresponde a los mapas de Leaflet
   * @todo Eliminar
   */
  // self.maprm = L.map("maprm");
  // self.map = L.map("map", {
  //   center: [self.$root.centerLat.toString(), self.$root.centerLng.toString()],
  //   zoom: 13,
  //   zoomControl: false,
  // });
  // self.$root.mapInitalized = true;

  // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   attribution:
  //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  // }).addTo(self.map);

  // // let myFilter = [
  // //     'brightness:100%',
  // //     'grayscale:50%',
  // // ];
  // //
  // // let myTileLayer = L.tileLayer.colorFilter('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
  // //     filter: myFilter,
  // // }).addTo(self.map);
  // //
  // // var myIcon = new L.Icon({
  // //   iconUrl: '/static/images/marker_x.png',
  // //   iconSize: [42, 42],
  // //   iconAnchor: [24, 48],
  // // });

  // // L.marker([item.latitud, item.longitud], {icon: myIcon}).addTo(self.map);
  // self.markerGroup = L.layerGroup().addTo(self.map);
  // self.locationGroup = L.layerGroup().addTo(self.map);

  // // ACTION ON DRAG MAP
  // google.maps.event.addListener(self.props.map, "dragend", function(e) {
  //     if (!self.gpsEnabled) {
  //       // var mapCenter = self.map.getCenter();
  //       self.geoLat = mapCenter.lat;
  //       self.geoLng = mapCenter.lng;
  //     }
  //   self.draggedMap = true;

  //   if (
  //     self.$$(".toast-map").length === 0 &&
  //     self.$props.nameFromLocalPage === undefined
  //   ) {
  //     var toastMap = self.$f7.toast.create({
  //       text: "Buscar en esta area",
  //       position: "center",
  //       cssClass: "toast-map",
  //       closeTimeout: 3000,
  //     });

  //     toastMap.open();
  //     //     eva.replace();

  //     self.$$(".toast-map").on("click", function() {
  //       toastMap.close();
  //       loadLocals(self, null, "Buscando locales");
  //     });
  //   }
  // });

  // self.geoLat = self.$root.centerLat;
  // self.geoLng = self.$root.centerLng;
};

////////////////
// SHIPPING MAP
////////////////

var onCartShippingMapCartSheetOpened = async function (self) {
  self.props.geocoder = new google.maps.Geocoder();

  var mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    // center: { lat: self.$root.centerLat, lng: self.$root.centerLng },
    // zoom: 13,
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  self.props.map = await new google.maps.Map(
    document.getElementById("shippingMapCart"),
    mapOptions
  );

  // Track map load
  gtag('event', 'map_loaded', {
    'event_category': 'Google Maps API',
    'event_label': 'Map Initialization',
    'subdomain': utils.getSubdomain(),
  });

  self.$$(".searchbar-map input").val("");

  if (
    !self.$root.shippingMapLatLng.lat &&
    !self.$root.shippingMapLatLng.lng &&
    self.$root.centerLat &&
    self.$root.centerLng
  ) {
    // reverseGeocode(self, self.$root.centerLat, self.$root.centerLng, 13);
    self.props.map.setCenter({
      lat: self.$root.centerLat,
      lng: self.$root.centerLng,
    });
    self.props.map.setZoom(13);

    // loadAutocompleteWidget(self);
    // markerOnClick(self);

    utils.customToast(
      self,
      "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Escriba su dirección para continuar",
      "toast-info",
      "top",
      6000
    );
  } else if (
    self.$root.shippingMapLatLng.lat &&
    self.$root.shippingMapLatLng.lng
  ) {
    setMarkerInMapAndCalculateCosts(
      self,
      {
        lat: self.$root.shippingMapLatLng.lat,
        lng: self.$root.shippingMapLatLng.lng,
      },
      14
    );
    // reverseGeocodeMarker(
    //   self,
    //   self.$root.shippingMapLatLng.lat,
    //   self.$root.shippingMapLatLng.lng,
    //   14
    // );
  }
};

// const loadAutocompleteWidget = async (self) => {
//   let input = document.querySelector("#cart-shipping-map-sheet-search input");

//   if (input) {
//     let input = document.querySelector("#cart-shipping-map-sheet-search input");

//     input.addEventListener("change", async (event) => {
//       utils.registerApiCost(
//         self,
//         "Maps - Autocomplete without Places Details - Per Session",
//         "loadAutocompleteWidget",
//         "map.js"
//       );
//     });

//     const options = {
//       // bounds: defaultBounds,
//       // componentRestrictions: { country: "us" },
//       fields: ["address_components", "formatted_address", "geometry", "name"],
//       strictBounds: false,
//       types: ["address"],
//     };

//     let autocomplete = new google.maps.places.Autocomplete(input, options);
//     autocomplete.bindTo("bounds", self.props.map);
//     autocomplete.addListener("place_changed", function() {
//       var place = autocomplete.getPlace();
//       loadAddressSuggestion(self, place);
//       autocomplete.setOptions({});

//       utils.registerApiCost(
//         self,
//         "Maps - Autocomplete (included with Places Details) - Per Session",
//         "loadAutocompleteWidget",
//         "map.js"
//       );
//       utils.registerApiCost(
//         self,
//         "Maps - Place Details API",
//         "loadAutocompleteWidget",
//         "map.js"
//       );
//     });
//   }
// };

// let markerOnClick = (self, map) => {
//   self.props.map.addListener("click", (evt) => {
//     if (
//       !self.$root.shippingMapLatLng.lat &&
//       !self.$root.shippingMapLatLng.lng
//     ) {
//       reverseGeocodeMarkerByClick(self, evt.latLng.lat(), evt.latLng.lng(), 14);
//     }
//   });
// };

let onCartShippingMapCartSheetClosed = (self) => {
  if (!self.$root.confirmedShippingMapTotals) {
    self.$root.showShippingMapTotals = false;
    self.$root.shippingMapTotals = 0.0;
    self.$root.costoEnvio = 0.0;
    self.$root.shippingMapLatLng = "";
    self.$root.shippingMapAddress = "";
  }
};

// let reverseGeocode = function(self, latitud, longitud, zoom, marker = false) {
//   const latlng = {
//     lat: parseFloat(latitud),
//     lng: parseFloat(longitud),
//   };

//   self.props.geocoder.geocode({ location: latlng }, (results, status) => {
//     utils.registerApiCost(
//       self,
//       "Maps - Geocoding API",
//       "reverseGeocode",
//       "map.js"
//     );

//     if (status === "OK") {
//       if (results[0]) {
//         self.props.map.setCenter(latlng);
//         self.props.map.setZoom(zoom);
//       } else {
//         utils.customToast(
//           self,
//           "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//           "toast-info",
//           "top",
//           3000
//         );
//       }
//     } else {
//       utils.customToast(
//         self,
//         "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//         "toast-info",
//         "top",
//         3000
//       );
//     }
//   });
// };

// let reverseGeocodeAddress = function(self, latitud, longitud, zoom) {
//   const latlng = {
//     lat: parseFloat(latitud),
//     lng: parseFloat(longitud),
//   };

//   self.props.geocoder.geocode({ location: latlng }, (results, status) => {
//     utils.registerApiCost(
//       self,
//       "Maps - Geocoding API",
//       "reverseGeocodeAddress",
//       "map.js"
//     );

//     if (status === "OK") {
//       if (results[0]) {
//         self.$root.shippingMapAddress = results[0].formatted_address;
//         // self.props.map.setCenter(latlng);
//         // self.props.map.setZoom(zoom);
//       } else {
//         utils.customToast(
//           self,
//           "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//           "toast-info",
//           "top",
//           3000
//         );
//       }
//     } else {
//       utils.customToast(
//         self,
//         "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//         "toast-info",
//         "top",
//         3000
//       );
//     }
//   });
// };

let setMarkerInMapAndCalculateCosts = (self, latlng, zoom) => {
  self.props.marker = new google.maps.Marker({
    map: self.props.map,
    position: latlng,
    draggable: true,
    animation: google.maps.Animation.DROP,
  });
  self.props.map.setCenter(latlng);
  self.props.map.setZoom(zoom);

  self.$root.shippingMapLatLng = latlng;

  // self.$root.shippingMapLatLng = {
  //   lat: results[0].geometry.location.lat(),
  //   lng: results[0].geometry.location.lng(),
  // };

  // self.$root.shippingMapAddress = results[0].formatted_address;

  markerDragListener(self);

  // let location = latLng;

  calculateShippingCostByPosition(self, latlng);

  self.$f7.dialog.close();
};

let reverseGeocodeMarker = function (self, latitud, longitud, zoom) {
  const latlng = {
    lat: parseFloat(latitud),
    lng: parseFloat(longitud),
  };

  self.props.map.setCenter(latlng);
  self.props.map.setZoom(14);


  self.props.geocoder.geocode({ location: latlng }, (results, status) => {
    gtag('event', 'geocode_request', {
      'event_category': 'Google Maps API',
      'event_label': 'Geocoding',
      'subdomain': subdomain,
    });

    if (status === "OK") {
      if (results[0]) {
        self.$root.shippingMapAddress = results[0].formatted_address;
        self.$root.shippingMapLatLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };

        setMarkerInMapAndCalculateCosts(self, latlng, zoom);
        //   self.props.marker = new google.maps.Marker({
        //     map: self.props.map,
        //     position: results[0].geometry.location,
        //     draggable: true,
        //     animation: google.maps.Animation.DROP,
        //   });
        //   self.props.map.setCenter(latlng);
        //   self.props.map.setZoom(zoom);
        //   self.$root.shippingMapLatLng = {
        //     lat: results[0].geometry.location.lat(),
        //     lng: results[0].geometry.location.lng(),
        //   };
        //   self.$root.shippingMapAddress = results[0].formatted_address;
        //   markerDragListener(self);
        //   let location = results[0].geometry.location.toJSON();
        //   calculateShippingCostByPosition(self, location);
        //   self.$f7.dialog.close();
      } else {
        utils.customToast(
          self,
          "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
          "toast-info",
          "top",
          3000
        );
      }
    } else {
      utils.customToast(
        self,
        "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
        "toast-info",
        "top",
        3000
      );
    }
  });
};

// let reverseGeocodeMarkerByClick = function(self, latitud, longitud, zoom) {
//   const latlng = {
//     lat: parseFloat(latitud),
//     lng: parseFloat(longitud),
//   };

//   self.props.geocoder.geocode({ location: latlng }, (results, status) => {
//     utils.registerApiCost(
//       self,
//       "Maps - Geocoding API",
//       "reverseGeocodeMarkerByClick",
//       "map.js"
//     );

//     if (status === "OK") {
//       if (results[0]) {
//         self.props.map.setCenter(results[0].geometry.location);
//         self.props.map.setZoom(14);
//         self.props.marker = new google.maps.Marker({
//           map: self.props.map,
//           position: results[0].geometry.location,
//           draggable: true,
//           animation: google.maps.Animation.DROP,
//         });

//         self.$root.shippingMapLatLng = {
//           lat: results[0].geometry.location.lat(),
//           lng: results[0].geometry.location.lng(),
//         };

//         self.$root.shippingMapAddress = results[0].formatted_address;

//         markerDragListener(self);

//         let location = results[0].geometry.location.toJSON();

//         calculateShippingCostByPosition(self, location);

//         self.$f7.toast.close();
//         utils.customToast(
//           self,
//           "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Arrastra el marcador para seleccionar otra ubicación.",
//           "toast-info",
//           "top",
//           8000
//         );
//       } else {
//         utils.customToast(
//           self,
//           "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//           "toast-info",
//           "top",
//           3000
//         );
//       }
//     } else {
//       utils.customToast(
//         self,
//         "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//         "toast-info",
//         "top",
//         3000
//       );
//     }
//   });
// };

var searchAddress = function (self) {
  if (self.props.marker) {
    self.props.marker.setMap(null);
  }

  var address = self.$$("#cart-shipping-map-sheet-search input")[0].value;
  if (address) {
    self.props.geocoder.geocode(
      { address: address, bounds: self.props.map.getBounds() },
      function (results, status) {
        gtag('event', 'geocode_request', {
          'event_category': 'Google Maps API',
          'event_label': 'Geocoding',
          'subdomain': utils.getSubdomain(),
        });

        if (status == "OK") {
          self.props.map.setCenter(results[0].geometry.location);
          self.props.map.setZoom(14);
          self.props.marker = new google.maps.Marker({
            map: self.props.map,
            position: results[0].geometry.location,
            draggable: true,
            animation: google.maps.Animation.DROP,
          });

          self.$$(".searchbar-map input").blur();

          self.$root.shippingMapLatLng = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };

          self.$root.shippingMapAddress = results[0].formatted_address;

          markerDragListener(self);

          let location = results[0].geometry.location.toJSON();

          calculateShippingCostByPosition(self, location);

          self.$f7.dialog.close();
          utils.customToast(
            self,
            "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Arrastra el marcador para seleccionar otra ubicación.",
            "toast-info",
            "top",
            6000
          );
        } else {
          utils.customToast(
            self,
            "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
            "toast-info",
            "top",
            3000
          );
        }
      }
    );
  }
};

// var loadAddressSuggestion = function(self, place) {
//   if (self.props.marker) {
//     self.props.marker.setMap(null);
//   }

//   // var address = self.$$("#cart-shipping-map-sheet-search input")[0].value;
//   // if (address) {
//   // self.props.geocoder.geocode(
//   // { address: address, bounds: self.props.map.getBounds() },
//   // function(results, status) {
//   //   if (status == "OK") {
//   self.props.map.setCenter(place.geometry.location);
//   self.props.map.setZoom(14);
//   self.props.marker = new google.maps.Marker({
//     map: self.props.map,
//     position: place.geometry.location,
//     draggable: true,
//     animation: google.maps.Animation.DROP,
//   });

//   self.$$(".searchbar-map input").blur();

//   self.$root.shippingMapLatLng = {
//     lat: place.geometry.location.lat(),
//     lng: place.geometry.location.lng(),
//   };

//   self.$root.shippingMapAddress = place.formatted_address;

//   markerDragListener(self);

//   let location = place.geometry.location.toJSON();

//   calculateShippingCostByPosition(self, location);

//   self.$f7.dialog.close();
//   utils.customToast(
//     self,
//     "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;Arrastra el marcador para seleccionar otra ubicación.",
//     "toast-info",
//     "top",
//     6000
//   );
//   // } else {
//   //   utils.customToast(
//   //     self,
//   //     "<i class='fas fa-exclamation-circle'></i>&nbsp;&nbsp;No hemos podido localizar la ubicación. Intenta nuevamente.",
//   //     "toast-info",
//   //     "top",
//   //     3000
//   //   );
//   // }
//   // }
//   // );
//   // }
// };

let markerDragListener = (self) => {
  google.maps.event.addListener(self.props.marker, "dragend", function (evt) {
    self.$$(".searchbar-map input").val("");
    self.$$(".searchbar-map input").blur();
    self.props.map.panTo(evt.latLng);

    // reverseGeocodeAddress(self, evt.latLng.lat(), evt.latLng.lng(), 14);
    // self.$root.shippingMapLatLng = {
    //   lat: evt.latLng.lat(),
    //   lng: evt.latLng.lng(),
    // };
    calculateShippingCostByPosition(self, {
      lat: evt.latLng.lat(),
      lng: evt.latLng.lng(),
    });
  });
};

let calculateShippingCostByPosition = async function (self, position) {
  let positionLatLng = await new google.maps.LatLng(
    parseFloat(position.lat),
    parseFloat(position.lng)
  );

  self.$f7.toast.close();

  let insideDelimitedShipping = await checkDelimitedShipping(
    self,
    positionLatLng
  );
  if (insideDelimitedShipping) return;

  let insideRadioShipping = await checkRadioShipping(self, position);
  if (insideRadioShipping) return;

  if (!insideDelimitedShipping && !insideRadioShipping) {
    self.$root.showShippingMapTotals = false;
    self.$root.shippingMapTotals = 0.0;
    self.$root.costoEnvio = 0.0;

    self.$f7.toast
      .create({
        text: "No realizamos envíos dentro de la zona elegida",
        position: "bottom",
        closeTimeout: 15000,
        cssClass: "toast-error",
      })
      .open();
  }
};

let checkDelimitedShipping = async (self, positionLatLng) => {
  let delimitedShipping = self.$root.listFoodShippingMethodsDelimitedMaps;
  var areaMatched = false;

  // Iterate through Google Maps delimited shipping methods
  for (let i = 0; i < delimitedShipping.length; i++) {
    let curArea = [];
    let geoReferenciasZona = await JSON.parse(
      delimitedShipping[i].geo_referencias_zona
    );

    // Iterate through Geo References
    for (let x = 0; x < geoReferenciasZona.length; x++) {
      await (async () => {
        let latLng = await new google.maps.LatLng(
          geoReferenciasZona[x].lat,
          geoReferenciasZona[x].lng
        );
        curArea.push(latLng);

        if (geoReferenciasZona.length === x + 1) {
          let polyArea = await new google.maps.Polygon({
            paths: curArea,
          });
          const addressContainedInArea = google.maps.geometry.poly.containsLocation(
            positionLatLng,
            polyArea
          );

          if (addressContainedInArea) {
            self.$root.showShippingMapTotals = true;
            self.$root.shippingMapTotals = delimitedShipping[i].costo_envio;
            self.$root.costoEnvio = delimitedShipping[i].costo_envio;
            areaMatched = true;
            return;
          } else {
            areaMatched = false;
            return;
          }
        }
      })();

      if (areaMatched) {
        break;
      }
    }

    if (delimitedShipping.length === i + 1) {
      // console.log(areaMatched);
      return areaMatched;
    }
  }
};

let checkRadioShipping = async (self, position) => {
  let radioShipping = self.$root.listFoodShippingMethodsRadioMaps;

  // Iterate through Google Maps radio shipping methods
  for (let i = 0; i < radioShipping.length; i++) {
    let breakLoop = false;

    await areaPointsNear(
      { lat: position.lat, lng: position.lng },
      { lat: self.$root.centerLat, lng: self.$root.centerLng },
      radioShipping[i].radio_de_envio
    ).then((r) => {
      if (r) {
        breakLoop = true;
        self.$root.showShippingMapTotals = true;
        self.$root.shippingMapTotals = radioShipping[i].costo_envio;
        self.$root.costoEnvio = radioShipping[i].costo_envio;
      } else {
        return false;
      }
    });

    if (breakLoop) {
      return true;
    }
  }
};

let areaPointsNear = async function (checkPoint, centerPoint, km) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
};

/**
 * @deprecated
 */
var navBack = function (self) {
  closeMapToast(self);

  self.props.categoriesSheetOpened = false;
  self.props.showLocals = false;

  if (self.$root.mapInitalized) {
    self.$$("#maprm").remove();
    self.$$("#map").remove();
    self.$root.mapInitalized = false;
  }

  self.$f7router.navigate(self.$f7router.previousRoute.path, {
    // ignoreCache: true,
    reloadAll: true,
  });
  // self.$f7router
  //   .back
  // { ignoreCache: true }
  // ();
};

/**
 * Comprueba si existen toasts abiertas y las cierra para que no se superpongan.
 * @param {object} self - La instancia de la vista.
 */
let closeMapToast = (self) => {
  if (self.$$(".toast-map").length > 0) {
    var toastMap = self.$f7.toast.get(".toast-map");
    toastMap.close();
  }
};

export default {
  loadLocalCategories,
  loadLocal,
  loadAllLocals,
  routingMap,
  loadLocals,
  searchInMap,
  setMapWithData,
  cleanMarkersFromMap,
  navigateToLocalFromMapSwiper,
  myLocation,
  selectResult,
  searchAllMap,
  searchAddress,
  areaPointsNear,
  navBack,
  onMapMounted,
  onCartShippingMapCartSheetOpened,
  onCartShippingMapCartSheetClosed,
  // markerOnClick,
  // reverseGeocodeMarkerByClick,
  // reverseGeocode,
  // reverseGeocodeAddress,
  // reverseGeocodeMarker,
  markerDragListener,
  closeMapToast,
  calculateShippingCostByPosition,
};

// map.panTo([50, 30]);-> Cuando se mueve un slide
