var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$root.loading === true || _vm.props.loading === true ||
      _vm.$root.changeMap === true ||
      _vm.$root.maintenanceMode === true
  )?_c('span',{staticClass:"fullscreen-loader-container"},[_c('div',{staticClass:"col fullscreen-loader"},[_c('div',{staticClass:"fullscreen-loader-image-container"},[(_vm.$root.maintenanceMode === true)?_c('div',{staticClass:"margin-bottom",style:({
          'background-image':
            'url(/static/images/Alacarta_Logo_Transparent.png)',
          'background-position': 'left center',
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          height: '15vh',
          width: '100%',
          'border-radius': '5px',
          // 'margin-left': '50%',
          // transform: 'translate(-25%, 0)',
          filter: 'grayscale(2) opacity(0.25)',
        })}):_vm._e(),_vm._v(" "),(_vm.$root.maintenanceMode === false)?_c('img',{staticClass:"fullscreen-loader-image",attrs:{"src":"static/images/loader-alacarta.gif","height":"200px"}}):_vm._e(),_vm._v(" "),(_vm.$root.maintenanceMode === true)?_c('div',[_c('h3',{},[_vm._v("\n          Estamos actualizando nuestra plataforma\n        ")])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }