<template>
  <f7-page name="city-page">
    <f7-block style="width: 100%; margin: 20px 0px 10px 0px">
      <f7-row>
        <f7-col width="70">
          <div
            v-bind:style="{
              'background-image':
                'url(/static/images/Alacarta_Logo_Transparent.png)',
              'background-position': 'left center',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              height: '100px',
              width: '100%',
              'border-radius': '5px',
            }"
          ></div>
          <!-- <span style="font-size: 32px; text-transform: capitalize;">{{ this.$root.ciudad }}</span> -->
        </f7-col>
        <f7-col
          width="30"
          style="
            z-index: 100;
            text-align: end;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          <f7-link @click="nativeShare">
            <i
              data-eva="share-outline"
              style="vertical-align: sub; padding: 5px 10px; margin-top: 8px"
              data-eva-fill="#444444"
            ></i>
          </f7-link>

          <f7-link v-show="!this.$root.standaloneApp && a2hsReady === false">
            <i
              class="a2hs-btn"
              data-eva="download-outline"
              style="vertical-align: sub; padding: 5px 10px; margin-top: 8px"
              data-eva-fill="#eaeaea"
            ></i>
          </f7-link>
          <f7-link
            v-show="!this.$root.standaloneApp && a2hsReady === true"
            @click="installApp"
          >
            <i
              class="a2hs-btn"
              data-eva="download-outline"
              style="vertical-align: sub; padding: 5px 10px; margin-top: 8px"
              data-eva-fill="#444444"
            ></i>
          </f7-link>
          <!-- <i data-eva="menu-outline" style="vertical-align: sub; padding: 5px 10px; margin-top: 8px;" data-eva-fill="#444444"></i> -->
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block v-if="!loading" style="width: 100%; margin-top: 30px">
      <f7-row
        v-for="(city, index) in listCities"
        :key="index"
        class="home-cities-list"
        v-bind:style="{
          'background-image':
            'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(\'https://' +
            $root.serverHostBase +
            city.icono_ciudad +
            '\)',
          'background-position': 'center center',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
        }"
      >
        <f7-col @click="navigateToCity(city.subdominio, city.icono_ciudad)">
          <!-- <f7-row>
            <f7-col>
              <div
                v-if="city.icono_ciudad"
                v-bind:style="{
                  'background-image': 'url(\'' + $root.serverHostBase + city.icono_ciudad + '\')',
                  'background-position': 'center center',
                  'background-size': 'contain',
                  'background-repeat': 'no-repeat',
                  'height': '60px',
                  'width': '100%',
                  'filter': 'grayscale(1)',
                  'margin-top': '20px',
                }"
              ></div>
            </f7-col>
          </f7-row> -->

          <f7-row>
            <f7-col>
              <div class="" style="color: #ffffff">
                {{ city.name }}
              </div>
            </f7-col>
          </f7-row>

          <f7-row>
            <f7-col width="100" style="text-align: center">
              <span v-if="city.cantidad_locales == 0" class="badge">
                No hay Locales
              </span>
              <span v-else-if="city.cantidad_locales == 1" class="badge">
                {{ city.cantidad_locales }} Local
              </span>
              <span v-else-if="city.cantidad_locales > 1" class="badge">
                {{ city.cantidad_locales }} Locales
              </span>
            </f7-col>

            <!-- <f7-col width="50" style="text-align: right; padding-right: 40px;">
              <f7-link href="/city/">
                <i data-eva="arrow-forward-outline" data-eva-fill="#444444"></i>
              </f7-link>
            </f7-col> -->
          </f7-row>
        </f7-col>
      </f7-row>
    </f7-block>

    <!-- MOCKUP CARD -->

    <f7-list media-list v-if="loading">
      <f7-list-item
        v-for="n in 4"
        :key="n"
        :class="`skeleton-text skeleton-effect-${effect}`"
        style="padding-left: 0px !important; width: 95%; margin: 10px auto"
        title="XXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
        subtitle="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
        text="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      >
        <f7-skeleton-block
          style="width: 60px; height: 60px; margin-top: 5px; border-radius: 10%"
          slot="media"
        ></f7-skeleton-block>
      </f7-list-item>
    </f7-list>

    <!-- MOCKUP CARD -->
  </f7-page>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import city from "../city.js";

export default {
  data: function() {
    return {
      listCities: [],
      loading: false,
      effect: "fade",
      data: [],
      listCities: [],
      // items: [],
      // vlData: {
      //     items: [],
      // },
      deferredPrompt: undefined,
      a2hsReady: false,
      loading: true,
      currentBannerImage: undefined,
    };
  },
  mounted: function() {
    const self = this;
    city.getCities(self);
    eva.replace();

    window.addEventListener("beforeinstallprompt", (e) => {
      self.a2hsReady = true;
      // Prevent the mini-infobar from appearing on mobile
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      self.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (showInstallPromotion) {
        showInstallPromotion();
      }
    });
  },
  updated: function() {
    eva.replace();
  },
  methods: {
    refresh(event) {
      const self = this;
      city.refresh(self, event);
    },
    // renderExternal(vl, vlData) {
    //   const self = this;
    //   city.renderExternal(self, vl, vlData);
    // },
    nativeShare() {
      const self = this;

      if (navigator.share) {
        navigator
          .share({
            title: `Alacarta`,
            text: `Si estas buscando donde comer, pasate por Alacarta.online  👉`,
            url: `https://app.alacarta.online/`,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    },
    installApp(e) {
      const self = this;

      var loadLocalsDialog = self.$f7.dialog.progress(
        "Instalando Alacarta Online",
        undefined,
        "#444444"
      );

      // Hide the app provided install promotion
      // hideMyInstallPromotion();
      // Show the install prompt
      self.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      self.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setTimeout(function() {
            loadLocalsDialog.close();
          }, 3000);
          // self.$f7.dialog.alert('User accepted the install prompt');
        } else {
          loadLocalsDialog.close();
          // self.$f7.dialog.alert('User dismissed the install prompt');
        }
      });
    },
    navigateToCity(cityName, cityImage) {
      const self = this;

      self.$root.ciudad = cityName;
      self.$root.icono_ciudad = cityImage;
      // self.$f7router.navigate(`https://${cityName}.alacarta.binar.io`);
      window.location.replace(
        `https://${cityName}.${self.$root.domainHostName}`
      );
    },
  },
};
</script>
