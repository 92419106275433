<template>
  <!-- SECCION | VARIANTES | SALON -->
  <div class="no-margin no-padding">
    <f7-row
      v-if="
        $root.docCurrentFood &&
        $root.listFoodType === 'Salon' &&
        $root.docCurrentFood.atributos_del_plato &&
        $root.docCurrentFood.atributos_del_plato.length > 0 &&
        $root.docCurrentFood.atributos_del_plato.some(
          (atributo) => atributo.habilitado === 1
        )
      "
      class="shop-attributes-title-section"
    >
      <f7-col>
        <span>VARIANTES</span>
      </f7-col>
    </f7-row>

    <!-- SECCION | VARIANTES | DELIVERY -->
    <f7-row
      v-if="
        $root.docCurrentFood &&
        $root.listFoodType === 'Delivery' &&
        $root.docCurrentFood.atributos_del_plato_delivery &&
        $root.docCurrentFood.atributos_del_plato_delivery.length > 0 &&
        $root.docCurrentFood.atributos_del_plato_delivery.some(
          (atributo) => atributo.habilitado_delivery === 1
        )
      "
      class="shop-attributes-title-section"
    >
      <f7-col>
        <span>VARIANTES</span>
      </f7-col>
    </f7-row>

    <!-- SELECCION DE ATRIBUTOS | SALON -->
    <span v-if="$root.listFoodType === 'Salon'">
      <f7-row
        class="shop-attributes-attribute-selection"
        v-for="(atributo, index) in $root.docCurrentFood.atributos_del_plato"
        :key="index"
      >
        <!-- ARTIBUTO -->
        <f7-col
          width="65"
          class="shop-attributes-attribute-title"
          v-if="atributo.habilitado === 1"
        >
          <!-- ARTIBUTO | SALON -->
          {{ atributo.nombre_atributo }}
          <br />
          <!-- PRECIO NORMAL DE ATRIBUTO | SALON -->
          <span
            :class="
              parseFloat(atributo.precio_rebajado) > 0
                ? 'shop-sale-price'
                : 'shop-normal-price'
            "
            >$ {{ formatCurrency(parseFloat(atributo.precio)) }}</span
          >
          &nbsp;&nbsp;
          <!-- PRECIO REBAJADO DE ATRIBUTO | SALON -->
          <span
            v-if="parseFloat(atributo.precio_rebajado) > 0"
            class="shop-normal-price"
            >$ {{ formatCurrency(parseFloat(atributo.precio_rebajado)) }}</span
          >
        </f7-col>
        <!-- CANTIDAD DE ATRIBUTO | SALON -->
        <f7-col
          width="35"
          class="shop-attributes-attribute-qty"
          v-if="atributo.habilitado === 1"
        >
          <f7-stepper
            v-if="atributo.sin_stock === 0"
            small
            round
            fill
            :value="atributo.cantidad"
            :buttons-only="false"
            @change="changeQtyAttributes"
            :data-attribute-index="index"
          ></f7-stepper>

          <span v-else-if="atributo.sin_stock === 1" style="color: #a9a9a9"
            >Sin Stock</span
          >
        </f7-col>
      </f7-row>
    </span>

    <!-- SELECCION DE ATRIBUTOS | DELIVERY -->
    <span v-if="$root.listFoodType === 'Delivery'">
      <f7-row
        class="shop-attributes-attribute-selection"
        v-for="(atributo, index) in $root.docCurrentFood
          .atributos_del_plato_delivery"
        :key="index"
      >
        <!-- ATRIBUTO | DELIVERY -->
        <f7-col
          width="65"
          class="shop-attributes-attribute-title"
          v-if="atributo.habilitado_delivery === 1"
        >
          {{ atributo.nombre_atributo_delivery }}
          <br />
          <!-- PRECIO NORMAL DE ATRIBUTO | SALON -->
          <span
            :class="
              parseFloat(atributo.precio_rebajado_delivery) > 0
                ? 'shop-sale-price'
                : 'shop-normal-price'
            "
            >$ {{ formatCurrency(parseFloat(atributo.precio_delivery)) }}</span
          >
          &nbsp;&nbsp;
          <!-- PRECIO REBAJADO DE ATRIBUTO | SALON -->
          <span
            v-if="parseFloat(atributo.precio_rebajado_delivery) > 0"
            class="shop-normal-price"
            >$
            {{
              formatCurrency(parseFloat(atributo.precio_rebajado_delivery))
            }}</span
          >
        </f7-col>
        <!-- CANTIDAD DE ATRIBUTO | DELIVERY -->
        <f7-col
          width="35"
          class="shop-attributes-attribute-qty"
          v-if="atributo.habilitado_delivery === 1"
        >
          <f7-stepper
            v-if="atributo.sin_stock_delivery === 0"
            small
            round
            fill
            :value="atributo.cantidad"
            @change="changeQtyAttributes"
            :data-attribute-index="index"
          ></f7-stepper>

          <span
            v-else-if="atributo.sin_stock_delivery === 1"
            style="color: #a9a9a9"
            >Sin Stock</span
          >
        </f7-col>
      </f7-row>
    </span>
  </div>
</template>

<script>
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    changeQtyAttributes(value, stepper) {
      /**
       *
       */
      const self = this;
      self.props.loadingAddToCartButton = true;

      let currentFoodName =
        self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      let attributeIndex = stepper.el.dataset.attributeIndex;
      let lsFoodCart = JSON.parse(
        window.localStorage.getItem(self.$root.currentFoodCartState)
      );
      let foodAttributesType;

      if (self.$root.listFoodType === "Salon") {
        foodAttributesType = lsFoodCart[currentFoodName].atributos_del_plato;
      } else if (self.$root.listFoodType === "Delivery") {
        foodAttributesType =
          lsFoodCart[currentFoodName].atributos_del_plato_delivery;
      }

      foodAttributesType[attributeIndex].cantidad = stepper.value;

      window.localStorage.setItem(
        self.$root.currentFoodCartState,
        JSON.stringify(lsFoodCart)
      );

      setTimeout(() => {
        utils.checkTotals(self);
        self.props.loadingAddToCartButton = false;
      }, 500);
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
