<template>
  <f7-block class="local-carta-plato-block">
    <f7-row>
      <!-- NOMBRE, DETALLE Y PRECIOS -->
      <f7-col width="75">
        <!-- NOMBRE DE PLATO -->
        <span class="local-carta-nombre-plato">{{ subitem.nombre_plato }}</span>
        <br />
        <i
          v-if="$root.listFoodType === 'Salon' && subitem.descripcion"
          class="plato-descripcion"
        >
          <div
            class="descripcion-plato"
            :data-name="subitem.name"
            :style="subitem.descripcion.length > 200 ? 'height: 100px;' : ''"
          >
            {{ subitem.descripcion }}
          </div>
        </i>
        <div
          v-if="
            $root.listFoodType === 'Salon' &&
            subitem.descripcion !== null &&
            subitem.descripcion.length > 200
          "
          class="
            toggle-descripcion-expand
            padding-top-half
            text-align-right
            more-description-button-container
          "
          :data-expand="subitem.name"
          @click="
            (e) => {
              expandDescription(e, subitem.name);
            }
          "
        >
          <i class="fas fa-chevron-down badge"></i>
        </div>
        <div
          v-if="
            $root.listFoodType === 'Salon' &&
            subitem.descripcion !== null &&
            subitem.descripcion.length > 200
          "
          class="
            toggle-descripcion-collapse
            padding-top-half
            text-align-right
            more-description-button-container
          "
          :data-collapse="subitem.name"
          style="display: none"
          @click="
            (e) => {
              collapseDescription(e, subitem.name);
            }
          "
        >
          <i class="fas fa-chevron-up badge"></i>
        </div>
        <i
          v-if="
            $root.listFoodType === 'Delivery' && subitem.descripcion_delivery
          "
          class="plato-descripcion"
        >
          <div
            class="descripcion-plato"
            :data-name="subitem.name"
            :style="
              subitem.descripcion_delivery.length > 200 ? 'height: 100px;' : ''
            "
          >
            {{ subitem.descripcion_delivery }}
          </div>
        </i>
        <div
          v-if="
            $root.listFoodType === 'Delivery' &&
            subitem.descripcion_delivery !== null &&
            subitem.descripcion_delivery.length > 200
          "
          class="
            toggle-descripcion-expand
            padding-top-half
            text-align-right
            more-description-button-container
          "
          :data-expand="subitem.name"
          @click="
            (e) => {
              expandDescription(e, subitem.name);
            }
          "
        >
          <i class="fas fa-chevron-down badge"></i>
        </div>
        <div
          v-if="
            subitem.descripcion_delivery !== null &&
            subitem.descripcion_delivery.length > 200
          "
          class="
            toggle-descripcion-collapse
            padding-top-half
            text-align-right
            more-description-button-container
          "
          :data-collapse="subitem.name"
          style="display: none"
          @click="
            (e) => {
              collapseDescription(e, subitem.name);
            }
          "
        >
          <i class="fas fa-chevron-up badge"></i>
        </div>
        <!-- PRECIO DE PLATO | CARTA SALON -->
        <menuListPricePrimary
          v-if="
            $root.docLocal.mostrar_precios &&
            subitem.precio > 0 &&
            $root.listFoodType === 'Salon'
          "
          :props="props"
          :subitem="subitem"
        />
        <!-- PRECIO DE PLATO | CARTA DELIVERY -->
        <menuListPriceSecondary
          v-else-if="
            $root.docLocal.mostrar_precios &&
            subitem.precio_delivery > 0 &&
            $root.listFoodType === 'Delivery'
          "
          :props="props"
          :subitem="subitem"
        />

        <!-- SEPARADOR PRECIO UNICO | ATRIBUTOS | CARTA SALON -->
        <span
          v-if="
            $root.listFoodType === 'Salon' &&
            subitem.precio > 0 &&
            subitem.atributos_del_plato.length > 0
          "
          style="color: #777777"
        >
          <i>|</i>
        </span>
        <!-- SEPARADOR PRECIO UNICO | ATRIBUTOS | CARTA DELIVERY -->
        <span
          v-if="
            $root.listFoodType === 'Delivery' &&
            subitem.precio_delivery > 0 &&
            subitem.atributos_del_plato_delivery.length > 0
          "
          style="color: #777777"
        >
          <i>|</i>
        </span>

        <!-- ATRIBUTOS SALON-->
        <menuAttributesPrimary
          v-for="(atributo, indexLevelAtributo) in subitem.atributos_del_plato"
          :key="atributo.name"
          :props="props"
          :subitem="subitem"
          :atributo="atributo"
          :indexLevelAtributo="indexLevelAtributo"
        />

        <!-- ATRIBUTOS DELIVERY-->
        <menuAttributesSecondary
          v-for="(
            atributo, indexLevelAtributoDelivery
          ) in subitem.atributos_del_plato_delivery"
          :key="atributo.name"
          :props="props"
          :subitem="subitem"
          :atributo="atributo"
          :indexLevelAtributoDelivery="indexLevelAtributoDelivery"
        />

        <!-- BOTON DE COMPRA -->
        <span
          style="margin-top: 5px; display: block"
          :style="
            subitem.descripcion !== null && subitem.descripcion.length > 60
              ? 'margin-top: 0 !important'
              : ''
          "
          v-if="$root.listFoodStore && subitem.sin_stock === 0"
        >
          <span class="badge badge-shop-from-local" @click="shopFood(subitem)">
            Pedir&nbsp;&nbsp;<i class="fas fa-plus" aria-hidden="true"></i>
          </span>
        </span>

        <!-- BOTON DE COMPRA | SIN STOCK -->
        <span
          style="margin-top: 5px; display: block"
          :style="
            subitem.descripcion !== null && subitem.descripcion.length > 60
              ? 'margin-top: 0 !important'
              : ''
          "
          v-if="$root.listFoodStore && subitem.sin_stock === 1"
        >
          <span class="badge badge-shop-from-local badge-disabled">
            Sin Stock
          </span>
        </span>

        <!-- BOTON DE COMPRA | SALON -->
        <!-- <span
          style="margin-top: 5px; display: block;"
          :style="
            subitem.descripcion !== null && subitem.descripcion.length > 60
              ? 'margin-top: 0 !important'
              : ''
          "
          v-if="
            $root.listFoodType === 'Salon' &&
              $root.listFoodStore &&
              subitem.sin_stock === 0
          "
        >
          <span class="badge badge-shop-from-local" @click="shopFood(subitem)">
            Pedir&nbsp;&nbsp;<i class="fas fa-plus" aria-hidden="true"></i>
          </span>
        </span> -->

        <!-- BOTON DE COMPRA | SALON | SIN STOCK -->
        <!-- <span
          style="margin-top: 5px; display: block;"
          :style="
            subitem.descripcion !== null && subitem.descripcion.length > 60
              ? 'margin-top: 0 !important'
              : ''
          "
          v-if="$root.listFoodType === 'Salon' && subitem.sin_stock === 1"
        >
          <span class="badge badge-shop-from-local badge-disabled">
            Sin Stock
          </span>
        </span> -->

        <!-- BOTON DE COMPRA | DELIVERY -->
        <!-- <span
          style="margin-top: 5px; display: block;"
          :style="
            subitem.descripcion_delivery !== null &&
            subitem.descripcion_delivery.length > 60
              ? 'margin-top: 0px !important'
              : ''
          "
          v-if="
            $root.listFoodType === 'Delivery' &&
              $root.listFoodStore &&
              subitem.sin_stock === 0
          "
        >
          <span class="badge badge-shop-from-local" @click="shopFood(subitem)">
            Pedir&nbsp;&nbsp;<i class="fas fa-plus" aria-hidden="true"></i>
          </span>
        </span> -->

        <!-- BOTON DE COMPRA | DELIVERY | SIN STOCK -->
        <!-- <span
          style="margin-top: 5px; display: block;"
          :style="
            subitem.descripcion !== null && subitem.descripcion.length > 60
              ? 'margin-top: 0 !important'
              : ''
          "
          v-if="$root.listFoodType === 'Delivery' && subitem.sin_stock === 1"
        >
          <span class="badge badge-shop-from-local badge-disabled">
            Sin Stock
          </span>
        </span> -->
      </f7-col>

      <!-- IMAGEN DEL PLATO -->
      <f7-col width="25">
        <div
          class="container-image-responsive-plato-thumb"
          v-if="subitem.imagen_principal"
          @click="
            openFoodImage(
              subitem.nombre_plato,
              subitem.descripcion,
              subitem.imagen_principal
            )
          "
          :style="{ 'padding-top': '100%' }"
        >
          <img
            class="image-responsive-plato-thumb lazy lazy-fade-in"
            :data-src="
              '' +
              'https://' +
              $root.serverHostBase +
              encodeURI(subitem.imagen_principal)
            "
            alt=""
            width="100%"
          />
        </div>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import local from "../../../local.js";
import menuListPricePrimary from "./menu-list-price-primary.vue";
import menuListPriceSecondary from "./menu-list-price-secondary.vue";
import menuAttributesPrimary from "./menu-list-attributes-primary";
import menuAttributesSecondary from "./menu-list-attributes-secondary";

export default {
  components: {
    menuListPricePrimary,
    menuListPriceSecondary,
    menuAttributesPrimary,
    menuAttributesSecondary,
  },
  props: {
    props: Object,
    subitem: Object,
  },
  methods: {
    shopFood(subitem) {
      const self = this;
      self.$root.textButtonShopAttributes = "AGREGAR AL PEDIDO";
      local.shopFood(this, subitem);
    },
    openFoodImage(name_food, food_description, image_url) {
      local.openFoodImage(this, name_food, food_description, image_url);
    },
    collapseDescription(e, subitemName) {
      local.collapseDescription(this, e, subitemName);
    },
    expandDescription(e, subitemName) {
      local.expandDescription(this, e, subitemName);
    },
  },
};
</script>
