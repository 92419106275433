<template>
  <f7-row
    v-if="$root.confirmedCart.length > 0 && Object.keys($root.cuponesLocal).length > 0"
  >
    <f7-col>
      <div
        class="item-title item-label shop-cart-title-section"
        style="color: #222222"
      >
        CUPON DE DESCUENTO
      </div>

      <f7-list style="margin: 0">
        <f7-list-item>
          <f7-row style="align-items: center;">
            <f7-col width="70">
              <f7-input
                class="cart-metodos-subopciones"
                type="text"
                clear-button
                validate
                placeholder="Ingrese su cupón aquí"
                :value="$root.cuponDescuento"
                @focus="inputOnFocus"
                @input="
                  (e) => {
                    $root.cuponDescuento = e.target.value;
                  }
                "
              >
              </f7-input>
            </f7-col>
            <f7-col width="30">
              <span
                class="badge badge-shipping-map"
                style="width: 100%; padding: 15px;"
                @click="
                  () => {
                    validateCoupon();
                  }
                "
                fill
                >Canjear</span>
            </f7-col>
          </f7-row>
        </f7-list-item>
        <!-- <f7-list-item>
          <span
            class="badge badge-shipping-map"
            style="width: 100%"
            @click="
              () => {
                validateCoupon();
              }
            "
            fill
            >Validar Cupón&nbsp;&nbsp;<i class="fas fa-check"></i
          ></span>
        </f7-list-item> -->
      </f7-list>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    inputOnFocus() {
      cart.inputOnFocus(this);
    },
    validateCoupon() {
      cart.validateCoupon(this);
    },
  },
};
</script>
