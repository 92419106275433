var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"local-carta-atributos-plato"},[(_vm.$root.listFoodType === 'Salon' && _vm.atributo.habilitado === 1 && _vm.atributo.sin_stock === 0)?_c('span',{staticClass:"badge local-carta-atributos-plato-badge"},[_c('span',{staticStyle:{"color":"#222222"}},[_vm._v(_vm._s(_vm.atributo.nombre_atributo))]),_vm._v(" \n    "),_vm._v(" "),(
        _vm.$root.docLocal.mostrar_precios &&
          _vm.$root.listFoodType === 'Salon' &&
          _vm.atributo.precio > 0
      )?_c('span',{class:_vm.atributo.precio_rebajado > 0 ? 'precio-normal-rebajado' : undefined},[_vm._v("\n      $\n      "+_vm._s(_vm.formatCurrency(parseFloat(_vm.atributo.precio)) || "")+"\n    ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"space-before-precio"},[_vm._v(" ")]),_vm._v(" "),(
        _vm.$root.docLocal.mostrar_precios &&
          _vm.$root.listFoodType === 'Salon' &&
          _vm.atributo.precio_rebajado > 0
      )?_c('span',{staticClass:"precio-rebajado"},[_vm._v("\n      $\n      "+_vm._s(_vm.formatCurrency(parseFloat(_vm.atributo.precio_rebajado)) || "")+" \n    ")]):_vm._e(),_vm._v(" "),(
        _vm.$root.listFoodType === 'Salon' &&
          _vm.indexLevelAtributo < _vm.subitem.atributos_del_plato.length - 1
      )?_c('i',{staticStyle:{"color":"#777777"}},[_vm._v("\n      | \n    ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }