<template>
  <f7-row class="local-cover">
    <f7-col>
      <!-- SLIDER -->
      <f7-swiper
        v-if="
          $root.docLocal &&
            $root.docLocal.imagenes_de_slider &&
            $root.docLocal.imagenes_de_slider.length > 0
        "
        class="local-cover-swiper"
        id="locals-swiper"
        :params="{
          // init: false,
          // effect: 'slide',
          // loop: props.loopSlides,
          // autoplay: props.autoplaySlides,
          // speed: 2000,
        }"
      >
        <!-- SLIDES DE LOCAL -->
        <f7-swiper-slide
          v-for="(slider, index) in $root.docLocal.imagenes_de_slider"
          :key="index"
          class="local-cover-swiper-slide"
        >
          <f7-row
            class="container-image-responsive"
            :style="{ 'padding-top': '56.25%' }"
          >
            <div
              class="image-responsive"
              v-bind:style="{
                'background-image':
                  'url(\'' +
                  'https://' +
                  $root.serverHostBase +
                  '/' +
                  encodeURI(slider.imagen_recortada) +
                  '\')',
              }"
            ></div>
          </f7-row>
        </f7-swiper-slide>
      </f7-swiper>

      <!-- SLIDES PLACEHOLDER -->
      <div
        v-else
        class="local-cover-placeholder-image"
        v-bind:style="{
          'background-image': 'url(/static/images/Alacarta_Logo.png)',
        }"
      ></div>

      <f7-row class="local-cover-badges">
        <!-- MONEDA -->
        <span v-if="$root.docLocal.mostrar_moneda">
          <span
            class="badge badge-carta notranslate"
          >
            <i class="fas fa-dollar-sign" aria-hidden="true"></i>
            &nbsp; {{$root.docLocal.moneda}}
          </span>
        </span>
        <!-- CABECERA TIPO DE CARTA -->
        <span>
          <span
            v-if="$root.listFoodType === ''"
            class="badge"
            @click="props.SelectionMenuSheetOpened = true"
          >
            Elija una Carta
          </span>
          <span
            v-if="
              $root.listFoodType !== '' && $root.listFoodName !== ''
            "
            class="badge badge-carta"
            @click="openSelectionCartaSheet"
          >
            <i class="fas fa-utensils" aria-hidden="true"></i>
            &nbsp; {{ $root.listFoodName }}
          </span>
        </span>

        <!-- CABECERA ABIERTO / CERRADO -->
        <span v-if="$root.docLocal.mostrar_horarios">
          <span
            v-if="$root.estadoHorarioLocal === 'Abierto'"
            class="badge badge-abierto"
            @click="props.openingHoursSheetOpened = true"
          >
            <i class="fas fa-clock" aria-hidden="true"></i>
            &nbsp; Abierto
          </span>
          <span
            v-else-if="$root.estadoHorarioLocal === 'Cerrado'"
            class="badge badge-cerrado"
            @click="props.openingHoursSheetOpened = true"
          >
            <i class="fas fa-clock" aria-hidden="true"></i>
            &nbsp; Cerrado
          </span>
          <span
            v-else
            class="badge badge-carta"
            @click="props.openingHoursSheetOpened = true"
          >
            <i class="fas fa-clock" aria-hidden="true"></i>
            &nbsp; Horarios
          </span>
        </span>
      </f7-row>
    </f7-col>
  </f7-row>
</template>

<script>
import local from "../../local.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    openSelectionCartaSheet() {
      local.openSelectionCartaSheet(this);
    },
  },
};
</script>
