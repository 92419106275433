<template>
  <f7-row
    v-if="
      $root.confirmedCart.length > 0 && $root.customFieldOtrosDatos
    "
  >
    <f7-col>
      <div
        class="item-title item-label shop-cart-title-section"
        style="color: #222222"
      >
        OTROS DATOS
      </div>

      <f7-list
        class="no-margin no-padding"
        v-for="(campo, index) in $root.listCustomFields"
        :key="campo.name"
      >
        <f7-list-input
          v-if="campo.asociado_a_metodo === 'Otros Datos'"
          class="cart-metodos-subopciones"
          :label="campo.nombre_campo"
          :type="$root.customFieldsTypes[campo.tipo_de_dato]"
          :info="campo.leyenda"
          @focus="inputOnFocus"
          :name="campo.name"
          :required="campo.obligatorio === 1 ? 'required' : ''"
          validate
          @change="
            (e) => {
              $root.listCustomFields[index].value = e.target.value;
            }
          "
        >
        </f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    inputOnFocus() {
      cart.inputOnFocus(this);
    },
  },
};
</script>
