<template>
  <f7-block
    class="total-cart-block"
    style="position: relative"
    v-if="
      $root.showShippingMapTotals === true &&
      props.mySavedLocationsLs &&
      props.mySavedLocationsLs.length > 0
    "
  >
    <!-- DIRECCION SELECCIONADA -->
    <!-- <f7-row class="totals-cart-row">
      <f7-col>
        <span style="font-weight: 400;">{{ $root.shippingMapAddress }}</span>
      </f7-col>
    </f7-row> -->
    <!-- COSTO DE ENVIO -->
    <f7-row class="totals-cart-row">
      <f7-col width="50">
        <span style="font-weight: 600">Costo de Envío</span>
      </f7-col>
      <f7-col
        width="50"
        style="text-align: right; font-weight: 600; font-size: 16px"
      >
        <span>$ {{ formatCurrency(parseFloat($root.shippingMapTotals)) }}</span>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <span style="margin-top: 5px; display: block">
          <span
            :class="'badge badge-hacer-pedido-white margin-top-half'"
            @click="confirmShippingMap"
          >
            <i class="fas fa-check" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;CONFIRMAR UBICACIÓN
          </span>
        </span>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import cart from "../../cart.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    confirmShippingMap() {
      cart.confirmShippingMap(this);
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
