<template>
  <f7-page
    name="home-page"
    class="home-page"
    @page:afterin="configSideMenu"
    @page:beforein="homeBeforeIn"
  >
    <fullscreenLoader :props="props" />

    <!-- BLOQUE PRINCIPAL | HOME -->
    <f7-block class="home-main-block">
      <!-- BANNER CIUDAD | HOME -->
      <div
        v-if="$root.icono_ciudad"
        class="home-banner-ciudad"
        :style="{
          'background-image':
            'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(\'https://' +
            $root.serverHostBase +
            $root.icono_ciudad +
            '\')',
        }"
      >
        <!-- BOTONES DEL BANNER -->
        <f7-row>
          <f7-col class="home-banner-ciudad-buttons">
            <!-- COMPARTIR -->
            <f7-link @click="nativeShare">
              <i
                data-eva="share-outline"
                class="home-banner-ciudad-icon"
                data-eva-fill="#ffffff"
              ></i>
            </f7-link>
            <!-- A2HS -->
            <f7-link v-show="a2hsReady" @click="installApp">
              <i
                class="a2hs-btn home-banner-ciudad-icon"
                data-eva="download-outline"
                data-eva-fill="#ffffff"
              ></i>
            </f7-link>
            <!-- MAP -->
            <f7-link @click="navigate('/map-locals/')">
              <i
                class="a2hs-btn home-banner-ciudad-icon"
                data-eva="pin-outline"
                data-eva-fill="#ffffff"
              ></i>
            </f7-link>
          </f7-col>
        </f7-row>

        <!-- TITULO DE CIUDAD -->
        <f7-row v-if="this.$root.ciudad">
          <f7-col class="home-banner-ciudad-title-container">
            <span class="home-banner-ciudad-title">
              <!-- {{ this.$root.ciudad }} -->
            </span>
          </f7-col>
        </f7-row>
      </div>
    </f7-block>

    <!-- BARRA DE BUSQUEDA -->
    <!-- <f7-block class="home-search-bar-block">
      <f7-row> -->
        <!-- INPUT -->
        <!-- <f7-col width="85">
          <f7-searchbar
            class="searchbar-locals"
            search-container=".search-list"
            :disable-button="!$theme.aurora"
            placeholder="Realiza una búsqueda"
            :clear-button="true"
          >
          </f7-searchbar>
        </f7-col> -->
        <!-- MENU -->
        <!-- <f7-col width="15" class="home-column-map">
          <f7-link panel-open="left">
            <i
              panel-open="left"
              data-eva="menu-outline"
              data-eva-fill="#444444"
              data-eva-height="32px"
              data-eva-width="32px"
            >
            </i>
          </f7-link>
        </f7-col> -->
      <!-- </f7-row>
    </f7-block> -->

    <!-- BARRA DE BUSQUEDA | SIN RESULTADOS -->
    <f7-block class="searchbar-not-found searchbar-not-found-block">
      <!-- IMAGEN -->
      <f7-row>
        <f7-col
          class="searchbar-not-found-image"
          :style="{
            'background-image': 'url(/static/images/Alacarta_Logo.png)',
          }"
        >
        </f7-col>
      </f7-row>
      <!-- TEXTO -->
      <f7-row>
        <f7-col class="searchbar-not-found-text">
          No hay resultados<br>para mostrar
        </f7-col>
      </f7-row>
    </f7-block>

    <!-- LISTA LOADER (SKELETON) -->
    <f7-list media-list v-if="props.loading" class="skeleton-list">
      <f7-list-item
        v-for="n in 6"
        :key="n"
        :class="`skeleton-text skeleton-effect-${effect} skeleton-item-custom`"
        title="XXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
        subtitle="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
        text="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      >
        <f7-skeleton-block
          class="skeleton-block-style"
          slot="media"
        ></f7-skeleton-block>
      </f7-list-item>
    </f7-list>

    <!-- LISTA VIRTUAL DE LOCALES -->
    <f7-list
      no-hairlines
      no-hairlines-between
      media-list
      class="virtual-list-locals search-list searchbar-found no-margin"
      virtual-list
      :virtual-list-params="{
        items,
        searchAll,
        renderExternal,
        setListHeight: true,
        height: '85',
      }"
    >
      <!-- LISTA CONTENEDORA DE LOCALES -->
      <ul class="home-lista-locales-ul">
        <!-- ITEM DE LOCAL -->
        <f7-list-item
          media-item
          v-for="(item, index) in vlData.items"
          :key="index"
          :data-docname="item.name"
          class="home-locales-list"
          @click="navigateToLocal(item.name)"
        >
          <!-- IMAGEN DE LOCAL -->
          <div
            slot="media"
            class="lazy lazy-fade-in demo-lazy home-local-imagen"
            v-if="item.vista_previa_logo_pagina_principal"
            :style="{
              'background-image':
                'url(\'' + item.vista_previa_logo_pagina_principal + '\')',
            }"
          ></div>

          <!-- IMAGEN PLACEHOLDER DE LOCAL -->
          <div
            slot="media"
            class="lazy lazy-fade-in demo-lazy home-local-imagen"
            v-else
            :style="{
              'background-image': 'url(/static/images/Alacarta_Logo.png)',
            }"
          ></div>

          <!-- NOMBRE DE LOCAL -->
          <div slot="title" class="home-local-nombre">
            <span>{{ item.nombre_local }}</span>
          </div>

          <!-- CATEGORIAS DE LOCAL -->
          <div slot="footer" class="home-footer-card">
            <span
              v-for="(categoria, index) in item.categorias"
              :key="index"
              class="badge badge-local"
            >
              {{ categoria.categoria_local }}
            </span>
          </div>
        </f7-list-item>
      </ul>
    </f7-list>

    <cityShareSheet :props="props" />
    <cityBannerSheet :props="props" />
  </f7-page>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import home from "../home.js";
import utils from "../utils.js";
import cityBannerSheet from "../components/city/city-banner-sheet.vue";
import cityShareSheet from "../components/city/city-share-sheet.vue";
import fullscreenLoader from "../components/fullscreen-loader.vue";

export default {
  components: {
    cityBannerSheet,
    cityShareSheet,
    fullscreenLoader,
  },
  data: function () {
    return {
      effect: "fade",
      data: [],
      items: [],
      vlData: {
        items: [],
      },
      props: {
        BannerSheetCityOpened: false,
        shareSheetOpened: false,
        currentBannerImage: undefined,
        loading: true,
      },
      deferredPrompt: undefined,
      a2hsReady: false,
      emptyQuery: true,
      docCiudad: undefined,
      // currentBannerLink: undefined,
      // ciudad: this.$root.ciudad,
      // local: this.$root.local,
      // online: this.$f7.params.online,
    };
  },
  mounted: function () {
    const self = this;
    self.props.loading = true;
    self.getAppTypes();
    self.getConfigGeneral();
    eva.replace();

    // home.loadLocals(self);
    // home.loadCity(self);

    // Manejo del botón de ATRAS en Android (Cierra lo que este abierto)
    (function () {
      if (window.history && window.history.pushState) {
        self.$$(window).on("popstate", function (e) {
          self.$f7.sheet.close();
          self.$f7.panel.close();
          self.$f7.dialog.close();
        });
      }
    })();

    // Limpia la barra de búsqueda.
    self.$$(".input-clear-button").on("click", function () {
      self.emptyQuery = true;
    });

    // Comprueba si la barra de búsqueda tiene una query vacia
    self.$$("input[type='search']").on("keyup", function (e) {
      let sbValue = self.$$("input[type='search']").val();

      if (sbValue === undefined || sbValue === null || sbValue === "") {
        self.emptyQuery = true;
      } else {
        self.emptyQuery = false;
      }
    });

    // A2HS
    window.addEventListener("beforeinstallprompt", (e) => {
      self.a2hsReady = true;
      // Prevent the mini-infobar from appearing on mobile
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      self.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (showInstallPromotion) {
        showInstallPromotion();
      }
    });
  },
  updated: function () {
    const self = this;
    eva.replace();
  },
  methods: {
    homeBeforeIn() {
      const self = this;
      self.$root.showMenu = true;
      // self.props.loading = true;
      // self.getAppTypes();
      // home.loadLocals(self);
    },
    // getMenuData() {
    //   const self = this;

    //   var getMenuDataCallback = function(e) {
    //     self.$root.menuData = e.message.data;
    //   };

    //   // if (JSON.parse(self.$root.ls.getItem('online')) === false) {
    //   //   refresh(self);
    //   // } else {
    //   utils.customRequest(
    //     self,
    //     getMenuDataCallback,
    //     "utils.doctype.get_menu_data",
    //     "POST",
    //     {
    //       ciudad: self.$root.ciudad,
    //     },
    //     ""
    //   );
    //   // }
    // },
    navigate: function (url) {
      const self = this;
      self.$f7router.navigate(url, {
        // reloadCurrent: true
      });
    },
    // refresh(event) {
    //   const self = this;
    //   home.refresh(self, event);
    // },
    renderExternal(vl, vlData) {
      const self = this;
      home.renderExternal(self, vl, vlData);
    },
    searchAll(query, items) {
      const self = this;
      const found = home.searchAll(self, query, items);
      return found;
    },
    formatDate(raw_date) {
      return moment(raw_date).format("D [de] MMMM [de] YYYY");
    },
    nativeShare() {
      const self = this;
      home.nativeShare(self);
    },
    installApp(e) {
      const self = this;
      home.installApp(self, e);
    },
    getAppTypes(parsedUrl) {
      const self = this;
      home.getAppTypes(parsedUrl, self);
    },
    getConfigGeneral() {
      const self = this;
      home.getConfigGeneral(self);
    },
    configSideMenu() {
      const self = this;
      self.$root.local = undefined;
    },
    navigateToLocal(localName) {
      const self = this;
      self.$root.loading = true;
      self.$root.localFromMapSwiper = false;
      self.$f7router.navigate("/local/" + localName + "/", {
        ignoreCache: false,
        reloadCurrent: true,
      });
    },
    // adjustBannerHeight() {
    //   const self = this;
    //   // self.$$("#banner-image-sheet").css("height", "200px");
    // },
    // redirectToBannerLink() {
    //   const self = this;
    //   if (
    //     self.currentBannerLink !== undefined &&
    //     self.currentBannerLink !== ""
    //   ) {
    //     window.location.replace(encodeURI(self.currentBannerLink));
    //   }
    // },
  },
};
</script>

<!-- TOOLBAR -->
<!-- <f7-block
      style="
        margin: 20px 15px 10px;
        padding-bottom: 10px;
        height: 150px;
        border: 1px solid transparent;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        "
      :style="{
        'background-image': 'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(\'' + $root.serverHostBase + this.$root.icono_ciudad + '\')',
        'background-position': 'center center',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      }"
    > -->
