<template id="local-share-sheet">
  <f7-sheet
    v-if="$root.docLocal"
    class="share-sheet local-share-sheet"
    backdrop
    bottom
    :swipeToClose="true"
    :closeByBackdropClick="true"
    :closeByOutsideClick="true"
    :opened="props.shareSheetOpened"
    @sheet:closed="props.shareSheetOpened = false"
  >
    <f7-toolbar>
      <span class="notch"></span>
      <f7-link
        class="close-sheet-btn"
        @click="props.shareSheetOpened = false"
        icon-md="material:close"
      ></f7-link>
    </f7-toolbar>

    <f7-page-content>
      <f7-block>
        <f7-row>
          <f7-col width="50" style="text-align: center">
            <f7-link
              class="external"
              :href="
                'https://api.whatsapp.com/send/?text=Esta%20carta%20es%20para%20ti!%20' +
                  'https://' +
                  $root.docLocal.subdominio +
                  '.' +
                  $root.domainHostName
              "
              style="display: block"
            >
              <i class="fab fa-whatsapp share-sheet-icons"></i>
              <br /><br />
              <span>WhatsApp!</span>
            </f7-link>
          </f7-col>

          <f7-col width="50" style="text-align: center">
            <f7-link
              class="external"
              :href="
                'https://www.facebook.com/sharer/sharer.php?u=' +
                  'https://' +
                  $root.docLocal.subdominio +
                  '.' +
                  $root.domainHostName
              "
              style="display: block"
            >
              <i class="fab fa-facebook-f share-sheet-icons"></i>
              <br /><br />
              <span>Facebook</span>
            </f7-link>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function() {
    const self = this;
  },
  methods: {},
};
</script>
