<template id="shop-attributes">
  <f7-page name="shop-attributes">
    <fullscreenLoader :props="props" />

    <f7-block
      class="shop-attributes-main-block"
      v-if="!props.loading && $root.docCurrentFood"
    >
      <!-- COVER DEL PLATO -->
      <shopAttributesCover :props="props" />

      <!-- CABECERA DEL PLATO -->
      <div class="shop-attributes-content">
        <shopAttributesHeader />

        <!-- PRECIO GLOBAL -->
        <shopAttributesMainPrice />

        <!-- SECCION | CANTIDAD -->
        <shopAttributesMainQuantity :props="props" />

        <!-- SECCION | VARIANTES -->
        <shopAttributesAttributes :props="props" />

        <!-- ADICIONALES -->
        <shopAttributesAdditionals
          :props="props"
          v-for="(item, indexLevel1) in props.listAditionalsSelected"
          :item="item"
          :indexLevel1="indexLevel1"
          :key="indexLevel1"
          :id="'accordionIx' + indexLevel1"
        />

        <!-- NOTAS ADICIONALES -->
        <shopAttributesNotes :props="props" />

        <!-- TOTALES | AGREGAR AL PEDIDO -->
        <shopAttributesTotals :props="props" />
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import fullscreenLoader from "../components/fullscreen-loader.vue";
import shopAttributesCover from "../components/shop-attributes/shop-attributes-cover.vue";
import shopAttributesHeader from "../components/shop-attributes/shop-attributes-header.vue";
import shopAttributesMainPrice from "../components/shop-attributes/shop-attributes-main-price.vue";
import shopAttributesMainQuantity from "../components/shop-attributes/shop-attributes-main-quantity.vue";
import shopAttributesAttributes from "../components/shop-attributes/shop-attributes-attributes.vue";
import shopAttributesAdditionals from "../components/shop-attributes/shop-attributes-additionals.vue";
import shopAttributesNotes from "../components/shop-attributes/shop-attributes-notes.vue";
import shopAttributesTotals from "../components/shop-attributes/shop-attributes-totals.vue";
import utils from "../utils.js";

export default {
  components: {
    fullscreenLoader,
    shopAttributesCover,
    shopAttributesHeader,
    shopAttributesMainPrice,
    shopAttributesMainQuantity,
    shopAttributesAttributes,
    shopAttributesAdditionals,
    shopAttributesNotes,
    shopAttributesTotals,
  },
  data: function () {
    return {
      props: {
        loading: true,
        totalFoodQty: 0,
        totalAttributes: 0,
        totalAdicionales: 0,
        totalAdditionalsCompleted: false,
        listAditionalsSelected: [],
        notaDelPlato: "",
        disableAddToCartButton: true,
        loadingAddToCartButton: false
      },
    };
  },
  mounted: function () {
    const self = this;
    eva.replace();

    if (self.$root.docCurrentFood) {
      self.getAdicionales();
      // if (self.$root.textButtonShopAttributes === "MODIFICAR PEDIDO") {
      utils.checkTotals(self);
      // }
      // self.props.loading = false;
      self.props.notaDelPlato = self.$root.docCurrentFood.nota_del_plato;
    } else {
      window.location.replace("https://" + window.location.host);
    }
  },
  updated: function () {
    const self = this;
    eva.replace();
  },
  methods: {
    getAdicionales() {
      const self = this;
      let lsFoodCart = JSON.parse(
        window.localStorage.getItem(self.$root.currentFoodCartState)
      );
      let itemName =
        self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      let lsFoodCurrentFood = lsFoodCart[itemName];

      if (self.$root.currentFoodCartState === "draft_cart") {
        self.props.listAditionalsSelected = lsFoodCurrentFood.adicionales;
      } else {
        var getAdicionalesCallback = function (e) {
          let serverData = e.message.data;

          if (serverData) {
            lsFoodCurrentFood.adicionales = serverData.adicionales;
            window.localStorage.setItem(
              self.$root.currentFoodCartState,
              JSON.stringify(lsFoodCart)
            );

            self.props.listAditionalsSelected = e.message.data.adicionales;
          }
        };

        utils.customRequest(
          self,
          getAdicionalesCallback,
          "adicional.adicional.get_adicionales",
          "POST",
          {
            docname: self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name,
          },
          null
        );
      }
    },
  },
};
</script>
