import LandingPage from "../pages/landing.vue";
import HomePage from "../pages/home.vue";
import CityPage from "../pages/city.vue";
import MapPage from "../pages/map.vue";
import MapLocalsPage from "../pages/map-locals.vue";
import LocalPage from "../pages/local.vue";
import ShopAttributes from "../pages/shop-attributes.vue";
import Cart from "../pages/cart.vue";

import cityBannerSheet from "../components/city/city-banner-sheet.vue";
import cartShippingMapSheet from "../components/cart/cart-shipping-map-sheet.vue";

// import DynamicRoutePage from "../pages/dynamic-route.vue";
// import RequestAndLoad from "../pages/request-and-load.vue";
// import NotFoundPage from "../pages/404.vue";

var routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/landing/",
    component: LandingPage,
  },
  {
    path: "/city/",
    component: CityPage,
  },
  {
    path: "/map/",
    component: MapPage,
  },
  {
    path: "/map-locals/",
    component: MapLocalsPage,
  },
  {
    path: "/local/:localId/",
    component: LocalPage,
  },
  {
    path: "/shop-attributes/",
    component: ShopAttributes,
  },
  {
    path: "/cart-shipping-map/",
    sheetModal: {
      component: cartShippingMapSheet,
    },
  },
  {
    path: "/cart/",
    component: Cart,
  },
  {
    path: "/banner-city-sheet/",
    sheetModal: {
      component: cityBannerSheet,
    },
  },
  {
    path: "(.*)",
    // component: NotFoundPage,
    redirect: "/landing/",
  },

  // {
  //   path: "/dynamic-route/blog/:blogId/post/:postId/",
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: "/request-and-load/user/:userId/",
  //   async: function(routeTo, routeFrom, resolve, reject) {
  //     // Router instance
  //     var router = this;

  //     // App instance
  //     var app = router.app;

  //     // Show Preloader
  //     app.preloader.show();

  //     // User ID from request
  //     var userId = routeTo.params.userId;

  //     // Simulate Ajax Request
  //     setTimeout(function() {
  //       // We got user data from request
  //       var user = {
  //         firstName: "Vladimir",
  //         lastName: "Kharlampidi",
  //         about: "Hello, i am creator of Framework7! Hope you like it!",
  //         links: [
  //           {
  //             title: "Framework7 Website",
  //             url: "http://framework7.io",
  //           },
  //           {
  //             title: "Framework7 Forum",
  //             url: "http://forum.framework7.io",
  //           },
  //         ],
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();

  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           context: {
  //             user: user,
  //           },
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
];

export default routes;
