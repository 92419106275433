var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$root.docLocal)?_c('f7-sheet',{staticClass:"share-sheet local-share-sheet",attrs:{"backdrop":"","bottom":"","swipeToClose":true,"closeByBackdropClick":true,"closeByOutsideClick":true,"opened":_vm.props.shareSheetOpened},on:{"sheet:closed":function($event){_vm.props.shareSheetOpened = false}}},[_c('f7-toolbar',[_c('span',{staticClass:"notch"}),_vm._v(" "),_c('f7-link',{staticClass:"close-sheet-btn",attrs:{"icon-md":"material:close"},on:{"click":function($event){_vm.props.shareSheetOpened = false}}})],1),_vm._v(" "),_c('f7-page-content',[_c('f7-block',[_c('f7-row',[_c('f7-col',{staticStyle:{"text-align":"center"},attrs:{"width":"50"}},[_c('f7-link',{staticClass:"external",staticStyle:{"display":"block"},attrs:{"href":'https://api.whatsapp.com/send/?text=Esta%20carta%20es%20para%20ti!%20' +
                'https://' +
                _vm.$root.docLocal.subdominio +
                '.' +
                _vm.$root.domainHostName}},[_c('i',{staticClass:"fab fa-whatsapp share-sheet-icons"}),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "),_c('span',[_vm._v("WhatsApp!")])])],1),_vm._v(" "),_c('f7-col',{staticStyle:{"text-align":"center"},attrs:{"width":"50"}},[_c('f7-link',{staticClass:"external",staticStyle:{"display":"block"},attrs:{"href":'https://www.facebook.com/sharer/sharer.php?u=' +
                'https://' +
                _vm.$root.docLocal.subdominio +
                '.' +
                _vm.$root.domainHostName}},[_c('i',{staticClass:"fab fa-facebook-f share-sheet-icons"}),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "),_c('span',[_vm._v("Facebook")])])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }