<template>
  <f7-block class="no-margin no-padding">
    <f7-row id="local-navbar" class="local-navbar-normal">
      <!-- MARGEN 5% -->
      <f7-col width="5"></f7-col>
      <!-- LOGO -->
      <f7-col width="35" class="local-header-logo">
        <div
          class="logo-local-normal"
          id="logo-pagina-principal"
          v-if="$root.docLocal"
          v-bind:style="{
            'background-image':
              'url(&quot;' +
              encodeURI($root.docLocal.vista_previa_logo_pagina_principal) +
              '&quot;)',
          }"
        ></div>
      </f7-col>
      <!-- TEXTO -->
      <f7-col
        id="local-navbar-text"
        class="local-navbar-text-normal"
        v-if="$root.docLocal"
        width="60"
      >
        <span class="notranslate">{{ $root.docLocal.nombre_local }}</span>
        <span class="slogan notranslate">{{ $root.docLocal.slogan }}</span>
      </f7-col>
      <!-- MARGEN 10% -->
      <!-- <f7-col width="10"> </f7-col> -->
    </f7-row>

    <!-- SEPARADOR HORIZONTAL -->
    <f7-row>
      <f7-col class="local-cabecera-iconos" width="80"> </f7-col>
    </f7-row>

    <!-- <f7-row>
      <video
      src="/static/videos/pedido-aprobado-check.mp4"
      autoplay muted playsinline
      style="width: 80%; margin: 0 auto"
    ></video>
    </f7-row> -->

    <!-- CABECERA DE LOCAL | ICONOS -->
    <f7-row class="margin-top-half margin-bottom">
      <f7-col></f7-col>
      <f7-col
        v-if="$root.docLocal.whatsapp"
        class="local-cabecera-iconos-col"
        @click="
          navigateToExternalLink(
            `https://api.whatsapp.com/send?phone=${$root.docLocal.whatsapp.replace(
              '+',
              ''
            )}`
          )
        "
      >
        <i class="fab fa-whatsapp local-cabecera-icono"></i>
        <!-- <span class="local-cabecera-iconos-texto">WhatsApp</span> -->
      </f7-col>
      <f7-col
        v-if="$root.docLocal.telefono"
        class="local-cabecera-iconos-col"
        @click="navigateToExternalLink(`tel:${$root.docLocal.telefono}`)"
      >
        <i class="fas fa-phone local-cabecera-icono"></i>
        <!-- <span class="local-cabecera-iconos-texto">Teléfono</span> -->
      </f7-col>
      <f7-col @click="nativeShare" class="local-cabecera-iconos-col link">
        <!-- <i
            data-eva="share-outline"
            data-eva-fill="#222222"
            data-eva-width="24px"
            data-eva-height="24px"
            style="position: relative; vertical-align: sub"
          ></i> -->
        <i class="fas fa-share-alt local-cabecera-icono"></i>
        <!-- <span class="local-cabecera-iconos-texto">Compartí</span> -->
      </f7-col>
      <f7-col></f7-col>
    </f7-row>

    <f7-row v-if="$root.docLocal.texto_informativo" class="margin">
      <f7-col class="text-align-center">
        {{ $root.docLocal.texto_informativo }}
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import local from "../../local.js";
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    navigateToExternalLink(url) {
      window.location.replace(url);
    },
    nativeShare() {
      const self = this;
      local.nativeShare(self);
    },
  },
};
</script>
