<template>
  <f7-sheet
    id="cart-shipping-map-sheet"
    class=""
    backdrop
    v-if="!props.loading"
    :swipeToClose="false"
    :closeByBackdropClick="false"
    :closeByOutsideClick="false"
    :opened="props.cartShippingMapSheetOpened"
    @sheet:open="onCartShippingMapCartSheetOpened"
    @sheet:closed="onCartShippingMapCartSheetClosed"
    style="height: 80vh"
  >
    <span
      class="close-cart-shipping-map-sheet-container"
      @click="
        props.cartShippingMapSheetOpened = false;
        props.cartMySavedLocationsSheetOpened = true;
      "
      ><i
        class="fas fa-times close-cart-shipping-map-sheet sheet-close-button"
      ></i
    ></span>
    <f7-page-content style="border-radius: 10px 10px 0 0">
      <f7-block class="map-navbar padding-horizontal-half">
        <f7-row>
          <div class="col-70">
            <f7-searchbar
              no-hairline
              class="searchbar-map"
              search-container=".search-list"
              placeholder="Ingresa tu dirección"
              :clear-button="true"
              id="cart-shipping-map-sheet-search"
              @focus="closeDialog"
            >
            </f7-searchbar>
          </div>
          <div @click="searchAddress" class="col-15 map-button">
            <f7-link>
              <i class="fa fa-search-location" style="font-size: 20px"></i>
            </f7-link>
          </div>
          <div @click="myLocation" class="col-15 map-button">
            <f7-link>
              <i class="fas fa-crosshairs" style="font-size: 20px"></i>
            </f7-link>
          </div>
        </f7-row>
        <shippingMapTotals :props="props" />
      </f7-block>

      <div id="shippingMapCart" class="map-container"></div>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import * as eva from "eva-icons/eva.min.js";
import map from "../../map.js";
import utils from "../../utils.js";
import shippingMapTotals from "./shipping-map-totals.vue";

export default {
  props: {
    props: Object,
  },
  components: {
    shippingMapTotals,
  },
  data: function() {
    return {};
  },
  beforeMount: function() {
    const self = this;
  },
  mounted: function() {
    const self = this;
    eva.replace();
  },
  updated: function() {
    eva.replace();
  },
  methods: {
    navBack() {
      const self = this;
      self.props.cartShippingMapSheetOpened = false;
    },
    searchAddress() {
      const self = this;
      map.searchAddress(self);
    },
    myLocation() {
      const self = this;
      map.myLocation(self);
    },
    onCartShippingMapCartSheetOpened() {
      const self = this;
      // self.enterTriggerSearch();
      map.onCartShippingMapCartSheetOpened(self);
    },
    onCartShippingMapCartSheetClosed() {
      const self = this;
      self.props.cartShippingMapSheetOpened = false;
      // self.props.cartMySavedLocationsSheetOpened = true;
      map.onCartShippingMapCartSheetClosed(self);
      self.$f7.toast.close();
    },
    // enterTriggerSearch() {
    //   const self = this;
    //   self.$$(".searchbar-map").on("keyup", (e) => {
    //     if (e.keyCode === 13) {
    //       e.preventDefault();
    //       self.searchAddress(self);
    //     }
    //   });
    // },
    closeDialog() {
      const self = this;
      self.$f7.toast.close();
    },
  },
};
</script>
