<template>
  <f7-row
    v-if="
      ($root.confirmedCart.length &&
        $root.listFoodShippingMethods.length > 0) ||
        ($root.confirmedCart.length &&
          $root.listFoodShippingMethodsRadioMaps.length > 0) ||
        ($root.confirmedCart.length &&
          $root.listFoodShippingMethodsDelimitedMaps.length > 0)
    "
  >
    <f7-col>
      <div
        class="item-title item-label shop-cart-title-section"
        style="color: #222222"
      >
        FORMA DE ENTREGA
      </div>
      <f7-list
        v-for="metodoEnvioList in $root.listFoodShippingMethods"
        :key="metodoEnvioList.name"
        style="margin: 0"
      >
        <!-- ZONAS DE ENVIO -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Delivery - Zonas de Envío' &&
              metodoEnvioList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoEnvioList.nombre"
          :value="metodoEnvioList.nombre"
          name="radio-entrega"
          :checked="$root.metodoEnvio === metodoEnvioList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoEnvio = e.target.value;
              $root.tipoMetodoEnvio = 'Delivery - Zonas de Envío';
              $root.costoEnvio = metodoEnvioList.costo_envio;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoEnvioList.costo_envio > 0"
              style="font-size: 12px"
            >
              + $ {{ Math.abs(parseFloat(metodoEnvioList.costo_envio)) }}
            </span>
          </template>
        </f7-list-item>
        <!-- TAKE-AWAY -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              metodoEnvioList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoEnvioList.nombre"
          :value="metodoEnvioList.nombre"
          name="radio-entrega"
          :checked="$root.metodoEnvio === metodoEnvioList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoEnvio = e.target.value;
              $root.tipoMetodoEnvio = 'Take Away';
              $root.costoEnvio = metodoEnvioList.costo_envio;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoEnvioList.costo_envio > 0"
              style="font-size: 12px"
            >
              + $ {{ Math.abs(parseFloat(metodoEnvioList.costo_envio)) }}
            </span>
          </template>
        </f7-list-item>
        <!-- TAKE AWAY | CAMPOS ASOCIADOS -->
        <f7-list-input
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="Nombre de quien retira"
          type="text"
          required
          validate
          :value="$root.nombreRetira"
          @focus="inputOnFocus"
          clear-button
          @input="
            (e) => {
              $root.nombreRetira = e.target.value;
            }
          "
        >
        </f7-list-input>
        <!-- CUANDO RETIRA | TITULO -->
        <li
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
        >
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">¿Cuándo lo retiras?</div>
            </div>
          </div>
        </li>
        <!-- CUANDO RETIRA | LO ANTES POSIBLE -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos al Momento' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="Lo Antes Posible"
          value="Lo Antes Posible"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira === 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'text';
              $root.horaRetira = 'Lo Antes Posible';
              $root.horaRetiraFormatted = e.target.value;
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | FECHA Y HORA -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos Agendados' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="En una fecha y hora"
          value="En una fecha y hora"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira !== 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'datetime-local';
              $root.horaRetira = '';
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | SELECTOR FECHA Y HORA -->
        <f7-list-input
          v-if="
            metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos Agendados' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Take Away' &&
              $root.metodoEnvio === metodoEnvioList.nombre &&
              $root.horaRetiraSelector === 'datetime-local'
          "
          class="cart-metodos-subopciones"
          type="datetime-local"
          info="Toca para elegir una fecha"
          :value="$root.horaRetira"
          validate
          @focus="
            (e) => {
              inputOnFocus;
            }
          "
          read-only
          @input="
            (e) => {
              $root.horaRetira = e.target.value;
              $root.horaRetiraFormatted = formatDate(e.target.value);
            }
          "
        >
        </f7-list-input>
        <!-- DELIVERY -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Delivery' &&
              metodoEnvioList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoEnvioList.nombre"
          :value="metodoEnvioList.nombre"
          name="radio-entrega"
          :checked="$root.metodoEnvio === metodoEnvioList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoEnvio = e.target.value;
              $root.tipoMetodoEnvio = 'Delivery';
              $root.costoEnvio = metodoEnvioList.costo_envio;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoEnvioList.costo_envio > 0"
              style="font-size: 12px"
            >
              + $ {{ Math.abs(parseFloat(metodoEnvioList.costo_envio)) }}
            </span>
          </template>
        </f7-list-item>
        <!-- DELIVERY | CAMPOS ASOCIADOS -->
        <f7-list-input
          v-if="
            ((metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="Nombre de quien recibe"
          type="text"
          :value="$root.nombreRecibe"
          @focus="inputOnFocus"
          name="delivery_nombre_recibe"
          clear-button
          required
          validate
          @input="
            (e) => {
              $root.nombreRecibe = e.target.value;
            }
          "
        >
        </f7-list-input>
        <f7-list-input
          v-if="
            ((metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="Dirección de envío completa"
          type="text"
          :value="$root.direccionRecibe"
          name="delivery_direccion_de_envio"
          clear-button
          required
          validate
          @focus="inputOnFocus"
          @input="
            (e) => {
              $root.direccionRecibe = e.target.value;
            }
          "
        >
        </f7-list-input>
        <f7-list-input
          v-if="
            ((metodoEnvioList &&
              metodoEnvioList.campo_entrecalles &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                metodoEnvioList.campo_entrecalles &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="Entre Calles"
          type="text"
          :value="$root.direccionEntrecalles"
          @focus="inputOnFocus"
          name="delivery_entrecalles"
          clear-button
          validate
          required
          @input="
            (e) => {
              $root.direccionEntrecalles = e.target.value;
            }
          "
        >
        </f7-list-input>
        <!-- CUANDO RETIRA | TITULO -->
        <li
          v-if="
            ((metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
        >
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">¿Cuando te lo enviamos?</div>
            </div>
          </div>
        </li>
        <!-- CUANDO RETIRA | LO ANTES POSIBLE -->
        <f7-list-item
          v-if="
            ((metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos al Momento' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                (metodoEnvioList.tiempo_entrega_pedidos ===
                  'Solo Pedidos al Momento' ||
                  metodoEnvioList.tiempo_entrega_pedidos ===
                    'Ambas Opciones') &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="Lo Antes Posible"
          value="Lo Antes Posible"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira === 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'text';
              $root.horaRetira = 'Lo Antes Posible';
              $root.horaRetiraFormatted = e.target.value;
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | FECHA Y HORA -->
        <f7-list-item
          v-if="
            ((metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos Agendados' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                (metodoEnvioList.tiempo_entrega_pedidos ===
                  'Solo Pedidos Agendados' ||
                  metodoEnvioList.tiempo_entrega_pedidos ===
                    'Ambas Opciones') &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="En una fecha y hora"
          value="En una fecha y hora"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira !== 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'datetime-local';
              $root.horaRetira = '';
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | SELECTOR FECHA Y HORA -->
        <f7-list-input
          v-if="
            ((metodoEnvioList &&
              (metodoEnvioList.tiempo_entrega_pedidos ===
                'Solo Pedidos Agendados' ||
                metodoEnvioList.tiempo_entrega_pedidos === 'Ambas Opciones') &&
              metodoEnvioList.metodo_de_envio === 'Delivery') ||
              (metodoEnvioList &&
                (metodoEnvioList.tiempo_entrega_pedidos ===
                  'Solo Pedidos Agendados' ||
                  metodoEnvioList.tiempo_entrega_pedidos ===
                    'Ambas Opciones') &&
                metodoEnvioList.metodo_de_envio ===
                  'Delivery - Zonas de Envío')) &&
              $root.metodoEnvio === metodoEnvioList.nombre &&
              $root.horaRetiraSelector === 'datetime-local'
          "
          class="cart-metodos-subopciones"
          type="datetime-local"
          info="Toca para elegir una fecha"
          :value="$root.horaRetira"
          @focus="inputOnFocus"
          clear-button
          @input="
            (e) => {
              $root.horaRetira = e.target.value;
              $root.horaRetiraFormatted = formatDate(e.target.value);
            }
          "
        >
        </f7-list-input>
        <!-- SALON -->
        <f7-list-item
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Salón' &&
              metodoEnvioList.habilitado === 1
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          :title="metodoEnvioList.nombre"
          :value="metodoEnvioList.nombre"
          name="radio-entrega"
          :checked="$root.metodoEnvio === metodoEnvioList.nombre"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.metodoEnvio = e.target.value;
              $root.tipoMetodoEnvio = 'Salón';
              $root.costoEnvio = metodoEnvioList.costo_envio;
            }
          "
        >
          <template #after>
            <span
              v-if="metodoEnvioList.costo_envio > 0"
              style="font-size: 12px"
            >
              + $ {{ Math.abs(parseFloat(metodoEnvioList.costo_envio)) }}
            </span>
          </template>
        </f7-list-item>
        <!-- SALON | CAMPOS ASOCIADOS -->
        <f7-list-input
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Salón' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="El Número de tu Mesa"
          type="text"
          :value="$root.mesa"
          name="salon_numero_mesa"
          clear-button
          maxlength="10"
          required
          validate
          @focus="inputOnFocus"
          @input="
            (e) => {
              $root.mesa = e.target.value;
            }
          "
        >
        </f7-list-input>

        <f7-list-input
          v-if="
            metodoEnvioList &&
              metodoEnvioList.metodo_de_envio === 'Salón' &&
              $root.metodoEnvio === metodoEnvioList.nombre
          "
          class="cart-metodos-subopciones"
          label="Tu Nombre"
          type="text"
          clear-button
          required
          validate
          name="salon_nombre"
          :value="$root.nombreMesa"
          @focus="inputOnFocus"
          @input="
            (e) => {
              $root.nombreMesa = e.target.value;
            }
          "
        >
        </f7-list-input>
      </f7-list>
      <!-- <f7-list-item
        v-if="
          metodoEnvioList && metodoEnvioList.metodo_de_envio === 'Delivery - Google Maps' &&
            metodoEnvioList.habilitado === 1
        "
        style="font-size: 16px; font-weight: 600;"
        radio
        radio-icon="end"
        :title="metodoEnvioList.nombre"
        :value="metodoEnvioList.nombre"
        name="radio-entrega"
        :checked="$root.metodoEnvio === metodoEnvioList.nombre"
        @click="inputOnFocus"
        @change="
          (e) => {
            $root.metodoEnvio = e.target.value;
            $root.tipoMetodoEnvio = 'Delivery - Google Maps';
            $root.costoEnvio = metodoEnvioList.costo_envio;
            initMap('map-shipping-cart-' + metodoEnvioList.name);
          }
        "
      >
        <template #after>
          <span v-if="metodoEnvioList.costo_envio > 0" style="font-size: 12px">
            + $ {{ Math.abs(parseFloat(metodoEnvioList.costo_envio)) }}
          </span>
        </template>
      </f7-list-item> -->
      <!-- <f7-list class="no-margin no-padding"> -->
      <!-- <f7-list-item
          v-if="true"
          style="font-size: 16px; font-weight: 600;"
          radio
          radio-icon="end"
          title="Delivery Google Maps"
          value="Delivery Google Maps"
          name="radio-entrega"
          :checked="false"
        >
          <template #after>
            <span
              v-if="metodoEnvioList.costo_envio > 0"
              style="font-size: 12px"
            >
              + $ {{ "-" }}
            </span>
          </template>
        </f7-list-item> -->
      <!-- DELIVERY - GOOGLE MAPS | CAMPOS ASOCIADOS -->
      <!-- <f7-block style="width: 100%" class="no-margin">
          <f7-block
            v-show="true"
            class="no-margin padding-bottom no-padding-horizontal"
          >
            <f7-row>
              <f7-col>
                <div
                  style="border-radius: 5px;"
                  id="map-shipping-cart"
                  class="map-container-shipping-cart"
                ></div>
              </f7-col>
            </f7-row>
          </f7-block>
        </f7-block> -->
      <!-- </f7-list> -->
      <!-- <f7-input
        class="cart-metodos-subopciones"
        label="Dirección"
        type="text"
        value=""
        @focus="inputOnFocus"
        name="google_maps_direction"
        clear-button
        required
        validate
        @input="
          (e) => {
            geocodingAddress(e);
          }
        "
      >
      </f7-input> -->
      <f7-list class="no-margin no-padding">
        <!-- DELIVERY - GOOGLE MAPS -->
        <!-- v-if="$root.confirmedShippingMapTotals" -->
        <f7-list-item
          v-if="
            $root.listFoodShippingMethodsDelimitedMaps.length > 0 ||
              $root.listFoodShippingMethodsRadioMaps.length > 0
          "
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="Calcula tu Envío"
          value="Envío a Domicilio"
          name="radio-entrega"
          :checked="$root.metodoEnvio === 'Envío a Domicilio'"
          @click="
            () => {
              inputOnFocus;
            }
          "
          @change="
            (e) => {
              $root.metodoEnvio = e.target.value;
              $root.tipoMetodoEnvio = 'Delivery - Google Maps';
              // $root.costoEnvio = $root.shippingMapTotals;
              calculateShippingCostByPosition();
            }
          "
        >
          <template #after>
            <span
              v-show="
                $root.metodoEnvio === 'Envío a Domicilio' &&
                  $root.confirmedShippingMapTotals
              "
              style="font-size: 12px"
            >
              + $ {{ Math.abs(parseFloat($root.shippingMapTotals)) }}
            </span>
          </template>
        </f7-list-item>
        <!-- DELIVERY - GOOGLE MAPS - DIRECCIÓN -->
        <f7-list-item
          v-show="
            $root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals
          "
        >
          <f7-row>
            <f7-col style="color: #734903; font-size: 14px; margin-top: -15px">
              {{ $root.shippingMapAddress }}
            </f7-col>
          </f7-row>
        </f7-list-item>
        <!-- MIS UBICACIONES - BOTON -->
        <f7-list-item
          v-show="
            $root.metodoEnvio === 'Envío a Domicilio' &&
              !$root.confirmedShippingMapTotals
          "
        >
          <span
            class="badge badge-shipping-map"
            style="width: 100%"
            @click="
              () => {
                openCartMySavedLocationsSheet();
              }
            "
            fill
            >Mis Direcciones</span
          >
        </f7-list-item>
        <!-- DELIVERY - GOOGLE MAPS - BOTON -->
        <!-- <f7-list-item
          v-show="
            $root.metodoEnvio === 'Envío a Domicilio' &&
            !$root.confirmedShippingMapTotals
          "
        >
          <span
            class="badge badge-shipping-map"
            style="width: 100%"
            @click="
              () => {
                inputOnFocus;
                openCartShippingMapSheet();
              }
            "
            fill
            >Nueva Ubicación</span
          >
        </f7-list-item> -->
        <f7-list-item
          v-show="
            $root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals
          "
        >
          <span
            class="badge badge-shipping-map"
            style="width: 100%"
            @click="
              () => {
                inputOnFocus;
                // openCartShippingMapSheet();
                openCartMySavedLocationsSheet();
              }
            "
            fill
            >Cambiar Dirección</span
          >
        </f7-list-item>
        <!-- DELIVERY | CAMPOS ASOCIADOS -->
        <f7-list-input
          v-show="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                $root.confirmedShippingMapTotals &&
                $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          label="Nombre de quien recibe"
          type="text"
          :value="$root.nombreRecibe"
          @focus="inputOnFocus"
          name="delivery_nombre_recibe"
          clear-button
          required
          validate
          @input="
            (e) => {
              $root.nombreRecibe = e.target.value;
            }
          "
        >
        </f7-list-input>
        <f7-list-input
          v-show="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                $root.listFoodShippingMethodsDelimitedMaps[0]
                  .campo_direccion_2) ||
                ($root.listFoodShippingMethodsRadioMaps[0] &&
                  $root.listFoodShippingMethodsRadioMaps[0]
                    .campo_direccion_2)) &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                  $root.listFoodShippingMethodsDelimitedMaps[0]
                    .campo_direccion_2) ||
                  ($root.listFoodShippingMethodsRadioMaps[0] &&
                    $root.listFoodShippingMethodsRadioMaps[0]
                      .campo_direccion_2)) &&
                $root.confirmedShippingMapTotals &&
                $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          label="Información Adicional"
          type="text"
          :value="$root.direccionAdicionalRecibe"
          name="delivery_direccion_adicional_de_envio"
          placeholder="Edificio, Dpto., Piso, etc."
          clear-button
          @focus="inputOnFocus"
          @input="
            (e) => {
              $root.direccionAdicionalRecibe = e.target.value;
            }
          "
        >
        </f7-list-input>
        <!-- CUANDO RETIRA | TITULO -->
        <li
          v-if="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                $root.confirmedShippingMapTotals &&
                $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
        >
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">¿Cuando te lo enviamos?</div>
            </div>
          </div>
        </li>
        <!-- CUANDO RETIRA | LO ANTES POSIBLE -->
        <f7-list-item
          v-if="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                $root.listFoodShippingMethodsDelimitedMaps[0]
                  .tiempo_entrega_pedidos === 'Solo Pedidos al Momento') ||
                ($root.listFoodShippingMethodsRadioMaps[0] &&
                  $root.listFoodShippingMethodsRadioMaps[0]
                    .tiempo_entrega_pedidos === 'Solo Pedidos al Momento') ||
                ($root.listFoodShippingMethodsDelimitedMaps[0] &&
                  $root.listFoodShippingMethodsDelimitedMaps[0]
                    .tiempo_entrega_pedidos === 'Ambas Opciones') ||
                ($root.listFoodShippingMethodsRadioMaps[0] &&
                  $root.listFoodShippingMethodsRadioMaps[0]
                    .tiempo_entrega_pedidos === 'Ambas Opciones')) &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                  $root.listFoodShippingMethodsDelimitedMaps[0]
                    .tiempo_entrega_pedidos === 'Solo Pedidos al Momento') ||
                  ($root.listFoodShippingMethodsRadioMaps[0] &&
                    $root.listFoodShippingMethodsRadioMaps[0]
                      .tiempo_entrega_pedidos === 'Solo Pedidos al Momento') ||
                  ($root.listFoodShippingMethodsDelimitedMaps[0] &&
                    $root.listFoodShippingMethodsDelimitedMaps[0]
                      .tiempo_entrega_pedidos === 'Ambas Opciones') ||
                  ($root.listFoodShippingMethodsRadioMaps[0] &&
                    $root.listFoodShippingMethodsRadioMaps[0]
                      .tiempo_entrega_pedidos === 'Ambas Opciones')) &&
                $root.confirmedShippingMapTotals &&
                $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="Lo Antes Posible"
          value="Lo Antes Posible"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira === 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'text';
              $root.horaRetira = 'Lo Antes Posible';
              $root.horaRetiraFormatted = e.target.value;
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | FECHA Y HORA -->
        <f7-list-item
          v-if="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                $root.listFoodShippingMethodsDelimitedMaps[0]
                  .tiempo_entrega_pedidos === 'Solo Pedidos Agendados') ||
                ($root.listFoodShippingMethodsRadioMaps[0] &&
                  $root.listFoodShippingMethodsRadioMaps[0]
                    .tiempo_entrega_pedidos === 'Solo Pedidos Agendados') ||
                ($root.listFoodShippingMethodsDelimitedMaps[0] &&
                  $root.listFoodShippingMethodsDelimitedMaps[0]
                    .tiempo_entrega_pedidos === 'Ambas Opciones') ||
                ($root.listFoodShippingMethodsRadioMaps[0] &&
                  $root.listFoodShippingMethodsRadioMaps[0]
                    .tiempo_entrega_pedidos === 'Ambas Opciones')) &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                $root.confirmedShippingMapTotals &&
                (($root.listFoodShippingMethodsDelimitedMaps[0] &&
                  $root.listFoodShippingMethodsDelimitedMaps[0]
                    .tiempo_entrega_pedidos === 'Solo Pedidos Agendados') ||
                  ($root.listFoodShippingMethodsRadioMaps[0] &&
                    $root.listFoodShippingMethodsRadioMaps[0]
                      .tiempo_entrega_pedidos === 'Solo Pedidos Agendados') ||
                  ($root.listFoodShippingMethodsDelimitedMaps[0] &&
                    $root.listFoodShippingMethodsDelimitedMaps[0]
                      .tiempo_entrega_pedidos === 'Ambas Opciones') ||
                  ($root.listFoodShippingMethodsRadioMaps[0] &&
                    $root.listFoodShippingMethodsRadioMaps[0]
                      .tiempo_entrega_pedidos === 'Ambas Opciones')) &&
                $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="En una fecha y hora"
          value="En una fecha y hora"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira !== 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetiraSelector = 'datetime-local';
              $root.horaRetira = '';
            }
          "
        >
        </f7-list-item>
        <!-- CUANDO RETIRA | SELECTOR FECHA Y HORA -->
        <f7-list-input
          v-if="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0 &&
              $root.horaRetiraSelector === 'datetime-local') ||
              ($root.metodoEnvio === 'Envío a Domicilio' &&
                $root.confirmedShippingMapTotals &&
                $root.listFoodShippingMethodsRadioMaps.length > 0 &&
                $root.horaRetiraSelector === 'datetime-local')
          "
          class="cart-metodos-subopciones"
          type="datetime-local"
          info="Toca para elegir una fecha"
          :value="$root.horaRetira"
          @focus="inputOnFocus"
          clear-button
          @input="
            (e) => {
              $root.horaRetira = e.target.value;
              $root.horaRetiraFormatted = formatDate(e.target.value);
            }
          "
        >
        </f7-list-input>
        <!-- <f7-list-input
          v-show="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          label="¿Cuándo te lo enviamos?"
          type="datetime-local"
          :value="$root.horaRetira"
          @focus="inputOnFocus"
          clear-button
          @input="
            (e) => {
              $root.horaRetira = e.target.value;
              $root.horaRetiraFormatted = formatDate(e.target.value);
            }
          "
        >
        </f7-list-input>
        <f7-list-item
          v-show="
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsDelimitedMaps.length > 0) ||
            ($root.metodoEnvio === 'Envío a Domicilio' &&
              $root.confirmedShippingMapTotals &&
              $root.listFoodShippingMethodsRadioMaps.length > 0)
          "
          class="cart-metodos-subopciones"
          style="font-size: 16px; font-weight: 600"
          radio
          radio-icon="end"
          title="Lo Antes Posible"
          value="Lo Antes Posible"
          name="radio-entrega-takeaway"
          :checked="$root.horaRetira === 'Lo Antes Posible'"
          @click="inputOnFocus"
          @change="
            (e) => {
              $root.horaRetira = 'Lo Antes Posible';
              $root.horaRetiraFormatted = e.target.value;
            }
          "
        >
        </f7-list-item> -->
      </f7-list>
      <!-- CAMPOS PERSONALIZABLES -->
      <f7-list
        class="no-margin no-padding"
        v-for="(campo, index) in $root.listCustomFields"
        :key="campo.name"
      >
        <f7-list-input
          v-if="campo.asociado_a_metodo === 'Método de Envío'"
          class="cart-metodos-subopciones"
          :label="campo.nombre_campo"
          :type="$root.customFieldsTypes[campo.tipo_de_dato]"
          :info="campo.leyenda"
          @focus="inputOnFocus"
          :name="campo.name"
          clear-button
          :required="campo.obligatorio === 1 ? 'required' : ''"
          validate
          @change="
            (e) => {
              $root.listCustomFields[index].value = e.target.value;
            }
          "
        >
        </f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";
import map from "../../map.js";
import utils from "../../utils.js";
const { format } = require("date-fns-tz");
const { parse } = require("date-fns");
const { es } = require("date-fns/locale");

export default {
  props: {
    props: Object,
  },
  data: function() {
    return {
      mySavedLocationsLs: [],
      // mySavedLocationsLs: {},
    };
  },
  mounted() {
    const self = this;
    // self.initMap();
    if (localStorage.getItem("my_saved_locations")) {
      self.mySavedLocationsLs = JSON.parse(
        localStorage.getItem("my_saved_locations")
      )[0];
    }
  },
  methods: {
    inputOnFocus() {
      cart.inputOnFocus(this);
    },
    formatDate(date) {
      var d = parse(date, "yyyy-MM-dd'T'HH:mm", new Date());
      var formattedDate = format(
        d,
        "eeee d 'de' MMMM 'de' yyyy 'a las' HH:mm 'hs'",
        {
          locale: es,
        }
      );
      return formattedDate;
    },
    // initMap() {
    //   const self = this;

    //   setTimeout(() => {
    //     self.props.geocoder = new google.maps.Geocoder();
    //     var latlng = new google.maps.LatLng(
    //       self.$root.centerLat,
    //       self.$root.centerLng
    //     );
    //     var mapOptions = {
    //       zoom: 14,
    //       center: latlng,
    //       fullscreenControl: false,
    //       streetViewControl: false,
    //       mapTypeControl: false,
    //       zoomControl: false,
    //       gestureHandling: "cooperative",
    //       styles: [
    //         {
    //           featureType: "poi",
    //           stylers: [{ visibility: "off" }],
    //         },
    //         {
    //           featureType: "transit",
    //           elementType: "labels.icon",
    //           stylers: [{ visibility: "off" }],
    //         },
    //       ],
    //     };
    //     setTimeout(() => {
    //       self.props.map = new google.maps.Map(
    //         document.getElementById("map-shipping-cart"),
    //         mapOptions
    //       );
    //     }, 1000);
    //   }, 1000);
    // },
    // geocodingAddress(e) {
    //   const self = this;

    //   var address = e.currentTarget.value;
    //   self.props.geocoder.geocode({ address: address }, function(
    //     results,
    //     status
    //   ) {
    //     utils.registerApiCost(
    //       self,
    //       "Maps - Geocoding API",
    //       "geocodingAddress",
    //       "cart-shipping-methods.vue"
    //     );
    //     if (status == "OK") {
    //       map.setCenter(results[0].geometry.location);
    //       var marker = new google.maps.Marker({
    //         map: map,
    //         position: results[0].geometry.location,
    //       });

    //       let location = results[0].geometry.location.toJSON();
    //     } else {
    //       frappe.msgprint("La direccion ingresada no existe");
    //       alert(
    //         "Geocode was not successful for the following reason: " + status
    //       );
    //     }
    //   });
    // },
    openCartShippingMapSheet() {
      const self = this;
      self.props.cartShippingMapSheetOpened = true;
    },
    openCartMySavedLocationsSheet() {
      const self = this;
      self.$root.confirmedShippingMapTotals = false;
      self.props.cartMySavedLocationsSheetOpened = true;
    },
    calculateShippingCostByPosition() {
      const self = this;

      if (
        self.props.mySavedLocationsLs &&
        self.props.mySavedLocationsLs.length > 0
      ) {
        map.calculateShippingCostByPosition(self, {
          lat: self.mySavedLocationsLs.lat,
          lng: self.mySavedLocationsLs.lng,
        });

        self.$root.shippingMapLatLng = {
          lat: self.mySavedLocationsLs.lat,
          lng: self.mySavedLocationsLs.lng,
        };

        self.$root.shippingMapAddress = self.mySavedLocationsLs.address;
        self.$root.confirmedShippingMapTotals = true;
      }
    },
  },
};
</script>
