<template>
  <span class="local-carta-precio-plato">
    <span
      class="badge local-carta-precio-plato-badge"
      v-if="parseFloat(subitem.precio_delivery) > 0"
    >
      <!-- PRECIO NORMAL | CARTA DELIVERY -->
      <span
        :class="
          subitem.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-if="subitem.precio_delivery > 0"
        >$ {{ formatCurrency(parseFloat(subitem.precio_delivery)) }}</span
      >
      <!-- PRECIO NORMAL CON DECIMAL | CARTA DELIVERY -->
      <!-- <span
        :class="
          subitem.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-if="parseFloat(subitem.precio_delivery) % 1 !== 0"
        >$ {{ parseFloat(subitem.precio_delivery).toFixed(2) }}</span
      > -->
      <!-- PRECIO NORMAL SIN DECIMAL | CARTA DELIVERY  -->
      <!-- <span
        :class="
          subitem.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-else
        >$ {{ parseFloat(subitem.precio_delivery).toFixed(0) }}</span
      > -->
      <!-- PRECIO REBAJADO | CARTA DELIVERY  -->
      <span class="precio-rebajado" v-if="subitem.precio_rebajado_delivery > 0"
        >&nbsp;$
        {{ formatCurrency(parseFloat(subitem.precio_rebajado_delivery)) }}</span
      >
      <!-- PRECIO REBAJADO CON DECIMAL | CARTA DELIVERY  -->
      <!-- <span
        class="precio-rebajado"
        v-if="
          subitem.precio_rebajado_delivery > 0 &&
            parseFloat(subitem.precio_rebajado_delivery) % 1 !== 0
        "
        >&nbsp;$
        {{ parseFloat(subitem.precio_rebajado_delivery).toFixed(2) }}</span
      > -->
      <!-- PRECIO REBAJADO SIN DECIMAL | CARTA DELIVERY  -->
      <!-- <span
        class="precio-rebajado"
        v-else-if="
          subitem.precio_rebajado_delivery > 0 &&
            parseFloat(subitem.precio_rebajado_delivery) % 1 === 0
        "
        >&nbsp;$
        {{
          subitem.precio_rebajado_delivery > 0 &&
            parseFloat(subitem.precio_rebajado_delivery).toFixed(0)
        }}</span
      > -->
      <!-- SEPARADOR ENTRE EL PRECIO GLOBAL Y LOS ATRIBUTOS -->
      <!-- <span
        v-if="
          $root.listFoodType === 'Salon' &&
            subitem.atributos_del_plato.length > 0
        "
        style="color: #777777"
      >
        &nbsp;|
      </span>

      <span
        v-if="
          $root.listFoodType === 'Delivery' &&
            subitem.atributos_del_plato_delivery.length > 0
        "
        style="color: #777777"
      >
        &nbsp;|
      </span> -->
    </span>
  </span>
</template>

<script>
import utils from "../../../utils.js";

export default {
  props: {
    props: Object,
    subitem: Object,
  },
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
