var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-sheet',{staticClass:"final-checkout-sheet",attrs:{"backdrop":"","swipeToClose":false,"closeByBackdropClick":false,"closeByOutsideClick":false,"opened":_vm.props.cartCheckoutPopupOpened},on:{"sheet:opened":_vm.onCartCheckoutPopupOpened,"sheet:closed":function($event){_vm.props.cartCheckoutPopupOpened = false}}},[_c('f7-page-content',{staticClass:"padding-bottom"},[_c('span',[_c('div',{staticClass:"display-flex align-items-center flex-direction-column",staticStyle:{"border-radius":"20px !important"}},[_c('video',{staticStyle:{"width":"80%","margin":"0 auto","border-radius":"20px !important","margin-top":"10px"},attrs:{"src":"/static/videos/pedido-aprobado-check.mp4","autoplay":"","muted":"","playsinline":""},domProps:{"muted":true}}),_vm._v(" "),(
            _vm.$root.docLocal.panel_pedidos && !_vm.$root.docLocal.pedidos_whatsapp
          )?_c('h3',{staticClass:"\n            margin-bottom-half\n            no-margin-top\n            padding-horizontal\n            text-align-center\n          ",staticStyle:{"font-size":"18px"}},[_vm._v("\n          ¡Tu pedido ha sido enviado! 🎉\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.panel_pedidos && !_vm.$root.docLocal.pedidos_whatsapp
          )?_c('p',{staticClass:"\n            no-margin-vertical\n            padding-horizontal\n            text-align-center\n            margin-bottom\n          "},[_vm._v("\n          Podrás ver su estado en tiempo real.\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.pedidos_whatsapp && !_vm.$root.docLocal.panel_pedidos
          )?_c('h3',{staticClass:"\n            margin-bottom-half\n            no-margin-top\n            padding-horizontal\n            text-align-center\n          ",staticStyle:{"font-size":"18px"}},[_vm._v("\n          ¡Gracias por tu compra! 🎉\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.pedidos_whatsapp && !_vm.$root.docLocal.panel_pedidos
          )?_c('p',{staticClass:"\n            no-margin-vertical\n            padding-horizontal\n            text-align-center\n            margin-bottom\n          "},[_vm._v("\n          Te estamos redirigiendo a WhatsApp\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.panel_pedidos && _vm.$root.docLocal.pedidos_whatsapp
          )?_c('h3',{staticClass:"\n            margin-bottom-half\n            no-margin-top\n            padding-horizontal\n            text-align-center\n          ",staticStyle:{"font-size":"18px"}},[_vm._v("\n          ¡Tu pedido está en proceso!\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.pedidos_whatsapp && _vm.$root.docLocal.panel_pedidos
          )?_c('p',{staticClass:"\n            no-margin-vertical\n            padding-horizontal\n            text-align-center\n            margin-top\n            margin-bottom-half\n          "},[_vm._v("\n          Enviar confirmación vía WhatsApp\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.pedidos_whatsapp &&
              _vm.$root.docLocal.panel_pedidos &&
              !_vm.props.cartCheckoutMPLinkReady
          )?_c('f7-row',{staticClass:"margin-horizontal",staticStyle:{"min-width":"80%"}},[_c('f7-col',[_c('span',{staticClass:"badge badge-hacer-pedido-disabled"},[_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin",staticStyle:{"position":"relative"}}),_vm._v("  ")]),_c('span',[_vm._v("AGUARDE")])])])],1):_vm._e(),_vm._v(" "),(
            _vm.$root.docLocal.pedidos_whatsapp &&
              _vm.$root.docLocal.panel_pedidos &&
              _vm.props.cartCheckoutMPLinkReady
          )?_c('f7-row',{staticClass:"margin-horizontal",staticStyle:{"min-width":"80%"}},[_c('f7-col',{on:{"click":_vm.sendWhatsAppOrder}},[_c('span',{staticClass:"badge badge-hacer-pedido"},[_c('span',[_c('i',{staticClass:"fab fa-whatsapp",staticStyle:{"position":"relative"}}),_vm._v("  ")]),_c('span',[_vm._v("ENVIAR")])])])],1):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }