<template>
  <f7-block class="cover-home no-margin">
    <f7-row style="width: 100%" v-if="$f7.device.desktop">
      <div class="col-50 medium-75 small-50 xsmall-50 large-75"></div>
      <div
        class="col-50 medium-25 small-50 xsmall-50 large-25"
        style="justify-content: flex-end; display: flex; margin-top: 5px"
      >
        <f7-button
          class="clients-area-button external no-margin-horizontal display-inline-flex"
          href="https://admin.alacarta.online"
          outline
          raise
        >
          <span class="display-inline-flex"
            ><i class="fas fa-user-circle" style="font-size: 20px"></i
          ></span>
          <span>&nbsp;&nbsp;AREA CLIENTES</span>
        </f7-button>
      </div>
    </f7-row>
    <f7-row class="cover-home-container">
      <div class="col-15"></div>

      <div class="col-100 medium-70 cover-col">
        <img
          class="cover-logo"
          src="/static/images/Alacarta_Logo_Transparent.png"
          alt="Logo Alacarta"
        />
        <div class="cover-description-container">
          <h1 class="cover-title">El menú en tus manos</h1>
          <p class="cover-text">
            Alacarta.online es la primer plataforma que permite la
            <span style="font-weight: 600">digitalización de tu menú</span>,
            diseñada especialmente para gastronomía, con todas las herramientas,
            y una personalización total además de una fácil navegación generando
            una excelente experiencia, permitiendo
            <span style="font-weight: 600">potenciar tu negocio</span> al
            máximo.
          </p>
        </div>
        <f7-row>
          <div class="col-100 medium-50">
            <f7-button
              class="cover-button"
              @click="scrollToAnchor('#contactanos')"
              fill
              raise
              >CONTACTANOS</f7-button
            >
          </div>
          <div class="col-100 medium-50">
            <f7-button
              href=""
              @click.prevent="cartaDemoPopupOpen"
              id="demo-button"
              class="cover-button external"
              fill
              raise
              >CARTAS DEMO</f7-button
            >
          </div>
        </f7-row>
      </div>

      <div class="col-15"></div>
    </f7-row>
    <f7-popover container-el="#demo-button" class="popover-menu">
      <f7-list>
        <f7-list-item
          popover-close
          external
          link="https://hamburgueseria.alacarta.online"
        >
          <span>
            <i class="fas fa-beer"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Hamburguesería</span>
          </span>
        </f7-list-item>
        <f7-list-item
          popover-close
          external
          link="https://pizzeria.alacarta.online"
        >
          <span>
            <i class="fas fa-beer"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Pizzeria</span>
          </span>
        </f7-list-item>
        <f7-list-item
          popover-close
          external
          link="https://resto.alacarta.online"
        >
          <span>
            <i class="fas fa-utensils"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Resto</span>
          </span>
        </f7-list-item>
        <f7-list-item
          popover-close
          external
          link="https://sushi.alacarta.online"
        >
          <span>
            <i class="fas fa-fish"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Sushi</span>
          </span>
        </f7-list-item>
        <f7-list-item
          popover-close
          external
          link="https://heladeria.alacarta.online"
        >
          <span>
            <i class="fas fa-ice-cream"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Heladería</span>
          </span>
        </f7-list-item>
        <f7-list-item
          popover-close
          external
          link="https://cafeteria.alacarta.online"
        >
          <span>
            <i class="fas fa-coffee"></i>
            &nbsp;&nbsp;
            <span style="color: #222222">Cafetería</span>
          </span>
        </f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-block>
</template>

<script>
export default {
  methods: {
    scrollToAnchor: function (id_element) {
      document.querySelector(id_element).scrollIntoView({ behavior: "smooth" });
    },
    cartaDemoPopupOpen() {
      const self = this;
      let popover = self.$f7.popover.get(".popover-menu");
      popover.$targetEl = self.$$("#demo-button");
      popover.open();
    },
  },
};
</script>
