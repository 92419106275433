<template id="local-image-food-sheet">
  <f7-sheet
    v-if="!$root.loadingFood"
    class="local-image-food-sheet"
    :opened="props.localImageFoodSheetOpened"
    @sheet:closed="props.localImageFoodSheetOpened = false"
    backdrop
    :closeByBackdropClick="true"
    :closeByOutsideClick="true"
  >
    <f7-page-content>
      <div
        style="
          height: 0;
          overflow: hidden;
          padding-top: 100%;
          background: #fff;
          position: relative;
          width: 100%;
          border-radius: 10px;
        "
      >
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 65px 25px 5px inset;
          "
          v-bind:style="{
            'background-image':
              'linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url(\'' +
              'https://' +
              $root.serverHostBase +
              '/' +
              encodeURI(props.currentFoodImage) +
              '\')',
          }"
        ></div>
      </div>

      <f7-row style="width: 100%; position: absolute; top: 0">
        <f7-col
          width="85"
          style="padding-top: 5px; padding-left: 15px; color: #efefef"
        >
          <!-- <span width="80" class="notch"></span> -->
          <span style="font-size: 26px">{{ props.currentFoodName }}</span>
          <!-- <br />
          <span style="font-size: 16px">{{
            props.currentFoodDescription
          }}</span> -->
        </f7-col>
        <f7-col
          width="15"
          style="padding-top: 15px; display: flex; justify-content: center"
        >
          <a @click="props.localImageFoodSheetOpened = false">
            <i class="fa fa-times" style="color: #fff; font-size: 22px;"></i>
          </a>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function () {
    const self = this;
  },
  methods: {},
};
</script>
