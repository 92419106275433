<template>
  <div>
    <span v-show="false">{{ foodAndAttributesQty }}</span>
    <f7-row
      class="shop-attributes-title-section"
      style="text-transform: uppercase"
      v-if="item.cantidad_maxima > 0"
    >
      <f7-col width="70" style="text-align: left; padding-left: 20px"
        >{{ item.name_category }}
        <span
          v-if="item.obligatorio === 1"
          style="font-size: 10px; color: #919191"
          >(Obligatorio)</span
        >
      </f7-col>

      <f7-col width="30">
        <span style="font-size: 16px">{{ item.cantidad_seleccionada }}</span>
        <span style="font-size: 14px; color: #777777">
          /
          {{ item.cantidad_maxima /* * foodAndAttributesQty */ }}</span
        >
      </f7-col>
    </f7-row>
    <!-- CONTENIDO DE CATEGORIA DE ADICIONAL -->
    <div v-for="(subitem, indexLevel2) in item.data" :key="indexLevel2">
      <!-- ADICIONAL -->
      <div
        v-if="
          subitem.doctype === 'Adicional' &&
          item.cantidad_maxima > 0 &&
          subitem.habilitado === 1
        "
        class="shop-attributes-attribute-selection"
      >
        <f7-row>
          <!-- NOMBRE, DETALLE Y PRECIOS -->
          <f7-col width="65" class="shop-attributes-attribute-title">
            {{ subitem.nombre_adicional }}
            <br />
            <span v-if="parseFloat(subitem.precio) > 0"
              >$ {{ formatCurrency(parseFloat(subitem.precio)) }}</span
            >
            <span v-else>&nbsp;</span>
          </f7-col>
          <f7-col width="35" class="shop-attributes-attribute-qty">
            <f7-stepper
              :ref="'stepper' + indexLevel1"
              v-if="subitem.cantidad_maxima !== 1 && subitem.sin_stock === 0"
              small
              round
              fill
              :buttons-only="false"
              :data-cantidad-minima="item.obligatorio ? 0 : 0"
              :data-cantidad-maxima="
                subitem.cantidad_maxima !== 0 ? subitem.cantidad_maxima : 100
              "
              :data-category-index="indexLevel1"
              :data-adicional-index="indexLevel2"
              :value="subitem.cantidad_seleccionada"
              @change="changeQtyAditionalCategory"
            ></f7-stepper>

            <f7-checkbox
              class="checkbox-adicionales"
              :ref="'checkbox' + indexLevel1"
              v-else-if="
                subitem.cantidad_maxima == 1 && subitem.sin_stock === 0
              "
              :data-category-index="indexLevel1"
              :data-adicional-index="indexLevel2"
              :checked="subitem.cantidad_seleccionada > 0"
              @change="changeQtyAditionalCategoryCheckbox"
            ></f7-checkbox>

            <span v-else-if="subitem.sin_stock === 1" style="color: #a9a9a9"
              >Sin Stock</span
            >
          </f7-col>
        </f7-row>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
    item: Object,
    indexLevel1: Number,
    id: String,
  },
  data() {
    return {
      lastFoodAndAttributesQty: 0,
    };
  },
  computed: {
    foodAndAttributesQty: function () {
      const self = this;

      let foodAndAttributesQty =
        (self.props.totalAttributes || 0) + (self.props.totalFoodQty || 0);

      if (self.lastFoodAndAttributesQty !== foodAndAttributesQty) {
        self.lastFoodAndAttributesQty = foodAndAttributesQty;
        self.cleanAdditionalsOnChangeQty();
      }

      return foodAndAttributesQty;
    },
  },
  mounted() {
    const self = this;

    if (self.lastFoodAndAttributesQty === 0) {
      self.cleanAdditionalsOnChangeQty();
    } else {
    }
  },
  methods: {
    cleanAdditionalsOnChangeQty() {
      const self = this;

      if (Object.values(self.$refs).length > 0) {
        let refsValues = Object.values(self.$refs)[0];
        let stepper = refsValues[0].f7Stepper;

        if (stepper) {
          stepper.min = 0;
          parseFloat(stepper.el.dataset.cantidadMinima); /* *
            (self.props.totalAttributes + self.props.totalFoodQty) */
          stepper.max = parseFloat(stepper.el.dataset.cantidadMaxima);

          if (stepper.value < stepper.el.dataset.cantidadMinima) {
            stepper.setValue(
              parseFloat(stepper.el.dataset.cantidadMinima),
              false
            );
            self.changeQtyAditionalCategory(
              parseFloat(stepper.el.dataset.cantidadMinima),
              stepper
            );
          } else {
            self.changeQtyAditionalCategory(stepper.value, stepper);
          }
          /* *
            (self.props.totalAttributes + self.props.totalFoodQty) */ // stepper.step = 1 /* self.props.totalAttributes + self.props.totalFoodQty */;
        } else {
          for (let i = 0; i < refsValues.length; i++) {
            let domElement = refsValues[i].$el;
            let inputElement = self.$$(domElement).find("input");

            // if (
            //   inputElement.prop("checked") === true ||
            //   inputElement[0].checked === true
            // ) {
            //   inputElement.prop("checked", false);
            //   inputElement[0].checked = false;
            //   self.changeQtyAditionalCategoryCheckbox({
            //     currentTarget: inputElement[0],
            //   });
            // }
            self.changeQtyAditionalCategoryCheckbox({
              currentTarget: inputElement[0],
            });
          }
        }
      }
    },
    changeQtyAditionalCategoryCheckbox(el) {
      /**
       *
       */
      const self = this;
      self.props.loadingAddToCartButton = true;

      if (self.$root.currentFoodCartState == "") {
        self.props.loadingAddToCartButton = false;
        return;
      }

      let currentFoodName =
        self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      let categoryIndex = parseInt(
        el.currentTarget.parentElement.dataset.categoryIndex
      );
      let adicionalIndex = parseInt(
        el.currentTarget.parentElement.dataset.adicionalIndex
      );
      let lsFoodCart = JSON.parse(
        window.localStorage.getItem(self.$root.currentFoodCartState)
      );
      let lsFoodCurrentFood = lsFoodCart[currentFoodName];
      let lsFoodCategoriaAdicional =
        lsFoodCurrentFood.adicionales[categoryIndex];
      let lsFoodAdicional = lsFoodCategoriaAdicional.data[adicionalIndex];
      let totalAdicionalesDeCategoria = 0;
      self.props.totalAdicionales = 0;
      let valueCheckbox = el.currentTarget.checked === true ? 1 : 0;

      lsFoodCategoriaAdicional.data.forEach((adicional, index) => {
        if (index !== adicionalIndex) {
          totalAdicionalesDeCategoria +=
            adicional.cantidad_seleccionada /* * self.foodAndAttributesQty */;
        }

        if (lsFoodCategoriaAdicional.data.length === index + 1) {
          if (
            totalAdicionalesDeCategoria +
              valueCheckbox /* * self.foodAndAttributesQty */ >
            lsFoodCategoriaAdicional.cantidad_maxima /* * self.foodAndAttributesQty */
          ) {
            // stepper.setValue(lsFoodCategoriaAdicional.cantidad_maxima);
            // stepper.setValue(stepper.value - valueCheckbox);
            self.$$(el.currentTarget).prop("checked", false);
            el.currentTarget.checked === false;
            self.props.loadingAddToCartButton = false;
          } else {
            lsFoodAdicional.cantidad_seleccionada =
              valueCheckbox /* * self.foodAndAttributesQty */;
            // if ("precio" in adicional && adicional.precio > 0) {
            //   adicional.total =
            //     adicional.cantidad_seleccionada * parseFloat(adicional.precio);
            // }

            lsFoodCategoriaAdicional.cantidad_seleccionada =
              totalAdicionalesDeCategoria +
              valueCheckbox /* * self.foodAndAttributesQty */;
            self.props.listAditionalsSelected = lsFoodCurrentFood.adicionales;

            for (let i = 0; i < self.props.listAditionalsSelected.length; i++) {
              self.props.totalAdicionales +=
                self.props.listAditionalsSelected[i].cantidad_seleccionada;
              /* * self.foodAndAttributesQty; */

              for (
                let x = 0;
                x < self.props.listAditionalsSelected[i].data.length;
                x++
              ) {
                if (
                  "precio" in self.props.listAditionalsSelected[i].data[x] &&
                  self.props.listAditionalsSelected[i].data[x].precio > 0
                ) {
                  self.props.listAditionalsSelected[i].data[x].total =
                    self.props.listAditionalsSelected[i].data[x]
                      .cantidad_seleccionada *
                    parseFloat(
                      self.props.listAditionalsSelected[i].data[x].precio
                    ) *
                    self.foodAndAttributesQty;
                }
              }
            }

            window.localStorage.setItem(
              self.$root.currentFoodCartState,
              JSON.stringify(lsFoodCart)
            );

            setTimeout(() => {
              utils.checkTotals(self);
              self.props.loadingAddToCartButton = false;
            }, 500);
          }
        }
      });
    },
    changeQtyAditionalCategory(value, stepper) {
      /**
       *
       */
      const self = this;
      self.props.loadingAddToCartButton = true;

      if (
        !self.$root.docCurrentFood.name_rand &&
        !self.$root.docCurrentFood.name
      ) {
        self.props.loadingAddToCartButton = false;
        return;
      }

      if (self.$root.currentFoodCartState == "") {
        self.props.loadingAddToCartButton = false;
        return;
      }

      let currentFoodName =
        self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      let categoryIndex = parseInt(stepper.el.dataset.categoryIndex);
      let adicionalIndex = parseInt(stepper.el.dataset.adicionalIndex);
      let lsFoodCart = JSON.parse(
        window.localStorage.getItem(self.$root.currentFoodCartState)
      );
      let lsFoodCurrentFood = lsFoodCart[currentFoodName];
      let lsFoodCategoriaAdicional =
        lsFoodCurrentFood.adicionales[categoryIndex];
      let lsFoodAdicional = lsFoodCategoriaAdicional.data[adicionalIndex];
      let totalAdicionalesDeCategoria = 0;
      self.props.totalAdicionales = 0;

      // Recorre todos los adicionales
      lsFoodCategoriaAdicional.data.forEach((adicional, index) => {
        // Comprueba los otros adicionales, diferentes al actual
        if (index !== adicionalIndex) {
          totalAdicionalesDeCategoria +=
            adicional.cantidad_seleccionada /* * foodAndAttributesQty */;
        }

        if (lsFoodCategoriaAdicional.data.length === index + 1) {
          // Comprueba si la cantidad del stepper actual es mayor a la cantidad máxima de la categoria
          if (
            totalAdicionalesDeCategoria + stepper.value >
            lsFoodCategoriaAdicional.cantidad_maxima /* * self.foodAndAttributesQty */
          ) {
            // stepper.setValue(lsFoodCategoriaAdicional.cantidad_maxima);
            stepper.setValue(
              stepper.value - 1 /* * self.foodAndAttributesQty */
            );
            self.props.loadingAddToCartButton = false;
          } else {
            lsFoodAdicional.cantidad_seleccionada = stepper.value;
            if ("precio" in adicional && adicional.precio > 0) {
              adicional.total =
                adicional.cantidad_seleccionada *
                /* * self.foodAndAttributesQty */ parseFloat(adicional.precio);
            }

            lsFoodCategoriaAdicional.cantidad_seleccionada =
              totalAdicionalesDeCategoria + stepper.value;
            self.props.listAditionalsSelected = lsFoodCurrentFood.adicionales;

            for (let i = 0; i < self.props.listAditionalsSelected.length; i++) {
              self.props.totalAdicionales +=
                self.props.listAditionalsSelected[i].cantidad_seleccionada *
                self.foodAndAttributesQty;

              for (
                let x = 0;
                x < self.props.listAditionalsSelected[i].data.length;
                x++
              ) {
                if (
                  "precio" in self.props.listAditionalsSelected[i].data[x] &&
                  self.props.listAditionalsSelected[i].data[x].precio > 0
                ) {
                  self.props.listAditionalsSelected[i].data[x].total =
                    self.props.listAditionalsSelected[i].data[x]
                      .cantidad_seleccionada *
                    parseFloat(
                      self.props.listAditionalsSelected[i].data[x].precio
                    ) *
                    self.foodAndAttributesQty;
                }
              }
            }

            window.localStorage.setItem(
              self.$root.currentFoodCartState,
              JSON.stringify(lsFoodCart)
            );

            setTimeout(() => {
              utils.checkTotals(self);
              self.props.loadingAddToCartButton = false;
            }, 500);
          }
        }
      });
    },
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
