<template id="cart-checkou-popup">
  <f7-sheet
    class="local-banner-sheet"
    backdrop
    :swipeToClose="true"
    :closeByBackdropClick="true"
    :closeByOutsideClick="true"
    :opened="props.localPopupLocalCerradoOpened"
    @sheet:opened="onLocalPopupLocalCerradoOpened"
    @sheet:closed="props.localPopupLocalCerradoOpened = false"
  >
    <span
      class="close-cart-shipping-map-sheet-container"
      @click="
        () => {
          props.localPopupLocalCerradoOpened = false;
          $root.rememberPopupLocalCerradoClosed = true;
        }
      "
      ><i
        class="fas fa-times close-cart-shipping-map-sheet sheet-close-button"
      ></i
    ></span>
    <f7-page-content class="padding-bottom" style="border-radius: 10px">
      <span>
        <div class="display-flex align-items-center flex-direction-column">
          <video
            src="/static/videos/local_cerrado.mp4"
            autoplay
            muted
            playsinline
            style="width: 65%; margin: 0 auto"
          ></video>
          <h3
            class="
              margin-bottom-half
              no-margin-top
              padding-horizontal
              text-align-center
            "
            style="font-size: 18px"
          >
            ¡Estamos Cerrados!
          </h3>
          <p
            v-if="$root.bloqueoPedidos"
            class="
              no-margin-vertical
              padding-horizontal
              text-align-center
              margin-bottom
            "
          >
            Puede navegar la carta. Consulte nuestro horario de atención.
          </p>
          <p
            v-else
            class="
              no-margin-vertical
              padding-horizontal
              text-align-center
              margin-bottom
            "
          >
            Puede navegar nuestra carta y realizar su pedido. Consulte horario de entrega.
          </p>
        </div>
      </span>
      <f7-row class="margin-horizontal">
        <f7-col @click="
        () => {
          props.localPopupLocalCerradoOpened = false;
          $root.rememberPopupLocalCerradoClosed = true;
        }
      ">
          <span>
            <span class="badge badge-hacer-pedido"> CONTINUAR </span>
          </span>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  mounted: function () {
    const self = this;
    document.querySelector("video").playbackRate = 0.5;
  },
  methods: {
    onLocalPopupLocalCerradoOpened() {
      const self = this;
      self.$root.rememberPopupLocalCerradoClosed = true;
      // setTimeout(() => {
      //   self.props.localPopupLocalCerradoOpened = false;
      //   // localStorage.setItem("ongoing_order_tracking_sheet_first_time", "true");
      // }, 5000);
    },
  },
};
</script>
