var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',{staticClass:"local-carta-plato-block"},[_c('f7-row',[_c('f7-col',{attrs:{"width":"75"}},[_c('span',{staticClass:"local-carta-nombre-plato"},[_vm._v(_vm._s(_vm.subitem.nombre_plato))]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.$root.listFoodType === 'Salon' && _vm.subitem.descripcion)?_c('i',{staticClass:"plato-descripcion"},[_c('div',{staticClass:"descripcion-plato",style:(_vm.subitem.descripcion.length > 200 ? 'height: 100px;' : ''),attrs:{"data-name":_vm.subitem.name}},[_vm._v("\n          "+_vm._s(_vm.subitem.descripcion)+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Salon' &&
          _vm.subitem.descripcion !== null &&
          _vm.subitem.descripcion.length > 200
        )?_c('div',{staticClass:"\n          toggle-descripcion-expand\n          padding-top-half\n          text-align-right\n          more-description-button-container\n        ",attrs:{"data-expand":_vm.subitem.name},on:{"click":function (e) {
            _vm.expandDescription(e, _vm.subitem.name);
          }}},[_c('i',{staticClass:"fas fa-chevron-down badge"})]):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Salon' &&
          _vm.subitem.descripcion !== null &&
          _vm.subitem.descripcion.length > 200
        )?_c('div',{staticClass:"\n          toggle-descripcion-collapse\n          padding-top-half\n          text-align-right\n          more-description-button-container\n        ",staticStyle:{"display":"none"},attrs:{"data-collapse":_vm.subitem.name},on:{"click":function (e) {
            _vm.collapseDescription(e, _vm.subitem.name);
          }}},[_c('i',{staticClass:"fas fa-chevron-up badge"})]):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Delivery' && _vm.subitem.descripcion_delivery
        )?_c('i',{staticClass:"plato-descripcion"},[_c('div',{staticClass:"descripcion-plato",style:(_vm.subitem.descripcion_delivery.length > 200 ? 'height: 100px;' : ''),attrs:{"data-name":_vm.subitem.name}},[_vm._v("\n          "+_vm._s(_vm.subitem.descripcion_delivery)+"\n        ")])]):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Delivery' &&
          _vm.subitem.descripcion_delivery !== null &&
          _vm.subitem.descripcion_delivery.length > 200
        )?_c('div',{staticClass:"\n          toggle-descripcion-expand\n          padding-top-half\n          text-align-right\n          more-description-button-container\n        ",attrs:{"data-expand":_vm.subitem.name},on:{"click":function (e) {
            _vm.expandDescription(e, _vm.subitem.name);
          }}},[_c('i',{staticClass:"fas fa-chevron-down badge"})]):_vm._e(),_vm._v(" "),(
          _vm.subitem.descripcion_delivery !== null &&
          _vm.subitem.descripcion_delivery.length > 200
        )?_c('div',{staticClass:"\n          toggle-descripcion-collapse\n          padding-top-half\n          text-align-right\n          more-description-button-container\n        ",staticStyle:{"display":"none"},attrs:{"data-collapse":_vm.subitem.name},on:{"click":function (e) {
            _vm.collapseDescription(e, _vm.subitem.name);
          }}},[_c('i',{staticClass:"fas fa-chevron-up badge"})]):_vm._e(),_vm._v(" "),(
          _vm.$root.docLocal.mostrar_precios &&
          _vm.subitem.precio > 0 &&
          _vm.$root.listFoodType === 'Salon'
        )?_c('menuListPricePrimary',{attrs:{"props":_vm.props,"subitem":_vm.subitem}}):(
          _vm.$root.docLocal.mostrar_precios &&
          _vm.subitem.precio_delivery > 0 &&
          _vm.$root.listFoodType === 'Delivery'
        )?_c('menuListPriceSecondary',{attrs:{"props":_vm.props,"subitem":_vm.subitem}}):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Salon' &&
          _vm.subitem.precio > 0 &&
          _vm.subitem.atributos_del_plato.length > 0
        )?_c('span',{staticStyle:{"color":"#777777"}},[_c('i',[_vm._v("|")])]):_vm._e(),_vm._v(" "),(
          _vm.$root.listFoodType === 'Delivery' &&
          _vm.subitem.precio_delivery > 0 &&
          _vm.subitem.atributos_del_plato_delivery.length > 0
        )?_c('span',{staticStyle:{"color":"#777777"}},[_c('i',[_vm._v("|")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.subitem.atributos_del_plato),function(atributo,indexLevelAtributo){return _c('menuAttributesPrimary',{key:atributo.name,attrs:{"props":_vm.props,"subitem":_vm.subitem,"atributo":atributo,"indexLevelAtributo":indexLevelAtributo}})}),_vm._v(" "),_vm._l((_vm.subitem.atributos_del_plato_delivery),function(atributo,indexLevelAtributoDelivery){return _c('menuAttributesSecondary',{key:atributo.name,attrs:{"props":_vm.props,"subitem":_vm.subitem,"atributo":atributo,"indexLevelAtributoDelivery":indexLevelAtributoDelivery}})}),_vm._v(" "),(_vm.$root.listFoodStore && _vm.subitem.sin_stock === 0)?_c('span',{staticStyle:{"margin-top":"5px","display":"block"},style:(_vm.subitem.descripcion !== null && _vm.subitem.descripcion.length > 60
            ? 'margin-top: 0 !important'
            : '')},[_c('span',{staticClass:"badge badge-shop-from-local",on:{"click":function($event){return _vm.shopFood(_vm.subitem)}}},[_vm._v("\n          Pedir  "),_c('i',{staticClass:"fas fa-plus",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_vm._v(" "),(_vm.$root.listFoodStore && _vm.subitem.sin_stock === 1)?_c('span',{staticStyle:{"margin-top":"5px","display":"block"},style:(_vm.subitem.descripcion !== null && _vm.subitem.descripcion.length > 60
            ? 'margin-top: 0 !important'
            : '')},[_c('span',{staticClass:"badge badge-shop-from-local badge-disabled"},[_vm._v("\n          Sin Stock\n        ")])]):_vm._e()],2),_vm._v(" "),_c('f7-col',{attrs:{"width":"25"}},[(_vm.subitem.imagen_principal)?_c('div',{staticClass:"container-image-responsive-plato-thumb",style:({ 'padding-top': '100%' }),on:{"click":function($event){return _vm.openFoodImage(
            _vm.subitem.nombre_plato,
            _vm.subitem.descripcion,
            _vm.subitem.imagen_principal
          )}}},[_c('img',{staticClass:"image-responsive-plato-thumb lazy lazy-fade-in",attrs:{"data-src":'' +
            'https://' +
            _vm.$root.serverHostBase +
            encodeURI(_vm.subitem.imagen_principal),"alt":"","width":"100%"}})]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }