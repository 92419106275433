<template>
  <f7-sheet
    id="cart-ongoing-order-sheet"
    class=""
    backdrop
    :swipeToClose="false"
    :closeByBackdropClick="false"
    :closeByOutsideClick="false"
    :opened="$root.cartOngoingOrderSheetOpened"
    @sheet:closed="onCartOngoingOrderSheetClosed"
    @sheet:opened="onCartOngoingOrderSheetOpened"
    style="max-height: 80vh"
  >
    <span
      class="close-cart-shipping-map-sheet-container"
      @click="onCartOngoingOrderSheetClose"
      ><i
        class="fas fa-times close-cart-shipping-map-sheet sheet-close-button"
      ></i
    ></span>
    <f7-page-content
      style="border-radius: 10px 10px 0 0; max-height: 80vh; overflow: scroll"
    >
      <span @click="whatsappOrderChat"
        ><i class="fab fa-whatsapp check-order-tracking-whatsapp"></i
      ></span>
      <f7-block>
        <f7-block-title medium class="text-align-center no-margin-bottom"
          ><span style="font-weight: bold">Tu Pedido</span></f7-block-title
        >
        <p class="text-align-center no-margin-top">
          {{ $root.ongoingOrder.numero_pedido }}
        </p>
        <br />
        <f7-row
          class="margin-bottom-half"
          v-if="
            !['Anulado', 'Rechazado', 'Cierre Automático'].includes(
              $root.ongoingOrder.estado
            ) &&
              props.orderProgress.progress >= 20 &&
              props.orderProgress.progress < 100 &&
              $root.ongoingOrder.demora_pedido > 0
          "
        >
          <span
            >Entrega Aproximada
            <strong
              >{{ props.endTimeOrder }} - {{ props.endTimeOrderPlus }}</strong
            ></span
          >
        </f7-row>
        <f7-row
          v-if="
            ['Anulado', 'Rechazado', 'Cierre Automático'].includes(
              $root.ongoingOrder.estado
            )
          "
        >
          <f7-col
            ><f7-progressbar
              class="cart-progressbar-rejected"
              :progress="100"
            ></f7-progressbar
          ></f7-col>
        </f7-row>
        <f7-row v-else>
          <f7-col
            ><f7-progressbar
              :infinite="props.orderProgress.progress === 20"
              :class="
                props.orderProgress.progress >= 20
                  ? 'cart-progressbar-completed'
                  : 'cart-progressbar-uncompleted'
              "
              :progress="100"
            ></f7-progressbar
          ></f7-col>
          <f7-col
            ><f7-progressbar
              :infinite="props.orderProgress.progress === 40"
              :class="
                props.orderProgress.progress >= 40
                  ? 'cart-progressbar-completed'
                  : 'cart-progressbar-uncompleted'
              "
              :progress="100"
            ></f7-progressbar
          ></f7-col>
          <f7-col
            ><f7-progressbar
              :infinite="props.orderProgress.progress === 60"
              :class="
                props.orderProgress.progress >= 60
                  ? 'cart-progressbar-completed'
                  : 'cart-progressbar-uncompleted'
              "
              :progress="100"
            ></f7-progressbar
          ></f7-col>
          <f7-col
            ><f7-progressbar
              :infinite="props.orderProgress.progress === 80"
              :class="
                props.orderProgress.progress >= 80
                  ? 'cart-progressbar-completed'
                  : 'cart-progressbar-uncompleted'
              "
              :progress="100"
            ></f7-progressbar
          ></f7-col>
          <f7-col
            ><f7-progressbar
              :class="
                props.orderProgress.progress >= 100
                  ? 'cart-progressbar-completed'
                  : 'cart-progressbar-uncompleted'
              "
              :progress="100"
            ></f7-progressbar
          ></f7-col>
        </f7-row>
        <p>
          <strong>{{ props.orderProgress.text }}</strong>
        </p>
        <f7-block
          class="no-padding-horizontal"
          v-if="
            ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
              $root.ongoingOrder.estado
            )
          "
        >
          <cartOngoingOrderList :props="props" />
        </f7-block>
        <!-- <f7-block> -->
        <cartOngoingOrderPayment :props="props" />
        <!-- </f7-block> -->
        <f7-block class="no-padding-horizontal no-margin-vertical">
          <cartOngoingOrderTotals :props="props" />
        </f7-block>
        <f7-block
          class="no-padding-horizontal no-margin-vertical"
          v-if="
            ['Nuevo', 'Aceptado', 'En Preparación', 'Enviado'].includes(
              $root.ongoingOrder.estado
            )
          "
        >
          <f7-row
            v-if="
              ['Aceptado', 'En Preparación', 'Enviado'].includes(
                $root.ongoingOrder.estado
              )
            "
          >
            <f7-col @click="orderDeliveredByClient"
              ><span class="badge badge-hacer-pedido no-margin-top">
                <i class="fa fa-check"></i>&nbsp;&nbsp;
                <span>YA LO RECIBÍ</span>
              </span></f7-col
            >
          </f7-row>
          <f7-row v-else>
            <f7-col
              ><span
                class="badge badge-hacer-pedido badge-disabled no-margin-top"
              >
                <i class="fa fa-check"></i>&nbsp;&nbsp;
                <span>YA LO RECIBÍ</span>
              </span></f7-col
            >
          </f7-row>
        </f7-block>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import cartOngoingOrderList from "./cart-ongoing-order-list.vue";
import cartOngoingOrderTotals from "./cart-ongoing-order-totals.vue";
import cartOngoingOrderPayment from "./cart-ongoing-order-payment.vue";
import cart from "../../cart";
import utils from "../../utils";

export default {
  props: {
    props: Object,
  },
  components: {
    cartOngoingOrderList,
    cartOngoingOrderTotals,
    cartOngoingOrderPayment,
  },
  mounted() {
    const self = this;
  },
  methods: {
    async onCartOngoingOrderSheetOpened() {
      const self = this;
    },
    onCartOngoingOrderSheetClosed() {
      const self = this;
      self.$root.cartOngoingOrderSheetOpened = false;
      self.$f7.toast.close();
    },
    onCartOngoingOrderSheetClose() {
      const self = this;
      if (
        ["Entregado", "Anulado", "Rechazado", "Cierre Automático"].includes(
          self.$root.ongoingOrder.estado
        )
      ) {
        self.$root.ongoingOrder = {};
        localStorage.removeItem("pedido_en_curso");
        localStorage.removeItem("ongoing_cart");
      }

      self.$root.cartOngoingOrderSheetOpened = false;
    },
    orderDeliveredByClient() {
      const self = this;

      let orderDeliveredByClientCallback = (r) => {
        if (self.$root.setIntervalUpdateOrder !== "") {
          clearInterval(self.$root.setIntervalUpdateOrder);
        }

        self.$root.ongoingOrder = {};
        localStorage.removeItem("pedido_en_curso");
        localStorage.removeItem("ongoing_cart");

        self.$root.cartOngoingOrderSheetOpened = false;
      };

      utils.customRequest(
        self,
        orderDeliveredByClientCallback,
        "pedido.pedido.cierre_pedido_por_cliente",
        "POST",
        {
          pedido_docname: self.$root.ongoingOrder.name,
        },
        null
        // callbackErr
      );
    },
    // payWithMP() {
    //   const self = this;
    //   window.location.replace(self.$root.ongoingOrder.payment_link_mp);
    // },
    async whatsappOrderChat() {
      const self = this;

      let whatsAppNumber = await new Promise(async (resolveWhatsAppNumber) => {
        if (self.$root.docLocal.sucursales.length === 0) {
          resolveWhatsAppNumber(
            self.$root.docLocal.whatsapp.replace("+", "").replace(" ", "")
          );
        } else if (self.$root.docLocal.sucursales.length === 1) {
          resolveWhatsAppNumber(
            self.$root.docLocal.sucursales[0].whatsapp
              .replace("+", "")
              .replace(" ", "")
          );
        } else {
          await new Promise((resolveSucursal) => {
            for (let i = 0; i < self.$root.docLocal.sucursales.length; i++) {
              if (
                self.$root.docLocal.sucursales[i].nombre_sucursal ===
                self.$root.ongoingOrder.sucursal
              ) {
                resolveWhatsAppNumber(
                  self.$root.docLocal.sucursales[i].whatsapp
                );
              }
            }
          });
        }
      });

      let whatsAppOrderChatText = encodeURIComponent(
        `Hola! Quisiera consultar por el estado del pedido ${self.$root.ongoingOrder.numero_pedido}.`
      );

      window.location.replace(
        `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${whatsAppOrderChatText}`
      );
    },
  },
};
</script>
