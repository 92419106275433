<template>
  <f7-row
    v-if="
      $root.confirmedCart.length > 0 && $root.docLocal.sucursales.length > 1
    "
  >
    <f7-col>
      <div
        class="item-title item-label shop-cart-title-section"
        style="color: #222222"
      >
        SUCURSAL
      </div>

      <f7-list style="margin: 0;">
        <f7-list-input
          class="select-sucursal-input"
          type="select"
          @focus="inputOnFocus"
          @input="
            (e) => {
              props.sucursal = e.target.value;
            }
          "
        >
          <option disabled selected>Elija una Sucursal</option>
          <option
            v-for="(data_sucursal, key_sucursal, index) in $root.docLocal
              .sucursales"
            :key="index"
            :value="key_sucursal"
            >{{ data_sucursal.nombre_sucursal }}</option
          >
        </f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
</template>

<script>
import cart from "../../cart.js";

export default {
  props: {
    props: Object,
  },
  methods: {
    inputOnFocus() {
      cart.inputOnFocus(this);
    },
  },
};
</script>
