<template>
  <f7-page name="landing-page" style="background-color: #ffffff">
    <!-- COVER -->
    <landingCover />

    <!-- QUIENES SOMOS -->
    <landingAboutUs
      :count="count"
      :countOrders="countOrders"
      :desktop="desktop"
    />

    <!-- SERVICIOS -->
    <landingServices />

    <!-- CONTACTO -->
    <landingContact />

    <!-- PIE DE PAGINA -->
    <landingFooter />

    <!-- BOTON FLOTANTE WHATSAPP -->
    <a
      class="external"
      href="https://wa.me/5491130930720"
      @click="trackGtmWaBtn"
    >
      <span class="whatsapp-float-btn">
        <i class="fab fa-whatsapp" style="color: #fff"></i>
      </span>
    </a>
  </f7-page>
</template>

<script>
import utils from "../utils.js";
import landingCover from "../components/landing/landing-cover.vue";
import landingAboutUs from "../components/landing/landing-about-us.vue";
import landingServices from "../components/landing/landing-services.vue";
import landingContact from "../components/landing/landing-contact.vue";
import landingFooter from "../components/landing/landing-footer.vue";

export default {
  components: {
    landingCover,
    landingAboutUs,
    landingServices,
    landingContact,
    landingFooter,
  },
  data() {
    return {
      num: 1500,
      count: 1,
      orders: 1000000,
      countOrders: 0,
      desktop: true,
      // cartaDemoPopupOpened: false,
    };
  },
  mounted: function () {
    const self = this;
    window.addEventListener("beforeinstallprompt", (e) => {
      self.a2hsReady = true;
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      self.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (showInstallPromotion) {
        showInstallPromotion();
      }
    });

    setTimeout(function () {
      window.history.replaceState({}, document.title, "/" + "");
    }, 250);

    setTimeout(() => {
      // Video Responsive
      var players = [
        'iframe[src*="youtube-nocookie.com"]',
        'iframe[src*="vimeo.com"]',
      ];

      // Select videos
      var fitVids = document.querySelectorAll(players.join(","));

      // If there are videos on the page...
      if (fitVids.length) {
        // Loop through videos
        for (var i = 0; i < fitVids.length; i++) {
          // Get Video Information
          var fitVid = fitVids[i];
          var width = fitVid.getAttribute("width");
          var height = fitVid.getAttribute("height");
          var aspectRatio = height / width;
          var parentDiv = fitVid.parentNode;

          // Wrap it in a DIV
          var div = document.createElement("div");
          div.className = "fitVids-wrapper";
          div.style.paddingBottom = aspectRatio * 100 + "%";
          parentDiv.insertBefore(div, fitVid);
          fitVid.remove();
          div.appendChild(fitVid);

          // Clear height/width from fitVid
          fitVid.removeAttribute("height");
          fitVid.removeAttribute("width");
        }
      }
    }, 0);
    self.desktop = self.$f7.device.desktop;
  },
  watch: {
    count: {
      handler(value) {
        if (value < this.num) {
          setTimeout(() => {
            this.count++;
          }, 1.5);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    countOrders: {
      handler(value) {
        if (value < this.orders) {
          setTimeout(() => {
            this.countOrders += 1000;
          }, 1.5);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    trackGtmWaBtn() {
      dataLayer.push({ event: "click-whatsapp-btn" });
    },
  },
};
</script>
