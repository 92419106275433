<template>
  <div class="no-margin no-padding">
    <f7-row
      v-if="
        ($root.docCurrentFood &&
          $root.listFoodType === 'Salon' &&
          parseFloat($root.docCurrentFood.precio) > 0) ||
        ($root.listFoodType === 'Delivery' &&
          parseFloat($root.docCurrentFood.precio_delivery) > 0)
      "
      class="shop-attributes-title-section"
    >
      <f7-col>
        <span>CANTIDAD</span>
      </f7-col>
    </f7-row>

    <!-- SELECCION DE CANTIDAD -->
    <span
      v-if="
        ($root.docCurrentFood &&
          $root.listFoodType === 'Salon' &&
          parseFloat($root.docCurrentFood.precio) > 0) ||
        ($root.listFoodType === 'Delivery' &&
          parseFloat($root.docCurrentFood.precio_delivery) > 0)
      "
    >
      <f7-row>
        <f7-col class="shop-attributes-attribute-qty">
          <f7-stepper
            ref="stepperMain"
            small
            round
            fill
            :value="$root.docCurrentFood.cantidad"
            :buttons-only="false"
            @change="changeQtyFood"
          ></f7-stepper>
        </f7-col>
      </f7-row>
    </span>
  </div>
</template>

<script>
import utils from "../../utils.js";

export default {
  props: {
    props: Object,
  },
  mounted() {
    const self = this;
    // setTimeout(() => {
    //   self.checkIfExistsAttributes();
    // }, 250);
    // self.checkIfExistsAttributes();
  },
  methods: {
    async changeQtyFood(value, stepper) {
      const self = this;
      self.props.loadingAddToCartButton = true;

      let lsFoodCart = {};
      // self.props.totalFoodQty = stepper.value;
      // let itemName =
      //   self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
      // lsFoodCart[itemName].cantidad = stepper.value;

      await (async function () {
        lsFoodCart = JSON.parse(
          window.localStorage.getItem(self.$root.currentFoodCartState)
        );

        let itemName =
          self.$root.docCurrentFood.name_rand || self.$root.docCurrentFood.name;
        self.$root.docCurrentFood.cantidad = stepper.value;
        lsFoodCart[itemName].cantidad = stepper.value;
      })().then((r) => {
        window.localStorage.setItem(
          self.$root.currentFoodCartState,
          JSON.stringify(lsFoodCart)
        );

        setTimeout(() => {
          utils.checkTotals(self);
          self.props.loadingAddToCartButton = false;
        }, 500);
      });
    },
    async checkIfExistsAttributes() {
      const self = this;

      if (Object.values(self.$refs).length > 0) {
        let refsValues = Object.values(self.$refs)[0];
        let stepper = refsValues.f7Stepper;

        if (stepper) {
          if (
            (self.$root.listFoodType === "Salon" &&
              !self.$root.docCurrentFood.atributos_del_plato) ||
            (self.$root.listFoodType === "Salon" &&
              self.$root.docCurrentFood.atributos_del_plato.length === 0)
          ) {
            if (
              !self.$root.docCurrentFood.cantidad ||
              self.$root.docCurrentFood.cantidad < 1
            ) {
              stepper.setValue(1, false);
              stepper.min = parseFloat(1);
              self.$root.docCurrentFood.cantidad = 1;
            }
          } else if (
            (self.$root.listFoodType === "Delivery" &&
              !self.$root.docCurrentFood.atributos_del_plato_delivery) ||
            (self.$root.listFoodType === "Delivery" &&
              self.$root.docCurrentFood.atributos_del_plato_delivery.length ===
                0)
          ) {
            if (
              !self.$root.docCurrentFood.cantidad ||
              self.$root.docCurrentFood.cantidad < 1
            ) {
              stepper.setValue(1, false);
              stepper.min = parseFloat(1);
              self.$root.docCurrentFood.cantidad = 1;
            }
          }
        }
      }
    },
  },
};
</script>
