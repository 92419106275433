<template id="local-carta-selection-sheet">
  <span class="fullscreen-carta-selection-container-white">
    <div class="col fullscreen-carta-selection">
      <div class="fullscreen-carta-selection-image-container">
        <img
          :src="encodeURI($root.docLocal.vista_previa_logo_pagina_principal)"
          class="fullscreen-carta-selection-image"
        />
      </div>
      <f7-block class="fullscreen-carta-selection-title" large>
        Elija una Carta
      </f7-block>
      <br />

      <f7-row v-for="(carta, key) in $root.docLocal.cartas" :key="carta.name">
        <f7-col style="text-align: center">
          <span
            class="badge fullscreen-carta-selection-button"
            @click="checkCartaType(carta, key)"
          >
            {{ carta.nombre_carta }}
          </span>
        </f7-col>
      </f7-row>
      <br />

      <f7-row
        v-if="$root.docLocal.habilitar_traduccion_automatica"
        style="height: 35px"
      ></f7-row>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    props: Object,
  },
  data: function() {
    return {
      cambioDeCartaPrompt: undefined,
    };
  },
  mounted: function() {
    const self = this;

    if (self.$root.docLocal && self.$root.docLocal.cartas.length === 1) {
      self.checkCartaType(self.$root.docLocal.cartas[0], 0);
    } else if (self.$root.docLocal && self.$root.docLocal.cartas.length > 1) {
      self.createPromptDialog("", 0);
    }
  },
  methods: {
    checkCartaType(carta, cartaIndex) {
      const self = this;

      if (carta.tipo_de_carta === "Primaria") {
        self.selectCartaSalon(carta, cartaIndex);
      } else if (carta.tipo_de_carta === "Secundaria") {
        self.selectCartaDelivery(carta, cartaIndex);
      }
    },
    selectCartaSalon(carta, cartaIndex) {
      const self = this;
      self.createPromptDialog("Salon", cartaIndex, carta);

      let lsFoodType = window.localStorage.getItem("food_type");
      let lsFoodTypeIndex = parseInt(
        window.localStorage.getItem("food_type_index")
      );
      let confirmedCart =
        JSON.parse(window.localStorage.getItem("confirmed_cart")) || {};

      if (
        lsFoodType &&
        lsFoodTypeIndex !== cartaIndex &&
        Object.keys(confirmedCart).length > 0
      ) {
        self.cambioDeCartaPrompt.open();
      } else {
        self.$root.listFoodName = carta.nombre_carta;
        self.$root.listFoodStore = carta.habilitar_pedidos;
        self.$root.listFoodPaymentMethods = carta.metodos_de_pago;
        self.$root.listFoodShippingMethods = carta.metodos_de_envio;
        self.$root.listFoodType = "Salon";
        self.$root.listFoodTypeIndex = cartaIndex;
        window.localStorage.setItem("food_type", "Salon");
        window.localStorage.setItem("food_type_index", cartaIndex);
        self.$root.listFoodSelected = self.$root.listFoodSalon;
        self.props.SelectionMenuSheetOpened = false;
      }
      self.props.loading = false;
    },
    selectCartaDelivery(carta, cartaIndex) {
      const self = this;
      self.createPromptDialog("Delivery", cartaIndex, carta);

      let lsFoodType = window.localStorage.getItem("food_type");
      let lsFoodTypeIndex = parseInt(
        window.localStorage.getItem("food_type_index")
      );
      let confirmedCart =
        JSON.parse(window.localStorage.getItem("confirmed_cart")) || {};

      if (
        lsFoodType &&
        lsFoodTypeIndex !== cartaIndex &&
        Object.keys(confirmedCart).length > 0
      ) {
        self.cambioDeCartaPrompt.open();
      } else {
        self.$root.listFoodName = carta.nombre_carta;
        self.$root.listFoodStore = carta.habilitar_pedidos;
        self.$root.listFoodPaymentMethods = carta.metodos_de_pago;
        self.$root.listFoodShippingMethods = carta.metodos_de_envio;
        self.$root.listFoodType = "Delivery";
        self.$root.listFoodTypeIndex = cartaIndex;
        window.localStorage.setItem("food_type", "Delivery");
        window.localStorage.setItem("food_type_index", cartaIndex);
        self.$root.listFoodSelected = self.$root.listFoodDelivery;
        self.props.SelectionMenuSheetOpened = false;
      }
      self.props.loading = false;
    },
    createPromptDialog(foodType, cartaIndex, carta) {
      const self = this;

      self.cambioDeCartaPrompt = self.$f7.dialog.create({
        cssClass: "elimina-carrito-dialog",
        title: "",
        text: `Tiene un pedido sin finalizar en la carta <strong>${self.$root.listFoodName}</strong>. Si cambia de carta el pedido se eliminará<br /><br />¿Desea Continuar?`,
        buttons: [
          {
            text: "No, Volver",
            cssClass: "elimina-carrito-cancel-btn-dialog",
            onClick: function(e, dialog) {
              self.cambioDeCartaPrompt.close();
            },
          },
          {
            text: "Si, Eliminar",
            cssClass: "elimina-carrito-ok-btn-dialog",
            onClick: function(e, dialog) {
              window.localStorage.setItem("drafted_cart", JSON.stringify({}));
              window.localStorage.setItem("cart", JSON.stringify({}));
              window.localStorage.setItem("confirmed_cart", JSON.stringify({}));
              self.$root.grandTotalCart = 0.0;
              self.$root.updateCart = false;
              self.$root.confirmedCart = [];
              self.$root.draftedCart = [];

              self.$root.listFoodName = carta.nombre_carta;
              self.$root.listFoodType = foodType;
              self.$root.listFoodTypeIndex = cartaIndex;
              window.localStorage.setItem("food_type", foodType);
              window.localStorage.setItem("food_type_index", cartaIndex);
              self.$root.listFoodSelected = self.$root.listFoodSalon;
              self.props.SelectionMenuSheetOpened = false;
            },
          },
        ],
        destroyOnClose: false,
      });
    },
  },
};
</script>
