<template>
  <span class="local-carta-atributos-plato">
    <!-- CONTENEDOR  -->
    <span
      class="badge local-carta-atributos-plato-badge"
      v-if="$root.listFoodType === 'Delivery' && atributo.habilitado_delivery === 1 && atributo.sin_stock_delivery === 0"
    >
      <!-- NOMBRE DE ATRIBUTO -->
      <span style="color: #222222">{{ atributo.nombre_atributo_delivery }}</span
      >&nbsp;
      <!-- PRECIO DELIVERY -->
      <!-- PRECIO DE ATRIBUTO | NORMAL | DELIVERY -->
      <span
        :class="
          atributo.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio_delivery > 0 &&
            $root.listFoodType == 'Delivery'
        "
      >
        <!-- <span class="space-before-precio">&nbsp;</span> -->
        $
        {{ formatCurrency(parseFloat(atributo.precio_delivery)) || "" }}
      </span>
      <span class="space-before-precio">&nbsp;</span>
      <!-- PRECIO DE ATRIBUTO | NORMAL | CON DECIMAL | DELIVERY -->
      <!-- <span
        :class="
          atributo.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio_delivery > 0 &&
            parseFloat(atributo.precio_delivery) % 1 !== 0 &&
            $root.listFoodType == 'Delivery'
        "
      >
        <span class="space-before-precio">&nbsp;</span>$
        {{ parseFloat(atributo.precio_delivery).toFixed(2) || ""
        }}<span class="space-before-precio">&nbsp;</span>
      </span> -->
      <!-- PRECIO DE ATRIBUTO | NORMAL | SIN DECIMAL | DELIVERY -->
      <!-- <span
        :class="
          atributo.precio_rebajado_delivery > 0
            ? 'precio-normal-rebajado'
            : undefined
        "
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio_delivery > 0 &&
            parseFloat(atributo.precio_delivery) % 1 === 0 &&
            $root.listFoodType == 'Delivery'
        "
      >
        <span class="space-before-precio">&nbsp;</span>$
        {{ parseFloat(atributo.precio_delivery).toFixed(0) || ""
        }}<span class="space-before-precio">&nbsp;</span>
      </span> -->
      <!-- PRECIO DE ATRIBUTO | REBAJADO | DELIVERY -->
      <span
        class="precio-rebajado"
        v-if="
          $root.docLocal.mostrar_precios &&
            atributo.precio_rebajado_delivery > 0 &&
            $root.listFoodType === 'Delivery'
        "
      >
        <!-- &nbsp; -->
        $
        {{
          formatCurrency(parseFloat(atributo.precio_rebajado_delivery)) || ""
        }}&nbsp;
      </span>
      <!-- PRECIO DE ATRIBUTO | REBAJADO | CON DECIMAL | DELIVERY -->
      <!-- <span
      class="precio-rebajado"
      v-if="
        $root.docLocal.mostrar_precios &&
          atributo.precio_rebajado_delivery > 0 &&
          parseFloat(atributo.precio_rebajado_delivery) % 1 !== 0 &&
          $root.listFoodType === 'Delivery'
      "
    >
      &nbsp;$
      {{ parseFloat(atributo.precio_rebajado_delivery).toFixed(2) || "" }}&nbsp;
    </span> -->
      <!-- PRECIO DE ATRIBUTO | REBAJADO | SIN DECIMAL | DELIVERY -->
      <!-- <span
      class="precio-rebajado"
      v-else-if="
        $root.docLocal.mostrar_precios &&
          atributo.precio_rebajado_delivery > 0 &&
          parseFloat(atributo.precio_rebajado_delivery) % 1 === 0 &&
          $root.listFoodType === 'Delivery'
      "
    >
      &nbsp;$
      {{ parseFloat(atributo.precio_rebajado_delivery).toFixed(0) || "" }}&nbsp;
    </span> -->
      <!-- SEPARADOR DE ATRIBUTOS (OMITE EL PIPE DEL FINAL) -->
      <i
        v-if="
          $root.listFoodType === 'Delivery' &&
            indexLevelAtributoDelivery <
              subitem.atributos_del_plato_delivery.length - 1
        "
        style="color: #777777"
      >
        |&nbsp;
      </i>
    </span>
  </span>
</template>

<script>
import utils from "../../../utils.js";

export default {
  props: {
    props: Object,
    subitem: Object,
    atributo: Object,
    indexLevelAtributoDelivery: Number,
  },
  methods: {
    formatCurrency(value) {
      return utils.formatCurrency(value);
    },
  },
};
</script>
