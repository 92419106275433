<template>
  <f7-block class="local-carta-subcategoria-block">
    <f7-row>
      <f7-col class="title-subcategory-container">
        <span class="local-carta-subcategoria-text">{{
          subitem.name_category
        }}</span>
        <span style="font-size: 12px; font-weight: 400;">{{
          subitem.descripcion
        }}</span>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import local from "../../../local.js";

export default {
  props: {
    props: Object,
    subitem: Object,
  },
};
</script>
