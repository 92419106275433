import utils from "./utils.js";
const { utcToZonedTime } = require("date-fns-tz");
const { isBefore, isAfter, parseISO } = require("date-fns");
const { es } = require("date-fns/locale");

const loadLocals = (self) => {
  /**
   * Trae todos los datos de los Locales para la Ciudad elegida.
   * @param {object} self - Instancia de la pantalla de Home.
   */

  // self.$root.ls.setItem("offlinePoints", JSON.stringify(e));

  var loadLocalsCallback = function(e) {
    if (e.message.data.length === 0) {
      self.$root.locals = e.message.data;
      window.location.replace(
        encodeURI(`https://${self.$root.domainHostName}/landing/`)
      );
      self.props.loading = false;
    } else {
      let locals = e.message.data;
      self.$root.locals = e.message.data;
      locals = locals.sort(() => Math.random() - 0.5);
      let virtualListLocals = self.$f7.virtualList.get(".virtual-list-locals");
      virtualListLocals.replaceAllItems(locals);
      self.props.loading = false;
    }

    // setTimeout(function() {
    //   self.$f7.lazy.create(".home-page");
    //   self.Dom7("div.lazy").trigger("lazy");
    // }, 150);
  };

  // if (JSON.parse(self.$root.ls.getItem("online")) === false) {
  //   refresh(self);
  // } else {
  utils.customRequest(
    self,
    loadLocalsCallback,
    "local.local.get_locals",
    "POST",
    {
      ciudad: self.$root.ciudad,
    },
    ""
  );
  // }
};

const loadLocalsAndCity = (self) => {
  /**
   * Trae todos los datos de los Locales para la Ciudad elegida.
   * @param {object} self - Instancia de la pantalla de Home.
   */

  // self.$root.ls.setItem("offlinePoints", JSON.stringify(e));

  var loadLocalsCallback = function(e) {
    if (e.message.data.length === 0) {
      self.$root.locals = e.message.data;
      window.location.replace(
        encodeURI(`https://${self.$root.domainHostName}/landing/`)
      );
      self.props.loading = false;
    } else {
      let locals = e.message.data;
      self.$root.locals = e.message.data;
      locals = locals.sort(() => Math.random() - 0.5);
      let virtualListLocals = self.$f7.virtualList.get(".virtual-list-locals");
      virtualListLocals.replaceAllItems(locals);

      loadCity(self);
    }

    // setTimeout(function() {
    //   self.$f7.lazy.create(".home-page");
    //   self.Dom7("div.lazy").trigger("lazy");
    // }, 150);
  };

  // if (JSON.parse(self.$root.ls.getItem("online")) === false) {
  //   refresh(self);
  // } else {
  utils.customRequest(
    self,
    loadLocalsCallback,
    "local.local.get_locals",
    "POST",
    {
      ciudad: self.$root.ciudad,
    },
    ""
  );
  // }
};

const loadCity = (self) => {
  /**
   * Trae todos los datos de la Ciudad elegida.
   * @param {object} self - Instancia de la pantalla de Home.
   */

  var loadCitysCallback = function(e) {
    self.docCiudad = e.message.data;
    self.currentBannerImage = self.docCiudad.imagen_banner;
    self.currentBannerLink = self.docCiudad.enlace_banner;
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcNow = utcToZonedTime(new Date(), timeZone);

    if (
      isAfter(utcNow, parseISO(self.docCiudad.mostrar_desde)) &&
      isBefore(utcNow, parseISO(self.docCiudad.mostrar_hasta))
    ) {
      if (self.docCiudad.habilitar_banner === 1) {
        self.props.BannerSheetCityOpened = true;
      }
    }

    self.props.loading = false;
    self.$root.changeMap = false;
  };

  // if (JSON.parse(self.$root.ls.getItem("online")) === false) {
  //   refresh(self);
  // } else {
  utils.customRequest(
    self,
    loadCitysCallback,
    "ciudad.ciudad.get_city_by_docname",
    "POST",
    {
      docname: self.$root.ciudad,
    },
    ""
  );
  // }
};

const renderExternal = (self, vl, vlData) => {
  self.vlData = vlData;
};

const searchAll = (self, query, items) => {
  /**
   * Realiza una busqueda a partir de la barra de busqueda.
   * @param {object} self - Instancia de la pantalla de Home.
   * @param {object} query - La cadena a buscar.
   * @param {object} items - Los items en los cuales buscar.
   */

  const found = [];

  for (let i = 0; i < items.length; i += 1) {
    if (
      items[i].nombre_local.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      query.trim() === ""
    ) {
      if (!found.includes(i)) {
        found.push(i);
      }
    }

    for (let x = 0; x < items[i].categorias.length; x += 1) {
      if (
        items[i].categorias[x].categoria_local
          .toLowerCase()
          .indexOf(query.toLowerCase()) >= 0 ||
        query.trim() === ""
      ) {
        if (!found.includes(i)) {
          found.push(i);
        }
      }
    }

    // for (let y = 0; y < items[i].categorias.length; y += 1) {
    //   if (items[i].etiquetas[x].etiqueta_local.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
    // }
  }
  return found; // return array with mathced indexes
};

const nativeShare = (self) => {
  /**
   * Comparte usando la funcion nativa o a traves de una Sheet (WhatsApp y Facebook).
   * @param {object} self - Instancia de la pantalla de Home.
   */

  const ciudad = self.$root.ciudad;
  const subdominioCiudad = self.$root.subdominioCiudad;
  const ciudadCap =
    self.$root.ciudad.charAt(0).toUpperCase() + self.$root.ciudad.slice(1);

  if (navigator.share) {
    navigator
      .share({
        title: `Alacarta ${ciudadCap}`,
        text: `Mira toda la gastronomía de ${ciudadCap} 👉`,
        url: `https://${subdominioCiudad}.alacarta.online/`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    self.props.shareSheetOpened = true;
  }
};

const installApp = (self, e) => {
  /**
   * Uso del A2HS con la opción de diferirlo.
   */
  var loadLocalsDialog = self.$f7.dialog.progress(
    "Instalando Alacarta Online",
    undefined,
    "#444444"
  );

  // Oculta la promocion provista por la App
  // hideMyInstallPromotion();

  // Muestra el prompt de forma diferida
  self.deferredPrompt.prompt();

  // Espera a que el usuario responda el prompt
  self.deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      setTimeout(function() {
        loadLocalsDialog.close();
      }, 3000);
      // self.$f7.dialog.alert('User accepted the install prompt');
    } else {
      loadLocalsDialog.close();
      // self.$f7.dialog.alert('User dismissed the install prompt');
    }
  });
};

const getAppTypes = (parsedUrl, self) => {
  /**
   * Uso del A2HS con la opción de diferirlo.
   * @param {object} self - Instancia de la pantalla de Home.
   * @param {string} parsedUrl - La URL a la que ingresa el usuario inicialmente.
   */

  // getVersion(self);

  if (
    window.location.href === `https://${self.$root.domainHostName}/landing/` ||
    window.location.href === `https://${self.$root.domainHostName}/landing`
  ) {
  } else if (window.location.href === `https://${self.$root.domainHostName}/`) {
    self.$f7.views.main.router.navigate("/landing/");
  } else {
    parsedUrl = window.location.hostname.split(".");

    const getAppTypesCallback = function(e) {
      getAppTypesCallbackRefactorized(self, e.message);

      // let docLocal = undefined;
      // let subdominio = undefined;
      // let docName = undefined;
      // let docImage = undefined;
      // let centerLat = undefined;
      // let centerLng = undefined;
      // let habilitado = undefined;
      // // let docCiudad = undefined;

      // if (e.message.data.length === 0) {
      //   self.$f7.views.main.router.navigate("/landing/");
      // }

      // e.message.data.forEach(function(item, i) {
      //   if (item.subdominio === parsedUrl[0]) {
      //     self.$root.appType = item.type;
      //     docLocal = item;
      //     subdominio = item.subdominio;
      //     docName = item.name;
      //     docImage = item.image;
      //     centerLat = item.latitud;
      //     centerLng = item.longitud;
      //     habilitado = item.habilitado;
      //   }

      //   if (e.message.data.length === i + 1) {
      //     if (!habilitado) {
      //       window.location.replace(
      //         encodeURI(`https://${self.$root.domainHostName}/landing/`)
      //       );
      //     }
      //     if (self.$root.appType === "Ciudad") {
      //       // self.$root.ciudad = parsedUrl[0].charAt(0).toUpperCase() + parsedUrl[0].slice(1);
      //       self.$root.ciudad = docName;
      //       self.$root.subdominioCiudad = subdominio;
      //       self.$root.icono_ciudad = docImage;
      //       self.$root.centerLat = centerLat;
      //       self.$root.centerLng = centerLng;

      //       // const subdomain = window.location.hostname.split(".")[0];
      //       const manifest = document.getElementById("manifest");
      //       const nextManifest = document.createElement("link");
      //       nextManifest.id = "manifest";
      //       nextManifest.rel = "manifest";
      //       nextManifest.href = `manifests/${subdominio}_manifest.json`;
      //       manifest.parentNode.replaceChild(nextManifest, manifest);

      //       const mobileAppleTitle = document.createElement("meta");
      //       mobileAppleTitle.name = "apple-mobile-web-app-title";
      //       mobileAppleTitle.content = subdominio;

      //       self.$f7.views.main.router.navigate("/");
      //       loadLocalsAndCity(self);
      //     } else if (self.$root.appType === "Local") {
      //       // self.$root.local = parsedUrl[0].charAt(0).toUpperCase() + parsedUrl[0].slice(1);
      //       self.$root.entryPoint = "local";
      //       self.$root.docLocal = docLocal;
      //       self.$root.ciudad = docLocal.ciudad;
      //       self.$root.local = docName;
      //       self.$root.centerLat = centerLat;
      //       self.$root.centerLng = centerLng;
      //       self.props.loading = false;

      //       self.$f7.views.main.router.navigate("/local/" + docName + "/", {
      //         reloadCurrent: true,
      //       });
      //     } else {
      //       // self.props.loading = false;
      //       self.$f7.views.main.router.navigate("/landing/");
      //     }
      //   }
      // });
    };

    // if (JSON.parse(self.$root.ls.getItem('online')) === false) {
    //   refresh(self);
    // } else {
    // utils.customRequest(
    //   self,
    //   getAppTypesCallback,
    //   "utils.get_app_types",
    //   "POST",
    //   {},
    //   ""
    // );

    utils.customRequest(
      self,
      getAppTypesCallback,
      "utils.get_app_types_refactorized",
      "POST",
      {
        parsed_url: parsedUrl,
      },
      ""
    );
    // }
  }
};

let getAppTypesCallbackRefactorized = (self, resp) => {
  if (resp.status_code === 404) {
    self.$f7.views.main.router.navigate("/landing/");
  } else if (resp.status_code === 200) {
    if (resp.data.doctype === "Ciudad") {
      self.$root.entryPoint = "ciudad";
      self.$root.appType = "Ciudad";
      self.$root.ciudad = resp.data.name;
      self.$root.subdominioCiudad = resp.data.subdominio;
      self.$root.icono_ciudad = resp.data.icono_ciudad;
      self.$root.centerLat = resp.data.latitud;
      self.$root.centerLng = resp.data.longitud;

      // const manifest = document.getElementById("manifest");
      // const nextManifest = document.createElement("link");
      // nextManifest.id = "manifest";
      // nextManifest.rel = "manifest";
      // nextManifest.href = `manifests/${resp.data.subdominio}_manifest.json`;
      // manifest.parentNode.replaceChild(nextManifest, manifest);

      // const mobileAppleTitle = document.createElement("meta");
      // mobileAppleTitle.name = "apple-mobile-web-app-title";
      // mobileAppleTitle.content = resp.data.subdominio;

      // self.$f7.views.main.router.navigate("/");
      loadLocalsAndCity(self);
    } else if (resp.data.doctype === "Local") {
      // self.$root.local = parsedUrl[0].charAt(0).toUpperCase() + parsedUrl[0].slice(1);
      self.$root.entryPoint = "local";
      self.$root.appType = "Local";
      self.$root.docLocal = resp.data;
      self.$root.ciudad = resp.data.ciudad;
      self.$root.local = resp.data.name;
      self.$root.centerLat = resp.data.latitud;
      self.$root.centerLng = resp.data.longitud;
      self.props.loading = false;

      setTimeout(() => {
        if (self.$root.docLocal.habilitar_traduccion_automatica) {
          document.querySelectorAll(".page-current").forEach((element) => {
            element.style.paddingBottom = "35px";
          });
        }
      }, 1000);

      self.$f7.views.main.router.navigate("/local/" + resp.data.name + "/", {
        reloadCurrent: true,
      });
    }
  }
};

let getConfigGeneral = function(self) {
  const getConfigGeneralCallback = function(e) {
    self.$root.maintenanceMode =
      e.message.data.modo_mantenimiento === 1 ? true : false;
  };

  const getConfigGeneralError = function(e) {
    self.$root.maintenanceMode = true;
  };

  utils.customRequest(
    self,
    getConfigGeneralCallback,
    "configuracion_general.configuracion_general.get_config_general",
    "POST",
    {},
    "",
    getConfigGeneralError
  );
};

let getVersion = function(self) {
  const getVersionCallback = function(e) {
    if (
      // No aplica a la Landing Page
      localStorage.getItem("version") !== e.message.version_actual &&
      window.location.href !== `https://${self.$root.domainHostName}/`
    ) {
      self.$f7.toast
        .create({
          title: "Nueva Versión",
          cssClass: "update-version-toast",
          text: "Nueva Versión de Alacarta.Online!",
          closeButton: true,
          closeButtonText: "Ingresar",
          on: {
            closeButtonClick: () => {
              self.$f7.dialog.progress("Ingresando");

              navigator.serviceWorker.getRegistration().then(function(reg) {
                if (reg) {
                  localStorage.setItem("version", e.message.version_actual);
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);
                } else {
                  localStorage.setItem("version", e.message.version_actual);
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);
                }
              });
            },
          },
        })
        .open();

      self.$$(".main-view").addClass("disable-newer-version");
      self.$$(".framework7-root").addClass("disable-newer-version-bgnd");
    }
  };

  utils.customRequest(
    self,
    getVersionCallback,
    "utils.get_version",
    "POST",
    {},
    ""
    // getConfigGeneralError
  );
};

// const refresh = (self, event) => {
//   /**
//    * Trae todos los datos de la Ciudad elegida.
//    * @param {object} self - Instancia de la pantalla de Home.
//    * @param {object} event - El evento que trigerea el refresco.
//    */

//   if (JSON.parse(self.$root.ls.getItem("online")) === false) {
//     // var data = JSON.parse(self.$root.ls.getItem("offlinePoints"));
//     self.data = data.message.data;
//     let virtualListLocals = self.$f7.virtualList.get(".virtual-list-locals");
//     virtualListLocals.replaceAllItems(data.message.data);

//     var ptr = self.$$(".ptr-content")[1];
//     self.$f7.ptr.done(ptr);
//   } else {
//     self.$f7router.refreshPage();
//   }
// };

export default {
  loadLocals,
  loadLocalsAndCity,
  loadCity,
  renderExternal,
  searchAll,
  nativeShare,
  installApp,
  getAppTypes,
  getConfigGeneral,
  // refresh,
};
