import utils from "./utils.js";
import local from "./local.js";
import * as eva from "eva-icons/eva.min.js";
const { zonedTimeToUtc, utcToZonedTime, format } = require("date-fns-tz");
const {
  isBefore,
  isAfter,
  parse,
  parseISO,
  isEqual,
  sub,
  add,
  getHours,
} = require("date-fns");
const { es } = require("date-fns/locale");
import Swiper from "framework7/components/swiper/swiper.js";

var getLocalByDocname = function(self, docname) {
  var getLocalByDocnameCallback = function(e) {
    if (e.message.success === false) {
      window.location.replace(`https://${subdomain}.alacarta.online`);
    } else {
      self.props.docLocal = e.message.data;
      self.$root.centerLat = e.message.data.latitud;
      self.$root.centerLng = e.message.data.longitud;
      self.props.openingHours = self.props.docLocal.horarios_de_local;
      self.$root.showOpeningHours = self.props.docLocal.mostrar_horarios;
      self.props.currentBannerImage = self.props.docLocal.vista_previa_banner;
      self.props.currentBannerLink = self.props.docLocal.enlace_banner;
      self.$root.ciudad = self.props.docLocal.ciudad_vinculo;
      self.$root.local = self.props.docLocal.nombre_local;
      self.$root.docLocal = self.props.docLocal;
      self.$root.docLocal.sucursales = e.message.sucursales;
      self.$root.docLocal.cartas = e.message.cartas;

      getCoupons(self);
      checkIsLocalIsOpen(self);
      checkBannerLocal(self);
      formatOpeningHours(self);

      const swiperEl = document.querySelector(".local-cover-swiper");

      if (swiperEl) {
        const swiper = swiperEl.swiper;

        if (self.props.docLocal.imagenes_de_slider.length > 1) {
          setTimeout(() => {
            swiper.params.speed = 2000;
            // swiper.params.loop = true;
            // swiper.params.loopedSlides = 1;
            swiper.params.autoplay.delay = 3000;
            swiper.params.autoplay.disableOnInteraction = false;
            swiper.params.autoplay.stopOnLastSlide = false;
            swiper.autoplay.start();
          }, 1000);
        } else {
          const swiper = document.querySelector(".local-cover-swiper").swiper;
        }
      }

      createPromptDialogLocal(self);

      // Show Google Translate Widget
      if (self.$root.docLocal.habilitar_traduccion_automatica === 1) {
        self.$$(".topbar-google-translate").css("display", "flex");
        self.$$(".framework7-root").css("padding-bottom", "56px");
      }

      let foodTypeLS = window.localStorage.getItem("food_type");
      let foodTypeIndexLS = window.localStorage.getItem("food_type_index");
      let cartaMatched = false;
      let cartaMapping = {
        Salon: "Primaria",
        Delivery: "Secundaria",
      };

      if (foodTypeIndexLS && foodTypeLS === "Salon") {
        // El usuario ya tiene la versión nueva con el seteo del indice de carta
        self.$root.listFoodType = "Salon";

        setMenuParameters(
          self,
          parseInt(foodTypeIndexLS),
          "Primaria",
          cartaMatched
        );
      } else if (foodTypeIndexLS && foodTypeLS === "Delivery") {
        // El usuario ya tiene la versión nueva con el seteo del indice de carta
        self.$root.listFoodType = "Delivery";

        setMenuParameters(
          self,
          parseInt(foodTypeIndexLS),
          "Secundaria",
          cartaMatched
        );
      } else {
        // El usuario no tiene la versión nueva con el seteo del indice de carta
        for (let i = 0; i < self.$root.docLocal.cartas.length; i++) {
          if (
            self.$root.docLocal.cartas[i].tipo_de_carta ==
            cartaMapping[foodTypeLS]
          ) {
            window.localStorage.setItem("food_type_index", i);
            foodTypeIndexLS = i;
          }

          if (self.$root.docLocal.cartas.length === i + 1) {
            if (!foodTypeIndexLS) {
              window.localStorage.removeItem("food_type");
              window.localStorage.removeItem("food_type_index");
              self.props.SelectionMenuSheetOpened = true;
            } else if (foodTypeIndexLS && foodTypeLS === "Salon") {
              self.$root.listFoodType = "Salon";

              setMenuParameters(
                self,
                parseInt(foodTypeIndexLS),
                "Primaria",
                cartaMatched
              );

              // for (let i = 0; i < self.$root.docLocal.cartas.length; i++) {
              //   setMenuParameters(self, i, "Primaria");
              // }
            } else if (foodTypeLS === "Delivery") {
              self.$root.listFoodType = "Delivery";

              setMenuParameters(
                self,
                parseInt(foodTypeIndexLS),
                "Secundaria",
                cartaMatched
              );

              // for (let i = 0; i < self.$root.docLocal.cartas.length; i++) {
              //   setMenuParameters(self, i, "Secundaria", cartaMatched);
              // }
            }
          }
        }
      }
    }
  };
  utils.customRequest(
    self,
    getLocalByDocnameCallback,
    "local.local.get_local_by_docname",
    "POST",
    {
      docname: docname,
    },
    ""
  );
  // }
};

const getOngoingOrders = async (self) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("pedido_en_curso")) {
      let getOngoingOrdersCallback = (r) => {
        if (r.message.success) {
          self.$root.ongoingOrder = r.message.data;
          resolve(true);
          //   if (
          //     ![
          //       "Entregado",
          //       "Anulado",
          //       "Rechazado",
          //       "Cierre Automático",
          //     ].includes(r.message.data.estado)
          //   ) {
          //     resolve(true);
          //   } else {
          //     if (self.$root.cartOngoingOrderSheetOpened) {
          //       // self.props.orderProgress =
          //       //   self.$root.ongoingOrderStatuses[r.message.data.estado];
          //       // self.$root.ongoingOrderProgress =
          //       //   self.$root.ongoingOrderStatuses[r.message.data.estado].progress;
          //       resolve(false);
          //     } else {
          //       clearInterval(self.$root.setIntervalUpdateOrder);
          //       // self.$root.ongoingOrder = {};
          //       // localStorage.removeItem("pedido_en_curso");
          //       // localStorage.removeItem("ongoing_cart");
          //       resolve(false);
          //     }
          //   }
          // } else {
          //   clearInterval(self.$root.setIntervalUpdateOrder);
          //   self.$root.ongoingOrder = {};
          //   localStorage.removeItem("pedido_en_curso");
          //   localStorage.removeItem("ongoing_cart");
          //   resolve(false);
        } else {
          resolve(false);
        }
      };

      utils.customRequest(
        self,
        getOngoingOrdersCallback,
        "pedido.pedido.get_ongoing_order",
        "POST",
        {
          pedido_docname: localStorage.getItem("pedido_en_curso"),
        },
        ""
      );
    } else {
      resolve(false);
    }
  });
};

const setMenuParameters = async (self, i, menuType, cartaMatched) => {
  self.$root.listFoodName = self.$root.docLocal.cartas[i].nombre_carta;
  self.$root.menuEnabled = self.$root.docLocal.cartas[i].habilitado;
  self.$root.listFoodStore = self.$root.docLocal.cartas[i].habilitar_pedidos;
  self.$root.listFoodPaymentMethods =
    self.$root.docLocal.cartas[i].metodos_de_pago;
  self.$root.listCustomFields =
    self.$root.docLocal.cartas[i].campo_personalizable_por_carta;
  self.$root.listFoodShippingMethods = [];
  self.props.listFoodShippingMethodsRadioMaps = self.$root.listFoodShippingMethodsRadioMaps = [];
  self.props.listFoodShippingMethodsDelimitedMaps = self.$root.listFoodShippingMethodsDelimitedMaps = [];
  cartaMatched = true;

  self.$root.customFieldOtrosDatos = self.$root.listCustomFields.some(
    (customField) => customField.asociado_a_metodo == "Otros Datos"
  );

  let metodoDeEnvio = JSON.parse(
    JSON.stringify(self.$root.docLocal.cartas[i].metodos_de_envio)
  );

  for (let x = 0; x < metodoDeEnvio.length; x++) {
    await separateShippingMethods(self, x, metodoDeEnvio);
  }
  orderShippingMethodsRadioMaps(self);

  if (i === self.$root.docLocal.cartas.length && !cartaMatched) {
    emptyCartAndCleanMenuType(self);
  }

  return;
};

const separateShippingMethods = async (self, x, metodoDeEnvio) => {
  if (
    metodoDeEnvio[x].metodo_de_envio === "Delivery - Google Maps" &&
    metodoDeEnvio[x].tipo_zona === "Radio" &&
    metodoDeEnvio[x].habilitado === 1
  ) {
    self.$root.listFoodShippingMethodsRadioMaps.push(metodoDeEnvio[x]);
    return;
  } else if (
    metodoDeEnvio[x].metodo_de_envio === "Delivery - Google Maps" &&
    metodoDeEnvio[x].tipo_zona === "Delimitada" &&
    metodoDeEnvio[x].habilitado === 1
  ) {
    self.$root.listFoodShippingMethodsDelimitedMaps.push(metodoDeEnvio[x]);
    return;
  } else if (
    metodoDeEnvio[x].metodo_de_envio !== "Delivery - Google Maps" &&
    metodoDeEnvio[x].habilitado === 1
  ) {
    self.$root.listFoodShippingMethods.push(metodoDeEnvio[x]);
    return;
  }
};

const orderShippingMethodsRadioMaps = async (self) => {
  self.$root.listFoodShippingMethodsRadioMaps = await self.$root.listFoodShippingMethodsRadioMaps.sort(
    function(a, b) {
      return a.radio_de_envio - b.radio_de_envio;
    }
  );
  return;
};

const emptyCartAndCleanMenuType = (self) => {
  self.$root.listFoodTypeIndex = "";
  self.$root.listFoodType = "";
  // self.$root.listFoodType = "";
  // self.props.listFoodName = "";
  self.$root.listFoodName = "";
  localStorage.setItem("food_type", "");
  localStorage.setItem("food_type_index", 0);
  localStorage.setItem("cart", "{}");
  localStorage.setItem("confirmed_cart", "{}");
  localStorage.setItem("draft_cart", "{}");
  self.$root.grandTotalCart = 0.0;
  self.$root.confirmedCart = [];
  self.$root.draftedCart = [];
  self.$root.updateCart = false;
  self.props.SelectionMenuSheetOpened = true;
};

const checkIsLocalIsOpen = (self) => {
  var horariosDiaActual = [];
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  var diaActual = capitalizeFLetter(
    format(new Date(), "eeee", {
      locale: es,
    })
  );
  // var diaActual = "Miércoles";
  const utcNow = utcToZonedTime(new Date(), timeZone);
  // const utcNow = parse("03/03/2021 01:31:00", "d/M/yyyy HH:mm:ss", new Date());

  checkOpeningHours(self, horariosDiaActual, diaActual, utcNow, timeZone);
};

const checkOpeningHours = (
  self,
  horariosDiaActual,
  diaActual,
  utcNow,
  timeZone
) => {
  // Itera sobre todos los días y horarios
  self.props.openingHours.forEach((item, index_1) => {
    // Comprueba cuales de los horarios concuerdan con el dia actual
    if (item.dia.toLowerCase() === diaActual.toLowerCase()) {
      // Almacena los multiples horarios del dia actual
      horariosDiaActual.push(item);
    }

    if (self.props.openingHours.length === index_1 + 1) {
      var estadoHorarioLocal = "";
      var stopChecking = false;

      if (horariosDiaActual.length === 0) {
        // self.props.estadoHorarioLocal = "Cerrado";
        self.$root.estadoHorarioLocal = "Cerrado";
        checkBloqueoPedidos(self);
      }

      // Itera sobre los multipls horarios del dia actual
      horariosDiaActual.forEach((item, index_2) => {
        if (!stopChecking) {
          // Horario de cierre y apertura del bloque horario
          let formattedHorarioCierre = parse(
            item.horario_cierre,
            "HH:mm:ss",
            utcNow
          );
          let formattedHorarioApertura = parse(
            item.horario_apertura,
            "HH:mm:ss",
            utcNow
          );

          // Comprueba si el horario del dia anterior es posterior al horario de apertura
          var diaAnterior = format(
            sub(utcNow, {
              days: 1,
            }),
            "eeee",
            {
              locale: es,
            }
          );

          var horarioAperturaDiaAnterior;
          var horarioCierreDiaAnterior;
          let formattedHorarioAperturaDiaAnterior = undefined;
          let formattedHorarioCierreDiaAnterior = undefined;

          self.props.openingHours.forEach((item, index_3) => {
            let formattedHorarioAperturaDiaAnteriorCheck = parse(
              item.horario_apertura,
              "HH:mm:ss",
              utcNow
            );

            let formattedHorarioCierreDiaAnteriorCheck = parse(
              item.horario_cierre,
              "HH:mm:ss",
              utcNow
            );

            if (
              item.dia.toLowerCase() === diaAnterior &&
              isBefore(
                formattedHorarioCierreDiaAnteriorCheck,
                formattedHorarioAperturaDiaAnteriorCheck
              )
            ) {
              // Importante: el horario tomado como cierre del dia actual es el ULTIMO de los multiples horarios del dia anterior
              formattedHorarioAperturaDiaAnterior = formattedHorarioAperturaDiaAnteriorCheck;
              formattedHorarioCierreDiaAnterior = formattedHorarioCierreDiaAnteriorCheck;
            } else if (
              item.dia.toLowerCase() === diaAnterior &&
              isBefore(
                formattedHorarioCierreDiaAnteriorCheck,
                formattedHorarioAperturaDiaAnteriorCheck
              )
            ) {
              formattedHorarioAperturaDiaAnterior = formattedHorarioAperturaDiaAnteriorCheck;
              formattedHorarioCierreDiaAnterior = formattedHorarioCierreDiaAnteriorCheck;
            }

            if (self.props.openingHours.length === index_3 + 1) {
              // if (
              //   formattedHorarioAperturaDiaAnterior === undefined &&
              //   formattedHorarioCierreDiaAnterior === undefined
              // ) {
              //   formattedHorarioAperturaDiaAnterior = formattedHorarioAperturaDiaAnteriorCheck;
              //   formattedHorarioCierreDiaAnterior = formattedHorarioCierreDiaAnteriorCheck;
              // }
              // Comprueba si la hora de cierre del día anterior es pasadas las 00:00
              if (
                formattedHorarioAperturaDiaAnterior &&
                formattedHorarioCierreDiaAnterior &&
                isBefore(
                  formattedHorarioCierreDiaAnterior,
                  formattedHorarioAperturaDiaAnterior
                )
              ) {
                // Comprueba si la hora actual es anterior/posterior a la hora de cierre
                // del día anterior.
                if (isBefore(utcNow, formattedHorarioCierreDiaAnterior)) {
                  estadoHorarioLocal = "Abierto";
                } else if (isAfter(utcNow, formattedHorarioCierreDiaAnterior)) {
                  [estadoHorarioLocal, stopChecking] = checkNowVsHoursBlock(
                    utcNow,
                    formattedHorarioApertura,
                    formattedHorarioCierre,
                    estadoHorarioLocal,
                    stopChecking
                  );
                }
              }
              // else if (
              //   isAfter(
              //     formattedHorarioCierreDiaAnterior,
              //     formattedHorarioAperturaDiaAnterior
              //   )
              // )
              else {
                [estadoHorarioLocal, stopChecking] = checkNowVsHoursBlock(
                  utcNow,
                  formattedHorarioApertura,
                  formattedHorarioCierre,
                  estadoHorarioLocal,
                  stopChecking
                );
              }
            }
          });
        }

        if (horariosDiaActual.length === index_2 + 1) {
          if (estadoHorarioLocal === "") {
            estadoHorarioLocal = "Cerrado";
          } else {
            // self.props.estadoHorarioLocal = estadoHorarioLocal;
            self.$root.estadoHorarioLocal = estadoHorarioLocal;
            checkBloqueoPedidos(self);
          }
        }
      });
    }
  });
};

const checkNowVsHoursBlock = (
  utcNow,
  formattedHorarioApertura,
  formattedHorarioCierre,
  estadoHorarioLocal,
  stopChecking
) => {
  // El horario de cierre del dia actual es anterior al horario de apertura (Cierra al día siguiente)
  if (formattedHorarioCierre.getTime() == formattedHorarioApertura.getTime()) {
    stopChecking = true;
    estadoHorarioLocal = "Cerrado";
    return [estadoHorarioLocal, stopChecking];
  } else if (isBefore(formattedHorarioCierre, formattedHorarioApertura)) {
    if (isAfter(utcNow, formattedHorarioApertura)) {
      estadoHorarioLocal = "Abierto";
      stopChecking = true;
      return [estadoHorarioLocal, stopChecking];
    } else {
      estadoHorarioLocal = "Cerrado";
      return [estadoHorarioLocal, stopChecking];
    }
  }

  // El horario de cierre del dia actual es posterior al horario de apertura (Cierra el mismo día)
  else if (isAfter(formattedHorarioCierre, formattedHorarioApertura)) {
    if (
      isAfter(utcNow, formattedHorarioApertura) &&
      isBefore(utcNow, formattedHorarioCierre)
    ) {
      estadoHorarioLocal = "Abierto";
      stopChecking = true;
      return [estadoHorarioLocal, stopChecking];
    } else {
      estadoHorarioLocal = "Cerrado";
      return [estadoHorarioLocal, stopChecking];
    }
  }
};

const checkBannerLocal = (self) => {
  let bannerLocalesSS = window.sessionStorage.getItem("banner_locales");
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcNow = utcToZonedTime(new Date(), timeZone);

  if (
    !bannerLocalesSS ||
    bannerLocalesSS.indexOf(self.$root.docLocal.name) === -1
  ) {
    if (self.props.docLocal.habilitar_banner === 1) {
      if (
        isAfter(utcNow, parseISO(self.props.docLocal.mostrar_desde)) &&
        isBefore(utcNow, parseISO(self.props.docLocal.mostrar_hasta))
      ) {
        self.props.BannerSheetLocalOpened = true;
      } else if (
        !self.props.docLocal.mostrar_desde ||
        self.props.docLocal.mostrar_desde === "" ||
        !self.props.docLocal.mostrar_hasta ||
        self.props.docLocal.mostrar_hasta === ""
      ) {
        self.props.BannerSheetLocalOpened = true;
      }
    }
  }
};

const capitalizeFLetter = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

async function getFood(self, docname) {
  let promisefFoodSalon = getFoodSalon(self, self.$f7route.params.localId);
  let foodSalon = await promisefFoodSalon;

  let promisefFoodDelivery = getFoodDelivery(
    self,
    self.$f7route.params.localId
  );
  let foodDelivery = await promisefFoodDelivery;
}

async function getFoodSalon(self, docname) {
  var getFoodSalonCallback = function(e) {
    var listFoodSalonItems = [];

    e.message.data.list_doc_platos_salon.forEach(function(item, index) {
      if (
        item.data.length > 0 &&
        item.name_category !== "Sin Categoría" &&
        item.habilitado
      ) {
        listFoodSalonItems.push(item);
      }

      if (e.message.data.list_doc_platos_salon.length === index + 1) {
        self.$root.listFoodSalon = listFoodSalonItems;

        if (self.$root.listFoodType === "Salon") {
          self.$root.listFoodSelected = self.$root.listFoodSalon;
        }
      }
    });
  };

  // var getFoodSalonCallbackErr = function(e) {
  //   self.props.loading = false;
  //   self.props.loadingFood = false;
  //   self.$root.changeMap = false;
  // };

  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };

  fetch(`/cartas/${docname}_primaria.json`, myInit)
    .then((response) => response.json())
    .then((data) => {
      var parsedData = {
        message: {
          data: {
            list_doc_platos_salon: data,
          },
        },
      };
      getFoodSalonCallback(parsedData);
    })
    .catch((err) => {
      // Si el fetch da error se llama a la DB como fallback
      utils.customRequest(
        self,
        getFoodSalonCallback,
        "plato.plato.get_food_salon",
        "POST",
        {
          docname: docname,
        },
        null
        // getFoodSalonCallbackErr
      );
    });
}

async function getFoodDelivery(self, docname) {
  var getFoodDeliveryCallback = function(e) {
    var listFoodDeliveryItems = [];

    e.message.data.list_doc_platos_delivery.forEach(function(item, index) {
      // e.forEach(function(item, index) {
      if (
        item.data.length > 0 &&
        item.name_category !== "Sin Categoría" &&
        item.habilitado
      ) {
        listFoodDeliveryItems.push(item);
      }

      if (e.message.data.list_doc_platos_delivery.length === index + 1) {
        // if (e.length === index + 1) {
        self.$root.listFoodDelivery = listFoodDeliveryItems;

        if (self.$root.listFoodType === "Delivery") {
          self.$root.listFoodSelected = self.$root.listFoodDelivery;
        }
      }
    });
  };

  // var getFoodDeliveryCallbackErr = function(e) {
  //   self.props.loading = false;
  //   self.props.loadingFood = false;
  //   self.$root.changeMap = false;
  // };

  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var myInit = {
    method: "GET",
    headers: myHeaders,
  };

  fetch(`/cartas/${docname}_secundaria.json`, myInit)
    .then((response) => response.json())
    .then((data) => {
      var parsedData = {
        message: {
          data: {
            list_doc_platos_delivery: data,
          },
        },
      };
      getFoodDeliveryCallback(parsedData);
    })
    .catch((err) => {
      // Si el fetch da error se llama a la DB como fallback
      utils.customRequest(
        self,
        getFoodDeliveryCallback,
        "plato.plato.get_food_delivery",
        "POST",
        {
          docname: docname,
        },
        null
        // getFoodDeliveryCallbackErr
      );
    });
}

const formatOpeningHours = (self) => {
  let formattedOpeningHours = {};

  self.$root.docLocal.horarios_de_local.forEach((item, index) => {
    if (item.dia in formattedOpeningHours) {
      formattedOpeningHours[item.dia].push(
        `De ${formatTime(item.horario_apertura)} a ${formatTime(
          item.horario_cierre
        )}`
      );
    } else {
      formattedOpeningHours[item.dia] = [
        `De ${formatTime(item.horario_apertura)} a ${formatTime(
          item.horario_cierre
        )}`,
      ];
    }

    if (self.$root.docLocal.horarios_de_local.length === index + 1) {
      self.props.formattedOpeningHours = formattedOpeningHours;
    }
  });
};

const formatTime = (time) => {
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcNow = utcToZonedTime(new Date(), timeZone);
  let parsedDate = format(parse(time, "HH:mm:ss", utcNow), "H:mm", {
    locale: es,
  });

  return parsedDate;
};

var refresh = function(self, event) {
  if (JSON.parse(self.$root.ls.getItem("online")) === false) {
    // var data = JSON.parse(self.$root.ls.getItem("offlinePoints"));
    self.data = data.message.data;
    var virtualListLocals = self.$f7.virtualList.get(".virtual-list-food");
    virtualListLocals.replaceAllItems(data.message.data);

    var ptr = self.$$(".ptr-content")[1];
    self.$f7.ptr.done(ptr);
  } else {
    self.$f7router.refreshPage();
  }
};

var renderExternal = function(self, vl, vlData) {
  self.props.vlData = vlData;
};

const shrinkHeader = (self) => {
  let page = self.$$(".page-content");
  let localPage = self.$$('.page[data-name="local-page"]>.page-content');

  page.on("scroll", () => {
    // console.log(localPage.scrollTop()) // will show page top position
    if (localPage.scrollTop() > 200) {
      self.$$("#logo-pagina-principal").css("display", "none");
      self.$$("#local-navbar").addClass("local-navbar-shrinked");
      self.$$("#logo-shrinked").css("display", "unset");
      self.$$("#local-name-shrinked").css("display", "unset");
      self.$$("#local-navbar-text").css("display", "none");
      self.$$(".local-toolbar").css("background-color", "#ffffff");
      self.$$(".local-toolbar").css("box-shadow", "0px 0px 25px -10px #222222");
      self
        .$$("#icon-local-menu")
        .attr("fill", "#222222")
        .css("filter", "drop-shadow(0px 0px 0px #fff)");
      self
        .$$("#icon-arrow-back")
        .attr("fill", "#222222")
        .css("filter", "drop-shadow(0px 0px 0px #fff)");
      self.$$("#local-navbar").removeClass("local-navbar-normal");
      // self.$$(".topbar-google-translate").hide();
    } else if (localPage.scrollTop() < 100) {
      self.$$("#logo-pagina-principal").css("display", "initial");
      self.$$("#logo-shrinked").css("display", "none");
      self.$$("#local-navbar").addClass("local-navbar-normal");
      self.$$("#local-navbar").removeClass("local-navbar-shrinked");
      self.$$("#local-navbar-text").css("display", "inherit");
      self.$$(".local-toolbar").css("background-color", "transparent");
      self.$$(".local-toolbar").css("box-shadow", "none");
      self
        .$$("#icon-local-menu")
        .attr("fill", "#ffffff")
        .css("filter", "drop-shadow(0px 0px 2px #222)");
      self
        .$$("#icon-arrow-back")
        .attr("fill", "#ffffff")
        .css("filter", "drop-shadow(0px 0px 2px #222)");
      self.$$("#local-name-shrinked").css("display", "none");
      // self.$$(".topbar-google-translate").show();
    }
  });
};

const nativeShare = (self) => {
  const local = self.$root.local;
  const localCap =
    self.$root.local.charAt(0).toUpperCase() + self.$root.local.slice(1);
  let textoCompartir = `Encontrá todo lo que tiene para ofrecerte ${localCap} 👉`;

  if (self.$root.docLocal.texto_compartir) {
    textoCompartir =
      self.$root.docLocal.texto_compartir.replace(
        "{{nombre_local}}",
        localCap
      ) + " 👉";
  }

  if (navigator.share) {
    navigator
      .share({
        title: `${localCap}`,
        text: textoCompartir,
        url: `https://${self.props.docLocal.subdominio}.${self.$root.domainHostName}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    self.props.shareSheetOpened = true;
  }
};

const createPromptDialogLocal = (self) => {
  let lsFoodLocal = window.localStorage.getItem("food_local");
  let lsCartConfirmed = JSON.parse(
    window.localStorage.getItem("confirmed_cart")
  );
  if (
    self.props.docLocal &&
    lsFoodLocal &&
    self.props.docLocal.nombre_local !== lsFoodLocal &&
    Object.keys(lsCartConfirmed).length > 0
  ) {
    self.cambioDeCartaPromptLocal = self.$f7.dialog
      .create({
        cssClass: "elimina-carrito-dialog",
        title: "",
        text: `Tiene un pedido sin finalizar en la carta del local <strong>${lsFoodLocal}</strong>. Si cambia de local el pedido se eliminará<br /><br />¿Desea Continuar?`,
        buttons: [
          {
            text: "No, Volver",
            cssClass: "elimina-carrito-cancel-btn-dialog",
            onClick: function(e, dialog) {
              self.cambioDeCartaPromptLocal.close();
              self.navigateTo("/", true);
            },
          },
          {
            text: "Si, Eliminar",
            cssClass: "elimina-carrito-ok-btn-dialog",
            onClick: function(e, dialog) {
              window.localStorage.setItem("drafted_cart", JSON.stringify({}));
              window.localStorage.setItem("cart", JSON.stringify({}));
              window.localStorage.setItem("confirmed_cart", JSON.stringify({}));
              self.$root.grandTotalCart = 0.0;
              self.$root.updateCart = false;
              self.$root.confirmedCart = [];
              self.$root.draftedCart = [];
              window.localStorage.setItem("food_local", "");
              window.localStorage.setItem("food_type", "");

              self.cambioDeCartaPromptLocal.close();
            },
          },
        ],
        destroyOnClose: true,
      })
      .open();
  }
};

let openSelectionCartaSheet = (self) => {
  if (
    self.$root.docLocal &&
    self.$root.docLocal.cartas &&
    self.$root.docLocal.cartas.length > 1
  ) {
    self.$root.listFoodType = "";
    self.props.SelectionMenuSheetOpened = true;
    // self.$root.listFoodType = window.localStorage.removeItem("food_type");
  }
};

let expandDescription = (self, e, subitemName) => {
  let descripcionElement = self.$$(`[data-name="${subitemName}"]`);
  let toggleElementExpand = self.$$(`[data-expand="${subitemName}"]`);
  let toggleElementCollapse = self.$$(`[data-collapse="${subitemName}"]`);

  descripcionElement.css("height", "auto");
  toggleElementExpand.hide();
  toggleElementCollapse.show();
};

let collapseDescription = (self, e, subitemName) => {
  let descripcionElement = self.$$(`[data-name="${subitemName}"]`);
  let toggleElementExpand = self.$$(`[data-expand="${subitemName}"]`);
  let toggleElementCollapse = self.$$(`[data-collapse="${subitemName}"]`);

  descripcionElement.css("height", "100px");
  toggleElementExpand.show();
  toggleElementCollapse.hide();
};

let openFoodImage = (self, name_food, food_description, image_url) => {
  self.props.currentFoodName = name_food;
  self.props.currentFoodDescription = food_description;
  self.props.currentFoodImage = image_url;
  self.props.localImageFoodSheetOpened = true;

  // var midPopup = ((self.$el.clientHeight - self.$el.clientWidth) / 2);
  self
    .$$(".local-image-food-sheet")
    // .css("transform", `translate(0, -50%)`)
    .css("top", `25%`)
    .css("border-radius", "20px");
};

let scrollToAccordion = (index) => {
  if (index > 0) {
    document
      .querySelector(`#accordionIx${index - 1}`)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

let shopFood = (self, subitem) => {
  let lsCartConfirmed = window.localStorage.getItem("confirmed_cart");
  let lsCart = window.localStorage.getItem("cart");
  let lsCartDraft = window.localStorage.getItem("draft_cart");

  self.$root.currentFoodCartState = "cart";
  self.$root.docCurrentFood = subitem;
  // self.$root.listFoodType = self.$root.listFoodType;
  self.$root.listFoodType = window.localStorage.getItem("food_type");

  // VALORES INICIALES PARA EL STORE DEL PLATO EN EL CARRITO
  let foodToAdd = {
    nombre_plato: subitem.nombre_plato,
    name: subitem.name,
    nombre_categoria_de_comida: subitem.nombre_categoria_de_comida,
    descripcion: subitem.descripcion,
    descripcion_delivery: subitem.descripcion_delivery,
    cantidad: 0,
    precio: subitem.precio,
    precio_rebajado: subitem.precio_rebajado,
    precio_delivery: subitem.precio_delivery,
    precio_rebajado_delivery: subitem.precio_rebajado_delivery,
    atributos_del_plato: subitem.atributos_del_plato,
    atributos_del_plato_delivery: subitem.atributos_del_plato_delivery,
    imagen_principal: subitem.imagen_principal,
    adicionales: [],
  };

  if (!window.localStorage.getItem("cart")) {
    // EL CARRITO NO SE HA CREADO
    window.localStorage.setItem(
      "cart",
      JSON.stringify({ [subitem.name]: foodToAdd })
    );
  } else {
    // HAY UN CARRITO CREADO
    let lsFoodCart = JSON.parse(window.localStorage.getItem("cart"));
    lsFoodCart[subitem.name] = foodToAdd;
    window.localStorage.setItem("cart", JSON.stringify(lsFoodCart));
  }

  if (!lsCartConfirmed) {
    // CREA UN CARRITO CONFIRMADO
    window.localStorage.setItem("confirmed_cart", JSON.stringify({}));
  }

  if (!lsCartDraft) {
    // CREA UN CARRITO BORRADOR
    window.localStorage.setItem("draft_cart", JSON.stringify({}));
  }
  self.$f7router.navigate("/shop-attributes/");
};

let cleanLocalStorage = (self) => {
  // local.cleanLocalStorage(this);
  window.localStorage.setItem("cart", "{}");
  window.localStorage.setItem("draft_cart", "{}");
  window.localStorage.setItem("confirmed_cart", "{}");
};

let getCoupons = (self) => {
  // Se usa para mostrar/ocultar la sección de Cupones del Carrito
  let getCouponsCallback = function(r) {
    let coupons = r.message.data || [];
    let bannerLocalesSS = window.sessionStorage.getItem("banner_locales");
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcNow = utcToZonedTime(new Date(), timeZone);

    for (let i = 0; i < coupons.length; i++) {
      if (
        isAfter(utcNow, parseISO(coupons[i].fecha_inicio_descuento)) &&
        isBefore(utcNow, parseISO(coupons[i].fecha_fin_descuento))
      ) {
        let cuponObj = {};
        self.$root.cuponesLocal[coupons[i].codigo_cupon] = coupons[i];
      }
    }
  };

  utils.customRequest(
    self,
    getCouponsCallback,
    "cupon.cupon.get_coupons",
    "POST",
    {
      local: self.$root.docLocal.name,
    },
    null
    // callbackErr
  );
};

let checkBloqueoPedidos = (self) => {
  if (self.$root.estadoHorarioLocal === "Cerrado") {
    if (self.$root.docLocal.pedidos_fuera_horario == "No Hacer Nada") {
      self.$root.bloqueoPedidos = false;
    } else if (
      self.$root.docLocal.pedidos_fuera_horario == "Informar y Bloquear Pedidos"
    ) {
      self.$root.bloqueoPedidos = true;
      showPopupLocalCerrado(self);
    } else if (
      self.$root.docLocal.pedidos_fuera_horario == "Informar y Permitir Pedidos"
    ) {
      self.$root.bloqueoPedidos = false;
      showPopupLocalCerrado(self);
    } else {
      self.$root.bloqueoPedidos = false;
    }
  } else {
    self.$root.bloqueoPedidos = false;
  }
};

let showPopupLocalCerrado = (self) => {
  if (!self.$root.rememberPopupLocalCerradoClosed) {
    self.props.localPopupLocalCerradoOpened = true;
  }
};

export default {
  getLocalByDocname,
  getFood,
  getFoodSalon,
  getFoodDelivery,
  refresh,
  renderExternal,
  shrinkHeader,
  nativeShare,
  capitalizeFLetter,
  checkIsLocalIsOpen,
  checkBannerLocal,
  formatOpeningHours,
  checkOpeningHours,
  formatTime,
  createPromptDialogLocal,
  openSelectionCartaSheet,
  expandDescription,
  collapseDescription,
  openFoodImage,
  scrollToAccordion,
  shopFood,
  cleanLocalStorage,
  getCoupons,
  getOngoingOrders,
};
