// Import Vue
import Vue from "vue";

// Import Framework7
// import Framework7 from "framework7/framework7-lite.esm.bundle.js";
import Framework7 from "framework7/framework7-lite.esm.js";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.css";
import "../css/fontawesome_5_13_3/css/all.css";

// Import App Component
import App from "../components/app.vue";
// import "leaflet-routing-machine";

import Panel from "framework7/components/panel/panel.js";
import Searchbar from "framework7/components/searchbar/searchbar.js";
import VirtualList from "framework7/components/virtual-list/virtual-list.js";
import ListIndex from "framework7/components/list-index/list-index.js";
import List from "framework7/components/virtual-list/virtual-list.js";
import Sheet from "framework7/components/sheet/sheet.js";
import Swiper from "framework7/components/swiper/swiper.js";
import Dialog from "framework7/components/dialog/dialog.js";
import Accordion from "framework7/components/accordion/accordion.js";
import Stepper from "framework7/components/stepper/stepper.js";
import Preloader from "framework7/components/preloader/preloader.js";
import Progressbar from "framework7/components/progressbar/progressbar.js";
import Toast from "framework7/components/toast/toast.js";
import Gauge from "framework7/components/gauge/gauge.js";
import Popover from "framework7/components/popover/popover.js";
import Form from "framework7/components/form/form.js";
import Input from "framework7/components/input/input.js";
import Lazy from "framework7/components/lazy/lazy.js";

// Init Framework7-Vue Plugin
Framework7.use([
  Framework7Vue,
  Panel,
  Searchbar,
  VirtualList,
  ListIndex,
  Sheet,
  Swiper,
  Dialog,
  Accordion,
  Stepper,
  Preloader,
  Progressbar,
  Toast,
  Gauge,
  Popover,
  Form,
  Input,
  Lazy,
]);

// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Init App
new Vue({
  el: "#app",
  render: (h) => h(App),
  data() {
    return {
      ls: window.localStorage,
      appType: undefined,
      ciudad: undefined,
      subdominioCiudad: undefined,
      locals: [],
      docLocal: undefined,
      estadoHorarioLocal: "",
      bloqueoPedidos: false,
      rememberPopupLocalCerradoClosed: false,
      icono_ciudad: undefined,
      local: undefined,
      entryPoint: "ciudad",
      centerLat: 0.0,
      centerLng: 0.0,
      mapInitalized: false,
      standaloneApp: false,
      showOpeningHours: false,
      localFromMapSwiper: false,
      showMenu: true,
      loading: false,
      changeMap: false,
      notLoad: false,
      docCurrentFood: undefined,
      listCustomFields: [],
      customFieldsTypes: {
        Texto: "text",
        Número: "number",
        Fecha: "date",
        "Fecha y Hora": "datetime-local",
        "E-mail": "email",
      },
      listFoodStore: false,
      menuEnabled: false,
      metodoEnvio: "",
      tipoMetodoEnvio: "",
      nombreRetira: "",
      horaRetiraSelector: "text",
      horaRetira: "Lo Antes Posible",
      nombreRecibe: "",
      gsoogleMapsSessionToken: "",
      direccionRecibe: "",
      direccionAdicionalRecibe: "",
      direccionEntrecalles: "",
      direccionGuardadaIndex: undefined,
      mesa: "",
      nombreMesa: "",
      cuponesLocal: {},
      cuponDescuento: "",
      valorCuponDescuentoTotal: 0.0,
      totalCart: 0.0,
      grandTotalCart: 0.0,
      grandSubtotalCart: 0.0,
      costoEnvio: 0.0,
      tipoRecargoPago: "Importe Fijo",
      metodoPago: "",
      tipoMetodoPago: "",
      efectivoPagaCon: "",
      recargoPago: 0.0,
      listFoodSalon: [],
      listFoodDelivery: [],
      listFoodSelected: [],
      loadingFood: true,
      listFoodType: "",
      listFoodTypeIndex: 0,
      listFoodName: "",
      showShippingMapTotals: false,
      shippingMapTotals: 0.0,
      confirmedShippingMapTotals: false,
      shippingMapAddress: "",
      shippingMapLatLng: {},
      confirmedCart: [],
      draftedCart: [],
      updateCart: false,
      currentFoodCartState: "",
      customFieldOtrosDatos: false,
      maintenanceMode: false,
      ongoingOrder: {},
      ongoingOrderProgress: 0,
      setIntervalUpdateOrder: "",
      ongoingOrderStatuses: {
        Nuevo: { progress: 20, text: "Esperando confirmación del negocio" },
        Aceptado: { progress: 40, text: "Aceptado" },
        "En Preparación": { progress: 60, text: "En Preparación" },
        Enviado: { progress: 80, text: "Tu pedido está en camino" },
        Entregado: { progress: 100, text: "Entregado" },
        Anulado: { progress: 100, text: "Anulado" },
        Rechazado: { progress: 100, text: "Rechazado" },
        "Cierre Automático": { progress: 100, text: "Finalizado" },
      },
      cartOngoingOrderSheetOpened: false,
      scheme: `https://`,
      // serverHostBase: "staging-admin.alacarta.online",
      // serverHostBase: "dev.alacarta.binar.io",
      serverHostBase: "admin.alacarta.online",
      // domainHostName: "alacarta.binar.io",
      domainHostName: "alacarta.online",
      host: `/api/method/alacarta.alacarta.doctype.`,
    };
  },
  watch: {
    listFoodSelected: {
      handler(val) {
        const self = this;
        if (val.length > 0) {
          self.loadingFood = false;
        }
      },
      deep: true,
    },
  },
  // Register App Component
  components: {
    app: App,
  },
});

// Sentry.init({
//   Vue,
//   dsn:
//     "https://8081edd75f9541cf9c424df57c2113f5@o68926.ingest.sentry.io/5903043",
//   integrations: [
//     new Integrations.BrowserTracing({
//       // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
