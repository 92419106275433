<template id="cart-checkou-popup">
  <f7-sheet
    class="final-checkout-sheet"
    backdrop
    :swipeToClose="false"
    :closeByBackdropClick="false"
    :closeByOutsideClick="false"
    :opened="props.cartCheckoutPopupOpened"
    @sheet:opened="onCartCheckoutPopupOpened"
    @sheet:closed="props.cartCheckoutPopupOpened = false"
  >
    <f7-page-content class="padding-bottom">
      <span>
        <div
          class="display-flex align-items-center flex-direction-column"
          style="border-radius: 20px !important"
        >
          <video
            src="/static/videos/pedido-aprobado-check.mp4"
            autoplay
            muted
            playsinline
            style="
              width: 80%;
              margin: 0 auto;
              border-radius: 20px !important;
              margin-top: 10px;
            "
          ></video>

          <!-- Con Panel + Sin WhatsApp -->
          <h3
            v-if="
              $root.docLocal.panel_pedidos && !$root.docLocal.pedidos_whatsapp
            "
            class="
              margin-bottom-half
              no-margin-top
              padding-horizontal
              text-align-center
            "
            style="font-size: 18px"
          >
            ¡Tu pedido ha sido enviado! 🎉
          </h3>
          <p
            v-if="
              $root.docLocal.panel_pedidos && !$root.docLocal.pedidos_whatsapp
            "
            class="
              no-margin-vertical
              padding-horizontal
              text-align-center
              margin-bottom
            "
          >
            Podrás ver su estado en tiempo real.
          </p>

          <!-- Sin Panel + Con WhatsApp -->
          <h3
            v-if="
              $root.docLocal.pedidos_whatsapp && !$root.docLocal.panel_pedidos
            "
            class="
              margin-bottom-half
              no-margin-top
              padding-horizontal
              text-align-center
            "
            style="font-size: 18px"
          >
            ¡Gracias por tu compra! 🎉
          </h3>
          <p
            v-if="
              $root.docLocal.pedidos_whatsapp && !$root.docLocal.panel_pedidos
            "
            class="
              no-margin-vertical
              padding-horizontal
              text-align-center
              margin-bottom
            "
          >
            Te estamos redirigiendo a WhatsApp
          </p>

          <!-- Con Panel + Con WhatsApp -->
          <h3
            v-if="
              $root.docLocal.panel_pedidos && $root.docLocal.pedidos_whatsapp
            "
            class="
              margin-bottom-half
              no-margin-top
              padding-horizontal
              text-align-center
            "
            style="font-size: 18px"
          >
            ¡Tu pedido está en proceso!
          </h3>
          <p
            v-if="
              $root.docLocal.pedidos_whatsapp && $root.docLocal.panel_pedidos
            "
            class="
              no-margin-vertical
              padding-horizontal
              text-align-center
              margin-top
              margin-bottom-half
            "
          >
            <!-- Te estamos redirigiendo a WhatsApp. Luego podrás ver su estado en
            tiempo real. -->
            Enviar confirmación vía WhatsApp
          </p>
          <f7-row
            v-if="
              $root.docLocal.pedidos_whatsapp &&
                $root.docLocal.panel_pedidos &&
                !props.cartCheckoutMPLinkReady
            "
            class="margin-horizontal"
            style="min-width: 80%"
          >
            <f7-col>
              <span class="badge badge-hacer-pedido-disabled">
                <span
                  ><i class="fas fa-spinner fa-spin" style="position: relative"></i
                  >&nbsp;&nbsp;</span
                ><span>AGUARDE</span>
              </span>
            </f7-col>
          </f7-row>
          <f7-row
            v-if="
              $root.docLocal.pedidos_whatsapp &&
                $root.docLocal.panel_pedidos &&
                props.cartCheckoutMPLinkReady
            "
            class="margin-horizontal"
            style="min-width: 80%"
          >
            <f7-col @click="sendWhatsAppOrder">
              <span class="badge badge-hacer-pedido">
                <span
                  ><i class="fab fa-whatsapp" style="position: relative"></i
                  >&nbsp;&nbsp;</span
                ><span>ENVIAR</span>
              </span>
            </f7-col>
          </f7-row>
        </div>
      </span>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import cart from "../../cart";

export default {
  props: {
    props: Object,
  },
  mounted: function() {
    const self = this;
    document.querySelector("video").playbackRate = 0.5;
  },
  methods: {
    onCartCheckoutPopupOpened() {
      const self = this;
      // setTimeout(() => {
      //   self.props.cartCheckoutPopupOpened = false;
      //   localStorage.setItem("ongoing_order_tracking_sheet_first_time", "true");
      // }, 2800);
    },
    sendWhatsAppOrder() {
      const self = this;

      cart.clearAllAfterOrder(self).then((r) => {
        self.props.cartCheckoutPopupOpened = false;
        self.props.cartCheckoutMPLinkReady = false;
        window.location.replace(
          `https://api.whatsapp.com/send?phone=${self.props.whatsAppNumber}&text=${self.props.lsCartToWhatsApp}`
        );
        // self.$root.cartOngoingOrderSheetOpened = true;
      });
    },
  },
};
</script>
